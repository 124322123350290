<template>
  <!-- 
    We place the entire resources menu below the pinned header. 
    So we wrap it in a top-level <div>, then a .container absolutely positioned. 
  -->
  <div>
    <div class="container">
      <h1>Get More Resources Below</h1>

      <div class="resource-box" @click="goToYourText">
        Get Help With Everything
      </div>
      <div class="resource-box" @click="goToBooks">
        Books
      </div>
      <div class="resource-box" @click="goToArticles">
        Articles
      </div>
      <div class="resource-box" @click="goToNews">
        News
      </div>
      <div class="resource-box" @click="goToResearch">
        Research
      </div>
      <div class="resource-box" @click="goToGames">
        Games
      </div>
      <div class="resource-box" @click="goToVideos">
        Videos
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ResourcesPage',
  methods: {
    goToYourText() {
      this.$router.push({ name: 'your-text' });
    },
    goToBooks() {
      this.$router.push({ name: 'books' });
    },
    goToArticles() {
      this.$router.push({ name: 'articles' });
    },
    goToNews() {
      this.$router.push({
        name: 'choose-action',
        params: { topicType: 'news', topicId: 0, topicName: 'News' },
      });
    },
    goToResearch() {
      this.$router.push({ name: 'research' });
    },
    goToGames() {
      this.$router.push({ name: 'games' });
    },
    goToVideos() {
      this.$router.push({ name: 'videos' });
    },
  },
};
</script>

<style>
/* ================= GLOBAL SCROLLBAR STYLES ================= */
::-webkit-scrollbar {
  width: 10px;
  background-color: #f0f0f0;
}
::-webkit-scrollbar-track {
  background-color: #e6e6e6;
}
::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
  border: 2px solid #e6e6e6;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
html {
  scrollbar-width: thin;
  scrollbar-color: #888 #e6e6e6;
}
/* =========================================================== */
</style>

<style scoped>
.container {
  position: absolute;
  top: 80px; 
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 20px;
}

h1 {
  font-size: 1.8rem;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.resource-box {
  background: linear-gradient(135deg, #444444, #2c2c2c);
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 20px;
  cursor: pointer;
  width: 100%;
  max-width: 200px;
  margin: 20px 0;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
  transition: transform 0.3s, box-shadow 0.3s, background-color 0.3s;
}

.resource-box:hover {
  background-color: #555;
  transform: translateY(-3px);
  box-shadow: 0 8px 20px rgba(0,0,0,0.2);
}
</style>
