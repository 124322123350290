<template>
  <div>
    <Header
      :showBackButton="true"
      backText="Get More Resources"
      @back="goToResources"
    />

    <!-- container is scroller -->
    <div class="container" ref="container">
      <h1>Videos</h1>

      <!-- Search field -->
      <input
        type="text"
        v-model="searchQuery"
        placeholder="Search for videos..."
        class="search-input"
        @input="saveSearchQuery"
      />

      <!-- Genre Buttons -->
      <div class="genres-container">
        <button
          v-for="genre in genres"
          :key="genre"
          @click="filterByGenre(genre)"
          :class="['genre-button', { 'active-genre': selectedGenre === genre }]"
        >
          {{ genre }}
        </button>
      </div>

      <p>Click on a thumbnail to watch the video</p>

      <div class="videos-grid">
        <div
          v-for="(video, index) in filteredVideos"
          :key="index"
          class="video-item"
          @click="openVideo(video.id)"
        >
          <img
            :src="`https://img.youtube.com/vi/${video.id}/0.jpg`"
            :alt="video.title"
            class="video-thumbnail"
          />
          <span>{{ video.title }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";

export default {
  name: "VideosPage",
  components: {
    Header,
  },
  data() {
    return {
      videos: [
        {
          id: "vL9PEoqA2yA",
          title: "Beating Enderdragon With My Bro in Minecraft",
          genre: "Gaming"
        },
        {
          id: "09RJjBW1SCs",
          title: "Building Dream Cabin With My Bro in Minecraft",
          genre: "Gaming"
        },
        {
          id: "DkcQE3C2em8",
          title: "Playing Fortnite Reload",
          genre: "Gaming"
        },
        {
          id: "-Nf9UoUtZbQ",
          title: "The Entire Chapter 1 of Red Dead Redemption 2",
          genre: "Gaming"
        },
        {
          id: "2iCWh68DiHI",
          title: "Mein Kampf by Adolf Hitler #1",
          genre: "Books"
        },
        {
          id: "RmyuD1Q5VvI",
          title: "Mein Kampf by Adolf Hitler #2",
          genre: "Books"
        },
        {
          id: "TKvyojKzpBg",
          title: "The Communist Manifesto by Karl Marx and Friedrich Engels",
          genre: "Books"
        },
        {
          id: "wtPW3bKJKUk",
          title: "The Great Gatsby by F. Scott Fitzgerald",
          genre: "Books"
        },
        {
          id: "R7pOTKf8yTQ",
          title: "Pride and Prejudice by Jane Austen",
          genre: "Books"
        },
        {
          id: "TuSwFW5t1NE",
          title: "Trying To Hit Unreal In Reload Ranked!",
          genre: "Gaming"
        },
        {
          id: "7iCJ840fg9M",
          title: "Romeo and Juliet by William Shakespeare",
          genre: "Books"
        },
        {
          id: "vSLOnq74FBA",
          title: "Playing Astro Playroom",
          genre: "Gaming"
        },
        {
          id: "bexsb2OAGNg",
          title: "Playing Fortnite Ranked Until I Win!",
          genre: "Gaming"
        },
        {
          id: "rrVgwZsKBGM",
          title: "The Entire Chapter 2 of Red Dead Redemption 2",
          genre: "Gaming"
        },
        {
          id: "hqCoqyk0t9k",
          title: "Playing Supermarket Simulator #1",
          genre: "Gaming"
        },
        {
          id: "jIimlqaIIwQ",
          title: "The Entire Chapter 3 of Red Dead Redemption 2",
          genre: "Gaming"
        },
        {
          id: "c8gmwLrw-hU",
          title: "Sense and Sensibility by Jane Austen",
          genre: "Books"
        },
        {
          id: "q-hkIs62SLg",
          title: "The Art of War by Sun Tzu",
          genre: "Books"
        },
        {
          id: "7t4919C_ub0&t=5s",
          title: "The Entire Chapter 4 of Red Dead Redemption 2",
          genre: "Gaming"
        },
        {
          id: "kGWIG-icvmA",
          title: 'Supermarket Simulator #2',
          genre: "Gaming"
        },
        {
          id: "XdlijSzNfV4",
          title: "How to Analyze People on Sight by Elsie Lincoln Benedict and Ralph Paine Benedict",
          genre: "Books"
        },
        {
          id: "hE9HzzZV1Yk",
          title: "The Entire Chapter 5 of Red Dead Redemption 2",
          genre: "Gaming"
        },
        {
          id: "n_laJY98seM",
          title: "Sims 4 #1",
          genre: "Gaming"
        },
        {
          id: "d1kGLsWOjK8",
          title: "Grimm's Fairy Tales by The Brothers Grimm",
          genre: "Books"
        },
        {
          id: "4lxTem2TlIg",
          title: "\"You´re a High School Mean Girl!\" Reacting to Candace Owens vs Mikhaila Peterson on Piers Morgan",
          genre: "Reacting"
        },
        {
          id: "c-7-WVlQUbw",
          title: "Emma by Jane Austen",
          genre: "Books"
        },
        {
          id: "QUwn7ZhLv4A",
          title: "The Entire Chapter 6 of Red Dead Redemption 2",
          genre: "Gaming"
        },
        {
          id: "G15witTPAwQ",
          title: "Persuasion by Jane Austen",
          genre: "Books"
        },
        {
          id: "d4IOHSmSl14",
          title: "\"These Are Not Real Americans!\" Reacting to Ben Shapiro x Charlie Kirk (with loud breathing)",
          genre: "Reacting"
        },
        {
          id: "EZ20uGieAmo",
          title: 'Stranded Deep #1',
          genre: "Gaming"
        },
        {
          id: "EG3Defeyjjo",
          title: 'The Entire Epilogue of Red Dead Redemption 2',
          genre: "Gaming"
        },
        {
          id: "xWlD0H6Eiw8",
          title: 'Northanger Abbey by Jane Austen',
          genre: "Books"
        },
        {
          id: "kNxZek5NOQQ",
          title: 'Ben Shapiro is RIGHT About Andrew Tate',
          genre: "Reacting"
        },
        {
          id: "NSZ0woOBXEA",
          title: 'Marvel Sucks',
          genre: "Reacting"
        },
        {
          id: "hVWG3oluFSs",
          title: 'Sims 4 #2 (while listening to Lex Fridman)',
          genre: "Gaming"
        },
        {
          id: "wWLoMN6fIBU",
          title: 'Supermarket Simulator #3 (while listening to Lex Fridman)',
          genre: "Gaming"
        },
        {
          id: "iSCb27sIxUI",
          title: 'The Adventures of Tom Sawyer by Mark Twain',
          genre: "Books"
        },
        {
          id: "DTNpV56qLLE",
          title: 'Conflicted on Canada´s Next Prime Minister | Reacting to Jordan Peterson and Pierre Poilievre',
          genre: "Reacting"
        },
        {
          id: "Hp-r0znBmx4",
          title: 'Supermarket Simulator #4 (while listening to Destiny and Ben Wikler)',
          genre: "Gaming"
        },
        {
          id: "hTMwHGxJFfY",
          title: '"Has Elon Turned EVIL?" Reacting to Elon Musk/Sam Harris Drama',
          genre: "Reacting"
        },
        {
          id: "n9fru_X7Jn4",
          title: 'The Further Adventures of Robinson Crusoe by Daniel Defoe',
          genre: "Books"
        },
        {
          id: "oTCZSTsuukA",
          title: 'Relativity: The Special and General Theory by Albert Einstein',
          genre: "Books"
        },
      ],
      searchQuery: "",
      genres: [
        "All",
        "Gaming",
        "Books",
        "Reacting"
      ],
      selectedGenre: "All",
    };
  },
  computed: {
    filteredVideos() {
      const query = this.searchQuery.toLowerCase();
      return this.videos
        .slice()
        .reverse()
        .filter((video) => {
          const matchesQuery = video.title.toLowerCase().includes(query);
          const videoGenres = video.genre ? video.genre.split(", ") : [];
          const matchesGenre =
            this.selectedGenre === "All" || videoGenres.includes(this.selectedGenre);
          return matchesQuery && matchesGenre;
        });
    },
  },
  methods: {
    goToResources() {
      this.$router.push({ name: "resources" });
    },
    openVideo(videoId) {
      window.open(`https://www.youtube.com/watch?v=${videoId}`, "_blank");
    },
    filterByGenre(genre) {
      this.selectedGenre = genre;
      localStorage.setItem('selectedGenre_VideosPage', genre);
    },
    saveSearchQuery() {
      localStorage.setItem('videoSearchQuery', this.searchQuery);
    },
    getSearchQuery() {
      return localStorage.getItem('videoSearchQuery') || '';
    },
    saveScrollPosition() {
      if (this.$refs.container) {
        localStorage.setItem('scrollPosition_VideosPage', this.$refs.container.scrollTop);
      }
    },
    restoreScrollPosition() {
      const pos = localStorage.getItem('scrollPosition_VideosPage');
      this.$nextTick(() => {
        if (this.$refs.container && pos) {
          this.$refs.container.scrollTop = parseInt(pos, 10);
        }
      });
    },
  },
  mounted() {
    // restore search + selected genre
    this.searchQuery = this.getSearchQuery();
    const savedGenre = localStorage.getItem('selectedGenre_VideosPage');
    if (savedGenre) {
      this.selectedGenre = savedGenre;
    }

    // restore scroll
    this.restoreScrollPosition();

    // Listen for scroll
    if (this.$refs.container) {
      this.$refs.container.addEventListener('scroll', this.saveScrollPosition);
    }
    // Also pagehide for tab close
    window.addEventListener('pagehide', this.saveScrollPosition);
  },
  beforeDestroy() {
    if (this.$refs.container) {
      this.$refs.container.removeEventListener('scroll', this.saveScrollPosition);
    }
    window.removeEventListener('pagehide', this.saveScrollPosition);
  },
};
</script>

<style>
/* ================= GLOBAL SCROLLBAR STYLES ================= */
::-webkit-scrollbar {
  width: 10px;
  background-color: #f0f0f0;
}
::-webkit-scrollbar-track {
  background-color: #e6e6e6;
}
::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
  border: 2px solid #e6e6e6;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
html {
  scrollbar-width: thin;
  scrollbar-color: #888 #e6e6e6;
}
/* =========================================================== */
</style>

<style scoped>
.container {
  position: absolute;
  top: 80px; 
  left: 0; 
  right: 0;
  bottom: 0;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 20px;
}

h1 {
  font-size: 1.8rem;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}
p {
  font-size: 1rem;
  color: #555;
  margin-bottom: 20px;
  text-align: center;
}

.search-input {
  width: 100%;
  max-width: 600px;
  padding: 10px;
  margin-bottom: 20px;
  box-sizing: border-box;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s ease;
}
.search-input:focus {
  outline: none;
  border-color: #333;
}

.genres-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
}
.genre-button {
  margin: 5px;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  background: linear-gradient(135deg, #444444, #2c2c2c);
  color: #ffffff;
  cursor: pointer;
  font-size: 0.95rem;
  transition: transform 0.3s, box-shadow 0.3s;
}
.genre-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

/* ===== New highlight style for the active genre ===== */
.active-genre {
  background: linear-gradient(135deg, #f39c12, #e74c3c) !important;
  transform: scale(1.1) !important;
  box-shadow: 0 8px 20px rgba(255, 130, 0, 0.4) !important;
  color: #fff !important;
  font-weight: 600;
}
/* =============================================== */

.videos-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.video-item {
  background: linear-gradient(135deg, #444444, #2c2c2c);
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 20px;
  cursor: pointer;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s, background-color 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.video-item:hover {
  background-color: #555;
  transform: translateY(-3px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.video-thumbnail {
  width: 100%;
  height: auto;
  border-radius: 4px;
  margin-bottom: 10px;
  max-width: 280px;
}

.video-item span {
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  margin-top: 5px;
  text-align: center;
}

@media (max-width: 800px) {
  .videos-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 500px) {
  .videos-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
