<template>
  <div class="mathgame-container">
    <h1 class="title">Math Game</h1>

    <p class="score-display">Score: {{ score }}</p>

    <div v-if="gameOver" class="game-over">
      <p>Game Over! Final Score: {{ score }}</p>
      <button class="action-button" @click="startGame">Play Again</button>
    </div>
    <div v-else class="math-content">
      <p class="problem">
        {{ displayProblem }}
      </p>
      <form @submit.prevent="submitAnswer" class="answer-form">
        <input
          type="number"
          v-model.number="userAnswer"
          class="answer-input"
          placeholder="Your answer..."
        />
        <button type="submit" class="action-button">Submit</button>
      </form>
      <p class="timer-text">Time left: {{ timer }}s</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "MathGame",
  data() {
    return {
      score: 0,
      gameOver: false,
      currentProblem: { num1: 0, num2: 0, op: "+" },
      userAnswer: null,
      timer: 20,      // 20 seconds
      timerInterval: null,
    };
  },
  computed: {
    displayProblem() {
      // if dividing => use ÷ for the symbol
      if (this.currentProblem.op === "/") {
        return `${this.currentProblem.num1} ÷ ${this.currentProblem.num2} = ?`;
      }
      return `${this.currentProblem.num1} ${this.currentProblem.op} ${this.currentProblem.num2} = ?`;
    },
  },
  methods: {
    startGame() {
      this.score = 0;
      this.gameOver = false;
      this.generateProblem();
      this.startTimer();
    },
    startTimer() {
      clearInterval(this.timerInterval);
      this.timer = 20;
      this.timerInterval = setInterval(() => {
        this.timer--;
        if (this.timer <= 0) {
          this.endGame();
        }
      }, 1000);
    },
    generateProblem() {
      // +, -, ×, / with bigger range => 1..50, ensuring integer division
      const ops = ["+", "-", "×", "/"];
      const op = ops[Math.floor(Math.random() * ops.length)];

      let num1 = Math.floor(Math.random() * 50) + 1;
      let num2 = Math.floor(Math.random() * 50) + 1;

      if (op === "/") {
        // integer division => pick smaller divisor (1..10), then pick a random multiple
        num2 = Math.floor(Math.random() * 10) + 1; 
        let multiple = Math.floor(Math.random() * 10) + 1; 
        num1 = num2 * multiple;
      }

      this.currentProblem = { num1, num2, op };
      this.userAnswer = null;
    },
    submitAnswer() {
      let correct;
      if (this.currentProblem.op === "+") {
        correct = this.currentProblem.num1 + this.currentProblem.num2;
      } else if (this.currentProblem.op === "-") {
        correct = this.currentProblem.num1 - this.currentProblem.num2;
      } else if (this.currentProblem.op === "×") {
        correct = this.currentProblem.num1 * this.currentProblem.num2;
      } else if (this.currentProblem.op === "/") {
        correct = this.currentProblem.num1 / this.currentProblem.num2;
      }

      if (Number(this.userAnswer) === correct) {
        this.score++;
        clearInterval(this.timerInterval);
        this.generateProblem();
        this.startTimer();
      } else {
        this.endGame();
      }
    },
    endGame() {
      this.gameOver = true;
      clearInterval(this.timerInterval);
    },
  },
  mounted() {
    this.startGame();
  },
  beforeUnmount() {
    clearInterval(this.timerInterval);
  },
};
</script>

<style scoped>
.mathgame-container {
  /* Full page minus header => center everything both ways, no boxes */
  margin-top: 60px;
  height: calc(100vh - 60px);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: #fefefe;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
  text-align: center;
  overflow: auto; 
  padding: 20px;
}

/* Title => bigger text */
.title {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333;
}

/* Score up top, bold */
.score-display {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #333;
  font-weight: bold;
}

/* If game over => show final result & replay button */
.game-over {
  margin: 20px 0;
  font-size: 1.2rem;
  color: #333;
}

/* The active problem => no child box, just center text */
.math-content {
  margin-bottom: 20px;
}

/* Show the problem in bigger text */
.problem {
  font-size: 1.4rem;
  margin: 20px 0;
  font-weight: bold;
}

/* The input form => horizontal alignment */
.answer-form {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.answer-input {
  width: 80px;
  margin-right: 10px;
  font-size: 1rem;
  padding: 5px;
  text-align: center;
}

/* Timer at the bottom in red */
.timer-text {
  font-size: 1rem;
  color: #f33;
  margin-top: 10px;
}

/* The main button style */
.action-button {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  margin-left: 5px;
  transition: background-color 0.3s, transform 0.3s;
}
.action-button:hover {
  background-color: #555;
  transform: translateY(-2px);
}

/* Mobile-friendly => smaller fonts, narrower input */
@media (max-width: 600px) {
  .title {
    font-size: 1.6rem;
  }
  .answer-input {
    width: 60px;
  }
  .problem {
    font-size: 1.2rem;
  }
}
</style>
