<template>
  <div id="app">
    <Header />
    <!-- Each page handles its own scroll area below the header -->
    <router-view />
  </div>
</template>

<script>
import Header from '@/components/Header.vue';

export default {
  name: 'App',
  components: {
    Header,
  },
};
</script>

<style>
/*
  We rely on main.css for global styling.
*/
</style>
