<template>
  <div>
    <!-- Header with back button -->
    <Header
      :showBackButton="true"
      backText="Back to Choose Action"
      @back="goBackToChooseAction"
    />

    <!-- 1) If there's an error, show it -->
    <p v-if="errorMessage" class="error-message">
      {{ errorMessage }}
    </p>

    <!-- 2) If book is still undefined => do not show anything (avoid flicker) -->
    <div v-else-if="book === undefined">
      <!-- Blank. You could display a spinner here if you wish, but it's optional. -->
    </div>

    <!-- 3) If book is explicitly null (404), show fallback message -->
    <div v-else-if="book === null" class="container">
      <div class="content">
        <h1 class="centered-title">No Book Found</h1>
        <p class="centered-content">
          A simpler version is not made yet. Contact
          <strong>resuvonia@gmail.com</strong> if you want a simpler version
          of this book as quick as possible.
        </p>
      </div>
    </div>

    <!-- 4) Otherwise, we have a real book object -->
    <div v-else>
      <!-- If user hasn't paid => show purchase form -->
      <div v-if="!paid" class="container">
        <div class="content">
          <h1 class="centered-title">{{ book.title }}</h1>

          <p class="book-description">
            This book is available in <strong>PDF</strong>, <strong>TXT</strong>, or
            <strong>DOCX</strong> format. Select your preferred format below,
            then click the button to purchase this book for
            <strong>\${{ book.price }}</strong>.
            Make sure to save your downloaded text/file so you don't have to pay again! The link will not work again.
          </p>

          <!-- Format selection -->
          <div class="format-selection">
            <p><strong>Select Format:</strong></p>
            <label>
              <input type="radio" value="pdf" v-model="selectedFormat" /> PDF
            </label>
            <label>
              <input type="radio" value="txt" v-model="selectedFormat" /> TXT
            </label>
            <label>
              <input type="radio" value="docx" v-model="selectedFormat" /> DOCX
            </label>
          </div>

          <!-- Purchase form -->
          <form @submit.prevent="purchaseBook" class="purchase-form">
            <label for="email">Email/Gmail (Required):</label>
            <input
              v-model="email"
              type="email"
              id="email"
              required
            />
            <button class="purchase-button" type="submit">
              Buy & Download with Stripe
            </button>
          </form>
        </div>
      </div>

      <!-- If user paid => show content or open PDF -->
      <div v-else>
        <!-- TXT => show text content -->
        <div v-if="selectedFormat === 'txt'" class="txt-container">
          <div class="txt-content">
            <h1 class="centered-title">{{ book.title }}</h1>
            <pre v-if="bookContent !== null" class="centered-content">
{{ bookContent.length === 0
  ? 'A simpler version is not made yet. Contact resuvonia@gmail.com if you want a simpler version of this book as quick as possible.'
  : bookContent
}}
            </pre>
            <p v-else>Loading TXT content...</p>
          </div>
        </div>

        <!-- PDF or DOCX => open in a new tab -->
        <div v-else-if="selectedFormat === 'pdf' || selectedFormat === 'docx'" class="container">
          <div class="content">
            <h1 class="centered-title">{{ book?.title }}</h1>
            <p v-if="downloadLink">
              Your file should have opened in a new tab or downloaded.
              If not, <a :href="downloadLink" target="_blank">click here</a>.
            </p>
            <p v-else>Preparing your file...</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from './Header.vue'
import axios from '@/axiosConfig.js' // baseURL pointing to your backend

export default {
  components: { Header },
  data() {
    return {
      book: undefined, // <-- 'undefined' means "still loading"
      email: '',
      errorMessage: '',
      selectedFormat: 'pdf',
      paid: false,
      bookContent: null,
      downloadLink: null,
      sessionId: null,
    }
  },
  async created() {
    console.log("[SimplerBookDetails] created. route params:", this.$route.params)
    console.log("[SimplerBookDetails] route query:", this.$route.query)

    const bookId = this.$route.params.bookId
    try {
      // 1) Attempt to load book details
      const response = await axios.get(`/api/stripe/book-details/${bookId}`)
      // If successful, we have an actual book object
      this.book = response.data
      console.log("[SimplerBookDetails] Book details loaded:", this.book)
    } catch (error) {
      console.error('[SimplerBookDetails] Failed to load book details:', error)
      // If it's 404 => no book found
      if (error.response && error.response.status === 404) {
        this.book = null // null => fallback message in template
      } else {
        this.errorMessage = 'Failed to load book details. Please try again.'
      }
    }

    // 2) If there's a Stripe session_id => verify
    if (this.$route.query.session_id) {
      this.sessionId = this.$route.query.session_id
      await this.verifySession(this.sessionId)
    }
  },
  methods: {
    async purchaseBook() {
      console.log("[SimplerBookDetails] purchaseBook => bookId:", this.book?.id, "format:", this.selectedFormat)
      try {
        // => POST /api/stripe/buy-book-checkout
        const response = await axios.post('/api/stripe/buy-book-checkout', {
          bookId: this.book.id,
          email: this.email,
          format: this.selectedFormat,
        })
        if (response.data && response.data.url) {
          console.log("[SimplerBookDetails] Stripe checkout url =>", response.data.url)
          window.location.href = response.data.url
        } else {
          this.errorMessage = 'Failed to initiate Stripe checkout.'
        }
      } catch (error) {
        console.error('[SimplerBookDetails] Error purchasing book:', error)
        this.errorMessage = 'Error purchasing the book. Please try again.'
      }
    },

    async verifySession(sessionId) {
      try {
        // => GET /api/stripe/verify-session?session_id=xxx
        const resp = await axios.get('/api/stripe/verify-session', {
          params: { session_id: sessionId }
        })
        console.log('[SimplerBookDetails] verifySession response:', resp.data)

        if (resp.data && resp.data.paid) {
          this.paid = true
          // Use the format from server metadata
          this.selectedFormat = resp.data.format
          // Fetch the actual content
          await this.fetchPaidContent(sessionId)
        } else {
          console.warn('[SimplerBookDetails] Session not paid or invalid.')
        }
      } catch (err) {
        console.error('[SimplerBookDetails] Error verifying session:', err)
        this.errorMessage = 'Could not verify payment. Please try again.'
      }
    },

    async fetchPaidContent(sessionId) {
      try {
        const bookId = this.$route.params.bookId
        const url = `/api/stripe/download-content/${bookId}?session_id=${sessionId}`
        console.log("[SimplerBookDetails] fetchPaidContent => GET", url)

        if (this.selectedFormat === 'txt') {
          // Text
          const resp = await axios.get(url, { responseType: 'text' })
          console.log("[SimplerBookDetails] .txt length =>", resp.data.length)
          this.bookContent = resp.data
        } else {
          // PDF or DOCX => blob
          const resp = await axios.get(url, { responseType: 'blob' })
          console.log("[SimplerBookDetails] .pdf/.docx =>", resp.data.size, 'bytes')
          const blob = new Blob([resp.data], {
            type: resp.headers['content-type'],
          })
          const fileURL = URL.createObjectURL(blob)
          // auto-open in a new tab
          window.open(fileURL, '_blank')
          this.downloadLink = fileURL
        }
      } catch (error) {
        console.error('[SimplerBookDetails] Error fetching paid content:', error)
        if (error.response && error.response.status === 403) {
          // means link was used or not verified
          this.errorMessage = error.response.data.error || 'This link is no longer valid.'
          // Force user back to "unpaid" state => show purchase form again
          this.paid = false
          this.bookContent = null
        } else {
          this.errorMessage = 'Could not load your paid content. Contact support.'
        }
      }
    },

    goBackToChooseAction() {
      this.$router.push({
        name: 'choose-action',
        params: {
          topicType: 'book',
          topicId: this.$route.params.bookId,
          topicName: this.book?.title || 'Book',
        },
      })
    },
  },
}
</script>

<style scoped>
/* There's no explicit "loading" styling here because we aren't showing "Loading...".
   If you wanted a spinner or a "Loading..." message, you could add a v-if="book===undefined" block in the template. */

/* Container and content layout */
.container {
  position: absolute;
  top: 80px;
  left: 0; right: 0; bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  font-family: "Helvetica Neue", Arial, sans-serif;
  background: #ffffff;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.centered-title {
  font-size: 1.8rem;
  margin-bottom: 20px;
  color: #333;
  font-weight: 600;
}

.book-description {
  font-size: 1.2rem;
  margin-bottom: 20px;
  line-height: 1.6;
  color: #444;
}

.centered-content {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 1.2rem;
  text-align: justify;
  width: 100%;
  max-width: 800px;
  line-height: 1.6;
  font-family: 'Georgia', serif;
  color: #444;
}

/* Format selection & purchase form */
.format-selection {
  margin-bottom: 20px;
}
.format-selection label {
  margin: 0 10px;
  cursor: pointer;
}

.purchase-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
  margin-bottom: 20px;
}
.purchase-form label {
  margin-bottom: 5px;
  align-self: flex-start;
  color: #333;
}
.purchase-form input {
  width: 100%;
  margin-bottom: 15px;
  padding: 10px;
  font-size: 16px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.purchase-button {
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 12px;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
}
.purchase-button:hover {
  background-color: #555;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.error-message {
  color: red;
  margin-top: 10px;
  font-weight: 500;
}

/* "txt" BookDetails container */
.txt-container {
  position: absolute;
  top: 80px;
  left: 0; right: 0; bottom: 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background: linear-gradient(135deg, #ffffff, #f3f3f3);
  font-family: "Helvetica Neue", Arial, sans-serif;
}

.txt-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;

  background: #f7f7f7;
  border-radius: 8px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  min-height: 100%;
}

@media (max-width: 600px) {
  .content,
  .txt-content {
    padding: 10px;
  }
  .centered-title {
    font-size: 1.4rem;
  }
  .book-description {
    font-size: 1.05rem;
  }
  .centered-content {
    font-size: 1rem;
    text-align: left;
    line-height: 1.5;
  }
}
</style>
