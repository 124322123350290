<template>
  <div class="hangman-container">
    <h2>Hangman: Vocabulary Training (100 Words)</h2>

    <!-- Show definition as a clue -->
    <p class="definition">
      <strong>Definition:</strong> {{ currentWord.definition }}
    </p>
    <p v-if="currentWord.synonyms && currentWord.synonyms.length > 0" class="synonyms">
      <strong>Synonyms:</strong> {{ currentWord.synonyms.join(', ') }}
    </p>

    <!-- Display underscores / guessed letters -->
    <p class="word-display">
      <span v-for="(letter, i) in wordLetters" :key="i">
        {{ guessedLetters.includes(letter.toLowerCase()) ? letter : '_' }}
      </span>
    </p>

    <!-- Win/Lose Messages -->
    <p v-if="isWon" class="victory-message">
      You guessed it! The word was "{{ currentWord.word }}".
    </p>
    <p v-if="isLost" class="defeat-message">
      You lost! The word was "{{ currentWord.word }}".
    </p>

    <!-- Alphabet => user guesses letters -->
    <div class="letters-grid">
      <button
        v-for="char in alphabet"
        :key="char"
        :disabled="guessedLetters.includes(char) || isWon || isLost"
        @click="guessLetter(char)"
        class="letter-btn"
      >
        {{ char }}
      </button>
    </div>

    <p>Wrong guesses: {{ wrongGuesses }} / {{ maxWrong }}</p>

    <!-- Restart button if won or lost -->
    <div v-if="isWon || isLost" class="results-area">
      <button class="action-button" @click="restartGame">
        Play Again
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Hangman',
  data() {
    return {
      // A large list of 100 advanced English words with short definitions and synonyms.
      vocabList: [
        {
          word: "abate",
          definition: "To lessen in intensity or degree.",
          synonyms: ["decrease", "diminish"],
        },
        {
          word: "aberration",
          definition: "A departure from what is normal or expected.",
          synonyms: ["deviation", "anomaly"],
        },
        {
          word: "abhor",
          definition: "To regard with disgust and hatred.",
          synonyms: ["loathe", "detest"],
        },
        {
          word: "acquiesce",
          definition: "To accept something reluctantly but without protest.",
          synonyms: ["consent", "comply"],
        },
        {
          word: "alacrity",
          definition: "Cheerful readiness, promptness, or willingness.",
          synonyms: ["eagerness", "enthusiasm"],
        },
        {
          word: "amiable",
          definition: "Having or displaying a friendly and pleasant manner.",
          synonyms: ["friendly", "pleasant"],
        },
        {
          word: "arcane",
          definition: "Understood by few; mysterious or secret.",
          synonyms: ["esoteric", "obscure"],
        },
        {
          word: "avarice",
          definition: "Extreme greed for wealth or material gain.",
          synonyms: ["greed", "cupidity"],
        },
        {
          word: "brazen",
          definition: "Bold and without shame.",
          synonyms: ["impudent", "shameless"],
        },
        {
          word: "brusque",
          definition: "Abrupt or offhand in speech or manner.",
          synonyms: ["curt", "blunt"],
        },
        {
          word: "cajole",
          definition: "To persuade someone by flattery or coaxing.",
          synonyms: ["coax", "persuade"],
        },
        {
          word: "callous",
          definition: "Showing or having an insensitive and cruel disregard for others.",
          synonyms: ["heartless", "uncaring"],
        },
        {
          word: "candor",
          definition: "The quality of being open, honest, and direct in expression.",
          synonyms: ["frankness", "honesty"],
        },
        {
          word: "chide",
          definition: "To scold or rebuke.",
          synonyms: ["reprimand", "reproach"],
        },
        {
          word: "circumspect",
          definition: "Wary and unwilling to take risks.",
          synonyms: ["cautious", "careful"],
        },
        {
          word: "clandestine",
          definition: "Kept secret or done secretively.",
          synonyms: ["furtive", "hidden"],
        },
        {
          word: "coerce",
          definition: "To persuade (an unwilling person) to do something by force or threats.",
          synonyms: ["compel", "pressure"],
        },
        {
          word: "coherent",
          definition: "Logical and consistent.",
          synonyms: ["logical", "consistent"],
        },
        {
          word: "complacency",
          definition: "A feeling of smug or uncritical satisfaction with oneself.",
          synonyms: ["self-satisfaction", "contentment"],
        },
        {
          word: "confound",
          definition: "To cause surprise or confusion in someone.",
          synonyms: ["perplex", "bewilder"],
        },
        {
          word: "conundrum",
          definition: "A confusing or difficult problem or question.",
          synonyms: ["puzzle", "riddle"],
        },
        {
          word: "deleterious",
          definition: "Causing harm or damage.",
          synonyms: ["harmful", "detrimental"],
        },
        {
          word: "demure",
          definition: "Reserved, modest, and shy.",
          synonyms: ["bashful", "reserved"],
        },
        {
          word: "despot",
          definition: "A ruler who holds absolute power, often in a cruel way.",
          synonyms: ["tyrant", "dictator"],
        },
        {
          word: "diaphanous",
          definition: "Light, delicate, and translucent.",
          synonyms: ["sheer", "transparent"],
        },
        {
          word: "diligent",
          definition: "Having or showing care in one's work or duties.",
          synonyms: ["industrious", "assiduous"],
        },
        {
          word: "ebullient",
          definition: "Cheerful and full of energy.",
          synonyms: ["exuberant", "enthusiastic"],
        },
        {
          word: "eclectic",
          definition: "Deriving ideas, style, or taste from a diverse range of sources.",
          synonyms: ["wide-ranging", "varied"],
        },
        {
          word: "egregious",
          definition: "Outstandingly bad; shocking.",
          synonyms: ["atrocious", "flagrant"],
        },
        {
          word: "elucidate",
          definition: "To make something clear; explain.",
          synonyms: ["clarify", "explain"],
        },
        {
          word: "embezzle",
          definition: "To steal or misappropriate (money placed in one's trust).",
          synonyms: ["misappropriate", "pilfer"],
        },
        {
          word: "enmity",
          definition: "A state or feeling of active opposition or hostility.",
          synonyms: ["hostility", "animosity"],
        },
        {
          word: "ephemeral",
          definition: "Lasting for a very short time.",
          synonyms: ["transient", "fleeting"],
        },
        {
          word: "eschew",
          definition: "To deliberately avoid using; abstain from.",
          synonyms: ["shun", "avoid"],
        },
        {
          word: "evanescent",
          definition: "Soon passing out of sight, memory, or existence.",
          synonyms: ["vanishing", "fleeting"],
        },
        {
          word: "fastidious",
          definition: "Very attentive to detail and concerned about accuracy.",
          synonyms: ["meticulous", "scrupulous"],
        },
        {
          word: "fatuous",
          definition: "Silly and pointless.",
          synonyms: ["foolish", "inane"],
        },
        {
          word: "feral",
          definition: "In a wild state, especially after escape from captivity.",
          synonyms: ["wild", "untamed"],
        },
        {
          word: "fractious",
          definition: "Irritable and quarrelsome.",
          synonyms: ["unruly", "irritable"],
        },
        {
          word: "garrulous",
          definition: "Excessively talkative, especially on trivial matters.",
          synonyms: ["chatty", "loquacious"],
        },
        {
          word: "grandiloquent",
          definition: "Pompous or extravagant in language, style, or manner.",
          synonyms: ["bombastic", "pretentious"],
        },
        {
          word: "hapless",
          definition: "Unfortunate or unlucky.",
          synonyms: ["ill-fated", "unlucky"],
        },
        {
          word: "hegemony",
          definition: "Leadership or dominance by one group over others.",
          synonyms: ["dominance", "supremacy"],
        },
        {
          word: "ignominious",
          definition: "Deserving or causing public disgrace or shame.",
          synonyms: ["humiliating", "disgraceful"],
        },
        {
          word: "impassive",
          definition: "Not feeling or showing emotion.",
          synonyms: ["unemotional", "blank"],
        },
        {
          word: "impervious",
          definition: "Not allowing fluid to pass through; unable to be affected by.",
          synonyms: ["resistant", "unaffected"],
        },
        {
          word: "impetuous",
          definition: "Acting or done quickly without thought or care.",
          synonyms: ["rash", "impulsive"],
        },
        {
          word: "implacable",
          definition: "Unable to be appeased or placated.",
          synonyms: ["unyielding", "unrelenting"],
        },
        {
          word: "impudent",
          definition: "Not showing due respect for another person.",
          synonyms: ["insolent", "impertinent"],
        },
        {
          word: "inane",
          definition: "Silly; stupid.",
          synonyms: ["foolish", "pointless"],
        },
        {
          word: "inchoate",
          definition: "Just begun and not fully formed or developed.",
          synonyms: ["rudimentary", "undeveloped"],
        },
        {
          word: "incontrovertible",
          definition: "Not able to be denied or disputed.",
          synonyms: ["indisputable", "unquestionable"],
        },
        {
          word: "inexorable",
          definition: "Impossible to stop or prevent.",
          synonyms: ["relentless", "unstoppable"],
        },
        {
          word: "insidious",
          definition: "Proceeding in a gradual, subtle way, but with harmful effects.",
          synonyms: ["stealthy", "treacherous"],
        },
        {
          word: "intrepid",
          definition: "Fearless; adventurous.",
          synonyms: ["brave", "bold"],
        },
        {
          word: "jubilant",
          definition: "Feeling or expressing great happiness and triumph.",
          synonyms: ["exultant", "joyful"],
        },
        {
          word: "laconic",
          definition: "Using very few words.",
          synonyms: ["concise", "terse"],
        },
        {
          word: "languid",
          definition: "Displaying or having a disinclination for physical exertion or effort; slow and relaxed.",
          synonyms: ["lazy", "unhurried"],
        },
        {
          word: "latent",
          definition: "Existing but not yet developed or manifest; hidden or concealed.",
          synonyms: ["dormant", "undeveloped"],
        },
        {
          word: "lurid",
          definition: "Very vivid in color, especially so as to create an unpleasantly harsh or unnatural effect.",
          synonyms: ["vivid", "ghastly"],
        },
        {
          word: "magnanimous",
          definition: "Generous or forgiving, especially toward a rival or less powerful person.",
          synonyms: ["generous", "noble"],
        },
        {
          word: "maudlin",
          definition: "Self-pityingly or tearfully sentimental.",
          synonyms: ["overemotional", "weepy"],
        },
        {
          word: "mendacious",
          definition: "Not telling the truth; lying.",
          synonyms: ["dishonest", "deceitful"],
        },
        {
          word: "mollify",
          definition: "To appease the anger or anxiety of (someone).",
          synonyms: ["soothe", "calm"],
        },
        {
          word: "nuance",
          definition: "A subtle difference in or shade of meaning, expression, or sound.",
          synonyms: ["subtlety", "distinction"],
        },
        {
          word: "obdurate",
          definition: "Stubbornly refusing to change one's opinion or course of action.",
          synonyms: ["inflexible", "unyielding"],
        },
        {
          word: "obsequious",
          definition: "Obedient or attentive to an excessive degree.",
          synonyms: ["servile", "fawning"],
        },
        {
          word: "obstreperous",
          definition: "Noisy and difficult to control.",
          synonyms: ["unruly", "boisterous"],
        },
        {
          word: "onerous",
          definition: "Involving a great deal of effort, trouble, or difficulty.",
          synonyms: ["burdensome", "heavy"],
        },
        {
          word: "opulent",
          definition: "Ostentatiously rich and luxurious or lavish.",
          synonyms: ["luxurious", "rich"],
        },
        {
          word: "ostentatious",
          definition: "Characterized by vulgar or pretentious display; designed to impress.",
          synonyms: ["showy", "flashy"],
        },
        {
          word: "panacea",
          definition: "A solution or remedy for all difficulties or diseases.",
          synonyms: ["cure-all", "universal remedy"],
        },
        {
          word: "paragon",
          definition: "A person or thing regarded as a perfect example of a particular quality.",
          synonyms: ["model", "epitome"],
        },
        {
          word: "paucity",
          definition: "The presence of something in only small or insufficient quantities.",
          synonyms: ["scarcity", "shortage"],
        },
        {
          word: "perfidious",
          definition: "Deceitful and untrustworthy.",
          synonyms: ["treacherous", "faithless"],
        },
        {
          word: "petulant",
          definition: "Childishly sulky or bad-tempered.",
          synonyms: ["peevish", "irritable"],
        },
        {
          word: "plethora",
          definition: "A large or excessive amount of something.",
          synonyms: ["abundance", "excess"],
        },
        {
          word: "precocious",
          definition: "Having developed certain abilities or inclinations at an earlier age than usual.",
          synonyms: ["advanced", "gifted"],
        },
        {
          word: "proclivity",
          definition: "A tendency to choose or do something regularly.",
          synonyms: ["inclination", "propensity"],
        },
        {
          word: "quixotic",
          definition: "Exceedingly idealistic; unrealistic and impractical.",
          synonyms: ["impractical", "romantic"],
        },
        {
          word: "rife",
          definition: "Of common occurrence; widespread (especially of something undesirable).",
          synonyms: ["prevalent", "abundant"],
        },
        {
          word: "salient",
          definition: "Most noticeable or important.",
          synonyms: ["prominent", "conspicuous"],
        },
        {
          word: "sanguine",
          definition: "Optimistic or positive, especially in a bad or difficult situation.",
          synonyms: ["hopeful", "confident"],
        },
        {
          word: "serendipity",
          definition: "The occurrence of events by chance in a happy or beneficial way.",
          synonyms: ["chance", "fluke"],
        },
        {
          word: "staid",
          definition: "Sedate, respectable, and unadventurous.",
          synonyms: ["serious", "sober"],
        },
        {
          word: "superfluous",
          definition: "Unnecessary, especially through being more than enough.",
          synonyms: ["redundant", "excess"],
        },
        {
          word: "surreptitious",
          definition: "Kept secret, especially because it would not be approved of.",
          synonyms: ["secretive", "stealthy"],
        },
        {
          word: "sycophant",
          definition: "A person who acts obsequiously toward someone important to gain advantage.",
          synonyms: ["toady", "flatterer"],
        },
        {
          word: "tacit",
          definition: "Understood or implied without being stated.",
          synonyms: ["unspoken", "implicit"],
        },
        {
          word: "taciturn",
          definition: "Reserved or uncommunicative in speech; saying little.",
          synonyms: ["quiet", "silent"],
        },
        {
          word: "tantamount",
          definition: "Equivalent in seriousness to; virtually the same as.",
          synonyms: ["equal", "equivalent"],
        },
        {
          word: "torpid",
          definition: "Mentally or physically inactive; lethargic.",
          synonyms: ["sluggish", "inert"],
        },
        {
          word: "ubiquitous",
          definition: "Present, appearing, or found everywhere.",
          synonyms: ["omnipresent", "pervasive"],
        },
        {
          word: "umbrage",
          definition: "Offense or annoyance.",
          synonyms: ["resentment", "offense"],
        },
        {
          word: "venerable",
          definition: "Accorded a great deal of respect, especially because of age, wisdom, or character.",
          synonyms: ["esteemed", "revered"],
        },
        {
          word: "vex",
          definition: "To make someone feel annoyed or frustrated.",
          synonyms: ["irritate", "anger"],
        },
        {
          word: "vociferous",
          definition: "Expressing or characterized by vehement opinions; loud and forceful.",
          synonyms: ["clamorous", "boisterous"],
        },
        {
          word: "wanton",
          definition: "Deliberate and unprovoked (of a cruel or violent action).",
          synonyms: ["unjustifiable", "gratuitous"],
        },
        {
          word: "wily",
          definition: "Skilled at gaining an advantage, especially deceitfully.",
          synonyms: ["crafty", "sly"],
        },
        {
          word: "zenith",
          definition: "The time at which something is most powerful or successful.",
          synonyms: ["peak", "apex"],
        },
      ],
      currentWord: {
        word: "",
        definition: "",
        synonyms: [],
      },
      guessedLetters: [],
      wrongGuesses: 0,
      maxWrong: 6, // 6 tries
    };
  },
  computed: {
    // break the current word into letters
    wordLetters() {
      return this.currentWord.word.split('');
    },
    // if every letter is guessed => isWon
    isWon() {
      // all letters are guessed?
      return this.wordLetters.every(letter =>
        this.guessedLetters.includes(letter.toLowerCase())
      );
    },
    // if user has guessed too many times => isLost
    isLost() {
      return this.wrongGuesses >= this.maxWrong && !this.isWon;
    },
    // letters A-Z
    alphabet() {
      return 'abcdefghijklmnopqrstuvwxyz'.split('');
    },
  },
  methods: {
    // guess a letter
    guessLetter(letter) {
      this.guessedLetters.push(letter);
      // check if letter is in the current word
      if (!this.currentWord.word.toLowerCase().includes(letter)) {
        this.wrongGuesses++;
      }
    },
    // reset the game
    restartGame() {
      this.guessedLetters = [];
      this.wrongGuesses = 0;
      this.pickRandomWord();
    },
    // pick a random word from vocabList
    pickRandomWord() {
      const randomIndex = Math.floor(Math.random() * this.vocabList.length);
      const chosen = this.vocabList[randomIndex];
      this.currentWord = {
        word: chosen.word.toLowerCase(), // unify case
        definition: chosen.definition,
        synonyms: chosen.synonyms || [],
      };
    },
  },
  mounted() {
    // pick initial random word
    this.pickRandomWord();
  },
};
</script>

<style scoped>
.hangman-container {
  margin-top: 60px; /* if your header is pinned at 60px */
  min-height: calc(100vh - 60px);
  overflow-y: auto;
  padding: 20px;
  text-align: center;
  box-sizing: border-box;
  background: #fff;
  font-family: 'Helvetica Neue', Arial, sans-serif;
}

.definition,
.synonyms {
  font-size: 1.1rem;
  margin: 15px 0;
  line-height: 1.4;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
}

.word-display {
  font-size: 2rem;
  margin: 20px 0;
  letter-spacing: 8px;
}

.victory-message {
  color: green;
  font-weight: bold;
  margin: 20px 0;
}

.defeat-message {
  color: red;
  font-weight: bold;
  margin: 20px 0;
}

/* Grid of letters to guess from => A-Z */
.letters-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(40px, 1fr));
  max-width: 400px; /* or whatever you like */
  gap: 5px;
  margin: 20px auto;
}

.letter-btn {
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.letter-btn:hover {
  background-color: #555;
}

.results-area {
  margin-top: 20px;
}

.action-button {
  background-color: #333;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 12px 20px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s, transform 0.3s;
}
.action-button:hover {
  background-color: #555;
  transform: translateY(-2px);
}
</style>
