<template>
  <div class="header" ref="headerRoot">
    <div class="col-left">
      <!-- Optional "Back" button -->
      <button v-if="showBackButton" @click="emitBack" class="nav-button">
        {{ backText }}
      </button>

      <!-- Menu button -> shown only if route != 'resources' -->
      <button
        v-if="shouldShowMenuButton"
        @click.stop="toggleMenu"
        class="nav-button"
      >
        Menu
      </button>
    </div>

    <div class="col-center">
      <router-link to="/resources">
        <!-- UPDATED: Use your S3 URL instead of /assets/profile-picture.jpg -->
        <img
          src="https://my-lion-assets.s3.eu-north-1.amazonaws.com/profile-picture.jpg"
          class="logo"
          alt="Logo"
        />
      </router-link>
    </div>

    <div class="col-right">
      <router-link to="/donate">
        <button class="nav-button">Donate</button>
      </router-link>
      <router-link to="/about-resuvonia">
        <button class="nav-button">About Resuvonia</button>
      </router-link>
    </div>

    <!-- Dropdown -->
    <div
      v-if="showMenuDropdown"
      class="dropdown-menu"
      :style="{ top: headerHeight + 'px' }"
    >
      <div class="dropdown-item resource-box" @click="goToYourText">
        Get Help With Everything
      </div>
      <div class="dropdown-item resource-box" @click="goToBooks">
        Books
      </div>
      <div class="dropdown-item resource-box" @click="goToArticles">
        Articles
      </div>
      <div class="dropdown-item resource-box" @click="goToNews">
        News
      </div>
      <div class="dropdown-item resource-box" @click="goToResearch">
        Research
      </div>
      <div class="dropdown-item resource-box" @click="goToGames">
        Games
      </div>
      <div class="dropdown-item resource-box" @click="goToVideos">
        Videos
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: {
    showBackButton: { type: Boolean, default: false },
    backText: { type: String, default: "Back" },
  },
  data() {
    return {
      showMenuDropdown: false,
      headerHeight: 80,
    };
  },
  computed: {
    shouldShowMenuButton() {
      return this.$route.name !== "resources";
    },
  },
  methods: {
    emitBack() {
      this.$emit("back");
    },
    toggleMenu() {
      this.showMenuDropdown = !this.showMenuDropdown;
    },
    handleClickOutside(event) {
      if (!this.$refs.headerRoot.contains(event.target)) {
        this.showMenuDropdown = false;
      }
    },
    closeMenuAndNavigate(routeObj) {
      this.showMenuDropdown = false;
      this.$router.push(routeObj);
    },
    goToYourText() {
      this.closeMenuAndNavigate({ name: "your-text" });
    },
    goToBooks() {
      this.closeMenuAndNavigate({ name: "books" });
    },
    goToArticles() {
      this.closeMenuAndNavigate({ name: "articles" });
    },
    goToNews() {
      this.closeMenuAndNavigate({
        name: "choose-action",
        params: { topicType: "news", topicId: 0, topicName: "News" },
      });
    },
    goToResearch() {
      this.closeMenuAndNavigate({ name: "research" });
    },
    goToGames() {
      this.closeMenuDropdown = false;
      this.$router.push({ name: "games" });
    },
    goToVideos() {
      this.closeMenuDropdown = false;
      this.$router.push({ name: "videos" });
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style>
/* ================= GLOBAL SCROLLBAR STYLES ================= */
::-webkit-scrollbar {
  width: 10px;
  background-color: #f0f0f0;
}
::-webkit-scrollbar-track {
  background-color: #e6e6e6;
}
::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
  border: 2px solid #e6e6e6;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
html {
  scrollbar-width: thin;
  scrollbar-color: #888 #e6e6e6;
}
/* =========================================================== */
</style>

<style scoped>
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  background: linear-gradient(135deg, #444444, #2c2c2c);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  color: #ffffff;
  z-index: 1000;
}

.col-left {
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.col-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.col-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 20px;
}

.logo {
  height: 50px;
  cursor: pointer;
}

.nav-button {
  background-color: #2c2c2c;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  cursor: pointer;
  font-size: 14px;
  margin-left: 10px;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
}
.nav-button:hover {
  background-color: #444444;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}
.col-left .nav-button:first-of-type {
  margin-left: 0;
}

.dropdown-menu {
  position: fixed;
  left: 0;
  width: 220px;
  background-color: #333;
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  z-index: 9999;
}

.resource-box {
  background: linear-gradient(135deg, #444444, #2c2c2c);
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 16px;
  cursor: pointer;
  text-align: center;
  width: 100%;
  margin: 0;
  box-shadow: none;
  transition: transform 0.3s, box-shadow 0.3s, background-color 0.3s;
}
.resource-box:hover {
  background-color: #555;
  transform: translateY(-3px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

@media (max-width: 600px) {
  .header {
    height: 80px;
  }
  .logo {
    height: 40px;
  }
  .nav-button {
    font-size: 12px;
    padding: 5px 8px;
  }
  .resource-box {
    padding: 12px;
    font-size: 14px;
  }
}
</style>
