<template>
  <div>
    <Header
      :showBackButton="true"
      backText="Back to Choose Action"
      @back="goBackToChooseAction"
    />
    <!-- container with overflow-y -->
    <div class="container" ref="container">
      <div class="content">
        <h1 class="centered-title">{{ book.title }}</h1>
        <!-- Display the text in a <pre> -->
        <pre class="centered-content">{{ bookContent }}</pre>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "./Header.vue";
import axios from "@/axiosConfig.js";
// or just use a global axios import

export default {
  components: { Header },
  data() {
    return {
      book: { id: null, title: "" },
      bookContent: "",
    };
  },
  mounted() {
    const bookId = this.$route.params.bookId;

    // Find the book from a local array or however you prefer
    this.book = this.getBookDetails(bookId);

    // 1) If the book has .txt => fetch it from direct S3
    this.fetchBookContent().then(() => {
      this.restoreScrollPosition();
    });

    // Listen for scroll
    if (this.$refs.container) {
      this.$refs.container.addEventListener("scroll", this.saveScrollPosition);
    }
    // Listen for tab close
    window.addEventListener("pagehide", this.saveScrollPosition);
  },
  beforeDestroy() {
    if (this.$refs.container) {
      this.$refs.container.removeEventListener("scroll", this.saveScrollPosition);
    }
    window.removeEventListener("pagehide", this.saveScrollPosition);
  },
  beforeRouteLeave(to, from, next) {
    this.saveScrollPosition();
    next();
  },
  methods: {
    getBookDetails(bookId) {
      // Same local array as BooksPage, or fetch from server if you like
      const books = [
        {
          id: 1,
          title: "813 by Maurice Leblanc",
          image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/813.png",
          // direct S3 link for text
          txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/813.txt",
          genre: "Classics, Mystery & Thriller, All",
        },
        {
    id: 2,
    title: "A Christmas Carol by Charles Dickens",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-christmas-carol.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-christmas-carol.txt",
    genre: "Classics, Social & Political, All"
  },
  {
    id: 3,
    title: "A Tale of Two Cities by Charles Dickens",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-tale-of-two-cities.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-tale-of-two-cities.txt",
    genre: "Classics, Historical, All"
  },
  {
    id: 4,
    title: "A Connecticut Yankee in King Arthur's Court by Mark Twain",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-connecticut-yankee-in-king-arthurs-court.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-connecticut-yankee-in-king-arthurs-court.txt",
    genre: "Classics, Science Fiction & Fantasy, Satire, All"
  },
  {
    id: 5,
    title: "A Midsummer Night's Dream by William Shakespeare",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-midsummer-nights-dream.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-midsummer-nights-dream.txt",
    genre: "Classics, Drama & Plays, Mythology & Legends, All"
  },
  {
    id: 6,
    title: "Alice's Adventures in Wonderland by Lewis Carroll",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/alice-in-wonderland.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/alice-in-wonderland.txt",
    genre: "Classics, Science Fiction & Fantasy, Children's Literature, All"
  },
  {
    id: 7,
    title: "Anna Karenina by Leo Tolstoy",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/anna-karenina.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/anna-karenina.txt",
    genre: "Classics, Romance, Social & Political, All"
  },
  {
    id: 8,
    title: "Adventures of Huckleberry Finn by Mark Twain",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/adventures-of-huckleberry.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/adventures-of-huckleberry.txt",
    genre: "Classics, Adventure, Social & Political, All"
  },
  {
    id: 9,
    title: "Bleak House by Charles Dickens",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/bleak-house.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/bleak-house.txt",
    genre: "Classics, Social & Political, Mystery & Thriller, All"
  },
  {
    id: 10,
    title: "A Doll's House by Henrik Ibsen",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-doll-s-house.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-doll-s-house.txt",
    genre: "Classics, Drama & Plays, Social & Political, All"
  },
  {
    id: 11,
    title: "A Room with a View by E. M. Forster",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-room-with-a-view.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-room-with-a-view.txt",
    genre: "Classics, Romance, Social & Political, All"
  },
  {
    id: 12,
    title: "A Woman of No Importance by Oscar Wilde",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-woman-of-no-importance.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-woman-of-no-importance.txt",
    genre: "Classics, Drama & Plays, Satire, All"
  },
  {
    id: 13,
    title: "Anne of Green Gables by L. M. Montgomery",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/anne-of-green-gables.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/anne-of-green-gables.txt",
    genre: "Classics, Children's Literature, All"
  },
  {
    id: 14,
    title: "Anne of the Island by L. M. Montgomery",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/anne-of-the-island.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/anne-of-the-island.txt",
    genre: "Classics, Children's Literature, All"
  },
  {
    id: 15,
    title: "A Hero of Our Time by Mikhail Lermontov",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-hero-of-our-time.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-hero-of-our-time.txt",
    genre: "Classics, Social & Political, All"
  },
  {
    id: 16,
    title: "Adam Bede by George Eliot",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/adam-bede.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/adam-bede.txt",
    genre: "Classics, Social & Political, All"
  },
  {
    id: 17,
    title: "A Tramp Abroad by Mark Twain",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-tramp-abroad.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-tramp-abroad.txt",
    genre: "Classics, Adventure, Satire, All"
  },
  {
    id: 18,
    title: "Buddenbrooks Verfall Einer Familie by Thomas Mann",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/buddenbrooks-verfall-einer-familie.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/buddenbrooks-verfall-einer-familie.txt",
    genre: "Classics, Social & Political, All"
  },
  {
    id: 19,
    title: "Barchester Towers by Anthony Trollope",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/barchester-towers.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/barchester-towers.txt",
    genre: "Classics, Social & Political, All"
  },
  {
    id: 20,
    title: "Beowulf by Anonymous",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/beowulf-an-anglo-saxon-epic-poem.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/beowulf-an-anglo-saxon-epic-poem.txt",
    genre: "Classics, Mythology & Legends, Poetry, All"
  },
  {
    id: 21,
    title: "Clarissa Harlowe; or, The History of a Young Lady, Volume 1 by Samuel Richardson",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/clarissa-harlowe-or-the-history-of-a-young-lady-volume-1.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/clarissa-harlowe-or-the-history-of-a-young-lady-volume-1.txt",
    genre: "Classics, Romance, Social & Political, All"
  },
  {
    id: 22,
    title: "Cranford by Elizabeth Gaskell",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/cranford.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/cranford.txt",
    genre: "Classics, Social & Political, All"
  },
  {
    id: 23,
    title: "Crime and Punishment by Fyodor Dostoevsky",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/crime-and-punishment.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/crime-and-punishment.txt",
    genre: "Classics, Social & Political, Mystery & Thriller, All"
  },
  {
    id: 24,
    title: "David Copperfield by Charles Dickens",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/david-copperfield.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/david-copperfield.txt",
    genre: "Classics, Social & Political, All"
  },
  {
    id: 25,
    title: "De Decamerone van Boccaccio by Giovanni Boccaccio",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/de-decamerone-van-boccaccio.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/de-decamerone-van-boccaccio.txt",
    genre: "Classics, Satire, All"
  },
  {
    id: 26,
    title: "Dead Souls by Nikolai Gogol",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/dead-souls.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/dead-souls.txt",
    genre: "Classics, Satire, Social & Political, All"
  },
  {
    id: 27,
    title: "Demian by Hermann Hesse",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/demian.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/demian.txt",
    genre: "Classics, Social & Political, All"
  },
  {
    id: 28,
    title: "Don Quixote by Miguel de Cervantes Saavedra",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/don-quixote.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/don-quixote.txt",
    genre: "Classics, Satire, Adventure, All"
  },
  {
    id: 29,
    title: "Emma by Jane Austen",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/emma.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/emma.txt",
    genre: "Classics, Romance, Satire, All"
  },
  {
    id: 30,
    title: "Eugene Oneguine [Onegin] by Alexander Pushkin",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/eugene-oneguine-[onegin].png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/eugene-oneguine-[onegin].txt",
    genre: "Classics, Poetry, All"
  },
  {
    id: 31,
    title: "Evelina, Or the History of a Young Lady's Entrance into the World by Fanny Burney",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/evelina-or-the-history-of-a-young-ladys-entrance-into-the-world.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/evelina-or-the-history-of-a-young-ladys-entrance-into-the-world.txt",
    genre: "Classics, Romance, Social & Political, All"
  },
  {
    id: 32,
    title: "Faust [Part 1] by Johann Wolfgang von Goethe",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/faust-[part-1].png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/faust-[part-1].txt",
    genre: "Classics, Drama & Plays, Mythology & Legends, All"
  },
  {
    id: 33,
    title: "Faust Der Tragödie Erster Teil by Johann Wolfgang von Goethe",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/faust-der-tragödie-erster-teil.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/faust-der-tragödie-erster-teil.txt",
    genre: "Classics, Drama & Plays, Mythology & Legends, All"
  },
  {
    id: 34,
    title: "Faust Der Tragödie Zweiter Teil by Johann Wolfgang von Goethe",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/faust-der-tragödie-zweiter-teil.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/faust-der-tragödie-zweiter-teil.txt",
    genre: "Classics, Drama & Plays, Mythology & Legends, All"
  },
  {
    id: 35,
    title: "Far From the Madding Crowd by Thomas Hardy",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/far-from-the-madding-crowd.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/far-from-the-madding-crowd.txt",
    genre: "Classics, Romance, All"
  },
  {
    id: 36,
    title: "Father Goriot by Honoré de Balzac",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/father-goriot.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/father-goriot.txt",
    genre: "Classics, Social & Political, All"
  },
  {
    id: 37,
    title: "Great Expectations by Charles Dickens",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/great-expectations.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/great-expectations.txt",
    genre: "Classics, Social & Political, All"
  },
  {
    id: 38,
    title: "Gulliver's Travels by Jonathan Swift",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/gullivers-travels.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/gullivers-travels.txt",
    genre: "Classics, Satire, Adventure, All"
  },
  {
    id: 39,
    title: "Heart of Darkness by Joseph Conrad",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/heart-of-darkness.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/heart-of-darkness.txt",
    genre: "Classics, Adventure, Social & Political, All"
  },
  {
    id: 40,
    title: "Hunger by Knut Hamsun",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/hunger.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/hunger.txt",
    genre: "Classics, Social & Political, All"
  },
  {
    id: 41,
    title: "Jude the Obscure by Thomas Hardy",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/jude-the-obscure.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/jude-the-obscure.txt",
    genre: "Classics, Social & Political, All"
  },
  // Notice there's no id:42 in your snippet (it jumps to 43).
  {
    id: 43,
    title: "King Lear by William Shakespeare",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/king-lear.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/king-lear.txt",
    genre: "Classics, Drama & Plays, All"
  },
  {
    id: 44,
    title: "Lady Audley's Secret by Mary Elizabeth Braddon",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/lady-audleys-secret.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/lady-audleys-secret.txt",
    genre: "Classics, Mystery & Thriller, All"
  },
  {
    id: 45,
    title: "Le Comte de Monte Cristo Tome I by Alexandre Dumas",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/le-comte-de-monte-cristo-tome-I.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/le-comte-de-monte-cristo-tome-I.txt",
    genre: "Classics, Adventure, Mystery & Thriller, All"
  },
  {
    id: 46,
    title: "Les Misérables by Victor Hugo",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/les-miserables.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/les-miserables.txt",
    genre: "Classics, Social & Political, All"
  },
  {
    id: 47,
    title: "Lord Jim by Joseph Conrad",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/lord-jim.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/lord-jim.txt",
    genre: "Classics, Adventure, All"
  },
  {
    id: 48,
    title: "Mansfield Park by Jane Austen",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/mansfield-park.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/mansfield-park.txt",
    genre: "Classics, Romance, Social & Political, All"
  },
  {
    id: 49,
    title: "Madame Bovary by Gustave Flaubert",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/madame-bovary.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/madame-bovary.txt",
    genre: "Classics, Romance, All"
  },
  {
    id: 50,
    title: "Moby Dick by Herman Melville",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/moby-dick.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/moby-dick.txt",
    genre: "Classics, Adventure, All"
  },
  {
    id: 51,
    title: "Middlemarch by George Eliot",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/middlemarch.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/middlemarch.txt",
    genre: "Classics, Social & Political, All"
  },
  {
    id: 52,
    title: "Northanger Abbey by Jane Austen",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/northanger-abbey.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/northanger-abbey.txt",
    genre: "Classics, Romance, Satire, All"
  },
  {
    id: 53,
    title: "Notes from the Underground by Fyodor Dostoevsky",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/notes-from-the-underground.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/notes-from-the-underground.txt",
    genre: "Classics, Philosophy, All"
  },
  {
    id: 54,
    title: "Notre-Dame de Paris Tome I by Victor Hugo",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/notre-dame-de-paris-tome-I.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/notre-dame-de-paris-tome-I.txt",
    genre: "Classics, Social & Political, Romance, All"
  },
  {
    id: 55,
    title: "Oedipus King of Thebes by Sophocles",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/oedipus-king-of-thebes.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/oedipus-king-of-thebes.txt",
    genre: "Classics, Drama & Plays, Mythology & Legends, All"
  },
  {
    id: 56,
    title: "Of Human Bondage by W. Somerset Maugham",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/of-human-bondage.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/of-human-bondage.txt",
    genre: "Classics, Social & Political, All"
  },
  {
    id: 57,
    title: "Oliver Twist by Charles Dickens",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/oliver-twist.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/oliver-twist.txt",
    genre: "Classics, Social & Political, All"
  },
  {
    id: 58,
    title: "Othello, the Moor of Venice by William Shakespeare",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/othello-the-moor-of-venice.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/othello-the-moor-of-venice.txt",
    genre: "Classics, Drama & Plays, All"
  },
  {
    id: 59,
    title: "Paradise Lost by John Milton",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/paradise-lost.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/paradise-lost.txt",
    genre: "Classics, Mythology & Legends, Poetry, All"
  },
  {
    id: 60,
    title: "Persuasion by Jane Austen",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/persuasion.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/persuasion.txt",
    genre: "Classics, Romance, All"
  },
  {
    id: 61,
    title: "Peter Pan by J. M. Barrie",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/peter-pan.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/peter-pan.txt",
    genre: "Classics, Children's Literature, Fantasy, All"
  },
  {
    id: 62,
    title: "Pride and Prejudice by Jane Austen",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/pride-and-prejudice.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/pride-and-prejudice.txt",
    genre: "Classics, Romance, All"
  },
  {
    id: 63,
    title: "Romeo and Juliet by William Shakespeare",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/romeo-juliet.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/romeo-juliet.txt",
    genre: "Classics, Drama & Plays, Romance, All"
  },
  {
    id: 64,
    title: "She by H. Rider Haggard",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/she.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/she.txt",
    genre: "Classics, Adventure, Fantasy, All"
  },
  {
    id: 65,
    title: "Siddhartha by Hermann Hesse",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/siddhartha.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/siddhartha.txt",
    genre: "Classics, Philosophy, Spirituality, All"
  },
  {
    id: 66,
    title: "Sense and Sensibility by Jane Austen",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/sense-and-sensibility.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/sense-and-sensibility.txt",
    genre: "Classics, Romance, All"
  },
  {
    id: 67,
    title: "Tess of the d'Urbervilles by Thomas Hardy",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/tess-of-the-d-urbervilles.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/tess-of-the-d-urbervilles.txt",
    genre: "Classics, Romance, Tragedy, All"
  },
  {
    id: 68,
    title: "The Adventures of Tom Sawyer by Mark Twain",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-adventures-of-tom-sawyer.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-adventures-of-tom-sawyer.txt",
    genre: "Classics, Adventure, Children's Literature, All"
  },
  {
    id: 69,
    title: "The Adventures of Sherlock Holmes by Arthur Conan Doyle",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-adventures-of-sherlock.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-adventures-of-sherlock.txt",
    genre: "Classics, Mystery & Thriller, All"
  },
  {
    id: 70,
    title: "The Aeneid by Virgil",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-aeneid.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-aeneid.txt",
    genre: "Classics, Mythology & Legends, All"
  },
  {
    id: 71,
    title: "The Arabian Nights: Their Best-Known Tales by Anonymous",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-arabian-nights-their-best-known-tales.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-arabian-nights-their-best-known-tales.txt",
    genre: "Classics, Adventure, Mythology & Legends, All"
  },
  {
    id: 72,
    title: "The Brothers Karamazov by Fyodor Dostoevsky",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-brothers-karamazov.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-brothers-karamazov.txt",
    genre: "Classics, Philosophy, All"
  },
  {
    id: 73,
    title: "The Call of the Wild by Jack London",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-call-of-the-wild.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-call-of-the-wild.txt",
    genre: "Classics, Adventure, All"
  },
  {
    id: 74,
    title: "The Canterbury Tales and Other Poems by Geoffrey Chaucer",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-canterbury-tales-and-other-poems.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-canterbury-tales-and-other-poems.txt",
    genre: "Classics, Poetry, All"
  },
  {
    id: 75,
    title: "The Count of Monte Cristo by Alexandre Dumas",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-count-of-monte-cristo.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-count-of-monte-cristo.txt",
    genre: "Classics, Adventure, Mystery & Thriller, All"
  },
  {
    id: 76,
    title: "The Divine Comedy by Dante Alighieri",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-divine-comedy.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-divine-comedy.txt",
    genre: "Classics, Spirituality, Poetry, All"
  },
  {
    id: 77,
    title: "The Great Gatsby by F. Scott Fitzgerald",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-great-gatsby.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-great-gatsby.txt",
    genre: "Classics, Romance, Social & Political, All"
  },
  {
    id: 78,
    title: "The Iliad by Homer",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-iliad.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-iliad.txt",
    genre: "Classics, Mythology & Legends, All"
  },
  {
    id: 79,
    title: "The Odyssey by Homer",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-odyssey.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-odyssey.txt",
    genre: "Classics, Mythology & Legends, Adventure, All"
  },
  {
    id: 80,
    title: "The Hound of the Baskervilles by Arthur Conan Doyle",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-hound-of-the-baskervilles.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-hound-of-the-baskervilles.txt",
    genre: "Classics, Mystery & Thriller, Gothic, All"
  },
  {
    id: 81,
    title: "The Idiot by Fyodor Dostoevsky",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-idiot.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-idiot.txt",
    genre: "Classics, Philosophy, All"
  },
  {
    id: 82,
    title: "The Importance of Being Earnest by Oscar Wilde",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-importance-of-being-earnest.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-importance-of-being-earnest.txt",
    genre: "Classics, Drama & Plays, Satire, All"
  },
  {
    id: 83,
    title: "The Jungle Book by Rudyard Kipling",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-jungle-book.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-jungle-book.txt",
    genre: "Classics, Adventure, Children's Literature, All"
  },
  {
    id: 84,
    title: "The Legend of Sleepy Hollow by Washington Irving",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-legend-of-sleepy-hollow.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-legend-of-sleepy-hollow.txt",
    genre: "Classics, Gothic, All"
  },
  {
    id: 85,
    title: "The Prince and the Pauper by Mark Twain",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-prince-and-the-pauper.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-prince-and-the-pauper.txt",
    genre: "Classics, Historical, Children's Literature, All"
  },
  {
    id: 86,
    title: "The House of the Seven Gables by Nathaniel Hawthorne",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-house-of-the-seven-gables.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-house-of-the-seven-gables.txt",
    genre: "Classics, Gothic, All"
  },
  {
    id: 87,
    title: "The Metamorphoses of Ovid by Ovid",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-metamorphoses-of-ovid.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-metamorphoses-of-ovid.txt",
    genre: "Classics, Mythology & Legends, Poetry, All"
  },
  {
    id: 88,
    title: "The Scarlet Letter by Nathaniel Hawthorne",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-scarlet-letter.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-scarlet-letter.txt",
    genre: "Classics, Romance, All"
  },
  {
    id: 89,
    title: "The Three Musketeers by Alexandre Dumas",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-three-musketeers.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-three-musketeers.txt",
    genre: "Classics, Adventure, Historical, All"
  },
  {
    id: 90,
    title: "The Time Machine by H. G. Wells",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-time-machine.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-time-machine.txt",
    genre: "Classics, Science Fiction & Fantasy, All"
  },
  {
    id: 91,
    title: "The Scarlet Pimpernel by Baroness Orczy",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-scarlet-pimpernel.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-scarlet-pimpernel.txt",
    genre: "Classics, Adventure, Historical, All"
  },
  {
    id: 92,
    title: "The Scarlet Plague by Jack London",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-scarlet-plague.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-scarlet-plague.txt",
    genre: "Classics, Science Fiction & Fantasy, All"
  },
  {
    id: 93,
    title: "The Strange Case of Dr Jekyll and Mr Hyde by Robert Louis Stevenson",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-strange-case.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-strange-case.txt",
    genre: "Classics, Gothic, Mystery & Thriller, All"
  },
  {
    id: 94,
    title: "The Turn of the Screw by Henry James",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-turn-of-the-screw.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-turn-of-the-screw.txt",
    genre: "Classics, Gothic, Mystery & Thriller, All"
  },
  {
    id: 95,
    title: "The Trial by Franz Kafka",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-trial.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-trial.txt",
    genre: "Classics, Philosophy, All"
  },
  {
    id: 96,
    title: "The War of the Worlds by H. G. Wells",
    // The user snippet didn't have a 'genre' for ID 96;
    // let's keep it consistent: "Classics, Science Fiction & Fantasy, All"
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-war-of-the-worlds.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-war-of-the-worlds.txt",
    genre: "Classics, Science Fiction & Fantasy, All"
  },
  {
    id: 97,
    title: "The Wind in the Willows by Kenneth Grahame",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-wind-in-the-willows.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-wind-in-the-willows.txt",
    genre: "Classics, Children's Literature, Fantasy, All"
  },
  {
    id: 98,
    title: "The Wonderful Wizard of Oz by L. Frank Baum",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-wonderful-wizard-of-oz.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-wonderful-wizard-of-oz.txt",
    genre: "Classics, Children's Literature, Fantasy, All"
  },
  {
    id: 99,
    title: "The Republic by Plato",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-republic.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-republic.txt",
    genre: "Classics, Philosophy, Political Science, All"
  },
  {
    id: 100,
    title: "Treasure Island by Robert Louis Stevenson",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/treasure-island.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/treasure-island.txt",
    genre: "Classics, Adventure, All"
  },
  {
    id: 101,
    title: "Twelfth Night; Or, What You Will by William Shakespeare",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/twelfth-night-or-what-you-will.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/twelfth-night-or-what-you-will.txt",
    genre: "Classics, Drama & Plays, All"
  },
  {
    id: 102,
    title: "Twenty Thousand Leagues Under the Sea by Jules Verne",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/twenty-thousand-leagues-under-the-sea.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/twenty-thousand-leagues-under-the-sea.txt",
    genre: "Classics, Adventure, Science Fiction & Fantasy, All"
  },
  {
    id: 103,
    title: "War and Peace by Leo Tolstoy",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/war-and-peace.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/war-and-peace.txt",
    genre: "Classics, Historical, All"
  },
  {
    id: 104,
    title: "Wuthering Heights by Emily Brontë",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/wuthering-heights.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/wuthering-heights.txt",
    genre: "Classics, Romance, Gothic, All"
  },
  {
    id: 105,
    title: "Jane Eyre by Charlotte Brontë",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/jane-eyre.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/jane-eyre.txt",
    genre: "Classics, Romance, Gothic, All"
  },
  {
    id: 106,
    title: "Ulysses by James Joyce",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/ulysses.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/ulysses.txt",
    genre: "Classics, Modernist, All"
  },
  {
    id: 107,
    title: "Ivanhoe by Sir Walter Scott",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/ivanhoe.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/ivanhoe.txt",
    genre: "Classics, Historical, Romance, All"
  },
  {
    id: 108,
    title: "Twice-Told Tales by Nathaniel Hawthorne",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/twice-told-tales.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/twice-told-tales.txt",
    genre: "Classics, Gothic, All"
  },
  {
    id: 109,
    title: "A Journey Into the Interior of the Earth by Jules Verne",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-journey-into-the-interior-of-the-earth.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-journey-into-the-interior-of-the-earth.txt",
    genre: "Science Fiction & Fantasy, Adventure, All"
  },
  {
    id: 110,
    title: "A Princess of Mars by Edgar Rice Burroughs",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-princess-of-mars.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-princess-of-mars.txt",
    genre: "Science Fiction & Fantasy, Adventure, All"
  },
  {
    id: 111,
    title: "Alice's Adventures in Wonderland by Lewis Carroll",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/alice-in-wonderland.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/alice-in-wonderland.txt",
    genre: "Classics, Children's Literature, Fantasy, All"
  },
  {
    id: 112,
    title: "Beyond Lies the Wub by Philip K. Dick",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/beyond-lies-the-wub.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/beyond-lies-the-wub.txt",
    genre: "Science Fiction & Fantasy, All"
  },
  {
    id: 113,
    title: "Around the World in Eighty Days by Jules Verne",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/around-the-world-in-eighty-days.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/around-the-world-in-eghty-days.txt",
    genre: "Classics, Adventure, All"
  },
  {
    id: 114,
    title: "After London or Wild England by Richard Jefferies",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/after-london-or-wild-england.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/after-london-or-wild-england.txt",
    genre: "Science Fiction & Fantasy, Adventure, All"
  },
  {
    id: 115,
    title: "A Voyage to Arcturus by David Lindsay",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-voyage-to-arcturus.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-voyage-to-arcturus.txt",
    genre: "Science Fiction & Fantasy, All"
  },
  {
    id: 116,
    title: "A Connecticut Yankee in King Arthur's Court by Mark Twain",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-connecticut-yankee-in-king-arthurs-court.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-connecticut-yankee-in-king-arthurs-court.txt",
    genre: "Classics, Historical, Science Fiction & Fantasy, All"
  },
  {
    id: 117,
    title: "Amphitryo by Plautus",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/amphitryo.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/amphitryo.txt",
    genre: "Classics, Drama & Plays, All"
  },
  {
    id: 118,
    title: "Childe Harold's Pilgrimage by Lord Byron",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/childe-harolds-pilgrimage.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/childe-harolds-pilgrimage.txt",
    genre: "Poetry, Classics, All"
  },
  {
    id: 119,
    title: "A Pail of Air by Fritz Leiber",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-pail-of-air.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-pail-of-air.txt",
    genre: "Science Fiction & Fantasy, All"
  },
  {
    id: 120,
    title: "Etidorhpa, or The End of Earth by John Uri Lloyd",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/etidorhpa-or-the-end-of-earth.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/etidorhpa-or-the-end-of-earth.txt",
    genre: "Science Fiction & Fantasy, All"
  },
  {
    id: 121,
    title: "Five Children and It by E. Nesbit",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/five-children-and-it.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/five-children-and-it.txt",
    genre: "Children's Literature, Science Fiction & Fantasy, All"
  },
  {
    id: 122,
    title: "Herland by Charlotte Perkins Gilman",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/herland.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/herland.txt",
    genre: "Science Fiction & Fantasy, Social & Political, All"
  },
  {
    id: 123,
    title: "Frankenstein by Mary Wollstonecraft Shelley",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/frankenstein.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/frankenstein.txt",
    genre: "Classics, Gothic, Science Fiction & Fantasy, All"
  },
  {
    id: 124,
    title: "L'Île Mystérieuse by Jules Verne",
    // sometimes spelled "lile-mysterieuse" in your snippet
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/lile-mysterieuse.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/lile-mysterieuse.txt",
    genre: "Science Fiction & Fantasy, Adventure, All"
  },
  {
    id: 125,
    title: "King Solomon's Mines by H. Rider Haggard",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/king-solomons-mines.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/king-solomons-mines.txt",
    genre: "Adventure, Science Fiction & Fantasy, All"
  },
  {
    id: 126,
    title: "Metamorphosis by Franz Kafka",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/metamorphosis.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/metamorphosis.txt",
    genre: "Classics, Horror & Gothic, All"
  },
  {
    id: 127,
    title: "Peter Pan by J. M. Barrie",
    // Note: This also appears at id 61 or 127 in some lists
    // Ensure no duplicates or remove one if needed
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/peter-pan.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/peter-pan.txt",
    genre: "Children's Literature, Classics, Fantasy, All"
  },
  {
    id: 128,
    title: "Swiss Family Robinson by Johann David Wyss",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/swiss-family-robinson.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/swiss-family-robinson.txt",
    genre: "Adventure, Children's Literature, All"
  },
  {
    id: 129,
    title: "She by H. Rider Haggard",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/she.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/she.txt",
    genre: "Adventure, Science Fiction & Fantasy, All"
  },
  {
    id: 130,
    title: "The Book of Dragons by Edith Nesbit",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-book-of-dragons.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-book-of-dragons.txt",
    genre: "Science Fiction & Fantasy, Children's Literature, All"
  },
  {
    id: 131,
    title: "The Call of the Wild by Jack London",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-call-of-the-wild.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-call-of-the-wild.txt",
    genre: "Adventure, Classics, All"
  },
  {
    id: 132,
    title: "The Dunwich Horror by H. P. Lovecraft",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-dunwich-horror.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-dunwich-horror.txt",
    genre: "Horror & Gothic, Science Fiction & Fantasy, All"
  },
  {
    id: 133,
    title: "The Coming Race by Edward Bulwer-Lytton",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-coming-race.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-coming-race.txt",
    genre: "Science Fiction & Fantasy, All"
  },
  {
    id: 134,
    title: "The Island of Doctor Moreau by H. G. Wells",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-island-of-doctor-moreau.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-island-of-doctor-moreau.txt",
    genre: "Science Fiction & Fantasy, Classics, All"
  },
  {
    id: 135,
    title: "The Invisible Man: A Grotesque Romance by H. G. Wells",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-invisible-man-a-grotesque-romance.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-invisible-man-a-grotesque-romance.txt",
    genre: "Science Fiction & Fantasy, Classics, All"
  },
  {
    id: 136,
    title: "The King in Yellow by Robert W. Chambers",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-king-in-yellow.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-king-in-yellow.txt",
    genre: "Horror & Gothic, Mystery & Thriller, All"
  },
  {
    id: 137,
    title: "The Princess and the Goblin by George MacDonald",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-princess-and-the-goblin.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-princess-and-the-goblin.txt",
    genre: "Children's Literature, Science Fiction & Fantasy, All"
  },
  {
    id: 138,
    title: "The House on the Borderland by William Hope Hodgson",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-house-on-the-borderland.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-house-on-the-borderland.txt",
    genre: "Horror & Gothic, Science Fiction & Fantasy, All"
  },
  {
    id: 139,
    title: "The Last Man by Mary Wollstonecraft Shelley",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-last-man.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-last-man.txt",
    genre: "Science Fiction & Fantasy, Classics, All"
  },
  {
    id: 140,
    title: "The Time Machine by H. G. Wells",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-time-machine.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-time-machine.txt",
    genre: "Science Fiction & Fantasy, Classics, All"
  },
  {
    id: 141,
    title: "The War of the Worlds by H. G. Wells",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-war-of-the-worlds.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-war-of-the-worlds.txt",
    genre: "Science Fiction & Fantasy, Classics, All"
  },
  {
    id: 142,
    title: "The Wonderful Wizard of Oz by L. Frank Baum",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-wonderful-wizard-of-oz.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-wonderful-wizard-of-oz.txt",
    genre: "Children's Literature, Fantasy, All"
  },
  {
    id: 143,
    title: "The Secret Garden by Frances Hodgson Burnett",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-secret-garden.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-secret-garden.txt",
    genre: "Children's Literature, Classics, All"
  },
  {
    id: 144,
    title: "The Tale of Peter Rabbit by Beatrix Potter",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-tale-of-peter-rabbit.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-tale-of-peter-rabbit.txt",
    genre: "Children's Literature, All"
  },
  {
    id: 145,
    title: "The Tale of Tom Kitten by Beatrix Potter",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-tale-of-tom-kitten.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-tale-of-tom-kitten.txt",
    genre: "Children's Literature, All"
  },
  {
    id: 146,
    title: "The Vampyre; A Tale by John William Polidori",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-vampyre-a-tale.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-vampyre-a-tale.txt",
    genre: "Horror & Gothic, All"
  },
  {
    id: 147,
    title: "Twenty Thousand Leagues Under the Sea by Jules Verne",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/twenty-thousand-leagues-under-the-sea.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/twenty-thousand-leagues-under-the-sea.txt",
    genre: "Science Fiction & Fantasy, Adventure, All"
  },
  {
    id: 148,
    title: "We by Yevgeny Zamyatin",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/we.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/we.txt",
    genre: "Science Fiction & Fantasy, Social & Political, All"
  },
  {
    id: 149,
    title: "813 by Maurice Leblanc",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/813.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/813.txt",
    genre: "Mystery & Thriller, All"
  },
  {
    id: 150,
    title: "A Study in Scarlet by Arthur Conan Doyle",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-study-in-scarlet.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-study-in-scarlet.txt",
    genre: "Mystery & Thriller, Classics, All"
  },
  {
    id: 151,
    title: "Arsène Lupin, Gentleman Burglar by Maurice Leblanc",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/arsene-lupin-gentleman-cambrioleur.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/arsene-lupin-gentleman-cambrioleur.txt",
    genre: "Mystery & Thriller, Adventure, All"
  },
  {
    id: 152,
    title: "A Hero of Our Time by Mikhail Lermontov",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-hero-of-our-time.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-hero-of-our-time.txt",
    genre: "Classics, Mystery & Thriller, All"
  },
  {
    id: 153,
    title: "Carmilla by Sheridan Le Fanu",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/carmilla.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/carmilla.txt",
    genre: "Horror & Gothic, Mystery & Thriller, All"
  },
  {
    id: 154,
    title: "Captain Blood by Rafael Sabatini",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/captain-blood.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/captain-blood.txt",
    genre: "Adventure, Historical, All"
  },
  {
    id: 155,
    title: "Brewster's Millions by George Barr McCutcheon",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/brewsters-millions.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/brewsters-millions.txt",
    genre: "Satire, Mystery & Thriller, All"
  },
  {
    id: 156,
    title: "Dangerous Connections V 1, 2, 3, 4 by Pierre Choderlos de Laclos",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/dangerous-connections-v-1,-2,-3,-4.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/dangerous-connections-v-1,-2,-3,-4.txt",
    genre: "Classics, Romance, All"
  },
  {
    id: 157,
    title: "Dracula by Bram Stoker",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/dracula.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/dracula.txt",
    genre: "Horror & Gothic, Classics, All"
  },
  {
    id: 158,
    title: "Ghost Stories of an Antiquary by M. R. James",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/ghost-stories-of-an-antiquary.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/ghost-stories-of-an-antiquary.txt",
    genre: "Horror & Gothic, Mystery & Thriller, All"
  },
  {
    id: 159,
    title: "Guy Falconer by William Le Queux",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/guy-falconer.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/guy-falconer.txt",
    genre: "Mystery & Thriller, All"
  },
  {
    id: 160,
    title: "Famous Modern Ghost Stories by Various Authors",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/famous-modern-ghost-stories.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/famous-modern-ghost-stories.txt",
    genre: "Mystery & Thriller, Horror & Gothic, All"
  },
  {
    id: 161,
    title: "Hidden Symbolism of Alchemy and the Occult Arts by Herbert Silberer",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/hidden-symbolism-of-alchemy-and-the-occult-arts.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/hidden-symbolism-of-alchemy-and-the-occult-arts.txt",
    genre: "Mystery & Thriller, Religion & Spirituality, Non-Fiction, All"
  },
  {
    id: 162,
    title: "Lady Audley's Secret by Mary Elizabeth Braddon",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/lady-audleys-secret.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/lady-audleys-secret.txt",
    genre: "Mystery & Thriller, Classics, All"
  },
  {
    id: 163,
    title: "Minute Mysteries [Detectograms] by Various Authors",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/minute-mysteries-%5Bdetectograms%5D.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/minute-mysteries-%5Bdetectograms%5D.txt",
    genre: "Mystery & Thriller, All"
  },
  {
    id: 164,
    title: "Le Comte de Monte Cristo Tome I by Alexandre Dumas",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/le-comte-de-monte-cristo-tome-I.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/le-comte-de-monte-cristo-tome-I.txt",
    genre: "Mystery & Thriller, Adventure, Classics, All"
  },
  {
    id: 165,
    title: "Tales of Terror and Mystery by Various Authors",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/tales-of-terror-and-mystery.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/tales-of-terror-and-mystery.txt",
    genre: "Mystery & Thriller, Horror & Gothic, All"
  },
  {
    id: 166,
    title: "The Adventures of Sherlock Holmes by Arthur Conan Doyle",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-adventures-of-sherlock.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-adventures-of-sherlock.txt",
    genre: "Mystery & Thriller, Classics, All"
  },
  {
    id: 167,
    title: "The Blonde Lady by Maurice Leblanc",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-blonde-lady.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-blonde-lady.txt",
    genre: "Mystery & Thriller, Adventure, All"
  },
  {
    id: 168,
    title: "The Crystal Stopper by Maurice Leblanc",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-crystal-stopper.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-crystal-stopper.txt",
    genre: "Mystery & Thriller, Adventure, All"
  },
  {
    id: 169,
    title: "The Cask by Freeman Wills Crofts",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-cask.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-cask.txt",
    genre: "Mystery & Thriller, All"
  },
  {
    id: 170,
    title: "The Hound of the Baskervilles by Arthur Conan Doyle",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-hound-of-the-baskervilles.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-hound-of-the-baskervilles.txt",
    genre: "Mystery & Thriller, Horror & Gothic, Classics, All"
  },
  {
    id: 171,
    title: "The Murder of Roger Ackroyd by Agatha Christie",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-murder-of-roger-ackroyd.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-murder-of-roger-ackroyd.txt",
    genre: "Mystery & Thriller, Classics, All"
  },
  {
    id: 172,
    title: "The Mysterious Affair at Styles by Agatha Christie",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-mysterious-affair-at-styles.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-mysterious-affair-at-styles.txt",
    genre: "Mystery & Thriller, Classics, All"
  },
  {
    id: 173,
    title: "The Memoirs of Sherlock Holmes by Arthur Conan Doyle",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-memoirs-of-sherlock-holmes.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-memoirs-of-sherlock-holmes.txt",
    genre: "Mystery & Thriller, Classics, All"
  },
  {
    id: 174,
    title: "The Moonstone by Wilkie Collins",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-moonstone.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-moonstone.txt",
    genre: "Mystery & Thriller, Historical, Classics, All"
  },
  {
    id: 175,
    title: "The Mysterious Stranger and Other Stories by Mark Twain",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-mysterious-stranger-and-other-stories.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-mysterious-stranger-and-other-stories.txt",
    genre: "Mystery & Thriller, Satire, All"
  },
  {
    id: 176,
    title: "The Mystery of the Blue Train by Agatha Christie",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-mystery-of-the-blue-train.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-mystery-of-the-blue-train.txt",
    genre: "Mystery & Thriller, Classics, All"
  },
  {
    id: 177,
    title: "The Return of Sherlock Holmes by Arthur Conan Doyle",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-return-of-sherlock-holmes.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-return-of-sherlock-holmes.txt",
    genre: "Mystery & Thriller, Classics, All"
  },
  {
    id: 178,
    title: "The Sign of the Four by Arthur Conan Doyle",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-sign-of-the-four.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-sign-of-the-four.txt",
    genre: "Mystery & Thriller, Adventure, Classics, All"
  },
  {
    id: 179,
    title: "The Red House Mystery by A.A. Milne",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-red-house-mystery.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-red-house-mystery.txt",
    genre: "Mystery & Thriller, All"
  },
  {
    id: 180,
    title: "The Valley of Fear by Arthur Conan Doyle",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-valley-of-fear.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-valley-of-fear.txt",
    genre: "Mystery & Thriller, Classics, All"
  },
  {
    id: 181,
    title: "The Secret Tomb by Maurice Leblanc",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-secret-tomb.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-secret-tomb.txt",
    genre: "Mystery & Thriller, Adventure, All"
  },
  {
    id: 182,
    title: "Uncle Silas: A Tale of Bartram-Haugh by J. Sheridan Le Fanu",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/uncle-silas-a-tale-of-bartram-haugh.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/uncle-silas-a-tale-of-bartram-haugh.txt",
    genre: "Mystery & Thriller, Horror & Gothic, Classics, All"
  },
  {
    id: 183,
    title: "Whose Body? by Dorothy L. Sayers",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/whose-body.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/whose-body.txt",
    genre: "Mystery & Thriller, All"
  },
  {
    id: 184,
    title: "Wieland or the Transformation: An American Tale by Charles Brockden Brown",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/wieland-or-the-transformation-an-american-tale.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/wieland-or-the-transformation-an-american-tale.txt",
    genre: "Mystery & Thriller, Horror & Gothic, All"
  },
  {
    id: 185,
    title: "Also sprach Zarathustra by Friedrich Nietzsche",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/also-sprach-zarathustra.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/also-sprach-zarathustra.txt",
    genre: "Philosophy, All"
  },
  // Note: ID 186 presumably missing or skipped in your data
  {
    id: 187,
    title: "A Vindication of the Rights of Woman by Mary Wollstonecraft",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-vindication-of-the-rights-of-woman.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-vindication-of-the-rights-of-woman.txt",
    genre: "Philosophy, Social & Political, All"
  },
  {
    id: 188,
    title: "Beyond Good and Evil by Friedrich Nietzsche",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/beyond-good-and-evil.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/beyond-good-and-evil.txt",
    genre: "Philosophy, All"
  },
  {
    id: 189,
    title: "A Discourse Upon the Origin and the Foundation of the Inequality Among Mankind by Jean-Jacques Rousseau",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-discourse-upon-the-origin-and-the-foundation-of-the-inequality-among-mankind.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-discourse-upon-the-origin-and-the-foundation-of-the-inequality-among-mankind.txt",
    genre: "Philosophy, Social & Political, All"
  },
  {
    id: 190,
    title: "An Essay Concerning Human Understanding Volume 1 by John Locke",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/an-essay-concerning-humane-understanding-volume-1.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/an-essay-concerning-humane-understanding-volume-1.txt",
    genre: "Philosophy, Science & Mathematics, All"
  },
  {
    id: 191,
    title: "Apology, Crito, and Phaedo of Socrates by Plato",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/apology-crito-and-phaedo-of-socrates.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/apology-crito-and-phaedo-of-socrates.txt",
    genre: "Philosophy, Classics, All"
  },
  {
    id: 192,
    title: "Aristotle on the Art of Poetry by Aristotle",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/aristotle-on-the-art-of-poetry.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/aristotle-on-the-art-of-poetry.txt",
    genre: "Philosophy, Poetry, All"
  },
  {
    id: 193,
    title: "Apology by Plato",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/apology.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/apology.txt",
    genre: "Philosophy, Classics, All"
  },
  {
    id: 194,
    title: "Areopagitica by John Milton",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/areopagitica.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/areopagitica.txt",
    genre: "Philosophy, Social & Political, All"
  },
  {
    id: 195,
    title: "Common Sense by Thomas Paine",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/common-sense.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/common-sense.txt",
    genre: "Philosophy, Social & Political, All"
  },
  {
    id: 196,
    title: "Dao de Jing: A Minimalist Translation by Laozi",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/dao-de-jing-a-minimalist-translation.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/dao-de-jing-a-minimalist-translation.txt",
    genre: "Philosophy, Religion & Spirituality, All"
  },
  {
    id: 197,
    title: "Dialogues Concerning Natural Religion by David Hume",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/dialogues-concerning-natural-religion.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/dialogues-concerning-natural-religion.txt",
    genre: "Philosophy, Religion & Spirituality, All"
  },
  {
    id: 198,
    title: "Discourse on the Method of Rightly Conducting One's Reason and of Seeking Truth in the Sciences by René Descartes",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/discourse-on-the-method-of-rightly-conducting-ones-reason-and-of-seeking-truth-in-the-sciences.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/discourse-on-the-method-of-rightly-conducting-ones-reason-and-of-seeking-truth-in-the-sciences.txt",
    genre: "Philosophy, Science & Mathematics, All"
  },
  {
    id: 199,
    title: "Democracy and Education: An Introduction to the Philosophy of Education by John Dewey",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/democracy-and-education-an-introduction-to-the-philosophy-of-education.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/democracy-and-education-an-introduction-to-the-philosophy-of-education.txt",
    genre: "Philosophy, Social & Political, All"
  },
  {
    id: 200,
    title: "Ecce Homo by Friedrich Nietzsche",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/ecce-homo.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/ecce-homo.txt",
    genre: "Philosophy, Biography & Autobiography, All"
  },
  {
    id: 201,
    title: "Emile by Jean-Jacques Rousseau",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/emile.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/emile.txt",
    genre: "Philosophy, Social & Political, Education, All"
  },
  {
    id: 202,
    title: "Essays by Ralph Waldo Emerson",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/essays-by-ralph-waldo-emerson.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/essays-by-ralph-waldo-emerson.txt",
    genre: "Philosophy, Non-Fiction, Classics, All"
  },
  {
    id: 203,
    title: "Essays of Michel de Montaigne by Michel de Montaigne",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/essays-of-michel-de-montaigne.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/essays-of-michel-de-montaigne.txt",
    genre: "Philosophy, Non-Fiction, All"
  },
  {
    id: 204,
    title: "Essays of Schopenhauer by Arthur Schopenhauer",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/essays-of-schopenhauer.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/essays-of-schopenhauer.txt",
    genre: "Philosophy, Non-Fiction, All"
  },
  {
    id: 205,
    title: "Ethics by Benedict de Spinoza",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/ethics.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/ethics.txt",
    genre: "Philosophy, Religion & Spirituality, All"
  },
  {
    id: 206,
    title: "Fundamental Principles of the Metaphysic of Morals by Immanuel Kant",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/fundamental-principles-of-the-metaphysic-of-morals.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/fundamental-principles-of-the-metaphysic-of-morals.txt",
    genre: "Philosophy, Non-Fiction, All"
  },
  {
    id: 207,
    title: "Heretics by G. K. Chesterton",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/heretics.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/heretics.txt",
    genre: "Philosophy, Religion & Spirituality, Satire, All"
  },
  {
    id: 208,
    title: "Laws by Plato",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/laws.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/laws.txt",
    genre: "Philosophy, Historical, Social & Political, All"
  },
  {
    id: 209,
    title: "Leviathan by Thomas Hobbes",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/leviathan.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/leviathan.txt",
    genre: "Philosophy, Social & Political, All"
  },
  {
    id: 210,
    title: "Laughter: An Essay on the Meaning of the Comic by Henri Bergson",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/laughter-an-essay-on-the-meaning-of-the-comic.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/laughter-an-essay-on-the-meaning-of-the-comic.txt",
    genre: "Philosophy, Satire, All"
  },
  {
    id: 211,
    title: "Meditations by Marcus Aurelius",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/meditations.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/meditations.txt",
    genre: "Philosophy, Religion & Spirituality, Biography & Autobiography, All"
  },
  {
    id: 212,
    title: "Man and Superman: A Comedy and a Philosophy by George Bernard Shaw",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/man-and-superman-a-comedy-and-a-philosophy.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/man-and-superman-a-comedy-and-a-philosophy.txt",
    genre: "Philosophy, Drama & Plays, Satire, All"
  },
  {
    id: 213,
    title: "On Liberty by John Stuart Mill",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/on-liberty.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/on-liberty.txt",
    genre: "Philosophy, Social & Political, Classics, All"
  },
  {
    id: 214,
    title: "On the Nature of Things by Lucretius",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/on-the-nature-of-things.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/on-the-nature-of-things.txt",
    genre: "Philosophy, Science & Mathematics, Mythology & Legends, All"
  },
  {
    id: 215,
    title: "Perpetual Peace: A Philosophical Essay by Immanuel Kant",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/perpetual-peace-a-philosophical-essay.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/perpetual-peace-a-philosophical-essay.txt",
    genre: "Philosophy, Social & Political, All"
  },
  {
    id: 216,
    title: "Politics: A Treatise on Government by Aristotle",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/politics-a-treatise-on-government.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/politics-a-treatise-on-government.txt",
    genre: "Philosophy, Historical, Social & Political, All"
  },
  {
    id: 217,
    title: "Phaedo, Phaedrus, Symposium by Plato",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/phaedo.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/phaedo.txt",
    genre: "Philosophy, Historical, All"
  },
  {
    id: 218,
    title: "Pragmatism: A New Name for Some Old Ways of Thinking by William James",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/pragmatism-a-new-name-for-some-old-ways-of-thinking.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/pragmatism-a-new-name-for-some-old-ways-of-thinking.txt",
    genre: "Philosophy, Non-Fiction, All"
  },
  {
    id: 219,
    title: "Principia Ethica by G. E. Moore",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/principia-ethica.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/principia-ethica.txt",
    genre: "Philosophy, Non-Fiction, All"
  },
  {
    id: 220,
    title: "Relativity: The Special and General Theory by Albert Einstein",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/relativity-the-special-and-general-theory.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/relativity-the-special-and-general-theory.txt",
    genre: "Philosophy, Science & Mathematics, Non-Fiction, All"
  },
  {
    id: 221,
    title: "The Art of War by Sun Tzu",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-art-of-war.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-art-of-war.txt",
    genre: "Philosophy, Historical, Social & Political, All"
  },
  {
    id: 222,
    title: "The Birth of Tragedy by Friedrich Nietzsche",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-birth-of-tragedy.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-birth-of-tragedy.txt",
    genre: "Philosophy, Drama & Plays, All"
  },
  {
    id: 223,
    title: "The Analects of Confucius by Confucius",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-analects-of-confucius.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-analects-of-confucius.txt",
    genre: "Philosophy, Religion & Spirituality, Historical, All"
  },
  {
    id: 224,
    title: "The Consolation of Philosophy by Boethius",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-consolation-of-philosophy.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-consolation-of-philosophy.txt",
    genre: "Philosophy, Religion & Spirituality, All"
  },
  {
    id: 225,
    title: "The Critique of Pure Reason by Immanuel Kant",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-critique-of-pure-reason.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-critique-of-pure-reason.txt",
    genre: "Philosophy, Science & Mathematics, All"
  },
  {
    id: 226,
    title: "The Dawn of Day by Friedrich Nietzsche",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-dawn-of-day.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-dawn-of-day.txt",
    genre: "Philosophy, Non-Fiction, All"
  },
  {
    id: 227,
    title: "The Ethics of Aristotle by Aristotle",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-ethics-of-aristotle.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-ethics-of-aristotle.txt",
    genre: "Philosophy, Historical, All"
  },
  {
    id: 228,
    title: "The Genealogy of Morals by Friedrich Nietzsche",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-genealogy-of-morals.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-genealogy-of-morals.txt",
    genre: "Philosophy, All"
  },
  {
    id: 229,
    title: "The Imitation of Christ by Thomas à Kempis",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-imitation-of-christ.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-imitation-of-christ.txt",
    genre: "Philosophy, Religion & Spirituality, All"
  },
  {
    id: 230,
    title: "The Prince by Niccolò Machiavelli",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-prince.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-prince.txt",
    genre: "Philosophy, Social & Political, Historical, All"
  },
  {
    id: 231,
    title: "The Joyful Wisdom (La Gaya Scienza) by Friedrich Nietzsche",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-joyful-wisdom-(la-gaya-scienza).png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-joyful-wisdom-(la-gaya-scienza).txt",
    genre: "Philosophy, All"
  },
  {
    id: 232,
    title: "The Problems of Philosophy by Bertrand Russell",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-problems-of-philosophy.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-problems-of-philosophy.txt",
    genre: "Philosophy, Science & Mathematics, All"
  },
  {
    id: 233,
    title: "The Meaning of Relativity by Albert Einstein",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-meaning-of-relativity.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-meaning-of-relativity.txt",
    genre: "Philosophy, Science & Mathematics, Non-Fiction, All"
  },
  {
    id: 234,
    title: "The Polity of the Athenians and the Lacedaemonians by Xenophon",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-polity-of-the-athenians-and-the-lacedaemonians.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-polity-of-the-athenians-and-the-lacedaemonians.txt",
    genre: "Philosophy, Historical, Social & Political, All"
  },
  {
    id: 235,
    title: "The Kama Sutra",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-kama-sutra.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-kama-sutra.txt",
    genre: "Philosophy, Religion & Spirituality, Historical, All"
  },
  {
    id: 236,
    title: "The Republic by Plato",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-republic.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-republic.txt",
    genre: "Philosophy, Social & Political, All"
  },
  {
    id: 237,
    title: "The Tao-Teh-King or The Tao and Its Characteristics by Laozi",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-tao-teh-king-or-the-tao-and-its-characteristics.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-tao-teh-king-or-the-tao-and-its-characteristics.txt",
    genre: "Philosophy, Religion & Spirituality, All"
  },
  {
    id: 238,
    title: "The Twilight of the Idols or How to Philosophize with the Hammer by Friedrich Nietzsche",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-twilight-of-the-idols-or-how-to-philosophize-with-the-hammer.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-twilight-of-the-idols-or-how-to-philosophize-with-the-hammer.txt",
    genre: "Philosophy, Social & Political, All"
  },
  {
    id: 239,
    title: "The Will to Believe and Other Essays in Popular Philosophy by William James",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-will-to-believe-and-other-essays-in-popular-philosophy.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-will-to-believe-and-other-essays-in-popular-philosophy.txt",
    genre: "Philosophy, Religion & Spirituality, All"
  },
  {
    id: 240,
    title: "The Will to Power: An Attempted Transvaluation of all Values Book I and II by Friedrich Nietzsche",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-will-to-power-an-attempted-transvaluation-of-all-values-book-I-and-II.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-will-to-power-an-attempted-transvaluation-of-all-values-book-I-and-II.txt",
    genre: "Philosophy, Social & Political, All"
  },
  {
    id: 241,
    title: "Tractatus Logico-Philosophicus by Ludwig Wittgenstein",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/tractatus-logico.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/tractatus-logico.txt",
    genre: "Philosophy, Science & Mathematics, All"
  },
  {
    id: 242,
    title: "Thus Spake Zarathustra by Friedrich Nietzsche",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/thus-spake-zarathustra.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/thus-spake-zarathustra.txt",
    genre: "Philosophy, Religion & Spirituality, All"
  },
  {
    id: 243,
    title: "Tragic Sense of Life by Miguel de Unamuno",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/tragic-sense-of-life.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/tragic-sense-of-life.txt",
    genre: "Philosophy, Religion & Spirituality, All"
  },
  {
    id: 244,
    title: "Utopia by Thomas More",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/utopia.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/utopia.txt",
    genre: "Philosophy, Social & Political, Historical, All"
  },
  {
    id: 245,
    title: "In Praise of Folly by Desiderius Erasmus",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/in-praise-of-folly.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/in-praise-of-folly.txt",
    genre: "Philosophy, Satire, Historical, All"
  },
  {
    id: 246,
    title: "Institutes of the Christian Religion (Vol. 1 of 2) by John Calvin",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/institutes-of-the-christian-religion-(vol.-1-of-2).png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/institutes-of-the-christian-religion-(vol.-1-of-2).txt",
    genre: "Philosophy, Religion & Spirituality, Historical, All"
  },
  {
    id: 247,
    title: "A Doll's House by Henrik Ibsen",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-doll-s-house.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-doll-s-house.txt",
    genre: "Drama & Plays, Social & Political, Classics, All"
  },
  {
    id: 248,
    title: "A Midsummer Night's Dream by William Shakespeare",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-midsummer-nights-dream.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-midsummer-nights-dream.txt",
    genre: "Drama & Plays, Classics, All"
  },
  {
    id: 249,
    title: "Hamlet in Greek by William Shakespeare",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/hamlet-in-greek.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/hamlet-in-greek.txt",
    genre: "Drama & Plays, Classics, All"
  },
  {
    id: 250,
    title: "An Enemy of the People by Henrik Ibsen",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/an-enemy-of-the-people.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/an-enemy-of-the-people.txt",
    genre: "Drama & Plays, Social & Political, Classics, All"
  },
  {
    id: 251,
    title: "A Woman of No Importance by Oscar Wilde",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-woman-of-no-importance.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-woman-of-no-importance.txt",
    genre: "Drama & Plays, Satire, Social & Political, All"
  },
  {
    id: 252,
    title: "A Modest Proposal by Jonathan Swift",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-modest-proposal.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-modest-proposal.txt",
    genre: "Satire, Social & Political, Classics, All"
  },
  {
    id: 253,
    title: "A Tale of a Tub by Jonathan Swift",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-tale-of-a-tub.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-tale-of-a-tub.txt",
    genre: "Satire, Philosophy, Social & Political, All"
  },
  {
    id: 254,
    title: "A Child's Dream of a Star by Charles Dickens",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-childs-dream-of-a-star.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-childs-dream-of-a-star.txt",
    genre: "Children's Literature, Drama & Plays, All"
  },
  {
    id: 255,
    title: "Das Leben und der Tod des Königs Lear by William Shakespeare",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/das-leben-und-der-tod-des-k%C3%B6nigs-lear.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/das-leben-und-der-tod-des-k%C3%B6nigs-lear.txt",
    genre: "Drama & Plays, Classics, Historical, All"
  },
  {
    id: 256,
    title: "Every Man in His Humor by Ben Jonson",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/every-man-in-his-humor.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/every-man-in-his-humor.txt",
    genre: "Drama & Plays, Satire, All"
  },
  {
    id: 257,
    title: "Everyman with Other Interludes including Eight Miracle Plays by Anonymous",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/everyman-with-other-interludes-including-eight-miracle-plays.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/everyman-with-other-interludes-including-eight-miracle-plays.txt",
    genre: "Drama & Plays, Religion & Spirituality, Classics, All"
  },
  {
    id: 258,
    title: "Hamlet (French) by William Shakespeare",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/hamlet-(french).png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/hamlet-(french).txt",
    genre: "Drama & Plays, Classics, Historical, All"
  },
  // Note: there's no 259 in your original list
  {
    id: 260,
    title: "Hamlet Prinz von Dänemark by William Shakespeare",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/hamlet-prinz-von-d%C3%A4nemark.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/hamlet-prinz-von-d%C3%A4nemark.txt",
    genre: "Drama & Plays, Classics, All"
  },
  {
    id: 261,
    title: "Hamlet the Prince of Denmark by William Shakespeare",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/hamlet-the-prince-of-denmark.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/hamlet-the-prince-of-denmark.txt",
    genre: "Drama & Plays, Classics, All"
  },
  {
    id: 262,
    title: "Hamleto Regido da Danujo by William Shakespeare",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/hamleto-regido-da-danujo.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/hamleto-regido-da-danujo.txt",
    genre: "Drama & Plays, Classics, All"
  },
  {
    id: 263,
    title: "King Lear by William Shakespeare",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/king-lear.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/king-lear.txt",
    genre: "Drama & Plays, Classics, All"
  },
  {
    id: 264,
    title: "Macbeth by William Shakespeare",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/macbeth.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/macbeth.txt",
    genre: "Drama & Plays, Classics, All"
  },
  {
    id: 265,
    title: "Lysistrata by Aristophanes",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/lysistrata.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/lysistrata.txt",
    genre: "Drama & Plays, Classics, All"
  },
  {
    id: 266,
    title: "Life is a Dream by Pedro Calderón de la Barca",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/life-is-a-dream.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/life-is-a-dream.txt",
    genre: "Drama & Plays, Classics, All"
  },
  {
    id: 267,
    title: "Medea of Euripides by Euripides",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/medea-of-euripides.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/medea-of-euripides.txt",
    genre: "Drama & Plays, Classics, All"
  },
  {
    id: 268,
    title: "Oedipus King of Thebes by Sophocles",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/oedipus-king-of-thebes.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/oedipus-king-of-thebes.txt",
    genre: "Drama & Plays, Classics, All"
  },
  {
    id: 269,
    title: "Much Ado About Nothing by William Shakespeare",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/much-ado-about-nothing.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/much-ado-about-nothing.txt",
    genre: "Drama & Plays, Classics, All"
  },
  {
    id: 270,
    title: "Othello, the Moor of Venice by William Shakespeare",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/othello-the-moor-of-venice.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/othello-the-moor-of-venice.txt",
    genre: "Drama & Plays, Classics, All"
  },
  {
    id: 271,
    title: "Pygmalion by George Bernard Shaw",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/pygmalion.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/pygmalion.txt",
    genre: "Drama & Plays, All"
  },
  {
    id: 272,
    title: "Romeo and Juliet by William Shakespeare",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/romeo-juliet.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/romeo-juliet.txt",
    genre: "Drama & Plays, Classics, All"
  },
  {
    id: 273,
    title: "Salomé: A Tragedy in One Act by Oscar Wilde",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/salome-a-tragedy-in-one-act.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/salome-a-tragedy-in-one-act.txt",
    genre: "Drama & Plays, All"
  },
  {
    id: 274,
    title: "Sakoontala or The Lost Ring: An Indian Drama by Kalidasa",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/sakoontala-or-the-lost-ring-an-indian-drama.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/sakoontala-or-the-lost-ring-an-indian-drama.txt",
    genre: "Drama & Plays, Classics, All"
  },
  {
    id: 275,
    title: "Puphejmo Dramo en tri Aktoj by Nikolaj Nekrasov",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/puphejmo-dramo-en-tri-aktoj.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/puphejmo-dramo-en-tri-aktoj.txt",
    genre: "Drama & Plays, All"
  },
  {
    id: 276,
    title: "She Stoops to Conquer by Oliver Goldsmith",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/she-stoops-to-conquer.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/she-stoops-to-conquer.txt",
    genre: "Drama & Plays, Classics, All"
  },
  {
    id: 277,
    title: "Plays of Sophocles: Oedipus the King, Oedipus at Colonus, Antigone by Sophocles",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/plays-of-sophocles-oedipus-the-king-oedipus-at-colonus-antigone.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/plays-of-sophocles-oedipus-the-king-oedipus-at-colonus-antigone.txt",
    genre: "Drama & Plays, Classics, All"
  },
  {
    id: 278,
    title: "Plays: The Father, Countess Julie, The Outlaw, The Stronger by August Strindberg",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/plays-the-father-countess-julie-the-outlaw-the-stronger.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/plays-the-father-countess-julie-the-outlaw-the-stronger.txt",
    genre: "Drama & Plays, All"
  },
  {
    id: 279,
    title: "Phaedra by Lucius Annaeus Seneca",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/phaedra.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/phaedra.txt",
    genre: "Drama & Plays, Classics, All"
  },
  {
    id: 280,
    title: "Tartuffe or The Hypocrite by Molière",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/tartuffe-or-the-hypocrite.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/tartuffe-or-the-hypocrite.txt",
    genre: "Drama & Plays, Classics, All"
  },
  {
    id: 281,
    title: "The Beggar's Opera",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-beggars-opera.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-beggars-opera.txt",
    genre: "Drama & Plays, All"
  },
  {
    id: 282,
    title: "The Fall of the House of Usher",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-fall-of-the-house-of-usher.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-fall-of-the-house-of-usher.txt",
    genre: "Drama & Plays, Horror & Gothic, All"
  },
  {
    id: 283,
    title: "The Hairy Ape",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-hairy-ape.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-hairy-ape.txt",
    genre: "Drama & Plays, All"
  },
  {
    id: 284,
    title: "The Imaginary Invalid by Molière",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-imaginary-invalid.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-imaginary-invalid.txt",
    genre: "Drama & Plays, Classics, All"
  },
  {
    id: 285,
    title: "The Importance of Being Earnest by Oscar Wilde",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-importance-of-being-earnest.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-importance-of-being-earnest.txt",
    genre: "Drama & Plays, Satire, All"
  },
  {
    id: 286,
    title: "The Inspector-General by Nikolai Gogol",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-inspector-general.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-inspector-general.txt",
    genre: "Drama & Plays, Satire, All"
  },
  {
    id: 287,
    title: "The Middle-Class Gentleman by Molière",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-middle-class-gentleman.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-middle-class-gentleman.txt",
    genre: "Drama & Plays, Classics, All"
  },
  {
    id: 288,
    title: "The Miser by Molière",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-miser.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-miser.txt",
    genre: "Drama & Plays, Classics, All"
  },
  {
    id: 289,
    title: "The Man Who Was Thursday: A Nightmare by G. K. Chesterton",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-man-who-was-thursday-a-nightmare.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-man-who-was-thursday-a-nightmare.txt",
    genre: "Drama & Plays, Mystery & Thriller, All"
  },
  {
    id: 290,
    title: "The School for Scandal by Richard Brinsley Sheridan",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-school-for-scandal.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-school-for-scandal.txt",
    genre: "Drama & Plays, Satire, All"
  },
  {
    id: 291,
    title: "The Sea Gull by Anton Chekhov",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-sea-gull.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-sea-gull.txt",
    genre: "Drama & Plays, All"
  },
  {
    id: 292,
    title: "The Tragedy of King Lear by William Shakespeare",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-tragedy-of-king-lear.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-tragedy-of-king-lear.txt",
    genre: "Drama & Plays, Classics, All"
  },
  {
    id: 293,
    title: "The Tragical History of Doctor Faustus by Christopher Marlowe",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-tragical-history-of-doctor-faustus.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-tragical-history-of-doctor-faustus.txt",
    genre: "Drama & Plays, Classics, All"
  },
  {
    id: 294,
    title: "The Robbers by Friedrich Schiller",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-robbers.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-robbers.txt",
    genre: "Drama & Plays, All"
  },
  {
    id: 295,
    title: "Twelfth Night; Or, What You Will by William Shakespeare",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/twelfth-night-or-what-you-will.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/twelfth-night-or-what-you-will.txt",
    genre: "Drama & Plays, Classics, All"
  },
  {
    id: 296,
    title: "Volpone; Or, The Fox by Ben Jonson",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/volpone-or-the-fox.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/volpone-or-the-fox.txt",
    genre: "Drama & Plays, Satire, All"
  },
  {
    id: 297,
    title: "Hobson's Choice: A Lancashire Comedy in Four Acts by Harold Brighouse",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/hobsons-choice-a-lancashire-comedy-in-four-acts.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/hobsons-choice-a-lancashire-comedy-in-four-acts.txt",
    genre: "Drama & Plays, All"
  },
  {
    id: 298,
    title: "A Brief Account of the Destruction of the Indies by Bartolome de las Casas",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-brief-account-of-the-destruction-of-the-indies.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-brief-account-of-the-destruction-of-the-indies.txt",
    genre: "Historical, All"
  },
  {
    id: 299,
    title: "A Short History of the World by H. G. Wells",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-short-history-of-the-world.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-short-history-of-the-world.txt",
    genre: "Historical, All"
  },
  {
    id: 300,
    title: "An Account of Egypt by Herodotus",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/an-account-of-egypt.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/an-account-of-egypt.txt",
    genre: "Historical, Classics, All"
  },
  {
    id: 301,
    title: "Agricola by Tacitus",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/agricola.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/agricola.txt",
    genre: "Historical, Classics, All"
  },
  {
    id: 302,
    title: "Antiquities of the Jews by Flavius Josephus",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/antiquities-of-the-jews.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/antiquities-of-the-jews.txt",
    genre: "Historical, Religion & Spirituality, All"
  },
  {
    id: 303,
    title: "After Life in Roman Paganism by Franz Cumont",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/after-life-in-roman-paganism.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/after-life-in-roman-paganism.txt",
    genre: "Historical, Religion & Spirituality, All"
  },
  {
    id: 304,
    title: "Boswell's Life of Johnson by James Boswell",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/boswells-life-of-johnson.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/boswells-life-of-johnson.txt",
    genre: "Biography & Autobiography, Historical, All"
  },
  {
    id: 305,
    title: "An Old Babylonian Version of the Gilgamesh Epic by Morris Jastrow",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-epic-of-gilgamesh.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-epic-of-gilgamesh.txt",
    genre: "Historical, Mythology & Legends, All"
  },
  {
    id: 306,
    title: "Apologia Pro Vita Sua by John Henry Newman",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/apologia-pro-vita-sua.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/apologia-pro-vita-sua.txt",
    genre: "Biography & Autobiography, Religion & Spirituality, All"
  },
  {
    id: 307,
    title: "De Bello Gallico and Other Commentaries by Julius Caesar",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/de-bello-gallico-and-other-commentaries.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/de-bello-gallico-and-other-commentaries.txt",
    genre: "Historical, Classics, All"
  },
  {
    id: 308,
    title: "Democracy in America Volume 1 by Alexis de Tocqueville",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/democracy-in-america-volume-1.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/democracy-in-america-volume-1.txt",
    genre: "Social & Political, Historical, All"
  },
  {
    id: 309,
    title: "Der Briefwechsel Zwischen Friedrich Engels und Karl Marx 1844 bis 1883 by Karl Marx & Friedrich Engels",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/der-briefwechsel-zwischen-friedrich-engels-und-karl-marx-1844-bis-1883.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/der-briefwechsel-zwischen-friedrich-engels-und-karl-marx-1844-bis-1883.txt",
    genre: "Social & Political, Historical, All"
  },
  {
    id: 310,
    title: "Fox's Book of Martyrs by John Foxe",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/foxs-book-of-martyrs.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/foxs-book-of-martyrs.txt",
    genre: "Historical, Religion & Spirituality, All"
  },
  {
    id: 311,
    title: "From Berlin to Bagdad and Babylon by F. M. A. V. Montagu",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/from-berlin-to-bagdad-and-babylon.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/from-berlin-to-bagdad-and-babylon.txt",
    genre: "Historical, Non-Fiction, All"
  },
  {
    id: 312,
    title: "Haida Texts and Myths by John Swanton",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/haida-texts-and-myths.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/haida-texts-and-myths.txt",
    genre: "Mythology & Legends, Historical, All"
  },
  {
    id: 313,
    title: "La Chartreuse de Parme by Stendhal",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/la-chartreuse-de-parme.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/la-chartreuse-de-parme.txt",
    genre: "Historical, Classics, All"
  },
  {
    id: 314,
    title: "Manners, Customs, and Dress during the Middle Ages and during the Renaissance Period by Paul Lacroix",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/manners-customs-and-dress-during-the-middle-ages-and-during-the-renaissance-period.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/manners-customs-and-dress-during-the-middle-ages-and-during-the-renaissance-period.txt",
    genre: "Historical, Non-Fiction, All"
  },
  {
    id: 315,
    title: "Martti Mestari ja Hänen Kisällinsä by Aleksis Kivi",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/martti-mestari-ja-h%C3%A4nen-kis%C3%A4llins%C3%A4.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/martti-mestari-ja-h%C3%A4nen-kis%C3%A4llins%C3%A4.txt",
    genre: "Historical, All"
  },
  {
    id: 316,
    title: "Memoirs of Napoleon Bonaparte Complete by Louis Antoine Fauvelet de Bourrienne",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/memoirs-of-napoleon-bonaparte-complete.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/memoirs-of-napoleon-bonaparte-complete.txt",
    genre: "Biography & Autobiography, Historical, All"
  },
  {
    id: 317,
    title: "Memoirs of the Court of Marie Antoinette, Queen of France, Complete by Madame Campan",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/memoirs-of-the-court-of-marie-antoinette-queen-of-france-complete.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/memoirs-of-the-court-of-marie-antoinette-queen-of-france-complete.txt",
    genre: "Biography & Autobiography, Historical, All"
  },
  {
    id: 318,
    title: "Narrative of the Life of Frederick Douglass, an American Slave by Frederick Douglass",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/narrative-of-the-life-of-frederick-douglass-an-american-slave.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/narrative-of-the-life-of-frederick-douglass-an-american-slave.txt",
    genre: "Biography & Autobiography, Historical, All"
  },
  {
    id: 319,
    title: "On Heroes, Hero-Worship, and the Heroic in History by Thomas Carlyle",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/on-heroes-hero-worship-and-the-heroic-in-history.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/on-heroes-hero-worship-and-the-heroic-in-history.txt",
    genre: "Historical, Philosophy, All"
  },
  {
    id: 320,
    title: "Plutarch's Lives of the Noble Grecians and Romans by Plutarch",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/plutarch-s-lives-of-the-noble-grecians-and-romans.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/plutarch-s-lives-of-the-noble-grecians-and-romans.txt",
    genre: "Historical, Classics, All"
  },
  {
    id: 321,
    title: "Quo Vadis: A Narrative of the Time of Nero by Henryk Sienkiewicz",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/quo-vadis-a-narrative-of-the-time-of-nero.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/quo-vadis-a-narrative-of-the-time-of-nero.txt",
    genre: "Historical, Classics, All"
  },
  {
    id: 322,
    title: "Scaramouche: A Romance of the French Revolution by Rafael Sabatini",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/scaramouche-a-romance-of-the-french-revolution.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/scaramouche-a-romance-of-the-french-revolution.txt",
    genre: "Historical, Romance, All"
  },
  {
    id: 323,
    title: "Plutarch's Lives Volume 1 (of 4) by Plutarch",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/plutarchs-lives-volume-1-(of-4).png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/plutarchs-lives-volume-1-(of-4).txt",
    genre: "Historical, Classics, All"
  },
  {
    id: 324,
    title: "Personal Memoirs of U.S. Grant (Complete) by Ulysses S. Grant",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/personal-memoirs-of-u-s-grant-(complete).png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/personal-memoirs-of-u-s-grant-(complete).txt",
    genre: "Biography & Autobiography, Historical, All"
  },
  {
    id: 325,
    title: "The Anglo-Saxon Chronicle",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-anglo-saxon-chronicle.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-anglo-saxon-chronicle.txt",
    genre: "Historical, Classics, All"
  },
  {
    id: 326,
    title: "The Anabasis of Alexander",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-anabasis-of-alexander.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-anabasis-of-alexander.txt",
    genre: "Historical, Classics, All"
  },
  {
    id: 327,
    title: "The Danish History Books I-IX",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-danish-history-books-I-IX.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-danish-history-books-I-IX.txt",
    genre: "Historical, All"
  },
  {
    id: 328,
    title: "The Germany and the Agricola of Tacitus",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-germany-and-the-agricola-of-tacitus.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-germany-and-the-agricola-of-tacitus.txt",
    genre: "Historical, Classics, All"
  },
  {
    id: 329,
    title: "The French Revolution: A History",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-french-revolution-a-history.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-french-revolution-a-history.txt",
    genre: "Historical, Social & Political, All"
  },
  {
    id: 330,
    title: "The History of Herodotus Volume 1",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-history-of-herodotus-volume-1.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-history-of-herodotus-volume-1.txt",
    genre: "Historical, Classics, All"
  },
  {
    id: 331,
    title: "The History of the Decline and Fall of the Roman Empire by Edward Gibbon",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-history-of-the-decline-and-fall-of-the-roman-empire.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-history-of-the-decline-and-fall-of-the-roman-empire.txt",
    genre: "Historical, Non-Fiction, All"
  },
  {
    id: 332,
    title: "The History of the Peloponnesian War by Thucydides",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-history-of-the-peloponnesian-war.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-history-of-the-peloponnesian-war.txt",
    genre: "Historical, Classics, All"
  },
  {
    id: 333,
    title: "The Magna Carta",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-magna-carta.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-magna-carta.txt",
    genre: "Historical, Non-Fiction, All"
  },
  {
    id: 334,
    title: "The Mahabharata of Krishna-Dwaipayana Vyasa",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-mahabharata-of-krishna-dwaipayana-vyasa.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-mahabharata-of-krishna-dwaipayana-vyasa.txt",
    genre: "Historical, Mythology & Legends, All"
  },
  {
    id: 335,
    title: "The Journals of Lewis and Clark 1804-1806 by Meriwether Lewis and William Clark",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-journals-of-lewis-and-clark-1804-1806.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-journals-of-lewis-and-clark-1804-1806.txt",
    genre: "Biography & Autobiography, Historical, All"
  },
  {
    id: 336,
    title: "The Story of Burnt Njal from the Icelandic of the Njal's Saga by George Webbe Dasent",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-story-of-burnt-njal-from-the-icelandic-of-the-njal-s-saga.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-story-of-burnt-njal-from-the-icelandic-of-the-njal-s-saga.txt",
    genre: "Historical, Mythology & Legends, All"
  },
  {
    id: 337,
    title: "The Wars of the Jews or The History of the Destruction of Jerusalem by Flavius Josephus",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-wars-of-the-jews-or-the-history-of-the-destruction-of-jerusalem.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-wars-of-the-jews-or-the-history-of-the-destruction-of-jerusalem.txt",
    genre: "Historical, Religion & Spirituality, All"
  },
  {
    id: 338,
    title: "The Underground Railroad by William Still",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-underground-railroad.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-underground-railroad.txt",
    genre: "Biography & Autobiography, Historical, All"
  },
  {
    id: 339,
    title: "The Story of Mankind by Hendrik Willem van Loon",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-story-of-mankind.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-story-of-mankind.txt",
    genre: "Historical, Non-Fiction, All"
  },
  {
    id: 340,
    title: "The Worst Journey in the World, Antarctic 1910-1913 by Apsley Cherry-Garrard",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-worst-journey-in-the-world-antarctic-1910-1913.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-worst-journey-in-the-world-antarctic-1910-1913.txt",
    genre: "Biography & Autobiography, Historical, All"
  },
  {
    id: 341,
    title: "War and Peace by Leo Tolstoy",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/war-and-peace.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/war-and-peace.txt",
    genre: "Historical, Classics, All"
  },
  {
    id: 342,
    title: "History of the Philippines",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/history-of-the-philippines.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/history-of-the-philippines.txt",
    genre: "Historical, Non-Fiction, All"
  },
  {
    id: 343,
    title: "History of the World War: An Authentic Narrative of the World's Greatest War",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/history-of-the-world-war-an-authentic-narrative-of-the-worlds-greatest-war.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/history-of-the-world-war-an-authentic-narrative-of-the-worlds-greatest-war.txt",
    genre: "Historical, Non-Fiction, All"
  },
  {
    id: 344,
    title: "A General Introduction to Psychoanalysis by Sigmund Freud",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-general-introduction-to-psychoanalysis.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-general-introduction-to-psychoanalysis.txt",
    genre: "Philosophy, Non-Fiction, All"
  },
  {
    id: 345,
    title: "A System of Logic, Ratiocinative and Inductive by John Stuart Mill",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-system-of-logic-ratiocinative-and-inductive.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-system-of-logic-ratiocinative-and-inductive.txt",
    genre: "Philosophy, Non-Fiction, All"
  },
  {
    id: 346,
    title: "An Introduction to Mathematics by Alfred North Whitehead",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/an-introduction-to-mathematics.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/an-introduction-to-mathematics.txt",
    genre: "Science & Mathematics, Non-Fiction, All"
  },
  {
    id: 347,
    title: "Autobiography of a Yogi by Paramahansa Yogananda",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/autobiography-of-a-yogi.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/autobiography-of-a-yogi.txt",
    genre: "Biography & Autobiography, Religion & Spirituality, All"
  },
  {
    id: 348,
    title: "Autobiography of Benjamin Franklin by Benjamin Franklin",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/autobiography-of-benjamin-franklin.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/autobiography-of-benjamin-franklin.txt",
    genre: "Biography & Autobiography, Historical, All"
  },
  {
    id: 349,
    title: "A First Spanish Reader by Angel Flores",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-first-spanish-reader.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-first-spanish-reader.txt",
    genre: "Non-Fiction, All"
  },
  {
    id: 350,
    title: "A Dictionary of Slang, Cant, and Vulgar Words by John S. Farmer",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-dictionary-of-slang-cant-and-vulgar-words.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-dictionary-of-slang-cant-and-vulgar-words.txt",
    genre: "Non-Fiction, All"
  },
  {
    id: 351,
    title: "Aeneidos by Virgil",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/aeneidos.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/aeneidos.txt",
    genre: "Classics, Poetry, All"
  },
  {
    id: 352,
    title: "An Investigation of the Laws of Thought by George Boole",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/an-investigation-of-the-laws-of-thought.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/an-investigation-of-the-laws-of-thought.txt",
    genre: "Science & Mathematics, Philosophy, Non-Fiction, All"
  },
  {
    id: 353,
    title: "An Inquiry into the Nature and Causes of the Wealth of Nations by Adam Smith",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/an-inquiry-into-the-nature-and-causes-of-the-wealth-of-nations.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/an-inquiry-into-the-nature-and-causes-of-the-wealth-of-nations.txt",
    genre: "Social & Political, Non-Fiction, All"
  },
  {
    id: 354,
    title: "An Account of Egypt by Herodotus",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/an-account-of-egypt.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/an-account-of-egypt.txt",
    genre: "Historical, Classics, All"
  },
  {
    id: 355,
    title: "Bacon's Essays and Wisdom of the Ancients by Francis Bacon",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/bacons-essays-and-wisdom-of-the-ancients.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/bacons-essays-and-wisdom-of-the-ancients.txt",
    genre: "Philosophy, Non-Fiction, All"
  },
  {
    id: 356,
    title: "Beautiful Shells by Various Authors",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/beautiful-shells.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/beautiful-shells.txt",
    genre: "Non-Fiction, All"
  },
  {
    id: 357,
    title: "Bypaths in Dixie by Sarah Johnson Cocke",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/bypaths-in-dixie.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/bypaths-in-dixie.txt",
    genre: "Non-Fiction, Historical, All"
  },
  {
    id: 358,
    title: "Boy Scouts Handbook by Boy Scouts of America",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/boy-scouts-handbook.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/boy-scouts-handbook.txt",
    genre: "Non-Fiction, All"
  },
  {
    id: 359,
    title: "Calculus Made Easy by Silvanus P. Thompson",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/calculus-made-easy.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/calculus-made-easy.txt",
    genre: "Science & Mathematics, Non-Fiction, All"
  },
  {
    id: 360,
    title: "Children's Books and Their Illustrators by Gleeson White",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/childrens-books-and-their-illustrators.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/childrens-books-and-their-illustrators.txt",
    genre: "Non-Fiction, All"
  },
  {
    id: 361,
    title: "Death and After by Annie Besant",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/death-and-after.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/death-and-after.txt",
    genre: "Religion & Spirituality, Non-Fiction, All"
  },
  {
    id: 362,
    title: "Diccionario Ingles-Espanol-Tagalog by Sofronio G. Calderon",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/diccionario-ingles-espanol-tagalog.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/diccionario-ingles-espanol-tagalog.txt",
    genre: "Non-Fiction, All"
  },
  {
    id: 363,
    title: "Doctrina Christiana by Anonymous",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/doctrina-christiana.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/doctrina-christiana.txt",
    genre: "Religion & Spirituality, Non-Fiction, All"
  },
  {
    id: 364,
    title: "Dream Psychology: Psychoanalysis for Beginners by Sigmund Freud",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/dream-psychology-psychoanalysis-for-beginners.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/dream-psychology-psychoanalysis-for-beginners.txt",
    genre: "Philosophy, Non-Fiction, All"
  },
  {
    id: 365,
    title: "Experimental Researches in Electricity, Volume 1 by Michael Faraday",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/experimental-researches-in-electricity-volume-1.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/experimental-researches-in-electricity-volume-1.txt",
    genre: "Science & Mathematics, Non-Fiction, All"
  },
  {
    id: 366,
    title: "Garden Cities of To-Morrow by Ebenezer Howard",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/garden-cities-of-to-morrow.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/garden-cities-of-to-morrow.txt",
    genre: "Social & Political, Non-Fiction, All"
  },
  {
    id: 367,
    title: "Grace Abounding to the Chief of Sinners by John Bunyan",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/grace-abounding-to-the-chief-of-sinners.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/grace-abounding-to-the-chief-of-sinners.txt",
    genre: "Religion & Spirituality, Non-Fiction, All"
  },
  {
    id: 368,
    title: "Gypsy Sorcery and Fortune Telling by Charles Godfrey Leland",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/gypsy-sorcery-and-fortune-telling.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/gypsy-sorcery-and-fortune-telling.txt",
    genre: "Non-Fiction, All"
  },
  {
    id: 369,
    title: "Helps to Latin Translation at Sight by Edmund Luce",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/helps-to-latin-translation-at-sight.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/helps-to-latin-translation-at-sight.txt",
    genre: "Non-Fiction, All"
  },
  {
    id: 370,
    title: "Harriss List of Covent Garden Ladies by Anonymous",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/harriss-list-of-covent-garden-ladies.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/harriss-list-of-covent-garden-ladies.txt",
    genre: "Non-Fiction, Historical, All"
  },
  {
    id: 371,
    title: "Japanese Girls and Women by Alice Mabel Bacon",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/japanese-girls-and-women.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/japanese-girls-and-women.txt",
    genre: "Non-Fiction, Historical, All"
  },
  {
    id: 372,
    title: "Korean-English Dictionary by Various Authors",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/korean-english-dictionary.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/korean-english-dictionary.txt",
    genre: "Non-Fiction, All"
  },
  {
    id: 373,
    title: "Little Masterpieces of Science: Mind by Various Authors",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/little-masterpieces-of-science-mind.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/little-masterpieces-of-science-mind.txt",
    genre: "Science & Mathematics, Non-Fiction, All"
  },
  {
    id: 374,
    title: "Mein Kampf (My Struggle) by Adolf Hitler",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/mein-kampf-my-struggle.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/mein-kampf-my-struggle.txt",
    genre: "Social & Political, Historical, Non-Fiction, All"
  },
  {
    id: 375,
    title: "Memoirs of Fanny Hill by John Cleland",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/memoirs-of-fanny-hill.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/memoirs-of-fanny-hill.txt",
    genre: "Fiction, Romance, All"
  },
  {
    id: 376,
    title: "My Bondage and My Freedom by Frederick Douglass",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/my-bondage-and-my-freedom.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/my-bondage-and-my-freedom.txt",
    genre: "Biography & Autobiography, Social & Political, Non-Fiction, All"
  },
  {
    id: 377,
    title: "My Life Volume 1 by Richard Wagner",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/my-life-volume-1.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/my-life-volume-1.txt",
    genre: "Biography & Autobiography, Non-Fiction, All"
  },
  {
    id: 378,
    title: "My Past is Mine by C. R. Boxer",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/my-past-is-mine.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/my-past-is-mine.txt",
    genre: "Biography & Autobiography, Non-Fiction, All"
  },
  {
    id: 379,
    title: "My Further Disillusionment by Emma Goldman",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/my-further-disillusionment.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/my-further-disillusionment.txt",
    genre: "Biography & Autobiography, Social & Political, Non-Fiction, All"
  },
  {
    id: 380,
    title: "Novo Dicionário by Various Authors",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/novo-dicionario.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/novo-dicionario.txt",
    genre: "Non-Fiction, All"
  },
  {
    id: 381,
    title: "Letters of Pliny by Pliny the Younger",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/letters-of-pliny.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/letters-of-pliny.txt",
    genre: "Historical, Non-Fiction, All"
  },
  {
    id: 382,
    title: "Letters to His Son Complete by Philip Stanhope",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/letters-to-his-son-complete.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/letters-to-his-son-complete.txt",
    genre: "Non-Fiction, All"
  },
  {
    id: 383,
    title: "On the Origin of Species by Charles Darwin",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/on-the-origin-of-species.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/on-the-origin-of-species.txt",
    genre: "Science & Mathematics, Non-Fiction, All"
  },
  {
    id: 384,
    title: "Philosophiae Naturalis Principia Mathematica by Isaac Newton",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/philosophiae-naturalis-principia-mathematica.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/philosophiae-naturalis-principia-mathematica.txt",
    genre: "Science & Mathematics, Non-Fiction, All"
  },
  {
    id: 385,
    title: "Pennsylvania Dutch Cooking by Unknown",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/pennsylvania-dutch-cooking.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/pennsylvania-dutch-cooking.txt",
    genre: "Non-Fiction, All"
  },
  {
    id: 386,
    title: "Palmistry for All by Cheiro",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/palmistry-for-all.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/palmistry-for-all.txt",
    genre: "Non-Fiction, Religion & Spirituality, All"
  },
  {
    id: 387,
    title: "Roget's Thesaurus of English Words and Phrases by Peter Mark Roget",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/rogets-thesaurus-of-english-words-and-phrases.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/rogets-thesaurus-of-english-words-and-phrases.txt",
    genre: "Non-Fiction, All"
  },
  {
    id: 388,
    title: "Relativity: The Special and General Theory by Albert Einstein",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/relativity-the-special-and-general-theory.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/relativity-the-special-and-general-theory.txt",
    genre: "Science & Mathematics, Non-Fiction, All"
  },
  {
    id: 389,
    title: "Texas Fossils: An Amateur Collector's Handbook by Unknown",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/texas-fossils-an-amateur-collectors-handbook.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/texas-fossils-an-amateur-collectors-handbook.txt",
    genre: "Science & Mathematics, Non-Fiction, All"
  },
  {
    id: 390,
    title: "Texas Rocks and Minerals: An Amateur's Guide by Unknown",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/texas-rocks-and-minerals-an-amateurs-guide.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/texas-rocks-and-minerals-an-amateurs-guide.txt",
    genre: "Science & Mathematics, Non-Fiction, All"
  },
  {
    id: 391,
    title: "The Art of Money Getting",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-art-of-money-getting.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-art-of-money-getting.txt",
    genre: "Non-Fiction, All"
  },
  {
    id: 392,
    title: "The Communist Manifesto",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-communist-manifesto.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-communist-manifesto.txt",
    genre: "Social & Political, Non-Fiction, All"
  },
  {
    id: 393,
    title: "The Economic Consequences of the Peace",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-economic-consequences-of-the-peace.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-economic-consequences-of-the-peace.txt",
    genre: "Social & Political, Non-Fiction, All"
  },
  {
    id: 394,
    title: "The Elements of Style",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-elements-of-style.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-elements-of-style.txt",
    genre: "Non-Fiction, All"
  },
  {
    id: 395,
    title: "The Great Controversy Between Christ and Satan",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-great-controversy-between-christ-and-satan.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-great-controversy-between-christ-and-satan.txt",
    genre: "Religion & Spirituality, Non-Fiction, All"
  },
  {
    id: 396,
    title: "The Jewish State by Theodor Herzl",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-jewish-state.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-jewish-state.txt",
    genre: "Social & Political, Historical, Non-Fiction, All"
  },
  {
    id: 397,
    title: "The Journals of Lewis and Clark 1804-1806 by Meriwether Lewis and William Clark",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-journals-of-lewis-and-clark-1804-1806.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-journals-of-lewis-and-clark-1804-1806.txt",
    genre: "Biography & Autobiography, Historical, Non-Fiction, All"
  },
  {
    id: 398,
    title: "The Principles of Economics with Applications to Practical Problems by Frank A. Fetter",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-principles-of-economics-with-applications-to-practical-problems.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-principles-of-economics-with-applications-to-practical-problems.txt",
    genre: "Social & Political, Non-Fiction, All"
  },
  {
    id: 399,
    title: "The Life of the Spider by J. Henri Fabre",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-life-of-the-spider.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-life-of-the-spider.txt",
    genre: "Science & Mathematics, Non-Fiction, All"
  },
  {
    id: 400,
    title: "The Meaning of Relativity by Albert Einstein",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-meaning-of-relativity.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-meaning-of-relativity.txt",
    genre: "Science & Mathematics, Non-Fiction, All"
  },
  {
    id: 401,
    title: "The Problems of Philosophy by Bertrand Russell",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-problems-of-philosophy.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-problems-of-philosophy.txt",
    genre: "Philosophy, Non-Fiction, All"
  },
  {
    id: 402,
    title: "The Natural History of Pliny Volume 1 (of 6) by Pliny the Elder",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-natural-history-of-pliny-volume-1-(of-6).png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-natural-history-of-pliny-volume-1-(of-6).txt",
    genre: "Science & Mathematics, Historical, Non-Fiction, All"
  },
  {
    id: 403,
    title: "The Souls of Black Folk by W.E.B. Du Bois",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-souls-of-black-folk.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-souls-of-black-folk.txt",
    genre: "Social & Political, Non-Fiction, All"
  },
  {
    id: 404,
    title: "The Science of Getting Rich by Wallace D. Wattles",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-science-of-getting-rich.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-science-of-getting-rich.txt",
    genre: "Non-Fiction, All"
  },
  {
    id: 405,
    title: "The Story of My Life: Complete by Helen Keller",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-story-of-my-life-complete.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-story-of-my-life-complete.txt",
    genre: "Biography & Autobiography, Non-Fiction, All"
  },
  {
    id: 406,
    title: "The Theory of the Leisure Class by Thorstein Veblen",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-theory-of-the-leisure-class.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-theory-of-the-leisure-class.txt",
    genre: "Social & Political, Non-Fiction, All"
  },
  {
    id: 407,
    title: "The Seven Lamps of Architecture by John Ruskin",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-seven-lamps-of-architecture.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-seven-lamps-of-architecture.txt",
    genre: "Non-Fiction, All"
  },
  {
    id: 408,
    title: "The Whitehouse Cookbook (1887) by Mrs. F.L. Gillette and Hugo Ziemann",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-whitehouse-cookbook-(1887).png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-whitehouse-cookbook-(1887).txt",
    genre: "Non-Fiction, All"
  },
  {
    id: 409,
    title: "Thought-Forms by Annie Besant and C. W. Leadbeater",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/thought-forms.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/thought-forms.txt",
    genre: "Religion & Spirituality, Non-Fiction, All"
  },
  {
    id: 410,
    title: "Woman in the Nineteenth Century by Margaret Fuller",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/woman-in-the-nineteenth-century.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/woman-in-the-nineteenth-century.txt",
    genre: "Social & Political, Non-Fiction, All"
  },
  {
    id: 411,
    title: "Woodworking Tools 1600–1900 by Peter C. Welsh",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/woodworking-tools-1600-1900.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/woodworking-tools-1600-1900.txt",
    genre: "Non-Fiction, Historical, All"
  },
  {
    id: 412,
    title: "How to Analyze People on Sight by Elsie Lincoln Benedict and Ralph Paine Benedict",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/how-to-analyze-people-on-sight.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/how-to-analyze-people-on-sight.txt",
    genre: "Non-Fiction, All"
  },
  {
    id: 413,
    title: "How to Live on 24 Hours a Day by Arnold Bennett",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/how-to-live-on-24-hours-a-day.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/how-to-live-on-24-hours-a-day.txt",
    genre: "Non-Fiction, All"
  },
  {
    id: 414,
    title: "A Connecticut Yankee in King Arthur's Court by Mark Twain",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-connecticut-yankee-in-king-arthurs-court.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-connecticut-yankee-in-king-arthurs-court.txt",
    genre: "Adventure, Satire, Classics, All"
  },
  {
    id: 415,
    title: "Around the World in Eighty Days by Jules Verne",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/around-the-world-in-eighty-days.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/around-the-world-in-eighty-days.txt",
    genre: "Adventure, Classics, All"
  },
  {
    id: 416,
    title: "Adventures of Huckleberry Finn by Mark Twain",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/adventures-of-huckleberry.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/adventures-of-huckleberry.txt",
    genre: "Adventure, Classics, All"
  },
  {
    id: 417,
    title: "Treasure Island by Robert Louis Stevenson",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/treasure-island.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/treasure-island.txt",
    genre: "Adventure, Classics, All"
  },
  {
    id: 418,
    title: "Captain Blood by Rafael Sabatini",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/captain-blood.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/captain-blood.txt",
    genre: "Adventure, Historical, Classics, All"
  },
  {
    id: 419,
    title: "Brewster's Millions by George Barr McCutcheon",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/brewsters-millions.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/brewsters-millions.txt",
    genre: "Satire, Adventure, All"
  },
  {
    id: 420,
    title: "A Journey Into the Interior of the Earth by Jules Verne",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-journey-into-the-interior-of-the-earth.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-journey-into-the-interior-of-the-earth.txt",
    genre: "Science Fiction & Fantasy, Adventure, Classics, All"
  },
  {
    id: 421,
    title: "A Voyage to Arcturus by David Lindsay",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-voyage-to-arcturus.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-voyage-to-arcturus.txt",
    genre: "Science Fiction & Fantasy, Adventure, All"
  },
  {
    id: 422,
    title: "A Princess of Mars by Edgar Rice Burroughs",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-princess-of-mars.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-princess-of-mars.txt",
    genre: "Science Fiction & Fantasy, Adventure, Classics, All"
  },
  {
    id: 423,
    title: "Gulliver's Travels by Jonathan Swift",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/gullivers-travels.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/gullivers-travels.txt",
    genre: "Satire, Adventure, Classics, All"
  },
  {
    id: 424,
    title: "Five Children and It by E. Nesbit",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/five-children-and-it.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/five-children-and-it.txt",
    genre: "Children's Literature, Adventure, Classics, All"
  },
  {
    id: 425,
    title: "Don Quixote by Miguel de Cervantes Saavedra",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/don-quixote.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/don-quixote.txt",
    genre: "Adventure, Satire, Classics, All"
  },
  {
    id: 426,
    title: "Following the Equator: A Journey Around the World by Mark Twain",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/following-the-equator-a-journey-around-the-world.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/following-the-equator-a-journey-around-the-world.txt",
    genre: "Adventure, Satire, Classics, All"
  },
  {
    id: 427,
    title: "Golden Fleece: The American Adventures of a Fortune Hunting Earl by David Graham Phillips",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/golden-fleece-the-american-adventures-of-a-fortune-hunting-earl.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/golden-fleece-the-american-adventures-of-a-fortune-hunting-earl.txt",
    genre: "Adventure, All"
  },
  {
    id: 428,
    title: "King Solomon's Mines by H. Rider Haggard",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/king-solomons-mines.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/king-solomons-mines.txt",
    genre: "Adventure, Classics, All"
  },
  {
    id: 429,
    title: "Kidnapped by Robert Louis Stevenson",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/kidnapped.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/kidnapped.txt",
    genre: "Adventure, Historical, Classics, All"
  },
  {
    id: 430,
    title: "L'Île Mystérieuse by Jules Verne",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/lile-mysterieuse.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/lile-mysterieuse.txt",
    genre: "Adventure, Classics, All"
  },
  {
    id: 431,
    title: "Moby Dick by Herman Melville",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/moby-dick.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/moby-dick.txt",
    genre: "Adventure, Classics, All"
  },
  {
    id: 432,
    title: "Martti Mestari ja Hänen Kisällinsä by Aleksis Kivi",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/martti-mestari-ja-h%C3%A4nen-kis%C3%A4llins%C3%A4.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/martti-mestari-ja-h%C3%A4nen-kis%C3%A4llins%C3%A4.txt",
    genre: "Adventure, All"
  },
  {
    id: 433,
    title: "King Lear by William Shakespeare",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/king-lear.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/king-lear.txt",
    genre: "Drama & Plays, Classics, All"
  },
  {
    id: 434,
    title: "Swiss Family Robinson by Johann David Wyss",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/swiss-family-robinson.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/swiss-family-robinson.txt",
    genre: "Adventure, Classics, All"
  },
  {
    id: 435,
    title: "Oliver Twist by Charles Dickens",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/oliver-twist.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/oliver-twist.txt",
    genre: "Historical, Adventure, Classics, All"
  },
  {
    id: 436,
    title: "She by H. Rider Haggard",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/she.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/she.txt",
    genre: "Adventure, Romance, Science Fiction & Fantasy, All"
  },
  {
    id: 437,
    title: "Sailing Alone Around the World by Joshua Slocum",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/sailing-alone-around-the-world.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/sailing-alone-around-the-world.txt",
    genre: "Adventure, Biography & Autobiography, All"
  },
  {
    id: 438,
    title: "Ragged Dick, or, Street Life in New York with the Boot Blacks by Horatio Alger",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/ragged-dick-or-street-life-in-new-york-with-the-boot-blacks.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/ragged-dick-or-street-life-in-new-york-with-the-boot-blacks.txt",
    genre: "Adventure, Social & Political, All"
  },
  {
    id: 439,
    title: "The Adventures of Tom Sawyer by Mark Twain",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-adventures-of-tom-sawyer.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-adventures-of-tom-sawyer.txt",
    genre: "Adventure, Classics, All"
  },
  {
    id: 440,
    title: "The Further Adventures of Robinson Crusoe by Daniel Defoe",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-further-adventures-of-robinson-crusoe.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-further-adventures-of-robinson-crusoe.txt",
    genre: "Adventure, Classics, All"
  },
  {
    id: 441,
    title: "The Call of the Wild by Jack London",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-call-of-the-wild.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-call-of-the-wild.txt",
    genre: "Adventure, Classics, All"
  },
  {
    id: 442,
    title: "The Adventures of Ferdinand by Tobias Smollett",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-adventures-of-ferdinand.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-adventures-of-ferdinand.txt",
    genre: "Adventure, All"
  },
  {
    id: 443,
    title: "The Island of Doctor Moreau by H.G. Wells",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-island-of-doctor-moreau.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-island-of-doctor-moreau.txt",
    genre: "Science Fiction & Fantasy, Adventure, All"
  },
  {
    id: 444,
    title: "The Princess and the Goblin by George MacDonald",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-princess-and-the-goblin.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-princess-and-the-goblin.txt",
    genre: "Adventure, Children's Literature, All"
  },
  {
    id: 445,
    title: "The Hound of the Baskervilles by Arthur Conan Doyle",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-hound-of-the-baskervilles.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-hound-of-the-baskervilles.txt",
    genre: "Mystery & Thriller, Classics, All"
  },
  {
    id: 446,
    title: "The Man with Two Left Feet and Other Stories by P. G. Wodehouse",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-man-with-two-left-feet-and-other-stories.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-man-with-two-left-feet-and-other-stories.txt",
    genre: "Adventure, Satire, All"
  },
  {
    id: 447,
    title: "The Open Boat and Other Stories by Stephen Crane",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-open-boat-and-other-stories.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-open-boat-and-other-stories.txt",
    genre: "Adventure, All"
  },
  {
    id: 448,
    title: "The Sea-Wolf by Jack London",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-sea-wolf.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-sea-wolf.txt",
    genre: "Adventure, Classics, All"
  },
  {
    id: 449,
    title: "The Scarlet Pimpernel by Baroness Orczy",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-scarlet-pimpernel.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-scarlet-pimpernel.txt",
    genre: "Adventure, Historical, Classics, All"
  },
  {
    id: 450,
    title: "The Secret Garden by Frances Hodgson Burnett",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-secret-garden.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-secret-garden.txt",
    genre: "Children's Literature, Romance, Classics, All"
  },
  {
    id: 451,
    title: "The Scarlet Plague by Jack London",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-scarlet-plague.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-scarlet-plague.txt",
    genre: "Science Fiction & Fantasy, Adventure, All"
  },
  {
    id: 452,
    title: "A Room with a View by E. M. Forster",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-room-with-a-view.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-room-with-a-view.txt",
    genre: "Romance, Classics, All"
  },
  {
    id: 453,
    title: "Anna Karenina by Leo Tolstoy",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/anna-karenina.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/anna-karenina.txt",
    genre: "Romance, Classics, All"
  },
  {
    id: 454,
    title: "Anne of Green Gables by L. M. Montgomery",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/anne-of-green-gables.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/anne-of-green-gables.txt",
    genre: "Children's Literature, Romance, Classics, All"
  },
  {
    id: 455,
    title: "Anne of the Island by L. M. Montgomery",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/anne-of-the-island.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/anne-of-the-island.txt",
    genre: "Children's Literature, Romance, Classics, All"
  },
  {
    id: 456,
    title: "Barchester Towers by Anthony Trollope",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/barchester-towers.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/barchester-towers.txt",
    genre: "Romance, Social & Political, All"
  },
  {
    id: 457,
    title: "A Sentimental Journey Through France and Italy by Laurence Sterne",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-sentimental-journey-through-france-and-italy.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-sentimental-journey-through-france-and-italy.txt",
    genre: "Romance, Satire, Classics, All"
  },
  {
    id: 458,
    title: "A Hero of Our Time by Mikhail Lermontov",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-hero-of-our-time.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-hero-of-our-time.txt",
    genre: "Romance, Historical, Classics, All"
  },
  {
    id: 459,
    title: "Ars Amatoria or The Art of Love by Ovid",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/ars-amatoria-or-the-art-of-love.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/ars-amatoria-or-the-art-of-love.txt",
    genre: "Romance, Mythology & Legends, Classics, All"
  },
  {
    id: 460,
    title: "Clarissa Harlowe; or, The History of a Young Lady, Volume 1 by Samuel Richardson",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/clarissa-harlowe-or-the-history-of-a-young-lady-volume-1.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/clarissa-harlowe-or-the-history-of-a-young-lady-volume-1.txt",
    genre: "Romance, Classics, All"
  },
  {
    id: 461,
    title: "Dangerous Connections V 1, 2, 3, 4 by Pierre Choderlos de Laclos",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/dangerous-connections-v-1,-2,-3,-4.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/dangerous-connections-v-1,-2,-3,-4.txt",
    genre: "Romance, Classics, All"
  },
  {
    id: 462,
    title: "Emma by Jane Austen",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/emma.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/emma.txt",
    genre: "Romance, Classics, All"
  },
  {
    id: 463,
    title: "Eugene Oneguine [Onegin] by Alexander Pushkin",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/eugene-oneguine-[onegin].png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/eugene-oneguine-[onegin].txt",
    genre: "Romance, Poetry, All"
  },
  {
    id: 464,
    title: "Evelina, Or the History of a Young Lady's Entrance into the World by Fanny Burney",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/evelina-or-the-history-of-a-young-ladys-entrance-into-the-world.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/evelina-or-the-history-of-a-young-ladys-entrance-into-the-world.txt",
    genre: "Romance, Classics, All"
  },
  {
    id: 465,
    title: "Frau Bovary by Gustave Flaubert",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/frau-bovary.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/frau-bovary.txt",
    genre: "Romance, Classics, All"
  },
  {
    id: 466,
    title: "Demian by Hermann Hesse",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/demian.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/demian.txt",
    genre: "Philosophy, All"
  },
  {
    id: 467,
    title: "Lady Susan by Jane Austen",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/lady-susan.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/lady-susan.txt",
    genre: "Romance, Classics, All"
  },
  {
    id: 468,
    title: "Love and Freindship by Jane Austen",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/love-and-freindship.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/love-and-freindship.txt",
    genre: "Romance, Classics, All"
  },
  {
    id: 469,
    title: "Jude the Obscure by Thomas Hardy",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/jude-the-obscure.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/jude-the-obscure.txt",
    genre: "Romance, Classics, All"
  },
  {
    id: 470,
    title: "Madame Bovary by Gustave Flaubert",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/madame-bovary.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/madame-bovary.txt",
    genre: "Romance, Classics, All"
  },
  {
    id: 471,
    title: "Persuasion by Jane Austen",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/persuasion.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/persuasion.txt",
    genre: "Romance, Classics, All"
  },
  {
    id: 472,
    title: "Pride and Prejudice by Jane Austen",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/pride-and-prejudice.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/pride-and-prejudice.txt",
    genre: "Romance, Classics, All"
  },
  {
    id: 473,
    title: "Romeo and Juliet by William Shakespeare",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/romeo-juliet.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/romeo-juliet.txt",
    genre: "Romance, Classics, Drama & Plays, All"
  },
  {
    id: 474,
    title: "Sense and Sensibility by Jane Austen",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/sense-and-sensibility.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/sense-and-sensibility.txt",
    genre: "Romance, Classics, All"
  },
  {
    id: 475,
    title: "Queen Lucia by E. F. Benson",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/queen-lucia.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/queen-lucia.txt",
    genre: "Romance, All"
  },
  {
    id: 476,
    title: "She by H. Rider Haggard",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/she.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/she.txt",
    genre: "Romance, Adventure, All"
  },
  {
    id: 477,
    title: "Tess of the d'Urbervilles by Thomas Hardy",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/tess-of-the-d-urbervilles.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/tess-of-the-d-urbervilles.txt",
    genre: "Romance, Classics, All"
  },
  {
    id: 478,
    title: "The Blue Castle",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-blue-castle.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-blue-castle.txt",
    genre: "Romance, All"
  },
  {
    id: 479,
    title: "The Gift of the Magi",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-gift-of-the-magi.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-gift-of-the-magi.txt",
    genre: "Romance, All"
  },
  {
    id: 480,
    title: "The Great Gatsby",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-great-gatsby.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-great-gatsby.txt",
    genre: "Romance, Classics, All"
  },
  {
    id: 481,
    title: "The Kreutzer Sonata and Other Stories by Leo Tolstoy",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-kreutzer-sonata-and-other-stories.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-kreutzer-sonata-and-other-stories.txt",
    genre: "Romance, Philosophy, All"
  },
  {
    id: 482,
    title: "The Lady of the Lake by Sir Walter Scott",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-lady-of-the-lake.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-lady-of-the-lake.txt",
    genre: "Romance, Poetry, Classics, All"
  },
  {
    id: 483,
    title: "The Moon and Sixpence by W. Somerset Maugham",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-moon-and-sixpence.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-moon-and-sixpence.txt",
    genre: "Romance, All"
  },
  {
    id: 484,
    title: "The Romance of Tristan and Iseult by Joseph Bédier",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-romance-of-tristan-and-iseult.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-romance-of-tristan-and-iseult.txt",
    genre: "Romance, Mythology & Legends, All"
  },
  {
    id: 485,
    title: "The Rainbow by D. H. Lawrence",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-rainbow.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-rainbow.txt",
    genre: "Romance, Classics, All"
  },
  {
    id: 486,
    title: "The Scarlet Letter by Nathaniel Hawthorne",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-scarlet-letter.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-scarlet-letter.txt",
    genre: "Romance, Classics, All"
  },
  {
    id: 487,
    title: "Jane Eyre by Charlotte Brontë",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/jane-eyre.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/jane-eyre.txt",
    genre: "Romance, Classics, All"
  },
  {
    id: 488,
    title: "Wuthering Heights by Emily Brontë",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/wuthering-heights.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/wuthering-heights.txt",
    genre: "Romance, Classics, All"
  },
  {
    id: 489,
    title: "Zuleika Dobson or an Oxford Love Story by Max Beerbohm",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/zuleika-dobson-or-an-oxford-love-story.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/zuleika-dobson-or-an-oxford-love-story.txt",
    genre: "Romance, Satire, All"
  },
  {
    id: 490,
    title: "A Shropshire Lad by A. E. Housman",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-shropshire-lad.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-shropshire-lad.txt",
    genre: "Poetry, All"
  },
  {
    id: 491,
    title: "Ancient Nahuatl Poetry by Daniel G. Brinton",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/ancient-nahuatl-poetry.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/ancient-nahuatl-poetry.txt",
    genre: "Poetry, Mythology & Legends, All"
  },
  {
    id: 492,
    title: "Childe Harold's Pilgrimage by Lord Byron",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/childe-harolds-pilgrimage.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/childe-harolds-pilgrimage.txt",
    genre: "Poetry, Classics, All"
  },
  {
    id: 493,
    title: "Beowulf by Anonymous",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/beowulf-an-anglo-saxon-epic-poem.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/beowulf-an-anglo-saxon-epic-poem.txt",
    genre: "Poetry, Mythology & Legends, Classics, All"
  },
  {
    id: 494,
    title: "Chaucer's Works, Volume 4 by Geoffrey Chaucer",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/chaucers-works-volume-4.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/chaucers-works-volume-4.txt",
    genre: "Poetry, Classics, All"
  },
  {
    id: 495,
    title: "Chaucer for Children: A Golden Key by Geoffrey Chaucer",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/chaucer-for-children-a-golden-key.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/chaucer-for-children-a-golden-key.txt",
    genre: "Poetry, Children's Literature, All"
  },
  {
    id: 496,
    title: "Aeneidos by Virgil",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/aeneidos.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/aeneidos.txt",
    genre: "Poetry, Mythology & Legends, Classics, All"
  },
  {
    id: 497,
    title: "Ars Amatoria or The Art of Love by Ovid",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/ars-amatoria-or-the-art-of-love.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/ars-amatoria-or-the-art-of-love.txt",
    genre: "Poetry, Mythology & Legends, Classics, All"
  },
  {
    id: 498,
    title: "Andersen's Fairy Tales by Hans Christian Andersen",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/andersen-s-fairy-tales.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/andersen-s-fairy-tales.txt",
    genre: "Children's Literature, Mythology & Legends, All"
  },
  {
    id: 499,
    title: "Divine Comedy, Longfellow Translation, Hell by Dante Alighieri",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/divine-comedy-longfellow-translation-hell.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/divine-comedy-longfellow-translation-hell.txt",
    genre: "Poetry, Classics, All"
  },
  {
    id: 500,
    title: "Don Juan by Lord Byron",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/don-juan.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/don-juan.txt",
    genre: "Poetry, Classics, All"
  },
  {
    id: 502,
    title: "Fables of La Fontaine, A New Edition with Notes by Jean de La Fontaine",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/fables-of-la-fontaine.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/fables-of-la-fontaine.txt",
    genre: "Poetry, Classics, All"
  },
  {
    id: 503,
    title: "Fifteen Sonnets of Petrarch by Francesco Petrarca",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/fifteen-sonnets-of-petrarch.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/fifteen-sonnets-of-petrarch.txt",
    genre: "Poetry, Classics, All"
  },
  {
    id: 504,
    title: "Eugene Oneguine [Onegin] by Alexander Pushkin",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/eugene-oneguine-[onegin].png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/eugene-oneguine-[onegin].txt",
    genre: "Poetry, Classics, All"
  },
  {
    id: 505,
    title: "Leaves of Grass by Walt Whitman",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/leaves-of-grass.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/leaves-of-grass.txt",
    genre: "Poetry, Classics, All"
  },
  {
    id: 506,
    title: "Les Fleurs du Mal by Charles Baudelaire",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/les-fleurs-du-mal.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/les-fleurs-du-mal.txt",
    genre: "Poetry, Classics, All"
  },
  {
    id: 507,
    title: "Mi Último Adiós by José Rizal",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/mi-ultimo-adios.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/mi-ultimo-adios.txt",
    genre: "Poetry, Historical, All"
  },
  {
    id: 508,
    title: "Le Roman de la Rose Tome I by Guillaume de Lorris",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/le-roman-de-la-rose-tome-i.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/le-roman-de-la-rose-tome-i.txt",
    genre: "Poetry, Classics, All"
  },
  {
    id: 509,
    title: "Marmion: A Tale of Flodden Field by Sir Walter Scott",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/marmion-a-a-tale-of-flodden-field.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/marmion-a-a-tale-of-flodden-field.txt",
    genre: "Poetry, Historical, All"
  },
  {
    id: 511,
    title: "Poems Every Child Should Know by Various",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/poems-every-child-should-know.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/poems-every-child-should-know.txt",
    genre: "Poetry, Children's Literature, All"
  },
  {
    id: 512,
    title: "Poems with the Ballad of Reading Gaol by Oscar Wilde",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/poems-with-the-ballad-of-reading-gaol.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/poems-with-the-ballad-of-reading-gaol.txt",
    genre: "Poetry, Classics, All"
  },
  {
    id: 513,
    title: "Prufrock and Other Observations by T. S. Eliot",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/prufrock-and-other-observations.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/prufrock-and-other-observations.txt",
    genre: "Poetry, Classics, All"
  },
  {
    id: 514,
    title: "Spoon River Anthology by Edgar Lee Masters",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/spoon-river-anthology.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/spoon-river-anthology.txt",
    genre: "Poetry, All"
  },
  {
    id: 515,
    title: "Spencer's The Faerie Queene Book 1 by Edmund Spenser",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/spencers-the-faerie-queene-book-1.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/spencers-the-faerie-queene-book-1.txt",
    genre: "Poetry, Classics, All"
  },
  {
    id: 516,
    title: "The Complete Poetical Works of Edgar Allan Poe",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-complete-poetical-works-of-edgar-allan-poe.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-complete-poetical-works-of-edgar-allan-poe.txt",
    genre: "Poetry, Horror & Gothic, All"
  },
  {
    id: 517,
    title: "The Flowers of Evil",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-flowers-of-evil.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-flowers-of-evil.txt",
    genre: "Poetry, Classics, All"
  },
  {
    id: 518,
    title: "The Congo and Other Poems",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-congo-and-other-poems.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-congo-and-other-poems.txt",
    genre: "Poetry, All"
  },
  {
    id: 519,
    title: "The Poems of Giacomo Leopardi by Giacomo Leopardi",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-poems-of-giacomo-leopardi.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-poems-of-giacomo-leopardi.txt",
    genre: "Poetry, Classics, All"
  },
  {
    id: 520,
    title: "The Odes of Casimire Translated by G. Hils",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-odes-of-casimire-translated-by-g-hils.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-odes-of-casimire-translated-by-g-hils.txt",
    genre: "Poetry, All"
  },
  {
    id: 521,
    title: "The Marriage of Heaven and Hell by William Blake",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-marriage-of-heaven-and-hell.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-marriage-of-heaven-and-hell.txt",
    genre: "Poetry, Philosophy, All"
  },
  {
    id: 522,
    title: "The Pied Piper of Hamelin by Robert Browning",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-pied-piper-of-hamelin.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-pied-piper-of-hamelin.txt",
    genre: "Poetry, Children's Literature, All"
  },
  {
    id: 523,
    title: "The Raven by Edgar Allan Poe",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-raven.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-raven.txt",
    genre: "Poetry, Horror & Gothic, All"
  },
  {
    id: 524,
    title: "The Task and Other Poems by William Cowper",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-task-and-other-poems.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-task-and-other-poems.txt",
    genre: "Poetry, All"
  },
  {
    id: 525,
    title: "The Works of Edgar Allan Poe by Edgar Allan Poe",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-works-of-edgar-allan-poe-volume-1.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-works-of-edgar-allan-poe-volume-1.txt",
    genre: "Poetry, Horror & Gothic, All"
  },
  {
    id: 527,
    title: "Young's Night Thoughts by Edward Young",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/youngs-night-thoughts.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/youngs-night-thoughts.txt",
    genre: "Poetry, Philosophy, All"
  },
  {
    id: 528,
    title: "Hymen by H.D. (Hilda Doolittle)",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/hymen.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/hymen.txt",
    genre: "Poetry, Classics, All"
  },
  {
    id: 529,
    title: "Idylls of the King by Alfred, Lord Tennyson",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/idylls-of-the-king.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/idylls-of-the-king.txt",
    genre: "Poetry, Classics, All"
  },
  {
    id: 530,
    title: "Alice's Adventures in Wonderland by Lewis Carroll",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/alice-in-wonderland.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/alice-in-wonderland.txt",
    genre: "Children's Literature, Classics, All"
  },
  {
    id: 531,
    title: "Anne of Green Gables by L. M. Montgomery",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/anne-of-green-gables.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/anne-of-green-gables.txt",
    genre: "Children's Literature, Classics, All"
  },
  {
    id: 532,
    title: "Andersen's Fairy Tales by Hans Christian Andersen",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/andersen-s-fairy-tales.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/andersen-s-fairy-tales.txt",
    genre: "Children's Literature, Mythology & Legends, All"
  },
  {
    id: 533,
    title: "Chaucer for Children: A Golden Key by Geoffrey Chaucer",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/chaucer-for-children-a-golden-key.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/chaucer-for-children-a-golden-key.txt",
    genre: "Children's Literature, Poetry, All"
  },
  {
    id: 534,
    title: "A Child's Dream of a Star by Charles Dickens",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-childs-dream-of-a-star.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-childs-dream-of-a-star.txt",
    genre: "Children's Literature, All"
  },
  {
    id: 535,
    title: "Beautiful Shells by Various Authors",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/beautiful-shells.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/beautiful-shells.txt",
    genre: "Children's Literature, All"
  },
  {
    id: 536,
    title: "Daddy Takes Us to the Garden by Howard R. Garis",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/daddy-takes-us-to-the-garden.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/daddy-takes-us-to-the-garden.txt",
    genre: "Children's Literature, All"
  },
  {
    id: 537,
    title: "Der Struwwelpeter by Heinrich Hoffmann",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/der-struwwelpeter.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/der-struwwelpeter.txt",
    genre: "Children's Literature, All"
  },
  {
    id: 538,
    title: "Fairy Tales of Hans Christian Andersen by Hans Christian Andersen",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/fairy-tales-of-hans-christian-andersen.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/fairy-tales-of-hans-christian-andersen.txt",
    genre: "Children's Literature, Mythology & Legends, All"
  },
  {
    id: 539,
    title: "Five Children and It by E. Nesbit",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/five-children-and-it.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/five-children-and-it.txt",
    genre: "Children's Literature, Classics, All"
  },
  {
    id: 540,
    title: "Favorite Fairy Tales by Various Authors",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/favorite-fairy-tales.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/favorite-fairy-tales.txt",
    genre: "Children's Literature, Mythology & Legends, All"
  },
  {
    id: 541,
    title: "Grimm's Fairy Tales by The Brothers Grimm",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/grimms-fairy-tales.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/grimms-fairy-tales.txt",
    genre: "Children's Literature, Mythology & Legends, All"
  },
  {
    id: 542,
    title: "Just So Stories by Rudyard Kipling",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/just-so-stories.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/just-so-stories.txt",
    genre: "Children's Literature, Classics, All"
  },
  {
    id: 543,
    title: "Just William by Richmal Crompton",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/just-william.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/just-william.txt",
    genre: "Children's Literature, Classics, All"
  },
  {
    id: 544,
    title: "Little Black Sambo by Helen Bannerman",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/little-black-sambo.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/little-black-sambo.txt",
    genre: "Children's Literature, All"
  },
  {
    id: 545,
    title: "McGuffey's First Eclectic Reader Revised Edition by William Holmes McGuffey",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/mcguffeys-first-eclectic-reader-revised-edition.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/mcguffeys-first-eclectic-reader-revised-edition.txt",
    genre: "Children's Literature, All"
  },
  {
    id: 546,
    title: "Millions of Cats by Wanda Gág",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/millions-of-cats.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/millions-of-cats.txt",
    genre: "Children's Literature, All"
  },
  {
    id: 547,
    title: "Japanese Fairy Tales by Yei Theodora Ozaki",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/japanese-fairy-tales.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/japanese-fairy-tales.txt",
    genre: "Children's Literature, Mythology & Legends, All"
  },
  {
    id: 548,
    title: "Peter Pan by J. M. Barrie",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/peter-pan.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/peter-pan.txt",
    genre: "Children's Literature, Classics, All"
  },
  {
    id: 549,
    title: "Pollyanna by Eleanor H. Porter",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/pollyanna.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/pollyanna.txt",
    genre: "Children's Literature, Classics, All"
  },
  {
    id: 550,
    title: "Old Granny Fox by Thornton W. Burgess",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/old-granny-fox.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/old-granny-fox.txt",
    genre: "Children's Literature, All"
  },
  {
    id: 551,
    title: "Poems Every Child Should Know by Various",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/poems-every-child-should-know.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/poems-every-child-should-know.txt",
    genre: "Children's Literature, Poetry, All"
  },
  {
    id: 552,
    title: "The Box-Car Children",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-box-car-children.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-box-car-children.txt",
    genre: "Children's Literature, Classics, All"
  },
  {
    id: 553,
    title: "The Book of Dragons",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-book-of-dragons.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-book-of-dragons.txt",
    genre: "Children's Literature, Classics, All"
  },
  {
    id: 554,
    title: "The Jungle Book by Rudyard Kipling",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-jungle-book.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-jungle-book.txt",
    genre: "Children's Literature, Classics, All"
  },
  {
    id: 555,
    title: "The Princess and the Goblin by George MacDonald",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-princess-and-the-goblin.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-princess-and-the-goblin.txt",
    genre: "Children's Literature, Classics, All"
  },
  {
    id: 556,
    title: "The Happy Prince and Other Tales",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-happy-prince-and-other-tales.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-happy-prince-and-other-tales.txt",
    genre: "Children's Literature, All"
  },
  {
    id: 557,
    title: "The Railway Children by E. Nesbit",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-railway-children.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-railway-children.txt",
    genre: "Children's Literature, Classics, All"
  },
  {
    id: 558,
    title: "The Secret Garden by Frances Hodgson Burnett",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-secret-garden.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-secret-garden.txt",
    genre: "Children's Literature, Classics, All"
  },
  {
    id: 559,
    title: "The Tale of Peter Rabbit by Beatrix Potter",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-tale-of-peter-rabbit.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-tale-of-peter-rabbit.txt",
    genre: "Children's Literature, All"
  },
  {
    id: 560,
    title: "The Tale of Tom Kitten by Beatrix Potter",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-tale-of-tom-kitten.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-tale-of-tom-kitten.txt",
    genre: "Children's Literature, All"
  },
  {
    id: 561,
    title: "The Wonderful Wizard of Oz by L. Frank Baum",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-wonderful-wizard-of-oz.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-wonderful-wizard-of-oz.txt",
    genre: "Children's Literature, Classics, All"
  },
  {
    id: 562,
    title: "Winnie the Pooh by A. A. Milne",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/winnie-the-pooh.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/winnie-the-pooh.txt",
    genre: "Children's Literature, All"
  },
  {
    id: 563,
    title: "Uncle Remus and Brer Rabbit by Joel Chandler Harris",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/uncle-remus-and-brer-rabbit.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/uncle-remus-and-brer-rabbit.txt",
    genre: "Children's Literature, Mythology & Legends, All"
  },
  {
    id: 564,
    title: "Household Stories by the Brothers Grimm by Brothers Grimm",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/household-stories-by-the-brothers-grimm.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/household-stories-by-the-brothers-grimm.txt",
    genre: "Children's Literature, Mythology & Legends, All"
  },
  {
    id: 565,
    title: "Carmilla by Sheridan Le Fanu",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/carmilla.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/carmilla.txt",
    genre: "Horror & Gothic, All"
  },
  {
    id: 566,
    title: "Frankenstein by Mary Shelley",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/frankenstein.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/frankenstein.txt",
    genre: "Horror & Gothic, Classics, All"
  },
  {
    id: 567,
    title: "Dracula by Bram Stoker",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/dracula.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/dracula.txt",
    genre: "Horror & Gothic, Classics, All"
  },
  {
    id: 568,
    title: "The Strange Case of Dr Jekyll and Mr Hyde by Robert Louis Stevenson",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-strange-case.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-strange-case.txt",
    genre: "Horror & Gothic, Classics, All"
  },
  {
    id: 569,
    title: "A Pail of Air by Fritz Leiber",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-pail-of-air.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-pail-of-air.txt",
    genre: "Science Fiction & Fantasy, Horror & Gothic, All"
  },
  {
    id: 570,
    title: "Famous Modern Ghost Stories by Various Authors",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/famous-modern-ghost-stories.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/famous-modern-ghost-stories.txt",
    genre: "Horror & Gothic, All"
  },
  {
    id: 571,
    title: "Ghost Stories of an Antiquary by M. R. James",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/ghost-stories-of-an-antiquary.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/ghost-stories-of-an-antiquary.txt",
    genre: "Horror & Gothic, All"
  },
  {
    id: 572,
    title: "Frankenstein by Mary Wollstonecraft Shelley",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/frankenstein.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/frankenstein.txt",
    genre: "Horror & Gothic, Classics, All"
  },
  {
    id: 573,
    title: "Le Corbeau by Edgar Allan Poe",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/le-corbeau.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/le-corbeau.txt",
    genre: "Horror & Gothic, Poetry, All"
  },
  {
    id: 574,
    title: "La Saga de Njal by Anonymous",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/la-saga-de-njal.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/la-saga-de-njal.txt",
    genre: "Mythology & Legends, Classics, All"
  },
  {
    id: 575,
    title: "Metamorphosis by Franz Kafka",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/metamorphosis.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/metamorphosis.txt",
    genre: "Classics, Horror & Gothic, Philosophy, All"
  },
  {
    id: 576,
    title: "Tales of Terror and Mystery by Various Authors",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/tales-of-terror-and-mystery.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/tales-of-terror-and-mystery.txt",
    genre: "Horror & Gothic, Mystery & Thriller, All"
  },
  {
    id: 577,
    title: "Salomé: A Tragedy in One Act by Oscar Wilde",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/salome-a-tragedy-in-one-act.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/salome-a-tragedy-in-one-act.txt",
    genre: "Horror & Gothic, Drama & Plays, All"
  },
  {
    id: 578,
    title: "The Dunwich Horror",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-dunwich-horror.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-dunwich-horror.txt",
    genre: "Horror & Gothic, Science Fiction & Fantasy, All"
  },
  {
    id: 579,
    title: "The Fall of the House of Usher",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-fall-of-the-house-of-usher.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-fall-of-the-house-of-usher.txt",
    genre: "Horror & Gothic, Classics, All"
  },
  {
    id: 580,
    title: "The Great God Pan",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-great-god-pan.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-great-god-pan.txt",
    genre: "Horror & Gothic, All"
  },
  {
    id: 581,
    title: "The House on the Borderland by William Hope Hodgson",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-house-on-the-borderland.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-house-on-the-borderland.txt",
    genre: "Horror & Gothic, Science Fiction & Fantasy, All"
  },
  {
    id: 582,
    title: "The King in Yellow by Robert W. Chambers",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-king-in-yellow.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-king-in-yellow.txt",
    genre: "Horror & Gothic, All"
  },
  {
    id: 583,
    title: "The Monk: A Romance by M. G. Lewis",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-monk-a-romance.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-monk-a-romance.txt",
    genre: "Horror & Gothic, Classics, Romance, All"
  },
  {
    id: 584,
    title: "The Legend of Sleepy Hollow by Washington Irving",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-legend-of-sleepy-hollow.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-legend-of-sleepy-hollow.txt",
    genre: "Horror & Gothic, Classics, All"
  },
  {
    id: 585,
    title: "The Vampyre; A Tale by John William Polidori",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-vampyre-a-tale.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-vampyre-a-tale.txt",
    genre: "Horror & Gothic, Classics, All"
  },
  {
    id: 586,
    title: "The Wendigo by Algernon Blackwood",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-wendigo.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-wendigo.txt",
    genre: "Horror & Gothic, All"
  },
  {
    id: 587,
    title: "The Turn of the Screw by Henry James",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-turn-of-the-screw.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-turn-of-the-screw.txt",
    genre: "Horror & Gothic, Mystery & Thriller, All"
  },
  {
    id: 588,
    title: "The Yellow Wallpaper by Charlotte Perkins Gilman",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-yellow-wallpaper.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-yellow-wallpaper.txt",
    genre: "Horror & Gothic, Social & Political, All"
  },
  {
    id: 589,
    title: "Uncle Silas: A Tale of Bartram-Haugh by J. Sheridan Le Fanu",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/uncle-silas-a-tale-of-bartram-haugh.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/uncle-silas-a-tale-of-bartram-haugh.txt",
    genre: "Horror & Gothic, Mystery & Thriller, All"
  },
  {
    id: 590,
    title: "Venus in Furs by Leopold von Sacher-Masoch",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/venus-in-furs.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/venus-in-furs.txt",
    genre: "Horror & Gothic, Romance, All"
  },
  {
    id: 591,
    title: "Wieland or the Transformation: An American Tale by Charles Brockden Brown",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/wieland-or-the-transformation-an-american-tale.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/wieland-or-the-transformation-an-american-tale.txt",
    genre: "Horror & Gothic, All"
  },
  {
    id: 592,
    title: "A Modest Proposal by Jonathan Swift",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-modest-proposal.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-modest-proposal.txt",
    genre: "Satire, Social & Political, All"
  },
  {
    id: 593,
    title: "A Vindication of the Rights of Woman by Mary Wollstonecraft",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-vindication-of-the-rights-of-woman.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-vindication-of-the-rights-of-woman.txt",
    genre: "Philosophy, Social & Political, All"
  },
  {
    id: 594,
    title: "An Inquiry into the Nature and Causes of the Wealth of Nations by Adam Smith",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/an-inquiry-into-the-nature-and-causes-of-the-wealth-of-nations.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/an-inquiry-into-the-nature-and-causes-of-the-wealth-of-nations.txt",
    genre: "Economics, Social & Political, All"
  },
  {
    id: 595,
    title: "An Enquiry Concerning Human Understanding by David Hume",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/an-enquiry-concerning-human-understanding.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/an-enquiry-concerning-human-understanding.txt",
    genre: "Philosophy, Social & Political, All"
  },
  {
    id: 596,
    title: "Against the Grain by Joris-Karl Huysmans",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/against-the-grain.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/against-the-grain.txt",
    genre: "Philosophy, Classics, All"
  },
  {
    id: 597,
    title: "Areopagitica by John Milton",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/areopagitica.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/areopagitica.txt",
    genre: "Philosophy, Social & Political, All"
  },
  {
    id: 598,
    title: "Common Sense by Thomas Paine",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/common-sense.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/common-sense.txt",
    genre: "Social & Political, Historical, All"
  },
  {
    id: 599,
    title: "Democracy in America Volume 1 by Alexis de Tocqueville",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/democracy-in-america-volume-1.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/democracy-in-america-volume-1.txt",
    genre: "Social & Political, Historical, All"
  },
  {
    id: 600,
    title: "Fundamental Principles of the Metaphysic of Morals by Immanuel Kant",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/fundamental-principles-of-the-metaphysic-of-morals.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/fundamental-principles-of-the-metaphysic-of-morals.txt",
    genre: "Philosophy, Social & Political, All"
  },
  {
    id: 601,
    title: "Der Briefwechsel Zwischen Friedrich Engels und Karl Marx 1844 bis 1883 by Karl Marx & Friedrich Engels",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/der-briefwechsel-zwischen-friedrich-engels-und-karl-marx-1844-bis-1883.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/der-briefwechsel-zwischen-friedrich-engels-und-karl-marx-1844-bis-1883.txt",
    genre: "Philosophy, Social & Political, All"
  },
  {
    id: 602,
    title: "Garden Cities of To-Morrow by Ebenezer Howard",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/garden-cities-of-to-morrow.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/garden-cities-of-to-morrow.txt",
    genre: "Social & Political, All"
  },
  {
    id: 603,
    title: "Herland by Charlotte Perkins Gilman",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/herland.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/herland.txt",
    genre: "Social & Political, Fiction, All"
  },
  {
    id: 604,
    title: "Les Conséquences Politiques de la Paix by Leon Trotsky",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/les-consequences-politiques-de-la-paix.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/les-consequences-politiques-de-la-paix.txt",
    genre: "Social & Political, Historical, All"
  },
  {
    id: 605,
    title: "Mein Kampf (My Struggle) by Adolf Hitler",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/mein-kampf-my-struggle.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/mein-kampf-my-struggle.txt",
    genre: "Social & Political, Historical, All"
  },
  {
    id: 606,
    title: "My Bondage and My Freedom by Frederick Douglass",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/my-bondage-and-my-freedom.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/my-bondage-and-my-freedom.txt",
    genre: "Biography & Autobiography, Social & Political, Historical, All"
  },
  {
    id: 607,
    title: "My Further Disillusionment by Emma Goldman",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/my-further-disillusionment.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/my-further-disillusionment.txt",
    genre: "Biography & Autobiography, Social & Political, All"
  },
  {
    id: 608,
    title: "News from Nowhere: Or, an Epoch of Rest by William Morris",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/news-from-nowhere-or-an-epoch-of-rest.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/news-from-nowhere-or-an-epoch-of-rest.txt",
    genre: "Social & Political, Fiction, All"
  },
  {
    id: 609,
    title: "Narrative of the Life of Frederick Douglass, an American Slave by Frederick Douglass",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/narrative-of-the-life-of-frederick-douglass-an-american-slave.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/narrative-of-the-life-of-frederick-douglass-an-american-slave.txt",
    genre: "Biography & Autobiography, Social & Political, Historical, All"
  },
  {
    id: 610,
    title: "On Liberty by John Stuart Mill",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/on-liberty.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/on-liberty.txt",
    genre: "Philosophy, Social & Political, All"
  },
  {
    id: 611,
    title: "Public Opinion by Walter Lippmann",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/public-opinion.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/public-opinion.txt",
    genre: "Social & Political, Philosophy, All"
  },
  {
    id: 612,
    title: "Second Treatise of Government by John Locke",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/second-treatise-of-government.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/second-treatise-of-government.txt",
    genre: "Philosophy, Social & Political, All"
  },
  {
    id: 613,
    title: "Social Devices for Impelling Women to Bear and Rear Children by Harriet Hardy",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/social-devices-for-impelling-women-to-bear-and-rear-children.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/social-devices-for-impelling-women-to-bear-and-rear-children.txt",
    genre: "Social & Political, All"
  },
  {
    id: 614,
    title: "The Communist Manifesto",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-communist-manifesto.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-communist-manifesto.txt",
    genre: "Philosophy, Social & Political, Historical, All"
  },
  {
    id: 615,
    title: "The Crowd: A Study of the Popular Mind",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-crowd-a-study-of-the-popular-mind.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-crowd-a-study-of-the-popular-mind.txt",
    genre: "Social & Political, Psychology, All"
  },
  {
    id: 616,
    title: "The Federalist Papers",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-federalist-paper.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-federalist-paper.txt",
    genre: "Social & Political, Historical, All"
  },
  {
    id: 617,
    title: "The Jewish State by Theodor Herzl",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-jewish-state.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-jewish-state.txt",
    genre: "Social & Political, Historical, All"
  },
  {
    id: 618,
    title: "The Negro Workers by George Padmore",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-negro-workers.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-negro-workers.txt",
    genre: "Social & Political, Historical, All"
  },
  {
    id: 619,
    title: "The Principles of Masonic Law by Albert G. Mackey",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-principles-of-masonic-law.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-principles-of-masonic-law.txt",
    genre: "Social & Political, All"
  },
  {
    id: 620,
    title: "The Principles of Economics with Applications to Practical Problems by Frank A. Fetter",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-principles-of-economics-with-applications-to-practical-problems.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-principles-of-economics-with-applications-to-practical-problems.txt",
    genre: "Social & Political, Economics, All"
  },
  {
    id: 621,
    title: "The Souls of Black Folk by W.E.B. Du Bois",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-souls-of-black-folk.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-souls-of-black-folk.txt",
    genre: "Biography & Autobiography, Social & Political, Historical, All"
  },
  {
    id: 622,
    title: "The Rising Tide of Color Against White World-Supremacy by Lothrop Stoddard",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-rising-tide-of-color-against-white-world-supremacy.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-rising-tide-of-color-against-white-world-supremacy.txt",
    genre: "Social & Political, Historical, All"
  },
  {
    id: 623,
    title: "The Theory of the Leisure Class by Thorstein Veblen",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-theory-of-the-leisure-class.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-theory-of-the-leisure-class.txt",
    genre: "Social & Political, Economics, All"
  },
  {
    id: 624,
    title: "The Witch-Cult in Western Europe: A Study in Anthropology by Margaret Alice Murray",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-witch-cult-in-western-europe-a-study-in-anthropology.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-witch-cult-in-western-europe-a-study-in-anthropology.txt",
    genre: "Social & Political, Anthropology, All"
  },
  {
    id: 625,
    title: "Utopia by Thomas More",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/utopia.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/utopia.txt",
    genre: "Philosophy, Social & Political, All"
  },
  {
    id: 626,
    title: "Woman in the Nineteenth Century by Margaret Fuller",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/woman-in-the-nineteenth-century.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/woman-in-the-nineteenth-century.txt",
    genre: "Social & Political, All"
  },
  {
    id: 627,
    title: "Incidents in the Life of a Slave Girl by Harriet Ann Jacobs",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/incidents-in-the-life-of-a-slave-girl.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/incidents-in-the-life-of-a-slave-girl.txt",
    genre: "Biography & Autobiography, Social & Political, Historical, All"
  },
  {
    id: 628,
    title: "An Introduction to Mathematics by Alfred North Whitehead",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/an-introduction-to-mathematics.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/an-introduction-to-mathematics.txt",
    genre: "Science & Mathematics, All"
  },
  {
    id: 629,
    title: "Calculus Made Easy by Silvanus P. Thompson",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/calculus-made-easy.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/calculus-made-easy.txt",
    genre: "Science & Mathematics, All"
  },
  {
    id: 630,
    title: "A Greek-English Lexicon to the New Testament by Joseph Henry Thayer",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-greek-english-lexicon-to-the-new-testament.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-greek-english-lexicon-to-the-new-testament.txt",
    genre: "Religion & Spirituality, Science & Mathematics, All"
  },
  {
    id: 631,
    title: "A System of Logic, Ratiocinative and Inductive by John Stuart Mill",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-system-of-logic-ratiocinative-and-inductive.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-system-of-logic-ratiocinative-and-inductive.txt",
    genre: "Philosophy, Science & Mathematics, All"
  },
  {
    id: 632,
    title: "1001-задача-для-умственного-счета by М. И. Сканави",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/1001-задача-для-умственного-счета.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/1001-задача-для-умственного-счета.txt",
    genre: "Science & Mathematics, All"
  },
  {
    id: 633,
    title: "A Dictionary of Slang, Cant, and Vulgar Words by John S. Farmer",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-dictionary-of-slang-cant-and-vulgar-words.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-dictionary-of-slang-cant-and-vulgar-words.txt",
    genre: "Social & Political, Linguistics, All"
  },
  {
    id: 634,
    title: "An Investigation of the Laws of Thought by George Boole",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/an-investigation-of-the-laws-of-thought.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/an-investigation-of-the-laws-of-thought.txt",
    genre: "Philosophy, Science & Mathematics, All"
  },
  {
    id: 635,
    title: "Experimental Researches in Electricity, Volume 1 by Michael Faraday",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/experimental-researches-in-electricity-volume-1.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/experimental-researches-in-electricity-volume-1.txt",
    genre: "Science & Mathematics, All"
  },
  {
    id: 636,
    title: "Helps to Latin Translation at Sight by Edmund Luce",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/helps-to-latin-translation-at-sight.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/helps-to-latin-translation-at-sight.txt",
    genre: "Science & Mathematics, Linguistics, All"
  },
  {
    id: 637,
    title: "Little Masterpieces of Science: Mind by Various Authors",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/little-masterpieces-of-science-mind.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/little-masterpieces-of-science-mind.txt",
    genre: "Science & Mathematics, All"
  },
  {
    id: 638,
    title: "Philosophiae Naturalis Principia Mathematica by Isaac Newton",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/philosophiae-naturalis-principia-mathematica.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/philosophiae-naturalis-principia-mathematica.txt",
    genre: "Science & Mathematics, Classics, All"
  },
  {
    id: 639,
    title: "Relativity: The Special and General Theory by Albert Einstein",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/relativity-the-special-and-general-theory.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/relativity-the-special-and-general-theory.txt",
    genre: "Science & Mathematics, Classics, All"
  },
  {
    id: 640,
    title: "The Golden Bough: A Study of Magic and Religion",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-golden-bough-a-study-of-magic-and-religion.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-golden-bough-a-study-of-magic-and-religion.txt",
    genre: "Religion & Spirituality, Mythology & Legends, All"
  },
  {
    id: 641,
    title: "Beowulf by Anonymous",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/beowulf-an-anglo-saxon-epic-poem.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/beowulf-an-anglo-saxon-epic-poem.txt",
    genre: "Mythology & Legends, Classics, Poetry, All"
  },
  {
    id: 642,
    title: "The Iliad by Homer",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-iliad.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-iliad.txt",
    genre: "Mythology & Legends, Classics, Poetry, All"
  },
  {
    id: 643,
    title: "The Odyssey by Homer",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-odyssey.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-odyssey.txt",
    genre: "Mythology & Legends, Classics, Poetry, All"
  },
  {
    id: 644,
    title: "The Epic of Gilgamesh by Anonymous",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-epic-of-gilgamesh.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-epic-of-gilgamesh.txt",
    genre: "Mythology & Legends, Classics, Poetry, All"
  },
  {
    id: 645,
    title: "An Old Babylonian Version of the Gilgamesh Epic by Morris Jastrow",
    // Reuse the same S3 object for both image/txt if that's how you stored them
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/an-old-babylonian-version-of-the-gilgamesh-epic.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/an-old-babylonian-version-of-the-gilgamesh-epic.txt",
    genre: "Mythology & Legends, Classics, All"
  },
  {
    id: 646,
    title: "Ars Amatoria or The Art of Love by Ovid",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/ars-amatoria-or-the-art-of-love.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/ars-amatoria-or-the-art-of-love.txt",
    genre: "Classics, Poetry, All"
  },
  {
    id: 647,
    title: "Haida Texts and Myths by John Swanton",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/haida-texts-and-myths.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/haida-texts-and-myths.txt",
    genre: "Mythology & Legends, All"
  },
  {
    id: 648,
    title: "Filipino Popular Tales by Dean S. Fansler",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/filipino-popular-tales.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/filipino-popular-tales.txt",
    genre: "Mythology & Legends, All"
  },
  {
    id: 649,
    title: "Myths and Legends of China by E. T. C. Werner",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/myths-and-legends-of-china.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/myths-and-legends-of-china.txt",
    genre: "Mythology & Legends, All"
  },
  {
    id: 650,
    title: "Old World Japan: Legends of the Land of the Gods by Frank Rinder",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/old-world-japan-legends-of-the-land-of-the-gods.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/old-world-japan-legends-of-the-land-of-the-gods.txt",
    genre: "Mythology & Legends, Historical, All"
  },
  {
    id: 651,
    title: "Philippine Folk Tales by Mabel Cook Cole",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/philippine-folk-tales.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/philippine-folk-tales.txt",
    genre: "Mythology & Legends, All"
  },
  {
    id: 652,
    title: "The Argonautica",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-argonautica.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-argonautica.txt",
    genre: "Mythology & Legends, Classics, All"
  },
  {
    id: 653,
    title: "The Aeneid",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-aeneid.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-aeneid.txt",
    genre: "Mythology & Legends, Classics, Poetry, All"
  },
  // 654 is skipped in the original list
  {
    id: 655,
    title: "The Mahabharata of Krishna-Dwaipayana Vyasa",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-mahabharata-of-krishna-dwaipayana-vyasa.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-mahabharata-of-krishna-dwaipayana-vyasa.txt",
    genre: "Mythology & Legends, Classics, Poetry, All"
  },
  {
    id: 656,
    title: "The Metamorphoses of Ovid by Ovid",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-metamorphoses-of-ovid.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-metamorphoses-of-ovid.txt",
    genre: "Mythology & Legends, Classics, Poetry, All"
  },
  {
    id: 657,
    title: "The Mabinogion by Charlotte Guest",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-mabinogion.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-mabinogion.txt",
    genre: "Mythology & Legends, Historical, All"
  },
  {
    id: 658,
    title: "The Ramayan of Valmiki translated into English Verse by Ralph T.H. Griffith",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-ramayan-of-valmiki-translated-into-english-verse.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-ramayan-of-valmiki-translated-into-english-verse.txt",
    genre: "Mythology & Legends, Classics, Poetry, All"
  },
  {
    id: 659,
    title: "The Song Celestial or Bhagavad-Gita (From the Mahâbhârata) by Edwin Arnold",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-song-celestial-or-bhagavad-gita-(from-the-mahabharata).png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-song-celestial-or-bhagavad-gita-(from-the-mahabharata).txt",
    genre: "Mythology & Legends, Religion & Spirituality, Poetry, All"
  },
  {
    id: 660,
    title: "The Thousand and One Nights Vol I by Anonymous",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-thousand-and-one-nights-vol-I.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-thousand-and-one-nights-vol-I.txt",
    genre: "Mythology & Legends, Classics, All"
  },
  {
    id: 661,
    title: "Hindu Literature: Comprising The Book of Good Counsels, Nala and Damayanti, The Ramayana and Sakuntala",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/hindu-literature-comprising-the-book-of-good-counsels-nala-and-damayanti-the-ramayana-and-sakuntala.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/hindu-literature-comprising-the-book-of-good-counsels-nala-and-damayanti-the-ramayana-and-sakuntala.txt",
    genre: "Mythology & Legends, Religion & Spirituality, Poetry, All"
  },
  {
    id: 662,
    title: "Autobiography of Benjamin Franklin by Benjamin Franklin",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/autobiography-of-benjamin-franklin.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/autobiography-of-benjamin-franklin.txt",
    genre: "Biography & Autobiography, Social & Political, All"
  },
  {
    id: 663,
    title: "Autobiography of Andrew Carnegie by Andrew Carnegie",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/autobiography-of-andrew-carnegie.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/autobiography-of-andrew-carnegie.txt",
    genre: "Biography & Autobiography, Social & Political, All"
  },
  // 664 is missing from your original list, so it jumps to 665
  {
    id: 665,
    title: "Apologia Pro Vita Sua by John Henry Newman",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/apologia-pro-vita-sua.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/apologia-pro-vita-sua.txt",
    genre: "Biography & Autobiography, Religion & Spirituality, All"
  },
  {
    id: 666,
    title: "Autobiography of a Yogi by Paramahansa Yogananda",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/autobiography-of-a-yogi.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/autobiography-of-a-yogi.txt",
    genre: "Biography & Autobiography, Religion & Spirituality, All"
  },
  {
    id: 667,
    title: "Grace Abounding to the Chief of Sinners by John Bunyan",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/grace-abounding-to-the-chief-of-sinners.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/grace-abounding-to-the-chief-of-sinners.txt",
    genre: "Biography & Autobiography, Religion & Spirituality, All"
  },
  {
    id: 668,
    title: "Franklin's Way to Wealth or Poor Richard Improved by Benjamin Franklin",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/franklins-way-to-wealth-or-poor-richard-improved.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/franklins-way-to-wealth-or-poor-richard-improved.txt",
    genre: "Biography & Autobiography, All"
  },
  {
    id: 669,
    title: "My Life Volume 1 by Richard Wagner",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/my-life-volume-1.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/my-life-volume-1.txt",
    genre: "Biography & Autobiography, All"
  },
  {
    id: 670,
    title: "Narrative of the Life of Frederick Douglass, an American Slave by Frederick Douglass",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/narrative-of-the-life-of-frederick-douglass-an-american-slave.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/narrative-of-the-life-of-frederick-douglass-an-american-slave.txt",
    genre: "Biography & Autobiography, Social & Political, Historical, All"
  },
  {
    id: 671,
    title: "Memoirs of the Court of Marie Antoinette, Queen of France, Complete by Madame Campan",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/memoirs-of-the-court-of-marie-antoinette-queen-of-france-complete.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/memoirs-of-the-court-of-marie-antoinette-queen-of-france-complete.txt",
    genre: "Biography & Autobiography, Historical, All"
  },
  {
    id: 672,
    title: "Personal Memoirs of U.S. Grant (Complete) by Ulysses S. Grant",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/personal-memoirs-of-u-s-grant-(complete).png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/personal-memoirs-of-u-s-grant-(complete).txt",
    genre: "Biography & Autobiography, Historical, All"
  },
  {
    id: 673,
    title: "Sailing Alone Around the World by Joshua Slocum",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/sailing-alone-around-the-world.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/sailing-alone-around-the-world.txt",
    genre: "Biography & Autobiography, Adventure, All"
  },
  {
    id: 674,
    title: "The Confessions of Jean-Jacques Rousseau",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-confessions-of-jean-jacques-rousseau-complete.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-confessions-of-jean-jacques-rousseau-complete.txt",
    genre: "Biography & Autobiography, Philosophy, All"
  },
  {
    id: 675,
    title: "The Education of Henry Adams",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-education-of-henry-adams.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-education-of-henry-adams.txt",
    genre: "Biography & Autobiography, Philosophy, Historical, All"
  },
  {
    id: 676,
    title: "The Memoirs of Jacques Casanova de Seingalt, 1725-1798 Complete by Jacques Casanova de Seingalt",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-memoirs-of-jacques-casanova-de-seingalt-1725-1798-complete.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-memoirs-of-jacques-casanova-de-seingalt-1725-1798-complete.txt",
    genre: "Biography & Autobiography, All"
  },
  {
    id: 677,
    title: "The Notebooks of Leonardo Da Vinci Complete by Leonardo Da Vinci",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-notebooks-of-leonardo-da-vinci-complete.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-notebooks-of-leonardo-da-vinci-complete.txt",
    genre: "Biography & Autobiography, Science & Mathematics, All"
  },
  {
    id: 678,
    title: "The Papers and Writings of Abraham Lincoln Complete by Abraham Lincoln",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-papers-and-writings-of-abraham-lincoln-complete.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-papers-and-writings-of-abraham-lincoln-complete.txt",
    genre: "Biography & Autobiography, Social & Political, Historical, All"
  },
  {
    id: 679,
    title: "The Story of My Life: Complete by Helen Keller",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-story-of-my-life-complete.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-story-of-my-life-complete.txt",
    genre: "Biography & Autobiography, Historical, All"
  },
  {
    id: 680,
    title: "The Underground Railroad by William Still",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-underground-railroad.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-underground-railroad.txt",
    genre: "Biography & Autobiography, Social & Political, All"
  },
  {
    id: 681,
    title: "The Worst Journey in the World, Antarctic 1910-1913 by Apsley Cherry-Garrard",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-worst-journey-in-the-world-antarctic-1910-1913.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-worst-journey-in-the-world-antarctic-1910-1913.txt",
    genre: "Biography & Autobiography, Adventure, Historical, All"
  },
  {
    id: 682,
    title: "Incidents in the Life of a Slave Girl by Harriet Ann Jacobs",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/incidents-in-the-life-of-a-slave-girl.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/incidents-in-the-life-of-a-slave-girl.txt",
    genre: "Biography & Autobiography, Social & Political, Historical, All"
  },
  {
    id: 683,
    title: "A Modest Proposal by Jonathan Swift",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-modest-proposal.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-modest-proposal.txt",
    genre: "Satire, Social & Political, All"
  },
  {
    id: 684,
    title: "A Tale of a Tub by Jonathan Swift",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-tale-of-a-tub.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/a-tale-of-a-tub.txt",
    genre: "Satire, Philosophy, All"
  },
  {
    id: 685,
    title: "Candide by Voltaire",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/candide.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/candide.txt",
    genre: "Satire, Philosophy, Classics, All"
  },
  // 686 and 687 are missing from your original list, so it jumps to 688
  {
    id: 688,
    title: "Gulliver's Travels by Jonathan Swift",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/gullivers-travels.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/gullivers-travels.txt",
    genre: "Satire, Adventure, Classics, All"
  },
  {
    id: 689,
    title: "Gargantua and Pantagruel by François Rabelais",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/gargantua-and-pantagruel.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/gargantua-and-pantagruel.txt",
    genre: "Satire, Philosophy, Classics, All"
  },
  {
    id: 690,
    title: "Erewhon, or Over the Range by Samuel Butler",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/erewhon-or-over-the-range.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/erewhon-or-over-the-range.txt",
    genre: "Satire, Social & Political, All"
  },
  {
    id: 691,
    title: "Micromegas by Voltaire",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/micromegas.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/micromegas.txt",
    genre: "Satire, Science Fiction & Fantasy, All"
  },
  {
    id: 692,
    title: "Sketches New and Old by Mark Twain",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/sketches-new-and-old.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/sketches-new-and-old.txt",
    genre: "Satire, Classics, All"
  },
  {
    id: 693,
    title: "Sult by Knut Hamsun",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/sult.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/sult.txt",
    genre: "Classics, Philosophy, All"
  },
  {
    id: 694,
    title: "The Battle of the Books and Other Short Pieces",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-battle-of-the-books-and-other-short-pieces.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-battle-of-the-books-and-other-short-pieces.txt",
    genre: "Satire, Philosophy, All"
  },
  {
    id: 695,
    title: "The Devil's Dictionary",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-devils-dictionary.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-devils-dictionary.txt",
    genre: "Satire, Humor, All"
  },
  {
    id: 696,
    title: "The Man That Corrupted Hadleyburg by Mark Twain",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-man-that-corrupted-hadleyburg.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-man-that-corrupted-hadleyburg.txt",
    genre: "Satire, Social & Political, All"
  },
  {
    id: 697,
    title: "The Inspector-General by Nikolai Gogol",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-inspector-general.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-inspector-general.txt",
    genre: "Satire, Drama & Plays, All"
  },
  {
    id: 698,
    title: "The School for Scandal by Richard Brinsley Sheridan",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-school-for-scandal.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-school-for-scandal.txt",
    genre: "Satire, Drama & Plays, All"
  },
  {
    id: 699,
    title: "In Praise of Folly by Desiderius Erasmus",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/in-praise-of-folly.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/in-praise-of-folly.txt",
    genre: "Satire, Philosophy, All"
  },
  {
    id: 700,
    title: "Tremendous Trifles by G. K. Chesterton",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/tremendous-trifles.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/tremendous-trifles.txt",
    genre: "Satire, Philosophy, All"
  },
  {
    id: 701,
    title: "The City of God Volume II",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-city-of-god-volume-ii.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-city-of-god-volume-ii.txt",
    genre: "Religion & Spirituality, Philosophy, All"
  },
  {
    id: 702,
    title: "The Enchiridion",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-enchiridion.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-enchiridion.txt",
    genre: "Religion & Spirituality, Philosophy, All"
  },
  {
    id: 703,
    title: "The Divine Comedy",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-divine-comedy.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-divine-comedy.txt",
    genre: "Classics, Religion & Spirituality, Poetry, All"
  },
  {
    id: 704,
    title: "The Imitation of Christ by Thomas à Kempis",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-imitation-of-christ.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-imitation-of-christ.txt",
    genre: "Religion & Spirituality, All"
  },
  {
    id: 705,
    title: "The King James Version of the Bible",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-king-james-version-of-the-bible.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-king-james-version-of-the-bible.txt",
    genre: "Religion & Spirituality, Classics, All"
  },
  {
    id: 706,
    title: "The Kama Sutra",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-kama-sutra.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-kama-sutra.txt",
    genre: "Religion & Spirituality, All"
  },
  {
    id: 707,
    title: "The Person and Work of the Holy Spirit by R. A. Torrey",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-person-and-work-of-the-holy-spirit.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-person-and-work-of-the-holy-spirit.txt",
    genre: "Religion & Spirituality, All"
  },
  {
    id: 708,
    title: "The Tao-Teh-King or The Tao and Its Characteristics by Laozi",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-tao-teh-king-or-the-tao-and-its-characteristics.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-tao-teh-king-or-the-tao-and-its-characteristics.txt",
    genre: "Religion & Spirituality, Philosophy, All"
  },
  // 709 is missing from your original list, so it jumps to 710
  {
    id: 710,
    title: "Thought-Forms by Annie Besant and C. W. Leadbeater",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/thought-forms.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/thought-forms.txt",
    genre: "Religion & Spirituality, All"
  },
  {
    id: 711,
    title: "The Koran (Al-Qur'an)",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-koran.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-koran.txt",
    genre: "Religion & Spirituality, All"
  },
  {
    id: 712,
    title: "Institutes of the Christian Religion (Vol. 1 of 2) by John Calvin",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/institutes-of-the-christian-religion-(vol.-1-of-2).png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/institutes-of-the-christian-religion-(vol.-1-of-2).txt",
    genre: "Religion & Spirituality, Philosophy, All"
  },
  {
    id: 713,
    title: "White Nights and Other Stories by Fyodor Dostoevsky",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/white-nights-and-other-stories.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/white-nights-and-other-stories.txt",
    genre: "All, Classics, Romance, Philosophy"
  },
  {
    id: 714,
    title: "1984 by George Orwell",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/1984.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/1984.txt",
    genre: "All, Classics, Social & Political"
  },
  {
    id: 715,
    title: "Endymion; A Poetic Romance by John Keats",
    image: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/endymion-a-poetic-romance.png",
    txt:   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/endymion-a-poetic-romance.txt",
    genre: "All, Romance, Poetry, Mythology & Legends,"
  },
      ];
      return books.find((b) => b.id === parseInt(bookId)) || {
        id: null,
        title: "",
      };
    },
    async fetchBookContent() {
      if (!this.book.txt) return;
      try {
        // 2) Do a direct GET to the .txt link from S3
        const resp = await axios.get(this.book.txt, { responseType: "text" });
        this.bookContent = resp.data;
      } catch (error) {
        console.error("Failed to fetch text from direct S3 link:", error);
        this.bookContent = "(Could not load the text. Check S3 public policy + CORS.)";
      }
    },
    saveScrollPosition() {
      const bookId = this.book.id;
      if (this.$refs.container) {
        localStorage.setItem(
          `scrollPosition_Book_${bookId}`,
          this.$refs.container.scrollTop
        );
      }
    },
    restoreScrollPosition() {
      const bookId = this.book.id;
      const savedPosition = localStorage.getItem(
        `scrollPosition_Book_${bookId}`
      );
      this.$nextTick(() => {
        if (this.$refs.container && savedPosition) {
          this.$refs.container.scrollTop = parseInt(savedPosition, 10);
        }
      });
    },
    goBackToChooseAction() {
      this.saveScrollPosition();
      this.$router.push({
        name: "choose-action",
        params: {
          topicType: "book",
          topicId: this.book.id,
          topicName: this.book.title,
        },
      });
    },
  },
};
</script>

<style>
/* ================= GLOBAL SCROLLBAR STYLES ================= */
::-webkit-scrollbar {
  width: 10px;
  background-color: #f0f0f0;
}
::-webkit-scrollbar-track {
  background-color: #e6e6e6;
}
::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
  border: 2px solid #e6e6e6;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
html {
  scrollbar-width: thin;
  scrollbar-color: #888 #e6e6e6;
}
</style>

<style scoped>
.container {
  position: absolute;
  top: 80px;
  left: 0; 
  right: 0;
  bottom: 0;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background: linear-gradient(135deg, #ffffff, #f3f3f3);
  font-family: "Helvetica Neue", Arial, sans-serif;
}

.content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;

  background: #f7f7f7;
  border-radius: 8px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  min-height: 100%;
}

.centered-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.8rem;
  line-height: 1.2;
  font-family: "Georgia", serif;
  color: #333;
  font-weight: 600;
}

.centered-content {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 1.2rem;
  text-align: justify;
  width: 100%;
  max-width: 800px;
  line-height: 1.6;
  font-family: 'Georgia', serif;
  color: #444;
}

@media (max-width: 600px) {
  .content {
    padding: 10px;
  }

  .centered-title {
    font-size: 1.4rem;
  }

  .centered-content {
    font-size: 1rem;
    text-align: left;
    line-height: 1.5;
  }
}
</style>
