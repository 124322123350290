<template>
  <div>
    <Header
      :showBackButton="true"
      backText="Previous Page"
      @back="goBackToPreviousPage"
    />
    <div class="container" ref="container">
      <div class="content">
        <h1 class="title">Theodor N. Engøy</h1>

        <!-- Founder image: from S3 -->
        <img
          src="https://my-lion-assets.s3.eu-north-1.amazonaws.com/theodor-engoy.png"
          alt="Theodor N. Engøy"
          class="founder-image"
        />

        <h2>Founder of Resuvonia</h2>

        <h3>Contact Resuvonia</h3>
        <!-- Gmail icon from S3 -->
        <a href="mailto:resuvonia@gmail.com" class="contact-logo">
          <img
            src="https://my-lion-assets.s3.eu-north-1.amazonaws.com/gmail-logo.png"
            alt="Gmail"
            class="social-logo"
          />
        </a>

        <h3>Resuvonia’s Social Media</h3>
        <div class="social-media-links">
          <!-- YouTube icon from S3 -->
          <a href="https://www.youtube.com/@Resuvonia" target="_blank">
            <img
              src="https://my-lion-assets.s3.eu-north-1.amazonaws.com/youtube-logo.png"
              alt="YouTube"
              class="social-logo"
            />
          </a>
          <!-- Instagram icon from S3 -->
          <a href="https://www.instagram.com/resuvonia/" target="_blank">
            <img
              src="https://my-lion-assets.s3.eu-north-1.amazonaws.com/instagram-logo.png"
              alt="Instagram"
              class="social-logo"
            />
          </a>
          <!-- X (Twitter) icon from S3 -->
          <a href="https://x.com/Resuvonia" target="_blank">
            <img
              src="https://my-lion-assets.s3.eu-north-1.amazonaws.com/x-logo.png"
              alt="Twitter/X"
              class="social-logo"
            />
          </a>
        </div>

        <div class="faq-section">
          <h2>Frequently Asked Questions</h2>
          <p>
            <strong>1) What is Resuvonia.com?</strong><br />
            Resuvonia.com is a website where you can access free information
            mostly generated through OpenAI’s LLMs (ChatGPT-4o and ChatGPT o1
            pro mode). You can ask for anything and get a quick response for
            free. You can send a text to resuvonia@gmail.com and get an
            evaluation, simplification, and feedback on it quickly for free. You
            get access to over 700 free books. You can also buy a simpler
            version of each book for $0.3 in PDF, .txt and docx format. You have
            free access to over 670 articles, free daily news, and free research
            studies. Each includes an optional test with about 20 questions.
            There are also free games on the platform.
          </p>

          <p>
            <strong>2) Who founded Resuvonia, and when, and where?</strong><br />
            It was founded by Theodor N. Engøy in September 2024 in Ås Kommune,
            Norway. Mr. Engøy was born in his living room on March 3, 2005 in
            Ås, Norway. He began learning programming in June 2023.
          </p>

          <p>
            <strong>3) How is Resuvonia.com made?</strong><br />
            Resuvonia.com is made with Vue.js, ChatGPT-4o, ChatGPT o1 pro mode,
            Netlify, Heroku, Github, and AWS S3.
          </p>

          <p>
            <strong>4) Who owns Resuvonia.com?</strong><br />
            Resuvonia.com belongs to the sole proprietorship of Theodor N.
            Engøy.
          </p>

          <p>
            <strong>5) Can I donate to Resuvonia.com?</strong><br />
            Yes, you can donate via Stripe if you’d like to support the
            platform.
          </p>

          <p>
            <strong>6) Why is it called “Resuvonia”?</strong><br />
            “Resuvonia” stands for resources, suggestions, and votes because Mr.
            Engøy believes these are key principles of the universe. The “-nia”
            ending is reminiscent of countries like Romania or Albania and
            sounds like a university or planet, which is cool, and also was
            necessary because “Resuvo” as a domain was too expensive :‑D
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "./Header.vue";

export default {
  components: { Header },
  methods: {
    goBackToPreviousPage() {
      this.$router.go(-1);
    },
    saveScrollPosition() {
      if (this.$refs.container) {
        localStorage.setItem(
          "scrollPosition_AboutResuvoniaPage",
          this.$refs.container.scrollTop
        );
      }
    },
    restoreScrollPosition() {
      const pos = localStorage.getItem("scrollPosition_AboutResuvoniaPage");
      this.$nextTick(() => {
        if (this.$refs.container && pos) {
          this.$refs.container.scrollTop = parseInt(pos, 10);
        }
      });
    },
  },
  mounted() {
    this.restoreScrollPosition();
    if (this.$refs.container) {
      this.$refs.container.addEventListener("scroll", this.saveScrollPosition);
    }
    window.addEventListener("pagehide", this.saveScrollPosition);
  },
  beforeDestroy() {
    if (this.$refs.container) {
      this.$refs.container.removeEventListener(
        "scroll",
        this.saveScrollPosition
      );
    }
    window.removeEventListener("pagehide", this.saveScrollPosition);
  },
};
</script>

<style>
/* GLOBAL SCROLLBAR STYLES (same as in Header.vue) */
::-webkit-scrollbar {
  width: 10px;
  background-color: #f0f0f0;
}
::-webkit-scrollbar-track {
  background-color: #e6e6e6;
}
::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
  border: 2px solid #e6e6e6;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
html {
  scrollbar-width: thin;
  scrollbar-color: #888 #e6e6e6;
}
</style>

<style scoped>
.container {
  position: absolute;
  top: 80px; /* matches the 80px black header in Header.vue */
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
}

.title {
  margin-bottom: 20px;
  font-size: 2rem;
  color: #333;
}

.founder-image {
  max-width: 200px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h2,
h3 {
  color: #444;
  margin-bottom: 15px;
}

.social-media-links {
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
}

.social-logo {
  width: 50px;
  transition: transform 0.3s;
}
.social-logo:hover {
  transform: translateY(-3px);
}

.contact-logo {
  margin-bottom: 20px;
  display: inline-block;
}

.faq-section {
  margin-top: 30px;
  max-width: 700px;
}

.faq-section p {
  margin-bottom: 1.2rem;
  line-height: 1.6;
  color: #555;
  text-align: center;
}

.faq-section strong {
  color: #333;
}
</style>
