<template>
  <div>
    <Header
      :showBackButton="true"
      backText="Back to Games"
      @back="goBackToGames"
    />
    <!-- Dynamic component => load the correct game by ID -->
    <component
      :is="gameComponent"
      :gameId="gameId"
      style="display: block;"
    />
  </div>
</template>

<script>
import Header from '@/components/Header.vue';

// Keep only the relevant games
import QuizGame from '@/components/games/QuizGame.vue';
import Hangman from '@/components/games/Hangman.vue';
import TriviaTimer from '@/components/games/TriviaTimer.vue';
import MathGame from '@/components/games/MathGame.vue';

export default {
  name: 'GameDetails',
  components: {
    Header,
    QuizGame,
    Hangman,
    TriviaTimer,
    MathGame,
  },
  props: ['gameId'],
  computed: {
    gameComponent() {
      const gid = Number(this.gameId);
      switch (gid) {
        case 1: return 'QuizGame';
        case 3: return 'Hangman';
        case 11: return 'TriviaTimer';
        case 12: return 'MathGame';
        default:
          return 'QuizGame';
      }
    },
  },
  methods: {
    goBackToGames() {
      this.$router.push({ name: 'games' });
    },
  },
};
</script>

<style scoped>
/* Minimal styles; the child components handle layout themselves. */
</style>
