<template>
  <div>
    <Header
      :showBackButton="true"
      backText="Back to Choose Action"
      @back="goBackToChooseAction"
    />
    <div class="container" ref="container">
      <div class="content">
        <h1 class="centered-title">{{ research.title }}</h1>
        <pre class="centered-content">{{ researchContent }}</pre>
      </div>
    </div>
  </div>
</template>

<script>
import Header from './Header.vue';

export default {
  components: { Header },
  data() {
    return {
      research: { title: '', txt: '' },
      researchContent: '',
    };
  },
  mounted() {
    const studyId = this.$route.params.studyId;

    // Full array of research items (IDs 1→59, matching ResearchPage.vue)
    const studies = [
    {
    "id": 1,
    "title": "Artificial Intelligence in Healthcare: Early Detection of Alzheimer’s Disease through Machine Learning",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-Artificial-Intelligence-in-Healthcare-Early-Detection-of-Alzheimer’s-Disease-through-Machine-Learning.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-Artificial-Intelligence-in-Healthcare-Early-Detection-of-Alzheimer’s-Disease-through-Machine-Learning.txt",
    "genre": "Artificial Intelligence"
  },
  {
    "id": 2,
    "title": "Artificial Intelligence in Medical Diagnosis: Improving Accuracy and Speed in Healthcare",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-Artificial-Intelligence-in-Medical-Diagnosis-Improving-Accuracy-and-Speed-in-Healthcare.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-Artificial-Intelligence-in-Medical-Diagnosis-Improving-Accuracy-and-Speed-in-Healthcare.txt",
    "genre": "Artificial Intelligence"
  },
  {
    "id": 3,
    "title": "Caloric Restriction and Longevity: Insights from the Study of Aging in Non-Human Primates",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-Caloric-Restriction-and-Longevity-Insights-from-the-Study-of-Aging-in-Non-Human-Primates.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-Caloric-Restriction-and-Longevity-Insights-from-the-Study-of-Aging-in-Non-Human-Primates.txt",
    "genre": "Aging & Longevity"
  },
  {
    "id": 4,
    "title": "CRISPR-Cas9 and Gene Editing: Unlocking the Potential to Treat Genetic Diseases",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-CRISPR-Cas9-and-Gene-Editing-Unlocking-the-Potential-to-Treat-Genetic-Diseases.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-CRISPR-Cas9-and-Gene-Editing-Unlocking-the-Potential-to-Treat-Genetic-Diseases.txt",
    "genre": "Genetics"
  },
  {
    "id": 5,
    "title": "Exercise and Brain Health: The Role of Aerobic Activity in Enhancing Cognitive Function and Neuroplasticity",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-Exercise-and-Brain-Health-The-Role-of-Aerobic-Activity-in-Enhancing-Cognitive-Function-and-Neuroplasticity.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-Exercise-and-Brain-Health-The-Role-of-Aerobic-Activity-in-Enhancing-Cognitive-Function-and-Neuroplasticity.txt",
    "genre": "Neuroscience"
  },
  {
    "id": 6,
    "title": "Gravitational Waves: A New Window into the Universe",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-Gravitational-Waves-A-New-Window-into-the-Universe.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-Gravitational-Waves-A-New-Window-into-the-Universe.txt",
    "genre": "Physics"
  },
  {
    "id": 7,
    "title": "Immunotherapy in Cancer: Harnessing the Body’s Immune System to Fight Tumors",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-Immunotherapy-in-Cancer-Harnessing-the-Body’s-Immune-System-to-Fight-Tumors.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-Immunotherapy-in-Cancer-Harnessing-the-Body’s-Immune-System-to-Fight-Tumors.txt",
    "genre": "Cancer Research"
  },
  {
    "id": 8,
    "title": "Neuroplasticity",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-Neuroplasticity.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-Neuroplasticity.txt",
    "genre": "Neuroscience"
  },
  {
    "id": 9,
    "title": "Pharmacogenomics: Personalized Medicine through Genetic Profiling",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-Pharmacogenomics-Personalized-Medicine-through-Genetic-Profiling.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-Pharmacogenomics-Personalized-Medicine-through-Genetic-Profiling.txt",
    "genre": "Pharmacology"
  },
  {
    "id": 10,
    "title": "Targeting the Immune System: The Role of Immune Checkpoint Inhibitors in Cancer Treatment",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-Targeting-the-Immune-System-The-Role-of-Immune-Checkpoint-Inhibitors-in-Cancer-Treatment.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-Targeting-the-Immune-System-The-Role-of-Immune-Checkpoint-Inhibitors-in-Cancer-Treatment.txt",
    "genre": "Immunology"
  },
  {
    "id": 11,
    "title": "The Development of mRNA Vaccines: A New Frontier in Infectious Disease Prevention",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Development-of-mRNA-Vaccines-A-New-Frontier-in-Infectious-Disease-Prevention.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Development-of-mRNA-Vaccines-A-New-Frontier-in-Infectious-Disease-Prevention.txt",
    "genre": "Biotechnology"
  },
  {
    "id": 12,
    "title": "The Discovery of Graphene: Revolutionizing Materials Science",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Discovery-of-Graphene-Revolutionizing-Materials-Science.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Discovery-of-Graphene-Revolutionizing-Materials-Science.txt",
    "genre": "Chemistry"
  },
  {
    "id": 13,
    "title": "The Effects of Climate Change on Polar Ice Sheets: A Study of Melting Rates and Global Sea-Level Rise",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Effects-of-Climate-Change-on-Polar-Ice-Sheets-A-Study-of-Melting-Rates-and-Global-Sea-Level-Rise.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Effects-of-Climate-Change-on-Polar-Ice-Sheets-A-Study-of-Melting-Rates-and-Global-Sea-Level-Rise.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 14,
    "title": "The Effects of Mindfulness Meditation on Reducing Stress and Anxiety: A Randomized Controlled Trial",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Effects-of-Mindfulness-Meditation-on-Reducing-Stress-and-Anxiety-A-Randomized-Controlled-Trial.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Effects-of-Mindfulness-Meditation-on-Reducing-Stress-and-Anxiety-A-Randomized-Controlled-Trial.txt",
    "genre": "Psychology"
  },
  {
    "id": 15,
    "title": "The Global Impact of Vaccination Programs: Eradication of Smallpox and the Fight Against Infectious Diseases",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Global-Impact-of-Vaccination-Programs-Eradication-of-Smallpox-and-the-Fight-Against-Infectious-Diseases.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Global-Impact-of-Vaccination-Programs-Eradication-of-Smallpox-and-the-Fight-Against-Infectious-Diseases.txt",
    "genre": "Public Health"
  },
  {
    "id": 16,
    "title": "The Impact of Clean Water and Sanitation on Reducing Child Mortality: Lessons from Global Health Initiatives",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Impact-of-Clean-Water-and-Sanitation-on-Reducing-Child-Mortality-Lessons-from-Global-Health-Initiatives.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Impact-of-Clean-Water-and-Sanitation-on-Reducing-Child-Mortality-Lessons-from-Global-Health-Initiatives.txt",
    "genre": "Public Health"
  },
  {
    "id": 17,
    "title": "The Impact of Climate Change on Coral Reefs: Assessing the Consequences of Ocean Warming and Acidification",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Impact-of-Climate-Change-on-Coral-Reefs-Assessing-the-Consequences-of-Ocean-Warming-and-Acidification.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Impact-of-Climate-Change-on-Coral-Reefs-Assessing-the-Consequences-of-Ocean-Warming-and-Acidification.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 18,
    "title": "The Impact of Deforestation on Biodiversity: A Global Study of Tropical Rainforests",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Impact-of-Deforestation-on-Biodiversity-A-Global-Study-of-Tropical-Rainforests.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Impact-of-Deforestation-on-Biodiversity-A-Global-Study-of-Tropical-Rainforests.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 19,
    "title": "The Impact of Gut Microbiota on Mental Health: Unraveling the Gut-Brain Axis",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Impact-of-Gut-Microbiota-on-Mental-Health-Unraveling-the-Gut-Brain-Axis.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Impact-of-Gut-Microbiota-on-Mental-Health-Unraveling-the-Gut-Brain-Axis.txt",
    "genre": "Immunology"
  },
  {
    "id": 20,
    "title": "The Impact of Microbiome Diversity on Immune System Function: A Study of Gut Health and Immunity",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Impact-of-Microbiome-Diversity-on-Immune-System-Function-A-Study-of-Gut-Health-and-Immunity.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Impact-of-Microbiome-Diversity-on-Immune-System-Function-A-Study-of-Gut-Health-and-Immunity.txt",
    "genre": "Immunology"
  },
  {
    "id": 21,
    "title": "The Impact of Ocean Acidification on Marine Life: Assessing the Consequences of CO2 Emissions",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Impact-of-Ocean-Acidification-on-Marine-Life-Assessing-the-Consequences-of-CO2-Emissions.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Impact-of-Ocean-Acidification-on-Marine-Life-Assessing-the-Consequences-of-CO2-Emissions.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 22,
    "title": "The Impact of Vaccination Programs on Eradicating Smallpox: A Global Health Milestone",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Impact-of-Vaccination-Programs-on-Eradicating-Smallpox-A-Global-Health-Milestone.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Impact-of-Vaccination-Programs-on-Eradicating-Smallpox-A-Global-Health-Milestone.txt",
    "genre": "Public Health"
  },
  {
    "id": 23,
    "title": "The Influence of Social Media on Mental Health: A Study on Depression and Anxiety in Adolescents",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Influence-of-Social-Media-on-Mental-Health-A-Study-on-Depression-and-Anxiety-in-Adolescents.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Influence-of-Social-Media-on-Mental-Health-A-Study-on-Depression-and-Anxiety-in-Adolescents.txt",
    "genre": "Psychology"
  },
  {
    "id": 24,
    "title": "The Role of 3D Bioprinting in Organ Regeneration: A Breakthrough in Tissue Engineering",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-3D-Bioprinting-in-Organ-Regeneration-A-Breakthrough-in-Tissue-Engineering.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-3D-Bioprinting-in-Organ-Regeneration-A-Breakthrough-in-Tissue-Engineering.txt",
    "genre": "Molecular Biology"
  },
  {
    "id": 25,
    "title": "The Role of Antisense Oligonucleotides in Treating Genetic Disorders: A New Frontier in Precision Medicine",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-Antisense-Oligonucleotides-in-Treating-Genetic-Disorders-A-New-Frontier-in-Precision-Medicine.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-Antisense-Oligonucleotides-in-Treating-Genetic-Disorders-A-New-Frontier-in-Precision-Medicine.txt",
    "genre": "Genetics"
  },
  {
    "id": 26,
    "title": "The Role of Artificial Intelligence in Drug Discovery: Revolutionizing the Development of New Medicines",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-Artificial-Intelligence-in-Drug-Discovery-Revolutionizing-the-Development-of-New-Medicines.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-Artificial-Intelligence-in-Drug-Discovery-Revolutionizing-the-Development-of-New-Medicines.txt",
    "genre": "Artificial Intelligence"
  },
  {
    "id": 27,
    "title": "The Role of Artificial Intelligence in Personalized Cancer Treatment: Optimizing Therapies through Predictive Modeling",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-Artificial-Intelligence-in-Personalized-Cancer-Treatment-Optimizing-Therapies-through-Predictive-Modeling.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-Artificial-Intelligence-in-Personalized-Cancer-Treatment-Optimizing-Therapies-through-Predictive-Modeling.txt",
    "genre": "Artificial Intelligence"
  },
  {
    "id": 28,
    "title": "The Role of Artificial Intelligence in Predicting Disease Outbreaks: Enhancing Global Health Surveillance",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-Artificial-Intelligence-in-Predicting-Disease-Outbreaks-Enhancing-Global-Health-Surveillance.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-Artificial-Intelligence-in-Predicting-Disease-Outbreaks-Enhancing-Global-Health-Surveillance.txt",
    "genre": "Artificial Intelligence"
  },
  {
    "id": 29,
    "title": "The Role of Artificial Photosynthesis in Carbon Capture: A New Approach to Combating Climate Change",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-Artificial-Photosynthesis-in-Carbon-Capture-A-New-Approach-to-Combating-Climate-Change.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-Artificial-Photosynthesis-in-Carbon-Capture-A-New-Approach-to-Combating-Climate-Change.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 30,
    "title": "The Role of Bacteriophage Therapy in Combatting Antibiotic Resistance: A New Era in Infection Treatment",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-Bacteriophage-Therapy-in-Combatting-Antibiotic-Resistance-A-New-Era-in-Infection-Treatment.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-Bacteriophage-Therapy-in-Combatting-Antibiotic-Resistance-A-New-Era-in-Infection-Treatment.txt",
    "genre": "Medical Science"
  },
  {
    "id": 31,
    "title": "The Role of Biodiversity in Ecosystem Resilience: A Study of Ecosystem Stability in Response to Environmental Change",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-Biodiversity-in-Ecosystem-Resilience-A-Study-of-Ecosystem-Stability-in-Response-to-Environmental-Change.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-Biodiversity-in-Ecosystem-Resilience-A-Study-of-Ecosystem-Stability-in-Response-to-Environmental-Change.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 32,
    "title": "The Role of Bioprinting in Organ Regeneration: Revolutionizing Transplant Medicine",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-Bioprinting-in-Organ-Regeneration-Revolutionizing-Transplant-Medicine.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-Bioprinting-in-Organ-Regeneration-Revolutionizing-Transplant-Medicine.txt",
    "genre": "Molecular Biology"
  },
  {
    "id": 33,
    "title": "The Role of CAR-T Cell Therapy in Treating Blood Cancers: A Revolution in Immunotherapy",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-CAR-T-Cell-Therapy-in-Treating-Blood-Cancers-A-Revolution-in-Immunotherapy.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-CAR-T-Cell-Therapy-in-Treating-Blood-Cancers-A-Revolution-in-Immunotherapy.txt",
    "genre": "Cancer Research"
  },
  {
    "id": 34,
    "title": "The Role of CRISPR-Cas12 in Enhancing Antiviral Therapies: A Study in Precision Medicine",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-CRISPR-Cas12-in-Enhancing-Antiviral-Therapies-A-Study-in-Precision-Medicine.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-CRISPR-Cas12-in-Enhancing-Antiviral-Therapies-A-Study-in-Precision-Medicine.txt",
    "genre": "Genetics"
  },
  {
    "id": 35,
    "title": "The Role of CRISPR-Cas13 in RNA Editing: A New Frontier in Genetic Therapies",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-CRISPR-Cas13-in-RNA-Editing-A-New-Frontier-in-Genetic-Therapies.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-CRISPR-Cas13-in-RNA-Editing-A-New-Frontier-in-Genetic-Therapies.txt",
    "genre": "Genetics"
  },
  {
    "id": 36,
    "title": "The Role of Epigenetic Reprogramming in Aging: Unlocking the Secrets of Cellular Longevity",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-Epigenetic-Reprogramming-in-Aging-Unlocking-the-Secrets-of-Cellular-Longevity.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-Epigenetic-Reprogramming-in-Aging-Unlocking-the-Secrets-of-Cellular-Longevity.txt",
    "genre": "Genetics"
  },
  {
    "id": 37,
    "title": "The Role of Epigenetics in Cancer: How Environmental Factors Influence Gene Expression",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-Epigenetics-in-Cancer-How-Environmental-Factors-Influence-Gene-Expression.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-Epigenetics-in-Cancer-How-Environmental-Factors-Influence-Gene-Expression.txt",
    "genre": "Genetics"
  },
  {
    "id": 38,
    "title": "The Role of Gene Therapy in Treating Hemophilia: A Breakthrough in Genetic Medicine",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-Gene-Therapy-in-Treating-Hemophilia-A-Breakthrough-in-Genetic-Medicine.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-Gene-Therapy-in-Treating-Hemophilia-A-Breakthrough-in-Genetic-Medicine.txt",
    "genre": "Genetics"
  },
  {
    "id": 39,
    "title": "The Role of Genetic Testing in Personalized Medicine: Tailoring Treatments to Individual Patients",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-Genetic-Testing-in-Personalized-Medicine-Tailoring-Treatments-to-Individual-Patients.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-Genetic-Testing-in-Personalized-Medicine-Tailoring-Treatments-to-Individual-Patients.txt",
    "genre": "Genetics"
  },
  {
    "id": 40,
    "title": "The Role of Gut-Brain Axis in Neurodegenerative Diseases: Understanding the Link Between Gut Microbiota and Cognitive Decline",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-Gut-Brain-Axis-in-Neurodegenerative-Diseases-Understanding-the-Link-Between-Gut-Microbiota-and-Cognitive-Decline.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-Gut-Brain-Axis-in-Neurodegenerative-Diseases-Understanding-the-Link-Between-Gut-Microbiota-and-Cognitive-Decline.txt",
    "genre": "Neuroscience"
  },
  {
    "id": 41,
    "title": "The Role of Immunotherapy in Treating Melanoma: The Success of PD-1 Inhibitors",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-Immunotherapy-in-Treating-Melanoma-The-Success-of-PD-1-Inhibitors.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-Immunotherapy-in-Treating-Melanoma-The-Success-of-PD-1-Inhibitors.txt",
    "genre": "Immunology"
  },
  {
    "id": 42,
    "title": "The Role of Microbial Therapy in Treating Inflammatory Bowel Disease: Harnessing the Power of the Gut Microbiome",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-Microbial-Therapy-in-Treating-Inflammatory-Bowel-Disease-Harnessing-the-Power-of-the-Gut-Microbiome.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-Microbial-Therapy-in-Treating-Inflammatory-Bowel-Disease-Harnessing-the-Power-of-the-Gut-Microbiome.txt",
    "genre": "Immunology"
  },
  {
    "id": 43,
    "title": "The Role of Mitochondrial DNA in Aging: Unraveling the Link Between Energy Production and Longevity",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-Mitochondrial-DNA-in-Aging-Unraveling-the-Link-Between-Energy-Production-and-Longevity.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-Mitochondrial-DNA-in-Aging-Unraveling-the-Link-Between-Energy-Production-and-Longevity.txt",
    "genre": "Molecular Biology"
  },
  {
    "id": 44,
    "title": "The Role of Nanotechnology in Cancer Treatment: Targeted Drug Delivery and Enhanced Therapeutics",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-Nanotechnology-in-Cancer-Treatment-Targeted-Drug-Delivery-and-Enhanced-Therapeutics.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-Nanotechnology-in-Cancer-Treatment-Targeted-Drug-Delivery-and-Enhanced-Therapeutics.txt",
    "genre": "Cancer Research"
  },
  {
    "id": 45,
    "title": "The Role of Optogenetics in Neuroscience: Unlocking the Brain’s Secrets with Light",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-Optogenetics-in-Neuroscience-Unlocking-the-Brain’s-Secrets-with-Light.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-Optogenetics-in-Neuroscience-Unlocking-the-Brain’s-Secrets-with-Light.txt",
    "genre": "Neuroscience"
  },
  {
    "id": 46,
    "title": "The Role of Quantum Computing in Drug Discovery: Accelerating the Development of New Medicines",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-Quantum-Computing-in-Drug-Discovery-Accelerating-the-Development-of-New-Medicines.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-Quantum-Computing-in-Drug-Discovery-Accelerating-the-Development-of-New-Medicines.txt",
    "genre": "Artificial Intelligence"
  },
  {
    "id": 47,
    "title": "The Role of Senolytic Therapies in Combating Age-Related Diseases: Targeting Senescent Cells for Healthier Aging",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-Senolytic-Therapies-in-Combating-Age-Related-Diseases-Targeting-Senescent-Cells-for-Healthier-Aging.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-Senolytic-Therapies-in-Combating-Age-Related-Diseases-Targeting-Senescent-Cells-for-Healthier-Aging.txt",
    "genre": "Aging & Longevity"
  },
  {
    "id": 48,
    "title": "The Role of Sleep in Cognitive Performance: Understanding How Sleep Affects Memory and Learning",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-Sleep-in-Cognitive-Performance-Understanding-How-Sleep-Affects-Memory-and-Learning.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-Sleep-in-Cognitive-Performance-Understanding-How-Sleep-Affects-Memory-and-Learning.txt",
    "genre": "Neuroscience"
  },
  {
    "id": 49,
    "title": "The Role of Stem Cells in Regenerative Medicine: Unlocking the Potential for Tissue Repair and Organ Regeneration",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-Stem-Cells-in-Regenerative-Medicine-Unlocking-the-Potential-for-Tissue-Repair-and-Organ-Regeneration.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-Stem-Cells-in-Regenerative-Medicine-Unlocking-the-Potential-for-Tissue-Repair-and-Organ-Regeneration.txt",
    "genre": "Molecular Biology"
  },
  {
    "id": 50,
    "title": "The Role of T-Cell Engineering in Cancer Immunotherapy: A New Approach to Targeting Solid Tumors",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-T-Cell-Engineering-in-Cancer-Immunotherapy-A-New-Approach-to-Targeting-Solid-Tumors.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-T-Cell-Engineering-in-Cancer-Immunotherapy-A-New-Approach-to-Targeting-Solid-Tumors.txt",
    "genre": "Cancer Research"
  },
  {
    "id": 51,
    "title": "The Role of Telomere Shortening in Age-Related Diseases: A Study of Cellular Aging and Longevity",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-Telomere-Shortening-in-Age-Related-Diseases-A-Study-of-Cellular-Aging-and-Longevity.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-Telomere-Shortening-in-Age-Related-Diseases-A-Study-of-Cellular-Aging-and-Longevity.txt",
    "genre": "Cell Biology"
  },
  {
    "id": 52,
    "title": "The Role of the Gut Microbiome in Shaping the Immune System: Insights into Autoimmunity and Inflammatory Diseases",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-the-Gut-Microbiome-in-Shaping-the-Immune-System-Insights-into-Autoimmunity-and-Inflammatory-Diseases.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-the-Gut-Microbiome-in-Shaping-the-Immune-System-Insights-into-Autoimmunity-and-Inflammatory-Diseases.txt",
    "genre": "Immunology"
  },
  {
    "id": 53,
    "title": "The Stanford Prison Experiment: Understanding the Power of Situational Forces on Human Behavior",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Stanford-Prison-Experiment-Understanding-the-Power-of-Situational-Forces-on-Human-Behavior.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Stanford-Prison-Experiment-Understanding-the-Power-of-Situational-Forces-on-Human-Behavior.txt",
    "genre": "Sociology"
  },
  {
    "id": 54,
    "title": "The Tuskegee Syphilis Study: A Case of Ethical Violations in Medical Research",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Tuskegee-Syphilis-Study-A-Case-of-Ethical-Violations-in-Medical-Research.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Tuskegee-Syphilis-Study-A-Case-of-Ethical-Violations-in-Medical-Research.txt",
    "genre": "Ethics in Research"
  },
  {
    "id": 55,
    "title": "The Milgram Obedience Experiment: Understanding Human Behavior and Authority",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Milgram-Obedience-Experiment-Understanding-Human-Behavior-and-Authority.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Milgram-Obedience-Experiment-Understanding-Human-Behavior-and-Authority.txt",
    "genre": "Psychology"
  },
  {
    "id": 56,
    "title": "The Double-Slit Experiment: Unveiling the Wave-Particle Duality",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Double-Slit-Experiment-Unveiling-the-Wave-Particle-Duality.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Double-Slit-Experiment-Unveiling-the-Wave-Particle-Duality.txt",
    "genre": "Physics"
  },
  {
    "id": 57,
    "title": "The Role of Deep-Sea Hydrothermal Vents in Origin of Life Research",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-Deep-Sea-Hydrothermal-Vents-in-Origin-of-Life-Research.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-Deep-Sea-Hydrothermal-Vents-in-Origin-of-Life-Research.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 58,
    "title": "The Role of Epigenetics in Mental Health: How Environmental Factors Influence Gene Expression",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-Epigenetics-in-Mental-Health-How-Environmental-Factors-Influence-Gene-Expression.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-Epigenetics-in-Mental-Health-How-Environmental-Factors-Influence-Gene-Expression.txt",
    "genre": "Genetics"
  },
  {
    "id": 59,
    "title": "The Role of Antibiotic Stewardship Programs in Combating Antimicrobial Resistance",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-Antibiotic-Stewardship-Programs-in-Combating-Antimicrobial-Resistance.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/STUDY-The-Role-of-Antibiotic-Stewardship-Programs-in-Combating-Antimicrobial-Resistance.txt",
    "genre": "Public Health"
  }
    ];

    // 1) find the research study by ID
    this.research =
      studies.find((study) => study.id === parseInt(studyId)) || {
        title: '',
        txt: '',
      };

    // 2) fetch .txt if present
    if (this.research && this.research.txt) {
      this.fetchResearchContent(this.research.txt).then(() => {
        this.restoreScrollPosition();
      });
    }

    // Listen for scroll
    if (this.$refs.container) {
      this.$refs.container.addEventListener('scroll', this.saveScrollPosition);
    }
    window.addEventListener('pagehide', this.saveScrollPosition);
  },
  beforeDestroy() {
    if (this.$refs.container) {
      this.$refs.container.removeEventListener('scroll', this.saveScrollPosition);
    }
    window.removeEventListener('pagehide', this.saveScrollPosition);
  },
  beforeRouteLeave(to, from, next) {
    this.saveScrollPosition();
    next();
  },
  methods: {
    fetchResearchContent(url) {
      // Encode if needed (especially if the URL has spaces or special chars)
      const encodedUrl = encodeURI(url);
      return fetch(encodedUrl)
        .then((response) => response.text())
        .then((content) => {
          this.researchContent = content;
        })
        .catch((error) =>
          console.error('Fetch error:', error)
        );
    },
    saveScrollPosition() {
      const studyId = this.$route.params.studyId;
      if (this.$refs.container) {
        const scrollPosition = this.$refs.container.scrollTop;
        localStorage.setItem(`scrollPosition_Research_${studyId}`, scrollPosition);
      }
    },
    restoreScrollPosition() {
      const studyId = this.$route.params.studyId;
      const savedPosition = localStorage.getItem(`scrollPosition_Research_${studyId}`);
      this.$nextTick(() => {
        if (this.$refs.container && savedPosition) {
          this.$refs.container.scrollTop = parseInt(savedPosition, 10);
        }
      });
    },
    goBackToChooseAction() {
      this.saveScrollPosition();
      this.$router.push({
        name: 'choose-action',
        params: {
          topicType: 'research',
          topicId: this.research.id,
          topicName: this.research.title,
        },
      });
    },
  },
};
</script>

<style>
/* ================= GLOBAL SCROLLBAR STYLES ================= */
::-webkit-scrollbar {
  width: 10px;
  background-color: #f0f0f0;
}
::-webkit-scrollbar-track {
  background-color: #e6e6e6;
}
::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
  border: 2px solid #e6e6e6;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
html {
  scrollbar-width: thin;
  scrollbar-color: #888 #e6e6e6;
}
</style>

<style scoped>
.container {
  position: absolute;
  top: 80px; 
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  font-family: "Helvetica Neue", Arial, sans-serif; 
  background: linear-gradient(135deg, #ffffff, #f5f5f5);
}

.content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  background-color: #f7f7f7;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  min-height: 100%;
}

.centered-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.6rem;
  line-height: 1.2;
  font-family: 'Georgia', serif;
  color: #333;
  font-weight: 600;
}

.centered-content {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 1.2rem;
  text-align: justify;
  width: 100%;
  max-width: 800px;
  line-height: 1.6;
  font-family: 'Georgia', serif;
  color: #444;
}

@media (max-width: 600px) {
  .content {
    padding: 10px;
  }

  .centered-title {
    font-size: 1.25rem;
  }

  .centered-content {
    font-size: 1rem;
    text-align: left;
  }
}
</style>
