<template>
  <div class="container">
    <Header
      :showBackButton="true"
      :backText="`Back to ${backText}`"
      :showLogoutButton="true"
      @back="goBack"
    />
    <div class="content">
      <h1>Choose an Action for {{ topicType }}: {{ topicName }}</h1>

      <!-- Always show "Read" option -->
      <div class="resource-box" @click="readContent">
        Read {{ topicType }}
      </div>

      <!-- Show "Read Simpler Version" only if topic is a book -->
      <div
        v-if="topicType === 'book'"
        class="resource-box"
        @click="readSimplerVersion"
      >
        Read Simpler Version
      </div>

      <!-- Show "Take a Test" if topic is NOT 'book' => includes news -->
      <div
        v-if="topicType !== 'book'"
        class="resource-box"
        @click="takeTest"
      >
        Take a Test about {{ topicName }}
      </div>
    </div>
  </div>
</template>

<script>
import Header from './Header.vue';

export default {
  name: 'ChooseActionPage',
  components: { Header },
  props: {
    topicType: String,  // e.g. "news"
    topicId: Number,    // e.g. 1 => january 5 2025
    topicName: String,  // e.g. "January 5 2025"
  },
  data() {
    return {
      backText: '', // e.g. "Get More Resources" if news, or "Books" if book
    };
  },
  methods: {
    // Called when user clicks the back button in the header
    goBack() {
      if (this.topicType === 'news') {
        // For news => always go to the resources page
        this.$router.push({ name: 'resources' });
      } else if (this.topicType === 'book') {
        this.$router.push('/books');
      } else if (this.topicType === 'article') {
        this.$router.push('/articles');
      } else if (this.topicType === 'research') {
        this.$router.push('/research');
      } else {
        console.warn('Unknown topic type:', this.topicType);
        this.$router.push({ name: 'resources' });
      }
    },

    readContent() {
      if (this.topicType === 'news') {
        // If topicId <= 0 => fallback to id=1 (so we can load january 5)
        if (!this.topicId || this.topicId <= 0) {
          console.warn("Invalid newsId => fallback to 1 for january 5.");
          this.$router.push({
            name: 'news-page',
            params: { newsId: 1 },
          });
          return;
        }
        // else normal path
        this.$router.push({
          name: 'news-page',
          params: { newsId: this.topicId },
        });
      } else if (this.topicType === 'research') {
        this.$router.push({
          name: 'research-details',
          params: { studyId: this.topicId },
        });
      } else if (this.topicType === 'book') {
        this.$router.push({
          name: 'book-details',
          params: { bookId: this.topicId },
        });
      } else if (this.topicType === 'article') {
        this.$router.push({
          name: 'article-details',
          params: { articleId: this.topicId },
        });
      } else {
        console.error(`Unknown topic type: ${this.topicType}`);
      }
    },

    readSimplerVersion() {
      if (this.topicType === 'book') {
        this.$router.push({
          name: 'simpler-book-details',
          params: { bookId: this.topicId },
        });
      } else {
        console.error(`No simpler version for ${this.topicType}`);
      }
    },

    takeTest() {
      // e.g. => /test/news/1/January-5-2025
      this.$router.push({
        name: 'test-page',
        params: {
          topicType: this.topicType,
          topicId: this.topicId,
          topicName: this.topicName,
        },
      });
    },
  },
  mounted() {
    // Dynamically set the backText
    if (this.topicType === 'news') {
      this.backText = 'Get More Resources';
    } else if (this.topicType === 'book') {
      this.backText = 'Books';
    } else if (this.topicType === 'article') {
      this.backText = 'Articles';
    } else if (this.topicType === 'research') {
      this.backText = 'Research';
    } else {
      // Fallback text
      this.backText = 'Resources';
    }
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  font-family: "Helvetica Neue", Arial, sans-serif;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;
  margin-top: 60px;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  flex: 1;

  background: linear-gradient(135deg, #f9f9f9, #f3f3f3);
  border-radius: 6px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.content h1 {
  font-size: 1.8rem;
  text-align: center;
  color: #333;
  margin-bottom: 30px;
  font-weight: 600;
}

.resource-box {
  background: linear-gradient(135deg, #444, #2c2c2c);
  color: #ffffff;
  border: none;
  border-radius: 6px;
  padding: 20px;
  cursor: pointer;
  text-align: center;
  width: 100%;
  max-width: 240px;
  margin: 20px 0;
  transition: transform 0.3s, box-shadow 0.3s, background-color 0.3s;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.resource-box:hover {
  background-color: #555555;
  transform: translateY(-3px);
  box-shadow: 0 8px 18px rgba(0, 0, 0, 0.2);
}
</style>
