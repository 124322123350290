<template>
  <div>
    <Header
      :showBackButton="true"
      backText="Back to Choose Action"
      @back="goBackToChooseAction"
    />
    <div class="container" ref="container">
      <div class="content">
        <h1>Test for "{{ topicName }}"</h1>

        <!-- Show loading spinner or message while fetching -->
        <div v-if="loading" class="loading-message">
          Loading test from S3...
        </div>

        <!-- Show error if test not found -->
        <div v-else-if="errorMessage">
          <p class="error-message">{{ errorMessage }}</p>
        </div>

        <!-- Otherwise, show the test once loaded -->
        <div v-else>
          <!-- Each question -->
          <div
            v-for="(question, index) in testQuestions"
            :key="index"
            class="question-item"
          >
            <p class="question-title">
              {{ index + 1 }}. {{ question.question }}
            </p>
            <ul>
              <li
                v-for="(answer, i) in question.answers"
                :key="i"
                :class="{
                  correct: submitted && answer === question.correctAnswer,
                  wrong:
                    submitted &&
                    answer === userAnswers[index] &&
                    answer !== question.correctAnswer,
                }"
                class="answer-option"
              >
                <input
                  type="radio"
                  :name="'question-' + index"
                  :value="answer"
                  v-model="userAnswers[index]"
                  :disabled="submitted"
                />
                {{ answer }}
                <!-- Show correctness after submit -->
                <span v-if="submitted && answer === question.correctAnswer">
                  - Correct
                </span>
                <span
                  v-if="
                    submitted &&
                    answer === userAnswers[index] &&
                    answer !== question.correctAnswer
                  "
                >
                  - Your Answer
                </span>
              </li>
            </ul>
          </div>

          <!-- Submit or Restart -->
          <div v-if="!submitted">
            <button @click="submitTest" class="action-button">Submit Test</button>
          </div>
          <div v-else>
            <h2>Results</h2>
            <p>You got {{ correctAnswers }} out of {{ testQuestions.length }} correct.</p>
            <button @click="restartTest" class="action-button">Restart Test</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from './Header.vue';

export default {
  components: { Header },
  data() {
    return {
      loading: true,
      errorMessage: '',
      topicId: null,
      topicName: '',
      topicType: '',
      testQuestions: [],
      userAnswers: [],
      submitted: false,
      correctAnswers: 0,
    };
  },
  methods: {
    // Main load function
    async loadTest() {
      // 1) Get route params
      this.topicType = this.$route.params.topicType || 'unknown';
      this.topicId = parseInt(this.$route.params.topicId, 10);
      this.topicName = this.$route.params.topicName || 'Untitled';

      // 2) If topicId is missing or zero, show error
      if (!this.topicId || this.topicId <= 0) {
        this.errorMessage = "Error: Invalid or missing topicId in the URL.";
        this.loading = false;
        return;
      }

      // 3) Build the S3 URL e.g. https://my-lion-assets.s3.eu-north-1.amazonaws.com/news_1.json
      const s3Base = "https://my-lion-assets.s3.eu-north-1.amazonaws.com/";
      // e.g. "article_1.json", "research_2.json", "news_3.json"
      const fileName = `${this.topicType}_${this.topicId}.json`;
      const testUrl = s3Base + fileName;

      try {
        // 4) Fetch JSON from S3
        const response = await fetch(testUrl);
        if (!response.ok) {
          throw new Error(`Test JSON not found or forbidden at: ${testUrl}`);
        }
        const data = await response.json();

        // 5) data should have: { "questions": [ ... ] }
        if (!data.questions || !Array.isArray(data.questions)) {
          throw new Error("JSON does not contain a 'questions' array.");
        }

        // 6) Assign to local state
        this.testQuestions = data.questions;
        this.userAnswers = new Array(this.testQuestions.length).fill(null);
      } catch (err) {
        console.error("Error loading test from S3:", err);
        this.errorMessage = err.message || "Unknown error loading test.";
      }

      this.loading = false;
    },

    goBackToChooseAction() {
      // Provide valid topicId to avoid the param warning
      if (!this.topicId || this.topicId <= 0) {
        // If invalid => just go to the resources or somewhere safe
        this.$router.push({ name: 'resources' });
        return;
      }

      this.$router.push({
        name: 'choose-action',
        params: {
          topicType: this.topicType,
          topicId: this.topicId,     // Must match route param
          topicName: this.topicName, // optional
        },
      });
    },

    submitTest() {
      // Count correct
      this.correctAnswers = this.testQuestions.reduce((count, question, idx) => {
        return count + (this.userAnswers[idx] === question.correctAnswer ? 1 : 0);
      }, 0);

      this.submitted = true;

      // If you want to store results, do an API POST here
      // e.g. fetch('/api/users/save-test-result', {...})
    },

    restartTest() {
      this.submitted = false;
      this.correctAnswers = 0;
      this.userAnswers = new Array(this.testQuestions.length).fill(null);
    },

    saveScrollPosition() {
      if (this.$refs.container) {
        const scrollPosition = this.$refs.container.scrollTop;
        localStorage.setItem('scrollPosition_TestPage', scrollPosition);
      }
    },
    restoreScrollPosition() {
      const pos = localStorage.getItem('scrollPosition_TestPage');
      this.$nextTick(() => {
        if (this.$refs.container && pos) {
          this.$refs.container.scrollTop = parseInt(pos, 10);
        }
      });
    },
  },
  async mounted() {
    await this.loadTest();            // Load from S3
    this.restoreScrollPosition();     // Restore scroll

    if (this.$refs.container) {
      this.$refs.container.addEventListener('scroll', this.saveScrollPosition);
    }
    window.addEventListener('pagehide', this.saveScrollPosition);
  },
  beforeDestroy() {
    if (this.$refs.container) {
      this.$refs.container.removeEventListener('scroll', this.saveScrollPosition);
    }
    window.removeEventListener('pagehide', this.saveScrollPosition);
  },
};
</script>

<style>
/* ================= GLOBAL SCROLLBAR STYLES ================= */
::-webkit-scrollbar {
  width: 10px;
  background-color: #f0f0f0;
}
::-webkit-scrollbar-track {
  background-color: #e6e6e6;
}
::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
  border: 2px solid #e6e6e6;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
html {
  scrollbar-width: thin;
  scrollbar-color: #888 #e6e6e6;
}
/* =========================================================== */
</style>

<style scoped>
.container {
  position: absolute;
  top: 80px; 
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;

  display: flex;
  flex-direction: column;
  font-family: 'Georgia', serif;
  box-sizing: border-box;

  background: linear-gradient(135deg, #f9f9f9, #ffffff);
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

.content h1 {
  font-size: 1.6rem;
  margin-bottom: 20px;
  color: #333;
}

.loading-message {
  font-size: 1.2rem;
  margin: 20px 0;
  color: #555;
}

.error-message {
  font-size: 1.2rem;
  margin: 20px 0;
  color: red;
  font-weight: 600;
}

.content h2 {
  font-size: 1.4rem;
  margin-top: 30px;
  margin-bottom: 10px;
}

.question-item {
  margin-bottom: 30px;
  width: 100%;
  text-align: left;
  border-bottom: 1px dashed #ccc;
  padding-bottom: 20px;
}
.question-item:last-of-type {
  border-bottom: none;
}

.question-title {
  font-weight: 600;
  color: #444;
  margin-bottom: 10px;
}

.answer-option {
  list-style-type: none;
  margin: 6px 0;
}

.correct {
  color: green;
  font-weight: bold;
}
.wrong {
  color: red;
  font-weight: bold;
}

.action-button {
  background-color: #333;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 12px 20px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease, transform 0.3s;
  font-family: 'Georgia', serif;
  font-size: 1rem;
}
.action-button:hover {
  background-color: #555;
  transform: translateY(-2px);
}

@media (max-width: 600px) {
  .content {
    padding: 10px;
    font-size: 0.95rem;
  }
  .content h1 {
    font-size: 1.4rem;
  }
  .content h2 {
    font-size: 1.2rem;
  }
  .question-item {
    margin-bottom: 25px;
    padding-bottom: 15px;
  }
}
</style>
