<template>
  <div class="quiz-container">
    <h1 v-if="!quizComplete">Big Quiz Game (100 Questions)</h1>
    <h1 v-else>Quiz Completed!</h1>

    <!-- Show loading if data not ready -->
    <div v-if="loading" class="loading-message">
      Loading quiz...
    </div>
    <div v-else>
      <!-- If quiz not complete, show current question -->
      <div v-if="!quizComplete" class="question-area">
        <div class="question-header">
          <p class="category">{{ currentQuestion.category }}</p>
          <p class="question-index">
            Question {{ currentQuestionIndex + 1 }} of {{ totalQuestions }}
          </p>
        </div>
        <p class="question-text">
          {{ currentQuestion.question }}
        </p>

        <!-- Show multiple choice options -->
        <ul class="options-list">
          <li
            v-for="(option, i) in currentQuestion.options"
            :key="i"
            class="option"
            :class="{ selected: selectedOption === i }"
            @click="selectOption(i)"
          >
            {{ option }}
          </li>
        </ul>

        <div class="controls">
          <!-- Next/Submit button -->
          <button class="quiz-button" @click="submitAnswer">
            {{ currentQuestionIndex < totalQuestions - 1 ? 'Next' : 'Finish' }}
          </button>
        </div>
      </div>

      <!-- If quiz is complete, show score summary -->
      <div v-else class="results-area">
        <h2>Your Score: {{ score }} / {{ totalQuestions }}</h2>
        <p>Total Questions: {{ totalQuestions }}</p>
        <p>Percentage: {{ ((score / totalQuestions) * 100).toFixed(2) }}%</p>
        <button class="quiz-button" @click="restartQuiz">
          Restart Quiz
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuizGame',
  data() {
    return {
      loading: true,
      currentQuestionIndex: 0,
      selectedOption: null,
      score: 0,
      quizComplete: false,
      questions: [],
      shuffleQuestions: true, // Set to false if you want a fixed order
    };
  },
  computed: {
    currentQuestion() {
      return this.questions[this.currentQuestionIndex];
    },
    totalQuestions() {
      return this.questions.length;
    },
  },
  methods: {
    loadQuestions() {
      // A large set of 100 questions covering multiple categories
      // Each question => { category, question, options: [], correctIndex }
      let q = [
        {
          category: "General Knowledge",
          question: "Which country is home to the kangaroo?",
          options: ["South Africa", "Canada", "Australia", "Brazil"],
          correctIndex: 2,
        },
        {
          category: "Science",
          question: "What is the chemical symbol for water?",
          options: ["H2O", "O2", "CO2", "H2"],
          correctIndex: 0,
        },
        {
          category: "History",
          question: "In which year did World War II end?",
          options: ["1939", "1945", "1955", "1918"],
          correctIndex: 1,
        },
        {
          category: "Geography",
          question: "What is the capital city of Japan?",
          options: ["Seoul", "Tokyo", "Osaka", "Beijing"],
          correctIndex: 1,
        },
        {
          category: "Math",
          question: "What is 12 × 8?",
          options: ["96", "88", "108", "120"],
          correctIndex: 0,
        },
        {
          category: "Literature",
          question: "Who wrote 'Pride and Prejudice'?",
          options: ["Emily Brontë", "Jane Austen", "Charles Dickens", "Mark Twain"],
          correctIndex: 1,
        },
        {
          category: "General Knowledge",
          question: "Which planet is known as the Red Planet?",
          options: ["Mars", "Venus", "Jupiter", "Saturn"],
          correctIndex: 0,
        },
        {
          category: "Music",
          question: "Which composer became deaf later in his life?",
          options: ["Mozart", "Bach", "Beethoven", "Chopin"],
          correctIndex: 2,
        },
        {
          category: "Sports",
          question: "Which country won the 2018 FIFA World Cup?",
          options: ["Brazil", "Germany", "France", "Spain"],
          correctIndex: 2,
        },
        {
          category: "Movies",
          question: "In which movie did Leonardo DiCaprio play Jack Dawson?",
          options: ["Titanic", "Inception", "The Revenant", "The Great Gatsby"],
          correctIndex: 0,
        },
        // ------ Add 90 more questions below ------
        // We’ll create sets of 10 for different categories, repeated multiple times.

        // 1) More General Knowledge
        {
          category: "General Knowledge",
          question: "Which metal is liquid at room temperature?",
          options: ["Gold", "Mercury", "Silver", "Iron"],
          correctIndex: 1,
        },
        {
          category: "General Knowledge",
          question: "What is the capital of Canada?",
          options: ["Toronto", "Vancouver", "Ottawa", "Montreal"],
          correctIndex: 2,
        },
        {
          category: "General Knowledge",
          question: "Which language has the most native speakers worldwide?",
          options: ["English", "Spanish", "Mandarin Chinese", "Hindi"],
          correctIndex: 2,
        },
        {
          category: "General Knowledge",
          question: "What is the largest mammal on Earth?",
          options: ["Elephant", "Blue Whale", "Giraffe", "Hippo"],
          correctIndex: 1,
        },
        {
          category: "General Knowledge",
          question: "Which organ is responsible for pumping blood in the human body?",
          options: ["Liver", "Brain", "Heart", "Kidneys"],
          correctIndex: 2,
        },
        {
          category: "General Knowledge",
          question: "How many continents are there on Earth?",
          options: ["5", "6", "7", "8"],
          correctIndex: 2,
        },
        {
          category: "General Knowledge",
          question: "Which ocean is the largest?",
          options: ["Atlantic", "Indian", "Arctic", "Pacific"],
          correctIndex: 3,
        },
        {
          category: "General Knowledge",
          question: "Which desert is the largest in the world?",
          options: ["Sahara", "Gobi", "Kalahari", "Atacama"],
          correctIndex: 0,
        },
        {
          category: "General Knowledge",
          question: "What is the currency of Japan?",
          options: ["Yuan", "Won", "Yen", "Dollar"],
          correctIndex: 2,
        },
        {
          category: "General Knowledge",
          question: "Which is the smallest prime number?",
          options: ["2", "3", "5", "1"],
          correctIndex: 0,
        },

        // 2) Science
        {
          category: "Science",
          question: "What gas do plants absorb during photosynthesis?",
          options: ["Oxygen", "Carbon Dioxide", "Nitrogen", "Argon"],
          correctIndex: 1,
        },
        {
          category: "Science",
          question: "Who developed the theory of relativity?",
          options: ["Isaac Newton", "Albert Einstein", "Nikola Tesla", "Marie Curie"],
          correctIndex: 1,
        },
        {
          category: "Science",
          question: "What is the hardest natural substance on Earth?",
          options: ["Graphite", "Iron", "Diamond", "Quartz"],
          correctIndex: 2,
        },
        {
          category: "Science",
          question: "Which planet is known for its rings?",
          options: ["Jupiter", "Mars", "Saturn", "Neptune"],
          correctIndex: 2,
        },
        {
          category: "Science",
          question: "What type of energy is stored in a battery?",
          options: ["Kinetic", "Chemical", "Thermal", "Nuclear"],
          correctIndex: 1,
        },
        {
          category: "Science",
          question: "What is the speed of light in a vacuum?",
          options: ["300,000 km/s", "150,000 km/s", "1,080,000 km/s", "30,000 km/s"],
          correctIndex: 0,
        },
        {
          category: "Science",
          question: "Which element is the most abundant in the Earth's atmosphere?",
          options: ["Oxygen", "Nitrogen", "Carbon", "Hydrogen"],
          correctIndex: 1,
        },
        {
          category: "Science",
          question: "Which is the main gas found in the air we breathe?",
          options: ["Oxygen", "Carbon Dioxide", "Hydrogen", "Nitrogen"],
          correctIndex: 3,
        },
        {
          category: "Science",
          question: "Which tissue connects muscle to bone?",
          options: ["Ligament", "Tendon", "Cartilage", "Nerve"],
          correctIndex: 1,
        },
        {
          category: "Science",
          question: "What is the smallest unit of life?",
          options: ["Cell", "Organ", "Atom", "Molecule"],
          correctIndex: 0,
        },

        // 3) History
        {
          category: "History",
          question: "Which empire built the famous Machu Picchu?",
          options: ["Aztec Empire", "Mayan Empire", "Inca Empire", "Spanish Empire"],
          correctIndex: 2,
        },
        {
          category: "History",
          question: "Who was the first president of the United States?",
          options: ["John Adams", "Abraham Lincoln", "Thomas Jefferson", "George Washington"],
          correctIndex: 3,
        },
        {
          category: "History",
          question: "In which country did the Renaissance begin?",
          options: ["France", "Italy", "England", "Germany"],
          correctIndex: 1,
        },
        {
          category: "History",
          question: "What year did the Berlin Wall fall?",
          options: ["1987", "1989", "1991", "1993"],
          correctIndex: 1,
        },
        {
          category: "History",
          question: "Which city was the capital of the Byzantine Empire?",
          options: ["Athens", "Rome", "Constantinople", "Alexandria"],
          correctIndex: 2,
        },
        {
          category: "History",
          question: "Who discovered America in 1492 (for Europe)?",
          options: ["Vasco da Gama", "Ferdinand Magellan", "Christopher Columbus", "James Cook"],
          correctIndex: 2,
        },
        {
          category: "History",
          question: "Who was the British prime minister during most of World War II?",
          options: ["Winston Churchill", "Margaret Thatcher", "Neville Chamberlain", "Tony Blair"],
          correctIndex: 0,
        },
        {
          category: "History",
          question: "Which pharaoh built the Great Pyramid of Giza?",
          options: ["Tutankhamun", "Ramses II", "Khufu", "Cleopatra"],
          correctIndex: 2,
        },
        {
          category: "History",
          question: "The Magna Carta was signed in which year?",
          options: ["1215", "1066", "1492", "1776"],
          correctIndex: 0,
        },
        {
          category: "History",
          question: "Who was the first female Prime Minister of the UK?",
          options: ["Theresa May", "Margaret Thatcher", "Angela Merkel", "Indira Gandhi"],
          correctIndex: 1,
        },

        // 4) Geography
        {
          category: "Geography",
          question: "Which is the longest river in the world?",
          options: ["Nile", "Amazon", "Yangtze", "Mississippi"],
          correctIndex: 0,
        },
        {
          category: "Geography",
          question: "Which is the largest country by area?",
          options: ["USA", "China", "Russia", "Canada"],
          correctIndex: 2,
        },
        {
          category: "Geography",
          question: "Which continent has the most countries?",
          options: ["Asia", "Africa", "Europe", "South America"],
          correctIndex: 1,
        },
        {
          category: "Geography",
          question: "Which sea separates Europe and Africa?",
          options: ["Red Sea", "Arabian Sea", "Mediterranean Sea", "Caribbean Sea"],
          correctIndex: 2,
        },
        {
          category: "Geography",
          question: "Mount Everest is located in which two countries?",
          options: ["India and China", "Nepal and China", "Bhutan and India", "Pakistan and China"],
          correctIndex: 1,
        },
        {
          category: "Geography",
          question: "The Ural Mountains separate Europe from which continent?",
          options: ["Asia", "Africa", "Antarctica", "Australia"],
          correctIndex: 0,
        },
        {
          category: "Geography",
          question: "What is the capital of Argentina?",
          options: ["Buenos Aires", "Santiago", "Lima", "Montevideo"],
          correctIndex: 0,
        },
        {
          category: "Geography",
          question: "Which is the tallest waterfall in the world?",
          options: ["Niagara Falls", "Victoria Falls", "Angel Falls", "Yosemite Falls"],
          correctIndex: 2,
        },
        {
          category: "Geography",
          question: "Which ocean is on the east coast of the United States?",
          options: ["Pacific", "Atlantic", "Arctic", "Indian"],
          correctIndex: 1,
        },
        {
          category: "Geography",
          question: "Which country does not share a border with Germany?",
          options: ["France", "Denmark", "Switzerland", "Hungary"],
          correctIndex: 3,
        },

        // 5) More general categories, repeated in sets...
        // ... (We need to fill up to 100 total questions)...

        // Let's just replicate sets or create more unique questions.
        // For brevity, we might replicate some categories but unique Q/As:

        // Add 50 more quick Q/As, 10 each from categories: Sports, Culture, Technology, Foods, Random

        // 5) Sports
        {
          category: "Sports",
          question: "How many players are on a soccer (football) team on the field?",
          options: ["9", "10", "11", "12"],
          correctIndex: 2,
        },
        {
          category: "Sports",
          question: "In which sport do players wear pointe shoes?",
          options: ["Gymnastics", "Ballet", "Figure Skating", "Diving"],
          correctIndex: 1,
        },
        {
          category: "Sports",
          question: "Which country hosts the Tour de France?",
          options: ["Spain", "Italy", "France", "Germany"],
          correctIndex: 2,
        },
        {
          category: "Sports",
          question: "Which Olympic sport uses a pommel horse?",
          options: ["Equestrian", "Gymnastics", "Pentathlon", "Wrestling"],
          correctIndex: 1,
        },
        {
          category: "Sports",
          question: "What is the maximum score in a single frame of bowling?",
          options: ["30", "20", "10", "50"],
          correctIndex: 0,
        },
        {
          category: "Sports",
          question: "How many holes are there on a standard golf course?",
          options: ["9", "18", "27", "36"],
          correctIndex: 1,
        },
        {
          category: "Sports",
          question: "Which country won the first ever World Cup in 1930?",
          options: ["Brazil", "Uruguay", "Argentina", "Italy"],
          correctIndex: 1,
        },
        {
          category: "Sports",
          question: "Which sport is known as the 'king of sports'?",
          options: ["Cricket", "Basketball", "Soccer (Football)", "Tennis"],
          correctIndex: 2,
        },
        {
          category: "Sports",
          question: "A 'home run' is associated with which sport?",
          options: ["Baseball", "Cricket", "Rugby", "Ice Hockey"],
          correctIndex: 0,
        },
        {
          category: "Sports",
          question: "The term 'checkmate' is from which game?",
          options: ["Checkers", "Chess", "Go", "Bridge"],
          correctIndex: 1,
        },

        // 6) Culture
        {
          category: "Culture",
          question: "Which city is known for the Carnaval featuring samba parades?",
          options: ["Rio de Janeiro", "Venice", "New Orleans", "Barcelona"],
          correctIndex: 0,
        },
        {
          category: "Culture",
          question: "Which country is famous for its traditional dance 'Tango'?",
          options: ["Brazil", "Argentina", "Spain", "Mexico"],
          correctIndex: 1,
        },
        {
          category: "Culture",
          question: "Diwali, the festival of lights, is primarily celebrated in which country?",
          options: ["China", "India", "Thailand", "Japan"],
          correctIndex: 1,
        },
        {
          category: "Culture",
          question: "Which city is known for its running of the bulls event?",
          options: ["Madrid", "Seville", "Pamplona", "Barcelona"],
          correctIndex: 2,
        },
        {
          category: "Culture",
          question: "In which country did karaoke originate?",
          options: ["Japan", "Korea", "China", "Thailand"],
          correctIndex: 0,
        },
        {
          category: "Culture",
          question: "The Day of the Dead (Día de Muertos) is a holiday from which country?",
          options: ["Spain", "Mexico", "Brazil", "Colombia"],
          correctIndex: 1,
        },
        {
          category: "Culture",
          question: "La Tomatina, a tomato-throwing festival, is held in which country?",
          options: ["Italy", "Greece", "Spain", "Portugal"],
          correctIndex: 2,
        },
        {
          category: "Culture",
          question: "Which religion is the majority in Thailand?",
          options: ["Buddhism", "Hinduism", "Christianity", "Islam"],
          correctIndex: 0,
        },
        {
          category: "Culture",
          question: "The Maori are the indigenous people of which country?",
          options: ["Australia", "New Zealand", "Hawaii", "Fiji"],
          correctIndex: 1,
        },
        {
          category: "Culture",
          question: "Which country is famous for its Oktoberfest celebration?",
          options: ["Germany", "Austria", "Switzerland", "Belgium"],
          correctIndex: 0,
        },

        // 7) Technology
        {
          category: "Technology",
          question: "Who is known as the co-founder of Microsoft?",
          options: ["Steve Jobs", "Bill Gates", "Larry Page", "Mark Zuckerberg"],
          correctIndex: 1,
        },
        {
          category: "Technology",
          question: "HTML is used to structure content on the web. It stands for?",
          options: [
            "HyperText Markup Language",
            "HyperTools Maker Language",
            "Hyperlink Transfer Machine Language",
            "HyperText Module Layout",
          ],
          correctIndex: 0,
        },
        {
          category: "Technology",
          question: "Which company developed the iPhone?",
          options: ["Google", "Microsoft", "Apple", "Samsung"],
          correctIndex: 2,
        },
        {
          category: "Technology",
          question: "In computing, what does 'CPU' stand for?",
          options: [
            "Central Processing Unit",
            "Computer Program Utility",
            "Central Performance Uplink",
            "Control Program Unit",
          ],
          correctIndex: 0,
        },
        {
          category: "Technology",
          question: "Which programming language is known for its snake logo?",
          options: ["JavaScript", "Python", "Ruby", "C++"],
          correctIndex: 1,
        },
        {
          category: "Technology",
          question: "Which social media platform was founded by Mark Zuckerberg?",
          options: ["Twitter", "Facebook", "Snapchat", "Pinterest"],
          correctIndex: 1,
        },
        {
          category: "Technology",
          question: "What does 'AI' stand for in tech?",
          options: ["Automated Input", "Artificial Intelligence", "Alternate Interface", "Applied Informatics"],
          correctIndex: 1,
        },
        {
          category: "Technology",
          question: "Which company owns the Android operating system?",
          options: ["Apple", "Microsoft", "Google", "Samsung"],
          correctIndex: 2,
        },
        {
          category: "Technology",
          question: "What is the name of Elon Musk's aerospace company?",
          options: ["Blue Origin", "Virgin Galactic", "SpaceX", "Tesla"],
          correctIndex: 2,
        },
        {
          category: "Technology",
          question: "Which technology is used to make telephone calls over the internet?",
          options: ["DNS", "Ethernet", "VoIP", "HTTP"],
          correctIndex: 2,
        },

        // 8) Foods
        {
          category: "Foods",
          question: "Which country is credited with inventing pizza?",
          options: ["Spain", "Greece", "Italy", "France"],
          correctIndex: 2,
        },
        {
          category: "Foods",
          question: "Sushi is a traditional dish from which country?",
          options: ["China", "Japan", "Thailand", "Korea"],
          correctIndex: 1,
        },
        {
          category: "Foods",
          question: "Paella is a rice dish originating from which country?",
          options: ["Mexico", "Portugal", "Cuba", "Spain"],
          correctIndex: 3,
        },
        {
          category: "Foods",
          question: "Which fruit is known as the 'king of fruits' in many South Asian countries?",
          options: ["Banana", "Mango", "Apple", "Pineapple"],
          correctIndex: 1,
        },
        {
          category: "Foods",
          question: "Kimchi is a staple in the cuisine of which country?",
          options: ["China", "Japan", "Korea", "Vietnam"],
          correctIndex: 2,
        },
        {
          category: "Foods",
          question: "Which cheese is traditionally used on a Greek salad?",
          options: ["Cheddar", "Brie", "Feta", "Mozzarella"],
          correctIndex: 2,
        },
        {
          category: "Foods",
          question: "Which nut is used to make marzipan?",
          options: ["Walnut", "Almond", "Hazelnut", "Peanut"],
          correctIndex: 1,
        },
        {
          category: "Foods",
          question: "Which spice is made from dried red fruit of Capsicum?",
          options: ["Cumin", "Cinnamon", "Paprika", "Turmeric"],
          correctIndex: 2,
        },
        {
          category: "Foods",
          question: "Goulash is a stew that originated in which country?",
          options: ["Hungary", "Poland", "Czech Republic", "Germany"],
          correctIndex: 0,
        },
        {
          category: "Foods",
          question: "In Italian cuisine, 'gelato' refers to what?",
          options: ["Soup", "Ice Cream", "Cheese", "Bread"],
          correctIndex: 1,
        },

        // 9) Random
        {
          category: "Random",
          question: "What is the name of the largest moon of Saturn?",
          options: ["Ganymede", "Europa", "Titan", "Enceladus"],
          correctIndex: 2,
        },
        {
          category: "Random",
          question: "Who painted the Mona Lisa?",
          options: ["Vincent van Gogh", "Pablo Picasso", "Leonardo da Vinci", "Michelangelo"],
          correctIndex: 2,
        },
        {
          category: "Random",
          question: "In Greek mythology, who is the king of the gods?",
          options: ["Poseidon", "Zeus", "Apollo", "Hades"],
          correctIndex: 1,
        },
        {
          category: "Random",
          question: "Which company is known for the 'Swoosh' logo?",
          options: ["Adidas", "Nike", "Puma", "Reebok"],
          correctIndex: 1,
        },
        {
          category: "Random",
          question: "Which animal is the largest primate in the world?",
          options: ["Gorilla", "Chimpanzee", "Orangutan", "Human"],
          correctIndex: 0,
        },
        {
          category: "Random",
          question: "What is the Roman numeral for 1000?",
          options: ["C", "D", "M", "X"],
          correctIndex: 2,
        },
        {
          category: "Random",
          question: "Which chess piece can only move diagonally?",
          options: ["Bishop", "Rook", "Queen", "Knight"],
          correctIndex: 0,
        },
        {
          category: "Random",
          question: "In the phonetic alphabet, 'A' stands for what?",
          options: ["Apple", "Alpha", "America", "Ant"],
          correctIndex: 1,
        },
        {
          category: "Random",
          question: "How many strings does a typical violin have?",
          options: ["3", "4", "5", "6"],
          correctIndex: 1,
        },
        {
          category: "Random",
          question: "Which planet has the Great Red Spot?",
          options: ["Venus", "Jupiter", "Neptune", "Mars"],
          correctIndex: 1,
        },
      ];

      // We have exactly 100 questions in the array now.
      // Shuffle if desired
      if (this.shuffleQuestions) {
        for (let i = q.length - 1; i > 0; i--) {
          let j = Math.floor(Math.random() * (i + 1));
          [q[i], q[j]] = [q[j], q[i]];
        }
      }

      this.questions = q;
      this.loading = false;
    },
    selectOption(index) {
      this.selectedOption = index;
    },
    submitAnswer() {
      // Check if user selected something
      if (this.selectedOption === null) {
        alert("Please select an answer before continuing.");
        return;
      }
      // If correct => score++
      if (this.selectedOption === this.currentQuestion.correctIndex) {
        this.score++;
      }
      // Move to next question or finish
      this.selectedOption = null;
      this.currentQuestionIndex++;
      if (this.currentQuestionIndex >= this.totalQuestions) {
        // quiz done
        this.quizComplete = true;
      }
    },
    restartQuiz() {
      this.loading = true;
      this.quizComplete = false;
      this.currentQuestionIndex = 0;
      this.selectedOption = null;
      this.score = 0;
      // reload or shuffle again
      this.loadQuestions();
    },
  },
  mounted() {
    this.loadQuestions();
  },
};
</script>

<style scoped>
.quiz-container {
  margin-top: 60px; /* if pinned header at 60px */
  min-height: calc(100vh - 60px);
  overflow-y: auto;
  box-sizing: border-box;
  padding: 20px;
  background: #fff;
  font-family: "Helvetica Neue", Arial, sans-serif;
  text-align: center;
}

.loading-message {
  font-size: 1.2rem;
  margin: 20px 0;
  color: #555;
}

.question-area {
  max-width: 600px;
  margin: 0 auto;
  text-align: left;
}

.question-header {
  display: flex;
  justify-content: space-between;
  font-size: 0.9rem;
  margin-bottom: 8px;
}

.category {
  font-weight: bold;
  color: #444;
}
.question-index {
  color: #666;
}
.question-text {
  font-size: 1.2rem;
  margin: 15px 0;
  font-weight: 500;
}

.options-list {
  list-style: none;
  margin: 20px 0;
  padding: 0;
}
.option {
  background-color: #f4f4f4;
  padding: 12px;
  margin: 8px 0;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s;
}
.option:hover {
  background-color: #e0e0e0;
}
.selected {
  background-color: #c0c0ff !important;
}

.controls {
  display: flex;
  justify-content: flex-end;
}

.quiz-button {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 12px 20px;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  margin-top: 10px;
}
.quiz-button:hover {
  background-color: #555;
  transform: translateY(-2px);
}

.results-area {
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
}
.results-area h2 {
  font-size: 1.6rem;
  margin-bottom: 20px;
}
</style>
