<template>
  <div>
    <Header
      :showBackButton="true"
      backText="Get More Resources"
      @back="goToResources"
    />
    <!-- container is the scroller -->
    <div class="container" ref="container">
      <div class="content">
        <h1>Search For Articles</h1>
        <input
          type="text"
          v-model="searchQuery"
          placeholder="Search for an article"
          @input="saveSearchQuery"
        />

        <!-- Genre Buttons -->
        <div class="genres-container">
          <button
            v-for="genre in genres"
            :key="genre"
            @click="filterByGenre(genre)"
            :class="['genre-button', { 'active-genre': selectedGenre === genre }]"
          >
            {{ genre }}
          </button>
        </div>

        <!-- Articles List -->
        <ul class="articles-list">
          <li
            v-for="article in filteredArticles"
            :key="article.id"
            class="article-item"
          >
            <!-- Navigate to ArticleDetails.vue when clicked -->
            <router-link
              :to="{
                name: 'choose-action',
                params: {
                  topicType: 'article',
                  topicId: article.id,
                  topicName: article.title,
                },
              }"
            >
              {{ article.title }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Header from './Header.vue';

export default {
  components: { Header },
  data() {
    return {
      // -------------------------------
      // FULL articles array goes here
      // -------------------------------
      articles: [
          {
    "id": 1,
    "title": "3D Bioprinting",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/3D-bioprinting.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/3D-bioprinting.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 2,
    "title": "Abraham Lincoln The Emancipator Of America",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/abraham-lincoln-the-emancipator-of-america.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/abraham-lincoln-the-emancipator-of-america.txt",
    "genre": "History"
  },
  {
    "id": 3,
    "title": "Abraham Maslow The Architect Of The Hierarchy Of Needs",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/abraham-maslow-the-architect-of-the-hierarchy-of-needs.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/abraham-maslow-the-architect-of-the-hierarchy-of-needs.txt",
    "genre": "Psychology"
  },
  {
    "id": 4,
    "title": "Absurdism The Philosophy Of The Absurd",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/absurdism-the-philosophy-of-the-absurd.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/absurdism-the-philosophy-of-the-absurd.txt",
    "genre": "Philosophy"
  },
  {
    "id": 5,
    "title": "Ada Lovelace The First Computer Programmer",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/ada-lovelace-the-first-computer-programmer.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/ada-lovelace-the-first-computer-programmer.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 6,
    "title": "Addressing Refugee And Migration Challenges",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/addressing-refugee-and-migration-challenges.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/addressing-refugee-and-migration-challenges.txt",
    "genre": "Political Science & Economics"
  },
  {
    "id": 7,
    "title": "Advance Space Exploration And Research",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/advance-space-exploration-and-research.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/advance-space-exploration-and-research.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 8,
    "title": "Afghanistan Conflict Inclusive Governance And International Support",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/afghanistan-conflict-inclusive-governance-and-international-support.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/afghanistan-conflict-inclusive-governance-and-international-support.txt",
    "genre": "Political Science & Economics"
  },
  {
    "id": 9,
    "title": "AI Powered Financial Services",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/AI-powered-financial-services.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/AI-powered-financial-services.txt",
    "genre": "Business & Finance"
  },
  {
    "id": 10,
    "title": "AI",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/AI.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/AI.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 11,
    "title": "Alan Turing The Father Of Computer Science",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/alan-turing-the-father-of-computer-science.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/alan-turing-the-father-of-computer-science.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 12,
    "title": "Albania",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/albania.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/albania.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 13,
    "title": "Albert Bandura The Theorist Of Social Learning",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/albert-bandura-the-theorist-of-social-learning.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/albert-bandura-the-theorist-of-social-learning.txt",
    "genre": "Psychology"
  },
  {
    "id": 14,
    "title": "Albert Einstein The Architect Of Modern Physics",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/albert-einstein-the-architect-of-modern-physics.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/albert-einstein-the-architect-of-modern-physics.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 15,
    "title": "Albert Ellis The Creator Of Rational Emotive Behavior Therapy",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/albert-ellis-the-creator-of-rational-emotive-behavior-therapy.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/albert-ellis-the-creator-of-rational-emotive-behavior-therapy.txt",
    "genre": "Psychology"
  },
  {
    "id": 16,
    "title": "Alfred Adler The Founder Of Individual Psychology",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/alfred-adler-the-founder-of-individual-psychology.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/alfred-adler-the-founder-of-individual-psychology.txt",
    "genre": "Psychology"
  },
  {
    "id": 17,
    "title": "Algebra The Language Of Mathematics",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/algebra-the-language-of-mathematics.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/algebra-the-language-of-mathematics.txt",
    "genre": "Science & Mathematics"
  },
  {
    "id": 18,
    "title": "Anarchism The Philosophy Of Stateless Society",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/anarchism-the-philosophy-of-stateless-society.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/anarchism-the-philosophy-of-stateless-society.txt",
    "genre": "Philosophy"
  },
  {
    "id": 19,
    "title": "Anarchism The Politics Of Statelessness",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/anarchism-the-politics-of-statelessness.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/anarchism-the-politics-of-statelessness.txt",
    "genre": "Political Science & Economics"
  },
  {
    "id": 20,
    "title": "Angela Merkel The Chancellor Of Europe",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/angela-merkel-the-chancellor-of-europe.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/angela-merkel-the-chancellor-of-europe.txt",
    "genre": "Biography & Autobiography"
  },
  {
    "id": 21,
    "title": "Angola",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/angola.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/angola.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 22,
    "title": "Antimicrobial Resistance",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/antimicrobial-resistance.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/antimicrobial-resistance.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 23,
    "title": "Archaeology and Ancient Civilizations",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/archaeology-and-ancient-civilizations.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/archaeology-and-ancient-civilizations.txt",
    "genre": "History"
  },
  {
    "id": 24,
    "title": "Archimedes The Mathematician Of The Ancient World",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/archimedes-the-mathematician-of-the-ancient-world.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/archimedes-the-mathematician-of-the-ancient-world.txt",
    "genre": "Science & Mathematics"
  },
  {
    "id": 25,
    "title": "Argentina",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/argentina.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/argentina.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 26,
    "title": "Aristotle The Philosopher Of Science And Reason",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/aristotle-the-philosopher-of-science-and-reason.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/aristotle-the-philosopher-of-science-and-reason.txt",
    "genre": "Philosophy"
  },
  {
    "id": 27,
    "title": "Armenia",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/armenia.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/armenia.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 28,
    "title": "Artificial Intelligence Ethics",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/artificial-intelligence-ethics.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/artificial-intelligence-ethics.txt",
    "genre": "Ethics & Technology"
  },
  {
    "id": 29,
    "title": "Artificial Intelligence In Healthcare",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/artificial-intelligence-in-healthcare.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/artificial-intelligence-in-healthcare.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 30,
    "title": "Astrobiology",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/astrobiology.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/astrobiology.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 31,
    "title": "Australia",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/australia.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/australia.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 32,
    "title": "Austria",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/austria.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/austria.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 33,
    "title": "Autonomous Ships",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/autonomous-ships.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/autonomous-ships.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 34,
    "title": "Azerbaijan",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/azerbaijan.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/azerbaijan.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 35,
    "title": "B.F. Skinner The Behaviorist And Pioneer Of Operant Conditioning",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/b.f.-skinner-the-behaviorist-and-pioneer-of-operant-conditioning.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/b.f.-skinner-the-behaviorist-and-pioneer-of-operant-conditioning.txt",
    "genre": "Psychology"
  },
  {
    "id": 36,
    "title": "Bahamas",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/bahamas.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/bahamas.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 37,
    "title": "Bahaullah The Founder Of The Bahai Faith",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/bahaullah-the-founder-of-the-bahai-faith.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/bahaullah-the-founder-of-the-bahai-faith.txt",
    "genre": "Religion & Spirituality"
  },
  {
    "id": 38,
    "title": "Bahrain",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/bahrain.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/bahrain.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 39,
    "title": "Bangladesh",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/bangladesh.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/bangladesh.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 40,
    "title": "Barbados",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/barbados.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/barbados.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 41,
    "title": "Behavioral Economics",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/behavioral-economics.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/behavioral-economics.txt",
    "genre": "Business & Finance"
  },
  {
    "id": 42,
    "title": "Behavioral Finance Understanding Investor Psychology",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/behavioral-finance-understanding-investor-psychology.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/behavioral-finance-understanding-investor-psychology.txt",
    "genre": "Business & Finance"
  },
  {
    "id": 43,
    "title": "Belarus",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/belarus.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/belarus.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 44,
    "title": "Belgium",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/belgium.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/belgium.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 45,
    "title": "Belize",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/belize.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/belize.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 46,
    "title": "Bhutan",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/bhutan.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/bhutan.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 47,
    "title": "Biodegradable Electronics",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/biodegradable-electronics.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/biodegradable-electronics.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 48,
    "title": "Biodiversity and Ecosystem Services",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/biodiversity-and-ecosystem-services.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/biodiversity-and-ecosystem-services.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 49,
    "title": "Biodiversity Corridors for Ecosystem Conservation",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/biodiversity-corridors-for-ecosystem-conservation.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/biodiversity-corridors-for-ecosystem-conservation.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 50,
    "title": "Biodiversity Hotspots",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/biodiversity-hotspots.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/biodiversity-hotspots.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 51,
    "title": "Biodiversity",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/biodiversity.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/biodiversity.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 52,
    "title": "Biofabrication",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/biofabrication.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/biofabrication.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 53,
    "title": "Biogeography",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/biogeography.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/biogeography.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 54,
    "title": "Biohacking",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/biohacking.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/biohacking.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 55,
    "title": "Bioinformatics",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/bioinformatics.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/bioinformatics.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 56,
    "title": "Bioluminescence Nature's Light Show",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/bioluminescence-natures-light-show.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/bioluminescence-natures-light-show.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 57,
    "title": "Bioluminescence",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/bioluminescence.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/bioluminescence.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 58,
    "title": "Biomimicry",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/biomimicry.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/biomimicry.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 59,
    "title": "Bioprinting",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/bioprinting.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/bioprinting.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 60,
    "title": "Blockchain Beyond Cryptocurrency Revolutionizing Industries",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/blockchain-beyond-cryptocurrency-revolutionizing-industries.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/blockchain-beyond-cryptocurrency-revolutionizing-industries.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 61,
    "title": "Blockchain in Healthcare",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/blockchain-in-healthcare.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/blockchain-in-healthcare.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 62,
    "title": "Blockchain Technology",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/blockchain-technology.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/blockchain-technology.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 63,
    "title": "Bolivia",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/bolivia.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/bolivia.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 64,
    "title": "Bosnia and Herzegovina",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/bosnia-and-herzegovina.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/bosnia-and-herzegovina.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 65,
    "title": "Brazil",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/brazil.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/brazil.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 66,
    "title": "Brunei",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/brunei.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/brunei.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 67,
    "title": "Buddhism: The Path to Enlightenment",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/buddhism-the-path-to-enlightenment.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/buddhism-the-path-to-enlightenment.txt",
    "genre": "Religion & Spirituality"
  },
  {
    "id": 68,
    "title": "Burkina Faso",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/burkina-faso.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/burkina-faso.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 69,
    "title": "Calculus: The Mathematics of Change",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/calculus-the-mathematics-of-change.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/calculus-the-mathematics-of-change.txt",
    "genre": "Science & Mathematics"
  },
  {
    "id": 70,
    "title": "Cambodia",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/cambodia.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/cambodia.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 71,
    "title": "Canada",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/canada.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/canada.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 72,
    "title": "Capitalism: The Free-Market Economy",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/capitalism-the-free-market-economy.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/capitalism-the-free-market-economy.txt",
    "genre": "Political Science & Economics"
  },
  {
    "id": 73,
    "title": "Carbon Capture and Storage",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/carbon-capture-and-storage.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/carbon-capture-and-storage.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 74,
    "title": "Carbon Nanotubes",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/carbon-nanotubes.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/carbon-nanotubes.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 75,
    "title": "Carl Friedrich Gauss: The Prince of Mathematicians",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/carl-friedrich-gauss-the-prince-of-mathematicians.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/carl-friedrich-gauss-the-prince-of-mathematicians.txt",
    "genre": "Science & Mathematics"
  },
  {
    "id": 76,
    "title": "Carl Jung: The Architect of Analytical Psychology",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/carl-jung-the-architect-of-analytical-psychology.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/carl-jung-the-architect-of-analytical-psychology.txt",
    "genre": "Psychology"
  },
  {
    "id": 77,
    "title": "Carl Rogers: The Humanistic Psychologist",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/carl-rogers-the-humanistic-psychologist.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/carl-rogers-the-humanistic-psychologist.txt",
    "genre": "Psychology"
  },
  {
    "id": 78,
    "title": "Carl Sagan: The Cosmic Communicator",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/carl-sagan-the-cosmic-communicator.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/carl-sagan-the-cosmic-communicator.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 79,
    "title": "Catherine the Great: The Enlightened Empress of Russia",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/catherine-the-great-the-enlightened-empress-of-russia.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/catherine-the-great-the-enlightened-empress-of-russia.txt",
    "genre": "History"
  },
  {
    "id": 80,
    "title": "Central African Republic (CAR)",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/central-african-republic-(car).png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/central-african-republic-(car).txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 81,
    "title": "Chad",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/chad.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/chad.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 82,
    "title": "Chile",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/chile.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/chile.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 83,
    "title": "China",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/china.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/china.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 84,
    "title": "Christianity: The Faith in Christ",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/christianity-the-faith-in-christ.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/christianity-the-faith-in-christ.txt",
    "genre": "Religion & Spirituality"
  },
  {
    "id": 85,
    "title": "Circular Economy for Waste Reduction",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/circular-economy-for-waste-reduction.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/circular-economy-for-waste-reduction.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 86,
    "title": "Citizen Science",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/citizen-science.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/citizen-science.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 87,
    "title": "Cleopatra VII: The Last Pharaoh of Egypt",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/cleopatra-VII-the-last-pharaoh-of-egypt.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/cleopatra-VII-the-last-pharaoh-of-egypt.txt",
    "genre": "History"
  },
  {
    "id": 88,
    "title": "Climate Change Adaption",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/climate-change-adaption.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/climate-change-adaption.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 89,
    "title": "Climate Change",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/climate-change.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/climate-change.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 90,
    "title": "Climate Engineering",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/climate-engineering.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/climate-engineering.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 91,
    "title": "Cognitive Biases in Decision Making",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/cognitive-biases-in-decision-making.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/cognitive-biases-in-decision-making.txt",
    "genre": "Psychology"
  },
  {
    "id": 92,
    "title": "Colombia",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/colombia.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/colombia.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 93,
    "title": "Color Science and Perception",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/color-science-and-perception.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/color-science-and-perception.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 94,
    "title": "Community-Based Renewable Energy Initiatives",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/community-based-renewable-energy-initiatives.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/community-based-renewable-energy-initiatives.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 95,
    "title": "Comoros",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/comoros.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/comoros.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 96,
    "title": "Complex Analysis: The Study of Complex Numbers and Functions",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/complex-analysis-the-study-of-complex-numbers-and-functions.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/complex-analysis-the-study-of-complex-numbers-and-functions.txt",
    "genre": "Science & Mathematics"
  },
  {
    "id": 97,
    "title": "Confucius: The Sage of China",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/confucius-the-sage-of-china.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/confucius-the-sage-of-china.txt",
    "genre": "Philosophy"
  },
  {
    "id": 98,
    "title": "Conservatism: The Tradition of Stability and Order",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/conservatism-the-tradition-of-stability-and-order.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/conservatism-the-tradition-of-stability-and-order.txt",
    "genre": "Political Science & Economics"
  },
  {
    "id": 99,
    "title": "Conservatism: Tradition and Stability",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/conservatism-tradition-and-stability.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/conservatism-tradition-and-stability.txt",
    "genre": "Political Science & Economics"
  },
  {
    "id": 100,
    "title": "Corporate Social Responsibility (CSR): Business for a Better World",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/corporate-social-responsibility-(CSR)-business-for-a-better-world.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/corporate-social-responsibility-(CSR)-business-for-a-better-world.txt",
    "genre": "Business & Finance"
  },
  {
    "id": 101,
    "title": "Costa Rica",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/costa-rica.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/costa-rica.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 102,
    "title": "Create a Supportive Environment",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/create-a-supportive-environment.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/create-a-supportive-environment.txt",
    "genre": "Self-Help & Personal Development"
  },
  {
    "id": 103,
    "title": "Croatia",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/croatia.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/croatia.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 104,
    "title": "Crowdfunding: Democratizing Fundraising and Investment",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/crowdfunding-democratizing-fundraising-and-investment.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/crowdfunding-democratizing-fundraising-and-investment.txt",
    "genre": "Business & Finance"
  },
  {
    "id": 105,
    "title": "Cryptocurrency: The Digital Revolution in Finance",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/cryptocurrency-the-digital-revolution-in-finance.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/cryptocurrency-the-digital-revolution-in-finance.txt",
    "genre": "Business & Finance"
  },
  {
    "id": 106,
    "title": "Cryptocurrency",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/cryptocurrency.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/cryptocurrency.txt",
    "genre": "Business & Finance"
  },
  {
    "id": 107,
    "title": "Cryptography: The Art of Secure Communication",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/cryptography-the-art-of-secure-communication.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/cryptography-the-art-of-secure-communication.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 108,
    "title": "Cryptography",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/cryptography.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/cryptography.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 109,
    "title": "Cultivate Positive Relationships",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/cultivate-positive-relationships.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/cultivate-positive-relationships.txt",
    "genre": "Self-Help & Personal Development"
  },
  {
    "id": 110,
    "title": "Cultural Astronomy and Human Society",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/cultural-astronomy-and-human-society.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/cultural-astronomy-and-human-society.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 111,
    "title": "Cultural Astronomy",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/cultural-astronomy.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/cultural-astronomy.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 112,
    "title": "Cultural Heritage Preservation",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/cultural-heritage-preservation.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/cultural-heritage-preservation.txt",
    "genre": "History & Culture"
  },
  {
    "id": 113,
    "title": "Cultural Impact of Mythology",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/cultural-impact-of-mythology.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/cultural-impact-of-mythology.txt",
    "genre": "Mythology & Legends"
  },
  {
    "id": 114,
    "title": "Cultural Sustainability",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/cultural-sustainability.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/cultural-sustainability.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 115,
    "title": "Cyber-Physical Systems",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/cyber-physical-systems.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/cyber-physical-systems.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 116,
    "title": "Cybersecurity Cooperation for Global Security",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/cybersecurity-cooperation-for-global-security.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/cybersecurity-cooperation-for-global-security.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 117,
    "title": "Cybersecurity in the Digital Age",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/cybersecurity-in-the-digital-age.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/cybersecurity-in-the-digital-age.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 118,
    "title": "Cybersecurity",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/cybersecurity.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/cybersecurity.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 119,
    "title": "Cyprus",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/cyprus.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/cyprus.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 120,
    "title": "Dark Matter and Dark Energy",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/dark-matter-and-dark-energy.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/dark-matter-and-dark-energy.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 121,
    "title": "Dark Tourism Ethics and Controversies",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/dark-tourism-ethics-and-controversies.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/dark-tourism-ethics-and-controversies.txt",
    "genre": "Ethics & Philosophy"
  },
  {
    "id": 122,
    "title": "Dark Tourism",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/dark-tourism.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/dark-tourism.txt",
    "genre": "Travel & Tourism"
  },
  {
    "id": 123,
    "title": "Data Sovereignty",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/data-sovereignty.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/data-sovereignty.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 124,
    "title": "Decentralized Energy Grids for Renewable Power",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/decentralized-energy-grids-for-renewable-power.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/decentralized-energy-grids-for-renewable-power.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 125,
    "title": "Democracy: Governance by the People",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/democracy-governance-by-the-people.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/democracy-governance-by-the-people.txt",
    "genre": "Political Science & Economics"
  },
  {
    "id": 126,
    "title": "Develop a Healthy Routine",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/develop-a-healthy-routine.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/develop-a-healthy-routine.txt",
    "genre": "Self-Help & Personal Development"
  },
  {
    "id": 127,
    "title": "Develop Technologies for Cosmic Sustainability",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/develop-technologies-for-cosmic-sustainability.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/develop-technologies-for-cosmic-sustainability.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 128,
    "title": "Differential Equations: Modeling Change and Dynamics",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/differential-equations-modeling-change-and-dynamics.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/differential-equations-modeling-change-and-dynamics.txt",
    "genre": "Science & Mathematics"
  },
  {
    "id": 129,
    "title": "Digital Detox",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/digital-detox.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/digital-detox.txt",
    "genre": "Self-Help & Personal Development"
  },
  {
    "id": 130,
    "title": "Digital Diplomacy for Global Cooperation",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/digital-diplomacy-for-global-cooperation.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/digital-diplomacy-for-global-cooperation.txt",
    "genre": "Political Science & International Relations"
  },
  {
    "id": 131,
    "title": "Digital Fabrication",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/digital-fabrication.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/digital-fabrication.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 132,
    "title": "Digital Humanities",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/digital-humanities.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/digital-humanities.txt",
    "genre": "Arts & Humanities"
  },
  {
    "id": 133,
    "title": "Digital Literacy and Inclusion for a Knowledge-Based Society",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/digital-literacy-and-inclusion-for-a-knowledge-based-society.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/digital-literacy-and-inclusion-for-a-knowledge-based-society.txt",
    "genre": "Education & Learning"
  },
  {
    "id": 134,
    "title": "Digital Minimalism",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/digital-minimalism.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/digital-minimalism.txt",
    "genre": "Self-Help & Personal Development"
  },
  {
    "id": 135,
    "title": "Digital Nomadism",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/digital-nomadism.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/digital-nomadism.txt",
    "genre": "Self-Help & Personal Development"
  },
  {
    "id": 136,
    "title": "Digital Therapeutics",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/digital-therapeutics.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/digital-therapeutics.txt",
    "genre": "Health & Wellness"
  },
  {
    "id": 137,
    "title": "Digital Twins in Healthcare",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/digital-twins-in-healthcare.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/digital-twins-in-healthcare.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 138,
    "title": "Diplomatic Engagement for Conflict Resolution",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/diplomatic-engagement-for-conflict-resolution.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/diplomatic-engagement-for-conflict-resolution.txt",
    "genre": "Political Science & International Relations"
  },
  {
    "id": 139,
    "title": "Djibouti",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/djibouti.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/djibouti.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 140,
    "title": "East Timor (Timor-Leste)",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/east-timor-(timor-leste).png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/east-timor-(timor-leste).txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 141,
    "title": "Eco-Friendly Transportation",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/eco-friendly-transportation.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/eco-friendly-transportation.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 142,
    "title": "Edge Computing",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/edge-computing.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/edge-computing.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 143,
    "title": "Egypt",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/egypt.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/egypt.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 144,
    "title": "Elizabeth Loftus: The Expert on Memory and False Memories",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/elizabeth-loftus-the-expert-on-memory-and-false-memories.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/elizabeth-loftus-the-expert-on-memory-and-false-memories.txt",
    "genre": "Psychology"
  },
  {
    "id": 145,
    "title": "Elon Musk: The Entrepreneur of the Future",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/elon-musk-the-entrepreneur-of-the-future.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/elon-musk-the-entrepreneur-of-the-future.txt",
    "genre": "Business & Finance"
  },
  {
    "id": 146,
    "title": "Embrace Global Cooperation and Unity",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/embrace-global-cooperation-and-unity.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/embrace-global-cooperation-and-unity.txt",
    "genre": "Political Science & International Relations"
  },
  {
    "id": 147,
    "title": "Emmy Noether: The Mathematician of Symmetry and Invariance",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/emmy-noether-the-mathematician-of-symmetry-and-invariance.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/emmy-noether-the-mathematician-of-symmetry-and-invariance.txt",
    "genre": "Science & Mathematics"
  },
  {
    "id": 148,
    "title": "Emotional Intelligence in Leadership",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/emotional-intelligence-in-leadership.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/emotional-intelligence-in-leadership.txt",
    "genre": "Psychology"
  },
  {
    "id": 149,
    "title": "Emotional Intelligence",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/emotional-intelligence.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/emotional-intelligence.txt",
    "genre": "Psychology"
  },
  {
    "id": 150,
    "title": "Engage in Lifelong Learning",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/engage-in-lifelong-learning.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/engage-in-lifelong-learning.txt",
    "genre": "Education & Learning"
  },
  {
    "id": 151,
    "title": "Enhance Global Health Security",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/enhance-global-health-security.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/enhance-global-health-security.txt",
    "genre": "Health & Wellness"
  },
  {
    "id": 152,
    "title": "Ensure Equitable Access to Space",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/ensure-equitable-access-to-space.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/ensure-equitable-access-to-space.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 153,
    "title": "Environmental Politics: The Politics of the Planet",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/environmental-politics-the-politics-of-the-planet.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/environmental-politics-the-politics-of-the-planet.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 154,
    "title": "Epigenetics",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/epigenetics.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/epigenetics.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 155,
    "title": "Equatorial Guinea",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/equatorial-guinea.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/equatorial-guinea.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 156,
    "title": "Erik Erikson: The Theorist of Psychosocial Development",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/erik-erikson-the-theorist-of-psychosocial-development.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/erik-erikson-the-theorist-of-psychosocial-development.txt",
    "genre": "Psychology"
  },
  {
    "id": 157,
    "title": "Eritrea",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/eritrea.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/eritrea.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 158,
    "title": "Establish Ethical Frameworks for Space Activities",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/establish-ethical-frameworks-for-space-activities.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/establish-ethical-frameworks-for-space-activities.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 159,
    "title": "Establish Regional Security Frameworks",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/establish-regional-security-frameworks.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/establish-regional-security-frameworks.txt",
    "genre": "Political Science & International Relations"
  },
  {
    "id": 160,
    "title": "Estonia",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/estonia.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/estonia.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 161,
    "title": "Eswatini (Swaziland)",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/eswatini-(swaziland).png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/eswatini-(swaziland).txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 162,
    "title": "Ethical AI",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/ethical-ai.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/ethical-ai.txt",
    "genre": "Ethics & Philosophy"
  },
  {
    "id": 163,
    "title": "Ethical Consumerism",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/ethical-consumerism.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/ethical-consumerism.txt",
    "genre": "Ethics & Philosophy"
  },
  {
    "id": 164,
    "title": "Ethical Hacking",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/ethical-hacking.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/ethical-hacking.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 165,
    "title": "Ethiopia",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/ethiopia.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/ethiopia.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 166,
    "title": "Ethiopia's Tigray Conflict: Humanitarian Access and National Dialogue",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/ethiopias-tigray-conflict-humanitarian-access-and-national-dialogue.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/ethiopias-tigray-conflict-humanitarian-access-and-national-dialogue.txt",
    "genre": "Political Science & International Relations"
  },
  {
    "id": 167,
    "title": "Euclid: The Father of Geometry",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/euclid-the-father-of-geometry.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/euclid-the-father-of-geometry.txt",
    "genre": "Science & Mathematics"
  },
  {
    "id": 168,
    "title": "Evolution of Public Health Systems",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/evolution-of-public-health-systems.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/evolution-of-public-health-systems.txt",
    "genre": "Health & Wellness"
  },
  {
    "id": 169,
    "title": "Evolution of Space Telescopes",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/evolution-of-space-telescopes.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/evolution-of-space-telescopes.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 170,
    "title": "Evolution: The Mechanism of Biological Diversity",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/evolution-the-mechanism-of-biological-diversity.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/evolution-the-mechanism-of-biological-diversity.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 171,
    "title": "Existentialism: The Philosophy of Existence",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/existentialism-the-philosophy-of-existence.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/existentialism-the-philosophy-of-existence.txt",
    "genre": "Ethics & Philosophy"
  },
  {
    "id": 172,
    "title": "Exoplanet Exploration",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/exoplanet-exploration.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/exoplanet-exploration.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 173,
    "title": "Expand Cosmic Education and Public Engagement",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/expand-cosmic-education-and-public-engagement.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/expand-cosmic-education-and-public-engagement.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 174,
    "title": "Explore and Preserve Cosmic Heritage",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/explore-and-preserve-cosmic-heritage.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/explore-and-preserve-cosmic-heritage.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 175,
    "title": "Fascism: The Ideology of Totalitarian Nationalism",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/fascism-the-ideology-of-totalitarian-nationalism.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/fascism-the-ideology-of-totalitarian-nationalism.txt",
    "genre": "Political Science & Economics"
  },
  {
    "id": 176,
    "title": "Federalism: The Balance of Power",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/federalism-the-balance-of-power.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/federalism-the-balance-of-power.txt",
    "genre": "Political Science & Economics"
  },
  {
    "id": 177,
    "title": "Fiji",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/fiji.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/fiji.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 178,
    "title": "Finland",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/finland.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/finland.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 179,
    "title": "Florence Nightingale: The Founder of Modern Nursing",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/florence-nightingale-the-founder-of-modern-nursing.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/florence-nightingale-the-founder-of-modern-nursing.txt",
    "genre": "Health & Wellness"
  },
  {
    "id": 180,
    "title": "Food Sovereignty",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/food-sovereignty.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/food-sovereignty.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 181,
    "title": "Forensic Anthropology",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/forensic-anthropology.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/forensic-anthropology.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 182,
    "title": "Forensic Entomology: Solving Crimes with Insects",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/forensic-entomology-solving-crimes-with-insects.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/forensic-entomology-solving-crimes-with-insects.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 183,
    "title": "Fractals: The Geometry of Nature",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/fractals-the-geometry-of-nature.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/fractals-the-geometry-of-nature.txt",
    "genre": "Science & Mathematics"
  },
  {
    "id": 184,
    "title": "France",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/france.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/france.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 185,
    "title": "Franklin D. Roosevelt: The Architect of the New Deal",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/franklin-d.-roosevelt-the-architect-of-the-new-deal.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/franklin-d.-roosevelt-the-architect-of-the-new-deal.txt",
    "genre": "Political Science & Economics"
  },
  {
    "id": 186,
    "title": "Frida Kahlo: Icon of Art and Resilience",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/frida-kahlo-icon-of-art-and-resilience.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/frida-kahlo-icon-of-art-and-resilience.txt",
    "genre": "Arts & Humanities"
  },
  {
    "id": 187,
    "title": "Friedrich Nietzsche: The Philosopher of Will and Power",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/friedrich-nietzsche-the-philosopher-of-will-and-power.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/friedrich-nietzsche-the-philosopher-of-will-and-power.txt",
    "genre": "Ethics & Philosophy"
  },
  {
    "id": 188,
    "title": "Gabon",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/gabon.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/gabon.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 189,
    "title": "Gambia",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/gambia.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/gambia.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 190,
    "title": "Game Theory: The Mathematics of Strategy",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/game-theory-the-mathematics-of-strategy.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/game-theory-the-mathematics-of-strategy.txt",
    "genre": "Science & Mathematics"
  },
  {
    "id": 191,
    "title": "Gamification in Education",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/gamification-in-education.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/gamification-in-education.txt",
    "genre": "Education & Learning"
  },
  {
    "id": 192,
    "title": "Gautama Buddha: The Enlightened One",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/gautama-buddha-the-enlightened-one.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/gautama-buddha-the-enlightened-one.txt",
    "genre": "Religion & Spirituality"
  },
  {
    "id": 193,
    "title": "Genetic Counseling: Ethics and Practice",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/genetic-counseling-ethics-and-practice.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/genetic-counseling-ethics-and-practice.txt",
    "genre": "Health & Wellness"
  },
  {
    "id": 194,
    "title": "Genetic Counseling",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/genetic-counseling.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/genetic-counseling.txt",
    "genre": "Health & Wellness"
  },
  {
    "id": 195,
    "title": "Genetic Engineering in Agriculture",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/genetic-engineering-in-agriculture.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/genetic-engineering-in-agriculture.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 196,
    "title": "Genetic Engineering",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/genetic-engineering.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/genetic-engineering.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 197,
    "title": "Genetic Genealogy",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/genetic-genealogy.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/genetic-genealogy.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 198,
    "title": "Genetics: The Blueprint of Life",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/genetics-the-blueprint-of-life.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/genetics-the-blueprint-of-life.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 199,
    "title": "Genetics",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/genetics.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/genetics.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 200,
    "title": "Geometry: The Study of Shapes and Spaces",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/geometry-the-study-of-shapes-and-spaces.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/geometry-the-study-of-shapes-and-spaces.txt",
    "genre": "Science & Mathematics"
  },
  {
    "id": 201,
    "title": "Georgia",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/georgia.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/georgia.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 202,
    "title": "Germany",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/germany.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/germany.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 203,
    "title": "Gestalt Psychology: The Theory of Perception",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/gestalt-psychology-the-theory-of-perception.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/gestalt-psychology-the-theory-of-perception.txt",
    "genre": "Psychology"
  },
  {
    "id": 204,
    "title": "Ghana",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/ghana.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/ghana.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 205,
    "title": "Greece",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/greece.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/greece.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 206,
    "title": "Green Chemistry",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/green-chemistry.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/green-chemistry.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 207,
    "title": "Group Theory: The Mathematics of Symmetry",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/group-theory-the-mathematics-of-symmetry.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/group-theory-the-mathematics-of-symmetry.txt",
    "genre": "Science & Mathematics"
  },
  {
    "id": 208,
    "title": "Guinea-Bissau",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/guinea-bissau.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/guinea-bissau.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 209,
    "title": "Guinea",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/guinea.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/guinea.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 210,
    "title": "Guru Nanak: The Founder of Sikhism",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/guru-nanak-the-founder-of-sikhism.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/guru-nanak-the-founder-of-sikhism.txt",
    "genre": "Religion & Spirituality"
  },
  {
    "id": 211,
    "title": "Guyana",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/guyana.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/guyana.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 212,
    "title": "Hans Eysenck: The Theorist of Personality and Intelligence",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/hans-eysenck-the-theorist-of-personality-and-intelligence.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/hans-eysenck-the-theorist-of-personality-and-intelligence.txt",
    "genre": "Psychology"
  },
  {
    "id": 213,
    "title": "Harness and Utilize Space Resources",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/harness-and-utilize-space-resources.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/harness-and-utilize-space-resources.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 214,
    "title": "Harriet Tubman: Conductor of the Underground Railroad",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/harriet-tubman-conductor-of-the-underground-railroad.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/harriet-tubman-conductor-of-the-underground-railroad.txt",
    "genre": "History"
  },
  {
    "id": 215,
    "title": "Harry Harlow: The Researcher of Attachment and Love",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/harry-harlow-the-researcher-of-attachment-and-love.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/harry-harlow-the-researcher-of-attachment-and-love.txt",
    "genre": "Psychology"
  },
  {
    "id": 216,
    "title": "Hedy Lamarr: Inventor and Hollywood Star",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/hedy-lamarr-inventor-and-hollywood-star.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/hedy-lamarr-inventor-and-hollywood-star.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 217,
    "title": "Henri Poincaré: The Last Universalist",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/henri-poincare-the-last-universalist.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/henri-poincare-the-last-universalist.txt",
    "genre": "Science & Mathematics"
  },
  {
    "id": 218,
    "title": "Hinduism: The Ancient Spiritual Tradition",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/hinduism-the-ancient-spiritual-tradition.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/hinduism-the-ancient-spiritual-tradition.txt",
    "genre": "Religion & Spirituality"
  },
  {
    "id": 219,
    "title": "History and Impact of Jazz Music",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/history-and-impact-of-jazz-music.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/history-and-impact-of-jazz-music.txt",
    "genre": "Arts & Humanities"
  },
  {
    "id": 220,
    "title": "Honduras",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/honduras.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/honduras.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 221,
    "title": "Howard Schultz: The Mind Behind Starbucks",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/howard-schultz-the-mind-behind-starbucks.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/howard-schultz-the-mind-behind-starbucks.txt",
    "genre": "Business & Finance"
  },
  {
    "id": 222,
    "title": "Human Augmentation",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/human-augmentation.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/human-augmentation.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 223,
    "title": "Human Cloning",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/human-cloning.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/human-cloning.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 224,
    "title": "Human-Machine Interface (HMI) and the Future of Interaction",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/human-machine-interface-(HMI)-and-the-future-of-interaction.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/human-machine-interface-(HMI)-and-the-future-of-interaction.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 225,
    "title": "Human-Machine Interface",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/human-machine-interface.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/human-machine-interface.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 226,
    "title": "Hungary",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/hungary.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/hungary.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 227,
    "title": "Hypatia of Alexandria: The First Female Mathematician",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/hypatia-of-alexandria-the-first-female-mathematician.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/hypatia-of-alexandria-the-first-female-mathematician.txt",
    "genre": "Science & Mathematics"
  },
  {
    "id": 228,
    "title": "Iceland",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/iceland.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/iceland.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 229,
    "title": "Immanuel Kant: The Philosopher of the Enlightenment",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/immanuel-kant-the-philosopher-of-the-enlightenment.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/immanuel-kant-the-philosopher-of-the-enlightenment.txt",
    "genre": "Philosophy"
  },
  {
    "id": 230,
    "title": "Impressionism",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/impressionism.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/impressionism.txt",
    "genre": "Arts & Humanities"
  },
  {
    "id": 231,
    "title": "India",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/india.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/india.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 232,
    "title": "Indonesia",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/indonesia.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/indonesia.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 233,
    "title": "Indra Nooyi: The Trailblazer in Corporate Leadership",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/indra-nooyi-the-trailblazer-in-corporate-leadership.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/indra-nooyi-the-trailblazer-in-corporate-leadership.txt",
    "genre": "Business & Finance"
  },
  {
    "id": 234,
    "title": "Initial Public Offering (IPO): Taking a Company Public",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/initial-public-offering-(IPO)-taking-a-company-public.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/initial-public-offering-(IPO)-taking-a-company-public.txt",
    "genre": "Business & Finance"
  },
  {
    "id": 235,
    "title": "Iran",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/iran.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/iran.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 236,
    "title": "Ireland",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/ireland.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/ireland.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 237,
    "title": "Isaac Newton: The Father of Classical Physics",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/isaac-newton-the-father-of-classical-physics.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/isaac-newton-the-father-of-classical-physics.txt",
    "genre": "Science & Mathematics"
  },
  {
    "id": 238,
    "title": "Islam: The Path of Submission",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/islam-the-path-of-submission.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/islam-the-path-of-submission.txt",
    "genre": "Religion & Spirituality"
  },
  {
    "id": 239,
    "title": "Israeli-Palestinian Conflict: Two-State Solution with International Mediation",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/israeli-palestinian-conflict-two-state-solution-with-international-mediation.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/israeli-palestinian-conflict-two-state-solution-with-international-mediation.txt",
    "genre": "Political Science & International Relations"
  },
  {
    "id": 240,
    "title": "Italy",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/italy.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/italy.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 241,
    "title": "Ivan Pavlov: The Founder of Classical Conditioning",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/ivan-pavlov-the-founder-of-classical-conditioning.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/ivan-pavlov-the-founder-of-classical-conditioning.txt",
    "genre": "Psychology"
  },
  {
    "id": 242,
    "title": "Ivory Coast (Côte d'Ivoire)",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/ivory-coast-(cote-d-ivoire).png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/ivory-coast-(cote-d-ivoire).txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 243,
    "title": "Jack Ma: The Architect of E-Commerce in China",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/jack-ma-the-architect-of-e-commerce-in-china.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/jack-ma-the-architect-of-e-commerce-in-china.txt",
    "genre": "Business & Finance"
  },
  {
    "id": 244,
    "title": "Jainism: The Path of Nonviolence",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/jainism-the-path-of-nonviolence.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/jainism-the-path-of-nonviolence.txt",
    "genre": "Religion & Spirituality"
  },
  {
    "id": 245,
    "title": "Jamaica",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/jamaica.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/jamaica.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 246,
    "title": "Jane Goodall: The Advocate for Primatology and Conservation",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/jane-goodall-the-advocate-for-primatology-and-conservation.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/jane-goodall-the-advocate-for-primatology-and-conservation.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 247,
    "title": "Jane Goodall: The Primatologist and Conservationist",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/jane-goodall-the-primatologist-and-conservationist.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/jane-goodall-the-primatologist-and-conservationist.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 248,
    "title": "Japan",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/japan.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/japan.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 249,
    "title": "Jawaharlal Nehru: The Architect of Modern India",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/jawaharlal-nehru-the-architect-of-modern-india.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/jawaharlal-nehru-the-architect-of-modern-india.txt",
    "genre": "History"
  },
  {
    "id": 250,
    "title": "Jean-Paul Sartre: The Philosopher of Existentialism",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/jean-paul-sartre-the-philosopher-of-existentialism.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/jean-paul-sartre-the-philosopher-of-existentialism.txt",
    "genre": "Philosophy"
  },
  {
    "id": 251,
    "title": "Jean Piaget: The Pioneer of Cognitive Development",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/jean-piaget-the-pioneer-of-cognitive-development.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/jean-piaget-the-pioneer-of-cognitive-development.txt",
    "genre": "Psychology"
  },
  {
    "id": 252,
    "title": "Jeff Bezos: The E-Commerce Pioneer",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/jeff-bezos-the-e-commerce-pioneer.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/jeff-bezos-the-e-commerce-pioneer.txt",
    "genre": "Business & Finance"
  },
  {
    "id": 253,
    "title": "Jesus Christ: The Central Figure of Christianity",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/jesus-christ-the-central-figure-of-christianity.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/jesus-christ-the-central-figure-of-christianity.txt",
    "genre": "Religion & Spirituality"
  },
  {
    "id": 254,
    "title": "Joan of Arc: The Warrior Saint",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/joan-of-arc-the-warrior-saint.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/joan-of-arc-the-warrior-saint.txt",
    "genre": "History"
  },
  {
    "id": 255,
    "title": "John B. Watson: The Father of Behaviorism",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/john-b.-watson-the-father-of-behaviorism.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/john-b.-watson-the-father-of-behaviorism.txt",
    "genre": "Psychology"
  },
  {
    "id": 256,
    "title": "John Locke: The Philosopher of Liberty and Empiricism",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/john-locke-the-philosopher-of-liberty-and-empiricism.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/john-locke-the-philosopher-of-liberty-and-empiricism.txt",
    "genre": "Philosophy"
  },
  {
    "id": 257,
    "title": "Jordan",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/jordan.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/jordan.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 258,
    "title": "Judaism: The Covenant and the Law",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/judaism-the-covenant-and-the-law.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/judaism-the-covenant-and-the-law.txt",
    "genre": "Religion & Spirituality"
  },
  {
    "id": 259,
    "title": "Karen Horney: The Pioneer of Feminine Psychology",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/karen-horney-the-pioneer-of-feminine-psychology.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/karen-horney-the-pioneer-of-feminine-psychology.txt",
    "genre": "Psychology"
  },
  {
    "id": 260,
    "title": "Karl Marx: Architect of Modern Socialism",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/karl-marx-architect-of-modern-socialism.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/karl-marx-architect-of-modern-socialism.txt",
    "genre": "Political Science & Economics"
  },
  {
    "id": 261,
    "title": "Kashmir Conflict: Bilateral Dialogue and Confidence Building Measures",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/kashmir-conflict-bilateral-dialogue-and-confidence-building-measures.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/kashmir-conflict-bilateral-dialogue-and-confidence-building-measures.txt",
    "genre": "Political Science & International Relations"
  },
  {
    "id": 262,
    "title": "Kenya",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/kenya.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/kenya.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 263,
    "title": "Kiribati",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/kiribati.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/kiribati.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 264,
    "title": "Korean Peninsula Conflict: Denuclearization and Peace Treaty",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/korean-peninsula-conflict-denuclearization-and-peace-treaty.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/korean-peninsula-conflict-denuclearization-and-peace-treaty.txt",
    "genre": "Political Science & International Relations"
  },
  {
    "id": 265,
    "title": "Kosovo",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/kosovo.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/kosovo.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 266,
    "title": "Kuwait",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/kuwait.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/kuwait.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 267,
    "title": "Kyrgyzstan",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/kyrgyzstan.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/kyrgyzstan.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 268,
    "title": "Laos",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/laos.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/laos.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 269,
    "title": "Laozi: The Founder of Taoism",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/laozi-the-founder-of-taoism.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/laozi-the-founder-of-taoism.txt",
    "genre": "Religion & Spirituality"
  },
  {
    "id": 270,
    "title": "Lebanon",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/lebanon.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/lebanon.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 271,
    "title": "Leonardo da Vinci: The Renaissance Genius",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/leonardo-da-vinci-the-renaissance-genius.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/leonardo-da-vinci-the-renaissance-genius.txt",
    "genre": "Arts & Humanities"
  },
  {
    "id": 272,
    "title": "Leonardo da Vinci",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/leonardo-da-vinci.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/leonardo-da-vinci.txt",
    "genre": "Arts & Humanities"
  },
  {
    "id": 273,
    "title": "Leonardo Fibonacci: The Mathematician Behind the Sequence",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/leonardo-fibonacci-the-mathematician-behind-the-sequence.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/leonardo-fibonacci-the-mathematician-behind-the-sequence.txt",
    "genre": "Science & Mathematics"
  },
  {
    "id": 274,
    "title": "Leonardo Fibonacci: The Medieval Mathematician",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/leonardo-fibonacci-the-medieval-mathematician.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/leonardo-fibonacci-the-medieval-mathematician.txt",
    "genre": "Science & Mathematics"
  },
  {
    "id": 275,
    "title": "Lesotho",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/lesotho.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/lesotho.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 276,
    "title": "Lev Vygotsky: The Pioneer of Sociocultural Theory",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/lev-vygotsky-the-pioneer-of-sociocultural-theory.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/lev-vygotsky-the-pioneer-of-sociocultural-theory.txt",
    "genre": "Psychology"
  },
  {
    "id": 277,
    "title": "Liberalism: The Ideology of Freedom and Equality",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/liberalism-the-ideology-of-freedom-and-equality.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/liberalism-the-ideology-of-freedom-and-equality.txt",
    "genre": "Political Science & Economics"
  },
  {
    "id": 278,
    "title": "Liberalism: The Philosophy of Freedom and Equality",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/liberalism-the-philosophy-of-freedom-and-equality.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/liberalism-the-philosophy-of-freedom-and-equality.txt",
    "genre": "Political Science & Economics"
  },
  {
    "id": 279,
    "title": "Liberia",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/liberia.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/liberia.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 280,
    "title": "Liechtenstein",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/liechtenstein.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/liechtenstein.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 281,
    "title": "Linear Algebra: The Mathematics of Vectors and Matrices",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/linear-algebra-the-mathematics-of-vectors-and-matrices.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/linear-algebra-the-mathematics-of-vectors-and-matrices.txt",
    "genre": "Science & Mathematics"
  },
  {
    "id": 282,
    "title": "Luxembourg",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/luxembourg.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/luxembourg.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 283,
    "title": "Machine Learning",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/machine-learning.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/machine-learning.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 284,
    "title": "Madagascar",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/madagascar.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/madagascar.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 285,
    "title": "Mahatma Gandhi: Leader of Nonviolent Resistance",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/mahatma-gandhi-leader-of-nonviolent-resistance.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/mahatma-gandhi-leader-of-nonviolent-resistance.txt",
    "genre": "History"
  },
  {
    "id": 286,
    "title": "Mahatma Gandhi: The Leader of Indian Independence",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/mahatma-gandhi-the-leader-of-indian-independence.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/mahatma-gandhi-the-leader-of-indian-independence.txt",
    "genre": "History"
  },
  {
    "id": 287,
    "title": "Malala Yousafzai: Advocate for Girls' Education",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/malala-yousafzai-advocate-for-girls-education.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/malala-yousafzai-advocate-for-girls-education.txt",
    "genre": "Education & Learning"
  },
  {
    "id": 288,
    "title": "Malawi",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/malawi.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/malawi.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 289,
    "title": "Malaysia",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/malaysia.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/malaysia.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 290,
    "title": "Maldives",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/maldives.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/maldives.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 291,
    "title": "Malta",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/malta.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/malta.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 292,
    "title": "Manage Time Effectively",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/manage-time-effectively.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/manage-time-effectively.txt",
    "genre": "Self-Help & Personal Development"
  },
  {
    "id": 293,
    "title": "Mansa Musa: The Wealthiest Emperor in History",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/mansa-musa-the-wealthiest-emperor-in-history.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/mansa-musa-the-wealthiest-emperor-in-history.txt",
    "genre": "History"
  },
  {
    "id": 294,
    "title": "Margaret Thatcher: The Iron Lady of Britain",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/margaret-thatcher-the-iron-lady-of-britain.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/margaret-thatcher-the-iron-lady-of-britain.txt",
    "genre": "History"
  },
  {
    "id": 295,
    "title": "Marie Antoinette: The Last Queen of France",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/marie-antoinette-the-last-queen-of-france.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/marie-antoinette-the-last-queen-of-france.txt",
    "genre": "History"
  },
  {
    "id": 296,
    "title": "Marie Curie: Pioneer of Radioactivity",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/marie-curie-pioneer-of-radioactivity.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/marie-curie-pioneer-of-radioactivity.txt",
    "genre": "Science & Mathematics"
  },
  {
    "id": 297,
    "title": "Marie Curie",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/marie-curie.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/marie-curie.txt",
    "genre": "Science & Mathematics"
  },
  {
    "id": 298,
    "title": "Marine Biology",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/marine-biology.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/marine-biology.txt",
    "genre": "Science & Mathematics"
  },
  {
    "id": 299,
    "title": "Marshall Islands",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/marshall-islands.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/marshall-islands.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 300,
    "title": "Marxism: The Theory of Class Struggle",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/marxism-the-theory-of-class-struggle.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/marxism-the-theory-of-class-struggle.txt",
    "genre": "Political Science & Economics"
  },
  {
    "id": 301,
    "title": "Mary Wollstonecraft: Pioneer of Feminist Thought",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/mary-wollstonecraft-pioneer-of-feminist-thought.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/mary-wollstonecraft-pioneer-of-feminist-thought.txt",
    "genre": "Philosophy"
  },
  {
    "id": 302,
    "title": "Mathematical Logic: The Foundation of Mathematical Reasoning",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/mathematical-logic-the-foundation-of-mathematical-reasoning.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/mathematical-logic-the-foundation-of-mathematical-reasoning.txt",
    "genre": "Science & Mathematics"
  },
  {
    "id": 303,
    "title": "Mathematical Logic: The Foundation of Reasoning",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/mathematical-logic-the-foundation-of-reasoning.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/mathematical-logic-the-foundation-of-reasoning.txt",
    "genre": "Science & Mathematics"
  },
  {
    "id": 304,
    "title": "Mauritania",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/mauritania.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/mauritania.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 305,
    "title": "Mauritius",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/mauritius.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/mauritius.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 306,
    "title": "Mergers and Acquisitions (M&A): Strategies for Growth and Expansion",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/mergers-and-acquisitions-(M&A)-strategies-for-growth-and-expansion.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/mergers-and-acquisitions-(M&A)-strategies-for-growth-and-expansion.txt",
    "genre": "Business & Finance"
  },
  {
    "id": 307,
    "title": "Mexico",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/mexico.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/mexico.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 308,
    "title": "Microbiology: The Invisible World of Microorganisms",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/microbiology-the-invisible-world-of-microorganisms.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/microbiology-the-invisible-world-of-microorganisms.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 309,
    "title": "Microbiology",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/microbiology.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/microbiology.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 310,
    "title": "Microbiome Research",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/microbiome-research.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/microbiome-research.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 311,
    "title": "Microbiomes",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/microbiomes.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/microbiomes.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 312,
    "title": "Microgrids",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/microgrids.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/microgrids.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 313,
    "title": "Micronesia (Federated States of Micronesia)",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/micronesia-(federated-states-of-micronesia).png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/micronesia-(federated-states-of-micronesia).txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 314,
    "title": "Moldova",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/moldova.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/moldova.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 315,
    "title": "Mongolia",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/mongolia.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/mongolia.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 316,
    "title": "Montenegro",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/montenegro.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/montenegro.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 317,
    "title": "Morocco",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/morocco.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/morocco.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 318,
    "title": "Mother Teresa: Saint of the Slums",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/mother-teresa-saint-of-the-slums.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/mother-teresa-saint-of-the-slums.txt",
    "genre": "History"
  },
  {
    "id": 319,
    "title": "Mozambique",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/mozambique.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/mozambique.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 320,
    "title": "Myanmar (Burma)",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/myanmar-(burma).png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/myanmar-(burma).txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 321,
    "title": "Namibia",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/namibia.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/namibia.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 322,
    "title": "Nanomedicine",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/nanomedicine.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/nanomedicine.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 323,
    "title": "Nanotechnology",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/nanotechnology.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/nanotechnology.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 324,
    "title": "Nationalism: Identity and Sovereignty",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/nationalism-identity-and-sovereignty.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/nationalism-identity-and-sovereignty.txt",
    "genre": "Political Science & Economics"
  },
  {
    "id": 325,
    "title": "Nationalism: The Politics of Identity and Sovereignty",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/nationalism-the-politics-of-identity-and-sovereignty.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/nationalism-the-politics-of-identity-and-sovereignty.txt",
    "genre": "Political Science & Economics"
  },
  {
    "id": 326,
    "title": "Nauru",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/nauru.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/nauru.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 327,
    "title": "Nelson Mandela: Champion of Freedom and Equality",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/nelson-mandela-champion-of-freedom-and-equality.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/nelson-mandela-champion-of-freedom-and-equality.txt",
    "genre": "History"
  },
  {
    "id": 328,
    "title": "Nelson Mandela: The Champion of South African Freedom",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/nelson-mandela-the-champion-of-south-african-freedom.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/nelson-mandela-the-champion-of-south-african-freedom.txt",
    "genre": "History"
  },
  {
    "id": 329,
    "title": "Nepal",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/nepal.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/nepal.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 330,
    "title": "Netherlands",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/netherlands.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/netherlands.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 331,
    "title": "Neuroaesthetics",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/neuroaesthetics.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/neuroaesthetics.txt",
    "genre": "Psychology"
  },
  {
    "id": 332,
    "title": "Neuroarchitecture: The Impact of Design on the Brain",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/neuroarchitecture-the-impact-of-design-on-the-brain.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/neuroarchitecture-the-impact-of-design-on-the-brain.txt",
    "genre": "Psychology"
  },
  {
    "id": 333,
    "title": "Neuroarchitecture",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/neuroarchitecture.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/neuroarchitecture.txt",
    "genre": "Psychology"
  },
  {
    "id": 334,
    "title": "Neurofinance",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/neurofinance.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/neurofinance.txt",
    "genre": "Business & Finance"
  },
  {
    "id": 335,
    "title": "Neuromorphic Engineering",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/neuromorphic-engineering.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/neuromorphic-engineering.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 336,
    "title": "Neuroplasticity",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/neuroplasticity.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/neuroplasticity.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 337,
    "title": "Neuroprosthetics",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/neuroprosthetics.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/neuroprosthetics.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 338,
    "title": "Neuroscience",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/neuroscience.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/neuroscience.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 339,
    "title": "New Zealand",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/new-zealand.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/new-zealand.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 340,
    "title": "Nicolaus Copernicus: The Father of Modern Astronomy",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/nicolaus-copernicus-the-father-of-modern-astronomy.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/nicolaus-copernicus-the-father-of-modern-astronomy.txt",
    "genre": "Science & Mathematics"
  },
  {
    "id": 341,
    "title": "Niger",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/niger.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/niger.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 342,
    "title": "Nigeria",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/nigeria.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/nigeria.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 343,
    "title": "Nikola Tesla: The Visionary Inventor",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/nikola-tesla-the-visionary-inventor.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/nikola-tesla-the-visionary-inventor.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 344,
    "title": "Nikolaus Otto: The Inventor of the Internal Combustion Engine",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/nikolaus-otto-the-inventor-of-the-internal-combustion-engine.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/nikolaus-otto-the-inventor-of-the-internal-combustion-engine.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 345,
    "title": "North Macedonia",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/north-macedonia.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/north-macedonia.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 346,
    "title": "Norway",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/norway.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/norway.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 347,
    "title": "Nuclear Disarmament and Non-Proliferation",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/nuclear-disarmament-and-non-proliferation.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/nuclear-disarmament-and-non-proliferation.txt",
    "genre": "Political Science & International Relations"
  },
  {
    "id": 348,
    "title": "Number Theory: The Study of Integers",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/number-theory-the-study-of-integers.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/number-theory-the-study-of-integers.txt",
    "genre": "Science & Mathematics"
  },
  {
    "id": 349,
    "title": "Ocean Acidification",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/ocean-acidification.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/ocean-acidification.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 350,
    "title": "Ocean Energy",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/ocean-energy.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/ocean-energy.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 351,
    "title": "Ocean Thermal Energy Conversion",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/ocean-thermal-energy-conversion.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/ocean-thermal-energy-conversion.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 352,
    "title": "Oman",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/oman.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/oman.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 353,
    "title": "Oprah Winfrey: The Media Mogul and Philanthropist",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/oprah-winfrey-the-media-mogul-and-philanthropist.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/oprah-winfrey-the-media-mogul-and-philanthropist.txt",
    "genre": "Business & Finance"
  },
  {
    "id": 354,
    "title": "Pakistan",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/pakistan.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/pakistan.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 355,
    "title": "Palau",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/palau.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/palau.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 356,
    "title": "Papua New Guinea",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/papua-new-guinea.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/papua-new-guinea.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 357,
    "title": "Paraguay",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/paraguay.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/paraguay.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 358,
    "title": "Peru",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/peru.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/peru.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 359,
    "title": "Phenomenology: The Philosophy of Experience",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/phenomenology-the-philosophy-of-experience.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/phenomenology-the-philosophy-of-experience.txt",
    "genre": "Philosophy"
  },
  {
    "id": 360,
    "title": "Philippines",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/philippines.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/philippines.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 361,
    "title": "Plant-Based Diets",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/plant-based-diets.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/plant-based-diets.txt",
    "genre": "Health & Wellness"
  },
  {
    "id": 362,
    "title": "Plate Tectonics: The Dynamic Earth",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/plate-tectonics-the-dynamic-earth.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/plate-tectonics-the-dynamic-earth.txt",
    "genre": "Science & Mathematics"
  },
  {
    "id": 363,
    "title": "Plato: The Philosopher of Ideals",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/plato-the-philosopher-of-ideals.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/plato-the-philosopher-of-ideals.txt",
    "genre": "Philosophy"
  },
  {
    "id": 364,
    "title": "Poland",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/poland.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/poland.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 365,
    "title": "Populism: The Politics of the People",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/populism-the-politics-of-the-people.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/populism-the-politics-of-the-people.txt",
    "genre": "Political Science & Economics"
  },
  {
    "id": 366,
    "title": "Populism: The Voice of the People",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/populism-the-voice-of-the-people.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/populism-the-voice-of-the-people.txt",
    "genre": "Political Science & Economics"
  },
  {
    "id": 367,
    "title": "Portugal",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/portugal.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/portugal.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 368,
    "title": "Postmodernism: The Philosophy of Fragmentation and Plurality",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/postmodernism-the-philosophy-of-fragmentation-and-plurality.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/postmodernism-the-philosophy-of-fragmentation-and-plurality.txt",
    "genre": "Philosophy"
  },
  {
    "id": 369,
    "title": "Practice Gratitude Daily",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/practice-gratitude-daily.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/practice-gratitude-daily.txt",
    "genre": "Self-Help & Personal Development"
  },
  {
    "id": 370,
    "title": "Practice Self-Compassion",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/practice-self-compassion.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/practice-self-compassion.txt",
    "genre": "Self-Help & Personal Development"
  },
  {
    "id": 371,
    "title": "Pragmatism: The Philosophy of Practical Consequences",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/pragmatism-the-philosophy-of-practical-consequences.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/pragmatism-the-philosophy-of-practical-consequences.txt",
    "genre": "Philosophy"
  },
  {
    "id": 372,
    "title": "Precision Agriculture",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/precision-agriculture.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/precision-agriculture.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 373,
    "title": "Private Equity: Investing in Growth and Transformation",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/private-equity-investing-in-growth-and-transformation.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/private-equity-investing-in-growth-and-transformation.txt",
    "genre": "Business & Finance"
  },
  {
    "id": 374,
    "title": "Probability: The Mathematics of Uncertainty",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/probability-the-mathematics-of-uncertainty.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/probability-the-mathematics-of-uncertainty.txt",
    "genre": "Science & Mathematics"
  },
  {
    "id": 375,
    "title": "Promote Economic Integration and Trade Partnerships",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/promote-economic-integration-and-trade-partnerships.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/promote-economic-integration-and-trade-partnerships.txt",
    "genre": "Political Science & International Relations"
  },
  {
    "id": 376,
    "title": "Promote Environmental Sustainability",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/promote-environmental-sustainability.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/promote-environmental-sustainability.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 377,
    "title": "Promote Multilateralism and Global Governance",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/promote-multilateralism-and-global-governance.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/promote-multilateralism-and-global-governance.txt",
    "genre": "Political Science & International Relations"
  },
  {
    "id": 378,
    "title": "Promote Planetary Protection and Stewardship",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/promote-planetary-protection-and-stewardship.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/promote-planetary-protection-and-stewardship.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 379,
    "title": "Promote Universal Scientific Collaboration",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/promote-universal-scientific-collaboration.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/promote-universal-scientific-collaboration.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 380,
    "title": "Prophet Muhammad: The Messenger of Islam",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/prophet-muhammad-the-messenger-of-islam.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/prophet-muhammad-the-messenger-of-islam.txt",
    "genre": "History"
  },
  {
    "id": 381,
    "title": "Public Transport Systems for Sustainable Mobility",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/public-transport-systems-for-sustainable-mobility.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/public-transport-systems-for-sustainable-mobility.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 382,
    "title": "Pursue the Search for Extraterrestrial Life",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/pursue-the-search-for-extraterrestrial-life.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/pursue-the-search-for-extraterrestrial-life.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 383,
    "title": "Pythagoras: The Philosopher and Mathematician",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/pythagoras-the-philosopher-and-mathematician.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/pythagoras-the-philosopher-and-mathematician.txt",
    "genre": "Science & Mathematics"
  },
  {
    "id": 384,
    "title": "Qatar",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/qatar.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/qatar.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 385,
    "title": "Quantum Biology",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/quantum-biology.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/quantum-biology.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 386,
    "title": "Quantum Computing",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/quantum-computing.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/quantum-computing.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 387,
    "title": "Quantum Cryptography",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/quantum-cryptography.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/quantum-cryptography.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 388,
    "title": "Quantum Mechanics",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/quantum-mechanics.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/quantum-mechanics.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 389,
    "title": "Quantum Sensors",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/quantum-sensors.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/quantum-sensors.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 390,
    "title": "Regenerative Agriculture",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/regenerative-agriculture.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/regenerative-agriculture.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 391,
    "title": "Relativity: The Fabric of Space and Time",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/relativity-the-fabric-of-space-and-time.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/relativity-the-fabric-of-space-and-time.txt",
    "genre": "Science & Mathematics"
  },
  {
    "id": 392,
    "title": "René Descartes: The Father of Modern Philosophy",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/rene-descartes-the-father-of-modern-philosophy.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/rene-descartes-the-father-of-modern-philosophy.txt",
    "genre": "Philosophy"
  },
  {
    "id": 393,
    "title": "Renewable Agriculture",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/renewable-agriculture.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/renewable-agriculture.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 394,
    "title": "Renewable Energy Policy and Its Global Impact",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/renewable-energy-policy-and-its-global-impact.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/renewable-energy-policy-and-its-global-impact.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 395,
    "title": "Renewable Energy Policy",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/renewable-energy-policy.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/renewable-energy-policy.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 396,
    "title": "Renewable Energy Storage: Key to a Sustainable Future",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/renewable-energy-storage-key-to-a-sustainable-future.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/renewable-energy-storage-key-to-a-sustainable-future.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 397,
    "title": "Renewable Energy Storage",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/renewable-energy-storage.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/renewable-energy-storage.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 398,
    "title": "Renewable Energy",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/renewable-energy.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/renewable-energy.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 399,
    "title": "Renewable Resources",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/renewable-resources.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/renewable-resources.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 400,
    "title": "Richard Branson: The Maverick Entrepreneur",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/richard-branson-the-maverick-entrepreneur.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/richard-branson-the-maverick-entrepreneur.txt",
    "genre": "Business & Finance"
  },
  {
    "id": 401,
    "title": "Richard Feynman: The Quantum Theorist",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/richard-feynman-the-quantum-theorist.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/richard-feynman-the-quantum-theorist.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 402,
    "title": "Robotics and the Future of Human Work",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/robotics-and-the-future-of-human-work.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/robotics-and-the-future-of-human-work.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 403,
    "title": "Robotics",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/robotics.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/robotics.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 404,
    "title": "Rohingya Crisis in Myanmar: Safe Return and Political Reconciliation",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/rohingya-crisis-in-myanmar-safe-return-and-political-reconciliation.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/rohingya-crisis-in-myanmar-safe-return-and-political-reconciliation.txt",
    "genre": "Political Science & International Relations"
  },
  {
    "id": 405,
    "title": "Rollo May: The Existential Psychologist",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/rollo-may-the-existential-psychologist.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/rollo-may-the-existential-psychologist.txt",
    "genre": "Psychology"
  },
  {
    "id": 406,
    "title": "Romania",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/romania.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/romania.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 407,
    "title": "Rosa Parks: The Mother of the Civil Rights Movement",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/rosa-parks-the-mother-of-the-civil-rights-movement.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/rosa-parks-the-mother-of-the-civil-rights-movement.txt",
    "genre": "History"
  },
  {
    "id": 408,
    "title": "Rosalind Franklin: The Unsung Hero of DNA",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/rosalind-franklin-the-unsung-hero-of-dna.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/rosalind-franklin-the-unsung-hero-of-dna.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 409,
    "title": "Rumi: The Mystic Poet of Sufism",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/rumi-the-mystic-poet-of-sufism.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/rumi-the-mystic-poet-of-sufism.txt",
    "genre": "Arts & Humanities"
  },
  {
    "id": 410,
    "title": "Russia",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/russia.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/russia.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 411,
    "title": "Rwanda",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/rwanda.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/rwanda.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 412,
    "title": "Saint Kitts and Nevis",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/saint-kitts-and-nevis.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/saint-kitts-and-nevis.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 413,
    "title": "San Marino",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/san-marino.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/san-marino.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 414,
    "title": "São Tomé and Príncipe",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/sao-tome-and-principe.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/sao-tome-and-principe.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 415,
    "title": "Saudi Arabia",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/saudi-arabia.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/saudi-arabia.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 416,
    "title": "Self-Healing Materials",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/self-healing-materials.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/self-healing-materials.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 417,
    "title": "Senegal",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/senegal.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/senegal.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 418,
    "title": "Serbia",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/serbia.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/serbia.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 419,
    "title": "Set and Pursue Personal Goals",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/set-and-pursue-personal-goals.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/set-and-pursue-personal-goals.txt",
    "genre": "Self-Help & Personal Development"
  },
  {
    "id": 420,
    "title": "Set Theory: The Foundation of Modern Mathematics",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/set-theory-the-foundation-of-modern-mathematics.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/set-theory-the-foundation-of-modern-mathematics.txt",
    "genre": "Science & Mathematics"
  },
  {
    "id": 421,
    "title": "Seychelles",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/seychelles.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/seychelles.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 422,
    "title": "Sheryl Sandberg: The Advocate for Women in Leadership",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/sheryl-sandberg-the-advocate-for-women-in-leadership.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/sheryl-sandberg-the-advocate-for-women-in-leadership.txt",
    "genre": "Business & Finance"
  },
  {
    "id": 423,
    "title": "Shinto: The Way of the Kami",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/shinto-the-way-of-the-kami.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/shinto-the-way-of-the-kami.txt",
    "genre": "Religion & Spirituality"
  },
  {
    "id": 424,
    "title": "Sierra Leone",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/sierra-leone.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/sierra-leone.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 425,
    "title": "Sigmund Freud: The Father of Psychoanalysis",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/sigmund-freud-the-father-of-psychoanalysis.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/sigmund-freud-the-father-of-psychoanalysis.txt",
    "genre": "Psychology"
  },
  {
    "id": 426,
    "title": "Sikhism: The Faith of Unity and Service",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/sikhism-the-faith-of-unity-and-service.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/sikhism-the-faith-of-unity-and-service.txt",
    "genre": "Religion & Spirituality"
  },
  {
    "id": 427,
    "title": "Simone de Beauvoir: The Philosopher of Feminism and Existentialism",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/simone-de-beauvoir-the-philosopher-of-feminism-and-existentialism.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/simone-de-beauvoir-the-philosopher-of-feminism-and-existentialism.txt",
    "genre": "Philosophy"
  },
  {
    "id": 428,
    "title": "Singapore",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/singapore.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/singapore.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 429,
    "title": "Circular Economy",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/circular-economy.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/circular-economy.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 430,
    "title": "Slovenia",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/slovenia.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/slovenia.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 431,
    "title": "Smart Cities",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/smart-cities.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/smart-cities.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 432,
    "title": "Smart Grids",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/smart-grids.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/smart-grids.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 433,
    "title": "Smart Textiles",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/smart-textiles.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/smart-textiles.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 434,
    "title": "Social Media Algorithms",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/social-media-algorithms.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/social-media-algorithms.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 435,
    "title": "Social Robotics",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/social-robotics.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/social-robotics.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 436,
    "title": "Socialism: The Pursuit of Economic and Social Equality",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/socialism-the-pursuit-of-economic-and-social-equality.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/socialism-the-pursuit-of-economic-and-social-equality.txt",
    "genre": "Political Science & Economics"
  },
  {
    "id": 437,
    "title": "Socialism: The Pursuit of Economic Equality",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/socialism-the-pursuit-of-economic-equality.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/socialism-the-pursuit-of-economic-equality.txt",
    "genre": "Political Science & Economics"
  },
  {
    "id": 438,
    "title": "Socrates: The Father of Western Philosophy",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/socrates-the-father-of-western-philosophy.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/socrates-the-father-of-western-philosophy.txt",
    "genre": "Philosophy"
  },
  {
    "id": 439,
    "title": "Solar Water Desalination",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/solar-water-desalination.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/solar-water-desalination.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 440,
    "title": "Solomon Islands",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/solomon-islands.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/solomon-islands.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 441,
    "title": "Somalia",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/somalia.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/somalia.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 442,
    "title": "South Africa",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/south-africa.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/south-africa.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 443,
    "title": "South China Sea Dispute: Multilateral Dialogue and Maritime Cooperation",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/south-china-sea-dispute-multilateral-dialogue-and-maritime-cooperation.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/south-china-sea-dispute-multilateral-dialogue-and-maritime-cooperation.txt",
    "genre": "Political Science & International Relations"
  },
  {
    "id": 444,
    "title": "South Korea",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/south-korea.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/south-korea.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 445,
    "title": "South Sudan",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/south-sudan.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/south-sudan.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 446,
    "title": "Space Colonization",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/space-colonization.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/space-colonization.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 447,
    "title": "Space Debris Mitigation",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/space-debris-mitigation.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/space-debris-mitigation.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 448,
    "title": "Space Elevators",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/space-elevators.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/space-elevators.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 449,
    "title": "Space Telescopes and Their Discoveries",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/space-telescopes-and-their-discoveries.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/space-telescopes-and-their-discoveries.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 450,
    "title": "Space Tourism: Exploring the Final Frontier",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/space-tourism-exploring-the-final-frontier.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/space-tourism-exploring-the-final-frontier.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 451,
    "title": "Space Tourism",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/space-tourism.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/space-tourism.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 452,
    "title": "Space Weather",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/space-weather.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/space-weather.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 453,
    "title": "Spain",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/spain.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/spain.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 454,
    "title": "Sri Lanka",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/sri-lanka.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/sri-lanka.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 455,
    "title": "Srinivasa Ramanujan: The Self-Taught Mathematical Genius",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/srinivasa-ramanujan-the-self-taught-mathematical-genius.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/srinivasa-ramanujan-the-self-taught-mathematical-genius.txt",
    "genre": "Science & Mathematics"
  },
  {
    "id": 456,
    "title": "Stephen Hawking: The Cosmologist and Theoretical Physicist",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/stephen-hawking-the-cosmologist-and-theoretical-physicist.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/stephen-hawking-the-cosmologist-and-theoretical-physicist.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 457,
    "title": "Steve Jobs: The Visionary Behind Apple",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/steve-jobs-the-visionary-behind-apple.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/steve-jobs-the-visionary-behind-apple.txt",
    "genre": "Business & Finance"
  },
  {
    "id": 458,
    "title": "Stock Market: The Engine of Capital and Investment",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/stock-market-the-engine-of-capital-and-investment.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/stock-market-the-engine-of-capital-and-investment.txt",
    "genre": "Business & Finance"
  },
  {
    "id": 459,
    "title": "Stoicism: The Philosophy of Resilience",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/stoicism-the-philosophy-of-resilience.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/stoicism-the-philosophy-of-resilience.txt",
    "genre": "Philosophy"
  },
  {
    "id": 460,
    "title": "Structuralism: The Philosophy of Structures",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/structuralism-the-philosophy-of-structures.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/structuralism-the-philosophy-of-structures.txt",
    "genre": "Philosophy"
  },
  {
    "id": 461,
    "title": "Support Community Engagement and Civic Participation",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/support-community-engagement-and-civic-participation.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/support-community-engagement-and-civic-participation.txt",
    "genre": "Self-Help & Personal Development"
  },
  {
    "id": 462,
    "title": "Suriname",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/suriname.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/suriname.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 463,
    "title": "Sustainable Agriculture: Balancing Food Production and Environmental Health",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/sustainable-agriculture-balancing-food-production-and-environmental-health.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/sustainable-agriculture-balancing-food-production-and-environmental-health.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 464,
    "title": "Sustainable Agriculture",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/sustainable-agriculture.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/sustainable-agriculture.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 465,
    "title": "Sustainable Aquaculture",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/sustainable-aquaculture.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/sustainable-aquaculture.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 466,
    "title": "Sustainable Investing: Aligning Profits with Purpose",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/sustainable-investing-aligning-profits-with-purpose.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/sustainable-investing-aligning-profits-with-purpose.txt",
    "genre": "Business & Finance"
  },
  {
    "id": 467,
    "title": "Sustainable Resource Management and Conflict Prevention",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/sustainable-resource-management-and-conflict-prevention.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/sustainable-resource-management-and-conflict-prevention.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 468,
    "title": "Sustainable Tourism and Environmental Conservation",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/sustainable-tourism-and-environmental-conservation.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/sustainable-tourism-and-environmental-conservation.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 469,
    "title": "Sustainable Tourism",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/sustainable-tourism.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/sustainable-tourism.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 470,
    "title": "Swami Vivekananda: The Spiritual Ambassador of Hinduism",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/swami-vivekananda-the-spiritual-ambassador-of-hinduism.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/swami-vivekananda-the-spiritual-ambassador-of-hinduism.txt",
    "genre": "Religion & Spirituality"
  },
  {
    "id": 471,
    "title": "Swarm Robotics",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/swarm-robotics.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/swarm-robotics.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 472,
    "title": "Sweden",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/sweden.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/sweden.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 473,
    "title": "Switzerland",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/switzerland.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/switzerland.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 474,
    "title": "Synthetic Biology",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/synthetic-biology.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/synthetic-biology.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 475,
    "title": "Syria",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/syria.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/syria.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 476,
    "title": "Syrian Civil War: A Comprehensive Peace Process",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/syrian-civil-war-a-comprehensive-peace-process.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/syrian-civil-war-a-comprehensive-peace-process.txt",
    "genre": "Political Science & International Relations"
  },
  {
    "id": 477,
    "title": "Tajikistan",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/tajikistan.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/tajikistan.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 478,
    "title": "Tanzania",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/tanzania.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/tanzania.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 479,
    "title": "Taoism: Harmony with the Tao",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/taoism-harmony-with-the-tao.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/taoism-harmony-with-the-tao.txt",
    "genre": "Religion & Spirituality"
  },
  {
    "id": 480,
    "title": "Taoism: The Philosophy of Harmony",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/taoism-the-philosophy-of-harmony.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/taoism-the-philosophy-of-harmony.txt",
    "genre": "Philosophy"
  },
  {
    "id": 481,
    "title": "Telemedicine for Accessible Healthcare",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/telemedicine-for-accessible-healthcare.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/telemedicine-for-accessible-healthcare.txt",
    "genre": "Health & Wellness"
  },
  {
    "id": 482,
    "title": "Thailand",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/thailand.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/thailand.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 483,
    "title": "The Amazon Rainforest",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-amazon-rainforest.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-amazon-rainforest.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 484,
    "title": "The Art of the Baroque Period",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-art-of-baroque-period.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-art-of-baroque-period.txt",
    "genre": "Arts & Humanities"
  },
  {
    "id": 485,
    "title": "The Big Bang Theory: The Origin of the Universe",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-big-bang-theory-the-origin-of-the-universe.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-big-bang-theory-the-origin-of-the-universe.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 486,
    "title": "The Chemistry of Cooking",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-chemistry-of-cooking.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-chemistry-of-cooking.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 487,
    "title": "The Cold War: A Geopolitical Rivalry",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-cold-war-a-geopolitical-rivalry.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-cold-war-a-geopolitical-rivalry.txt",
    "genre": "History"
  },
  {
    "id": 488,
    "title": "The Cold War",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-cold-war.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-cold-war.txt",
    "genre": "History"
  },
  {
    "id": 489,
    "title": "The Concept of Collective Consciousness",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-concept-of-collective-consciousness.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-concept-of-collective-consciousness.txt",
    "genre": "Philosophy"
  },
  {
    "id": 490,
    "title": "The Crusades",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-crusades.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-crusades.txt",
    "genre": "History"
  },
  {
    "id": 491,
    "title": "The Cultural Significance of Folk Music",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-cultural-significance-of-folk-music.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-cultural-significance-of-folk-music.txt",
    "genre": "Arts & Humanities"
  },
  {
    "id": 492,
    "title": "The Development of Renewable Energy Technologies",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-development-of-renewable-energy-technologies.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-development-of-renewable-energy-technologies.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 493,
    "title": "The Dynamics of Predator-Prey Relationships",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-dynamics-of-predator-prey-relationships.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-dynamics-of-predator-prey-relationships.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 494,
    "title": "The Economics of Universal Basic Income (UBI)",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-economics-of-universal-basic-income-(ubi).png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-economics-of-universal-basic-income-(ubi).txt",
    "genre": "Political Science & Economics"
  },
  {
    "id": 495,
    "title": "The Ethics of Animal Testing",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-ethics-of-animal-testing.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-ethics-of-animal-testing.txt",
    "genre": "Ethics & Philosophy"
  },
  {
    "id": 496,
    "title": "The Ethics of Artificial Life",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-ethics-of-artificial-life.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-ethics-of-artificial-life.txt",
    "genre": "Ethics & Philosophy"
  },
  {
    "id": 497,
    "title": "The Ethics of Genetic Modification",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-ethics-of-genetic-modification.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-ethics-of-genetic-modification.txt",
    "genre": "Ethics & Philosophy"
  },
  {
    "id": 498,
    "title": "The Evolution of Democracy",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-evolution-of-democracy.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-evolution-of-democracy.txt",
    "genre": "Political Science & Economics"
  },
  {
    "id": 499,
    "title": "The Evolution of Early Human Societies",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-evolution-of-early-human-societies.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-evolution-of-early-human-societies.txt",
    "genre": "History"
  },
  {
    "id": 500,
    "title": "The Evolution of Economic Systems",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-evolution-of-economic-systems.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-evolution-of-economic-systems.txt",
    "genre": "Political Science & Economics"
  },
  {
    "id": 501,
    "title": "The Evolution of Educational Systems",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-evolution-of-educational-systems.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-evolution-of-educational-systems.txt",
    "genre": "Education & Learning"
  },
  {
    "id": 502,
    "title": "The Evolution of Fashion and Clothing",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-evolution-of-fashion-and-clothing.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-evolution-of-fashion-and-clothing.txt",
    "genre": "Arts & Humanities"
  },
  {
    "id": 503,
    "title": "The Evolution of Language and Communication",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-evolution-of-language-and-communication.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-evolution-of-language-and-communication.txt",
    "genre": "Arts & Humanities"
  },
  {
    "id": 504,
    "title": "The Evolution of Linguistics",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-evolution-of-linguistics.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-evolution-of-linguistics.txt",
    "genre": "Arts & Humanities"
  },
  {
    "id": 505,
    "title": "The Evolution of Medicine",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-evolution-of-medicine.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-evolution-of-medicine.txt",
    "genre": "Health & Wellness"
  },
  {
    "id": 506,
    "title": "The Evolution of Music Recording Technology",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-evolution-of-music-recording-technology.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-evolution-of-music-recording-technology.txt",
    "genre": "Arts & Humanities"
  },
  {
    "id": 507,
    "title": "The Evolution of Renewable Agriculture Practices",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-evolution-of-renewable-agriculture-practices.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-evolution-of-renewable-agriculture-practices.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 508,
    "title": "The Evolution of the English Language",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-evolution-of-the-english-language.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-evolution-of-the-english-language.txt",
    "genre": "Arts & Humanities"
  },
  {
    "id": 509,
    "title": "The Evolution of the Internet",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-evolution-of-the-internet.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-evolution-of-the-internet.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 510,
    "title": "The Evolution of Video Game Design",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-evolution-of-video-game-design.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-evolution-of-video-game-design.txt",
    "genre": "Arts & Humanities"
  },
  {
    "id": 511,
    "title": "The Exploration of the Deep Ocean",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-exploration-of-the-deep-ocean.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-exploration-of-the-deep-ocean.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 512,
    "title": "The French Revolution",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-french-revolution.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-french-revolution.txt",
    "genre": "History"
  },
  {
    "id": 513,
    "title": "The Future of Artificial Intelligence in Space Exploration",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-future-of-artificial-intelligence-in-space-exploration.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-future-of-artificial-intelligence-in-space-exploration.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 514,
    "title": "The Future of Education: Personalized Learning and EdTech",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-future-of-education-personalized-learning-and-edtech.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-future-of-education-personalized-learning-and-edtech.txt",
    "genre": "Education & Learning"
  },
  {
    "id": 515,
    "title": "The Future of Food Security",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-future-of-food-security.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-future-of-food-security.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 516,
    "title": "The Future of Remote Work",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-future-of-remote-work.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-future-of-remote-work.txt",
    "genre": "Business & Finance"
  },
  {
    "id": 517,
    "title": "The Global Water Crisis",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-global-water-crisis.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-global-water-crisis.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 518,
    "title": "The Great Barrier Reef",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-great-barrier-reef.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-great-barrier-reef.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 519,
    "title": "The Great Depression",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-great-depression.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-great-depression.txt",
    "genre": "History"
  },
  {
    "id": 520,
    "title": "The Green Revolution: Agricultural Transformation",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-green-revolution-agricultural-transformation.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-green-revolution-agricultural-transformation.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 521,
    "title": "The Green Revolution",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-green-revolution.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-green-revolution.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 522,
    "title": "The History and Development of Jazz Music",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-history-and-development-of-jazz-music.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-history-and-development-of-jazz-music.txt",
    "genre": "Arts & Humanities"
  },
  {
    "id": 523,
    "title": "The History and Evolution of Architecture",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-history-and-evolution-of-architecture.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-history-and-evolution-of-architecture.txt",
    "genre": "Arts & Humanities"
  },
  {
    "id": 524,
    "title": "The History and Evolution of Computing",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-history-and-evolution-of-computing.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-history-and-evolution-of-computing.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 525,
    "title": "The History and Evolution of Dance",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-history-and-evolution-of-dance.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-history-and-evolution-of-dance.txt",
    "genre": "Arts & Humanities"
  },
  {
    "id": 526,
    "title": "The History and Impact of Cinema",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-history-and-impact-of-cinema.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-history-and-impact-of-cinema.txt",
    "genre": "Arts & Humanities"
  },
  {
    "id": 527,
    "title": "The History and Impact of the Industrial Revolution",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-history-and-impact-of-the-industrial-revolution.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-history-and-impact-of-the-industrial-revolution.txt",
    "genre": "History"
  },
  {
    "id": 528,
    "title": "The History and Significance of the Silk Road",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-history-and-significance-of-the-silk-road.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-history-and-significance-of-the-silk-road.txt",
    "genre": "History"
  },
  {
    "id": 529,
    "title": "The History of Comic Books",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-history-of-comic-books.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-history-of-comic-books.txt",
    "genre": "Arts & Humanities"
  },
  {
    "id": 530,
    "title": "The History of Cryptocurrencies",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-history-of-cryptocurrencies.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-history-of-cryptocurrencies.txt",
    "genre": "Business & Finance"
  },
  {
    "id": 531,
    "title": "The History of Cryptography",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-history-of-cryptography.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-history-of-cryptography.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 532,
    "title": "The History of Espionage",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-history-of-espionage.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-history-of-espionage.txt",
    "genre": "History"
  },
  {
    "id": 533,
    "title": "The History of Human Rights",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-history-of-human-rights.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-history-of-human-rights.txt",
    "genre": "History"
  },
  {
    "id": 534,
    "title": "The History of Public Health",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-history-of-public-health.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-history-of-public-health.txt",
    "genre": "Health & Wellness"
  },
  {
    "id": 535,
    "title": "The History of Quantum Physics",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-history-of-quantum-physics.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-history-of-quantum-physics.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 536,
    "title": "The History of Space Colonization Concepts",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-history-of-space-colonization-concepts.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-history-of-space-colonization-concepts.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 537,
    "title": "The History of Space Exploration",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-history-of-space-exploration.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-history-of-space-exploration.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 538,
    "title": "The History of Space Stations",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-history-of-space-stations.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-history-of-space-stations.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 539,
    "title": "The History of Space Telescopes",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-history-of-space-telescopes.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-history-of-space-telescopes.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 540,
    "title": "The History of the Internet",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-history-of-the-internet.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-history-of-the-internet.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 541,
    "title": "The History of the Olympics Games",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-history-of-the-olympics-games.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-history-of-the-olympics-games.txt",
    "genre": "History"
  },
  {
    "id": 542,
    "title": "The History of the Printing Press",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-history-of-the-printing-press.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-history-of-the-printing-press.txt",
    "genre": "Arts & Humanities"
  },
  {
    "id": 543,
    "title": "The History of Vaccination",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-history-of-vaccination.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-history-of-vaccination.txt",
    "genre": "Health & Wellness"
  },
  {
    "id": 544,
    "title": "The Human Brain",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-human-brain.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-human-brain.txt",
    "genre": "Health & Wellness"
  },
  {
    "id": 545,
    "title": "The Human Genome Project",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-human-genome-project.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-human-genome-project.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 546,
    "title": "The Human Immune System",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-human-immune-system.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-human-immune-system.txt",
    "genre": "Health & Wellness"
  },
  {
    "id": 547,
    "title": "The Impact of Artificial Intelligence on Art",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-impact-of-artificial-intelligence-on-art.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-impact-of-artificial-intelligence-on-art.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 548,
    "title": "The Impact of Artificial Intelligence on Education",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-impact-of-artificial-intelligence-on-education.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-impact-of-artificial-intelligence-on-education.txt",
    "genre": "Education & Learning"
  },
  {
    "id": 549,
    "title": "The Impact of Artificial Intelligence on Employment",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-impact-of-artificial-intelligence-on-employment.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-impact-of-artificial-intelligence-on-employment.txt",
    "genre": "Business & Finance"
  },
  {
    "id": 550,
    "title": "The Impact of Big Data on Healthcare",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-impact-of-big-data-on-healthcare.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-impact-of-big-data-on-healthcare.txt",
    "genre": "Health & Wellness"
  },
  {
    "id": 551,
    "title": "The Impact of Global Trade on Developing Economies",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-impact-of-global-trade-on-developing-economies.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-impact-of-global-trade-on-developing-economies.txt",
    "genre": "Business & Finance"
  },
  {
    "id": 552,
    "title": "The Impact of Globalization on Culture",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-impact-of-globalization-on-culture.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-impact-of-globalization-on-culture.txt",
    "genre": "Arts & Humanities"
  },
  {
    "id": 553,
    "title": "The Impact of Media on Public Perception",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-impact-of-media-on-public-perception.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-impact-of-media-on-public-perception.txt",
    "genre": "Arts & Humanities"
  },
  {
    "id": 554,
    "title": "The Impact of Social Media on Mental Health",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-impact-of-social-media-on-mental-health.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-impact-of-social-media-on-mental-health.txt",
    "genre": "Health & Wellness"
  },
  {
    "id": 555,
    "title": "The Impact of Social Media on Politics",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-impact-of-social-media-on-politics.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-impact-of-social-media-on-politics.txt",
    "genre": "Political Science & International Relations"
  },
  {
    "id": 556,
    "title": "The Impact of Social Media on Society",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-impact-of-social-media-on-society.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-impact-of-social-media-on-society.txt",
    "genre": "Social & Political"
  },
  {
    "id": 557,
    "title": "The Impact of Space Weather on Earth",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-impact-of-space-weather-on-earth.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-impact-of-space-weather-on-earth.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 558,
    "title": "The Industrial Revolution",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-industrial-revolution.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-industrial-revolution.txt",
    "genre": "History"
  },
  {
    "id": 559,
    "title": "The Influence of Ancient Greek Philosophy on Modern Thought",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-influence-of-ancient-greek-philosophy-on-modern-thought.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-influence-of-ancient-greek-philosophy-on-modern-thought.txt",
    "genre": "Philosophy"
  },
  {
    "id": 560,
    "title": "The Influence of Renaissance Humanism",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-influence-of-renaissance-humanism.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-influence-of-renaissance-humanism.txt",
    "genre": "Philosophy"
  },
  {
    "id": 561,
    "title": "The Internet of Things (IoT) and Smart Cities",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-internet-of-things-IoT-and-smart-cities.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-internet-of-things-IoT-and-smart-cities.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 562,
    "title": "The Internet of Things",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-internet-of-things.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-internet-of-things.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 563,
    "title": "The Mathematics of Infinity",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-mathematics-of-infinity.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-mathematics-of-infinity.txt",
    "genre": "Science & Mathematics"
  },
  {
    "id": 564,
    "title": "The Neuroscience of Creativity",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-neuroscience-of-creativity.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-neuroscience-of-creativity.txt",
    "genre": "Psychology"
  },
  {
    "id": 565,
    "title": "The Neuroscience of Sleep",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-neuroscience-of-sleep.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-neuroscience-of-sleep.txt",
    "genre": "Psychology"
  },
  {
    "id": 566,
    "title": "The Ottoman Empire",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-ottoman-empire.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-ottoman-empire.txt",
    "genre": "History"
  },
  {
    "id": 567,
    "title": "The Philosophy of Existentialism",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-philosophy-of-existentialism.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-philosophy-of-existentialism.txt",
    "genre": "Philosophy"
  },
  {
    "id": 568,
    "title": "The Philosophy of Language",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-philosophy-of-language.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-philosophy-of-language.txt",
    "genre": "Philosophy"
  },
  {
    "id": 569,
    "title": "The Philosophy of Minimalism",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-philosophy-of-minimalism.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-philosophy-of-minimalism.txt",
    "genre": "Philosophy"
  },
  {
    "id": 570,
    "title": "The Philosophy of Science: Understanding the Scientific Method",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-philosophy-of-science-understanding-the-scientific-method.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-philosophy-of-science-understanding-the-scientific-method.txt",
    "genre": "Philosophy"
  },
  {
    "id": 571,
    "title": "The Philosophy of Science",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-philosophy-of-science.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-philosophy-of-science.txt",
    "genre": "Philosophy"
  },
  {
    "id": 572,
    "title": "The Philosophy of Time",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-philosophy-of-time.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-philosophy-of-time.txt",
    "genre": "Philosophy"
  },
  {
    "id": 573,
    "title": "The Physics of Quantum Entanglement",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-physics-of-quantum-entanglement.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-physics-of-quantum-entanglement.txt",
    "genre": "Science & Mathematics"
  },
  {
    "id": 574,
    "title": "The Physics of Sound",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-physics-of-sound.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-physics-of-sound.txt",
    "genre": "Science & Mathematics"
  },
  {
    "id": 575,
    "title": "The Potential of Vertical Farming",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-potential-of-vertical-farming.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-potential-of-vertical-farming.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 576,
    "title": "The Principles of Democracy",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-principles-of-democracy.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-principles-of-democracy.txt",
    "genre": "Political Science & International Relations"
  },
  {
    "id": 577,
    "title": "The Psychology of Color",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-psychology-of-color.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-psychology-of-color.txt",
    "genre": "Psychology"
  },
  {
    "id": 578,
    "title": "The Psychology of Creativity",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-psychology-of-creativity.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-psychology-of-creativity.txt",
    "genre": "Psychology"
  },
  {
    "id": 579,
    "title": "The Psychology of Forgiveness",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-psychology-of-forgiveness.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-psychology-of-forgiveness.txt",
    "genre": "Psychology"
  },
  {
    "id": 580,
    "title": "The Psychology of Humor",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-psychology-of-humor.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-psychology-of-humor.txt",
    "genre": "Psychology"
  },
  {
    "id": 581,
    "title": "The Psychology of Learning",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-psychology-of-learning.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-psychology-of-learning.txt",
    "genre": "Psychology"
  },
  {
    "id": 582,
    "title": "The Psychology of Memory",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-psychology-of-memory.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-psychology-of-memory.txt",
    "genre": "Psychology"
  },
  {
    "id": 583,
    "title": "The Psychology of Motivation",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-psychology-of-motivation.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-psychology-of-motivation.txt",
    "genre": "Psychology"
  },
  {
    "id": 584,
    "title": "The Psychology of Procrastination",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-psychology-of-procrastination.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-psychology-of-procrastination.txt",
    "genre": "Psychology"
  },
  {
    "id": 585,
    "title": "The Renaissance in Art",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-renaissance-in-art.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-renaissance-in-art.txt",
    "genre": "Arts & Humanities"
  },
  {
    "id": 586,
    "title": "The Renaissance in Science",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-renaissance-in-science.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-renaissance-in-science.txt",
    "genre": "History"
  },
  {
    "id": 587,
    "title": "The Renaissance",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-renaissance.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-renaissance.txt",
    "genre": "History"
  },
  {
    "id": 588,
    "title": "The Renewable Energy Transition",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-renewable-energy-transition.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-renewable-energy-transition.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 589,
    "title": "The Rise of E-Commerce",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-rise-of-e-commerce.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-rise-of-e-commerce.txt",
    "genre": "Business & Finance"
  },
  {
    "id": 590,
    "title": "The Rise of Eco-Cities",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-rise-of-eco-cities.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-rise-of-eco-cities.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 591,
    "title": "The Role of Bees in Agriculture",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-role-of-bees-in-agriculture.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-role-of-bees-in-agriculture.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 592,
    "title": "The Role of Education in Society",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-role-of-education-in-society.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-role-of-education-in-society.txt",
    "genre": "Education & Learning"
  },
  {
    "id": 593,
    "title": "The Role of Forensic Science in Criminal Justice",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-role-of-forensic-science-in-criminal-justice.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-role-of-forensic-science-in-criminal-justice.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 594,
    "title": "The Role of Microorganisms in Human Health",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-role-of-microorganisms-in-human-health.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-role-of-microorganisms-in-human-health.txt",
    "genre": "Health & Wellness"
  },
  {
    "id": 595,
    "title": "The Role of Microorganisms in the Environment",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-role-of-microorganisms-in-the-environment.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-role-of-microorganisms-in-the-environment.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 596,
    "title": "The Role of Music in Human Culture",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-role-of-music-in-human-culture.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-role-of-music-in-human-culture.txt",
    "genre": "Arts & Humanities"
  },
  {
    "id": 597,
    "title": "The Role of Music Therapy in Healing",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-role-of-music-therapy-in-healing.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-role-of-music-therapy-in-healing.txt",
    "genre": "Health & Wellness"
  },
  {
    "id": 598,
    "title": "The Role of Philosophy in Science",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-role-of-philosophy-in-science.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-role-of-philosophy-in-science.txt",
    "genre": "Philosophy"
  },
  {
    "id": 599,
    "title": "The Role of Public Libraries in Modern Society",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-role-of-public-libraries-in-modern-society.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-role-of-public-libraries-in-modern-society.txt",
    "genre": "Education & Learning"
  },
  {
    "id": 600,
    "title": "The Role of Social Entrepreneurship",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-role-of-social-entrepreneurship.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-role-of-social-entrepreneurship.txt",
    "genre": "Business & Finance"
  },
  {
    "id": 601,
    "title": "The Role of Sports in Society",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-role-of-sports-in-society.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-role-of-sports-in-society.txt",
    "genre": "Social & Political"
  },
  {
    "id": 602,
    "title": "The Role of Women in Ancient Civilizations",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-role-of-women-in-ancient-civilizations.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-role-of-women-in-ancient-civilizations.txt",
    "genre": "History"
  },
  {
    "id": 603,
    "title": "The Role of Women in Science",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-role-of-women-in-science.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-role-of-women-in-science.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 604,
    "title": "The Science Behind Food Preservation",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-science-behind-food-preservation.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-science-behind-food-preservation.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 605,
    "title": "The Science of Climate Change",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-science-of-climate-change.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-science-of-climate-change.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 606,
    "title": "The Science of Fractals in Nature",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-science-of-fractals-in-nature.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-science-of-fractals-in-nature.txt",
    "genre": "Science & Mathematics"
  },
  {
    "id": 607,
    "title": "The Science of Habit Formation",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-science-of-habit-formation.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-science-of-habit-formation.txt",
    "genre": "Psychology"
  },
  {
    "id": 608,
    "title": "The Science of Happiness: What Makes Us Happy?",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-science-of-happiness-what-makes-us-happy.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-science-of-happiness-what-makes-us-happy.txt",
    "genre": "Psychology"
  },
  {
    "id": 609,
    "title": "The Science of Happiness",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-science-of-happiness.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-science-of-happiness.txt",
    "genre": "Psychology"
  },
  {
    "id": 610,
    "title": "The Science of Holography",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-science-of-holography.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-science-of-holography.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 611,
    "title": "The Science of Hydrology",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-science-of-hydrology.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-science-of-hydrology.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 612,
    "title": "The Science of Memory and Forgetting",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-science-of-memory-and-forgetting.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-science-of-memory-and-forgetting.txt",
    "genre": "Psychology"
  },
  {
    "id": 613,
    "title": "The Science of Ocean Currents",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-science-of-ocean-currents.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-science-of-ocean-currents.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 614,
    "title": "The Science of Sleep and Dreams",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-science-of-sleep-and-dreams.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-science-of-sleep-and-dreams.txt",
    "genre": "Psychology"
  },
  {
    "id": 615,
    "title": "The Science of Sleep",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-science-of-sleep.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-science-of-sleep.txt",
    "genre": "Psychology"
  },
  {
    "id": 616,
    "title": "The Significance of Rituals in Human Culture",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-significance-of-rituals-in-human-culture.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-significance-of-rituals-in-human-culture.txt",
    "genre": "Arts & Humanities"
  },
  {
    "id": 617,
    "title": "The Silk Road",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-silk-road.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-silk-road.txt",
    "genre": "History"
  },
  {
    "id": 618,
    "title": "The Sociology of Fashion",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-sociology-of-fashion.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-sociology-of-fashion.txt",
    "genre": "Arts & Humanities"
  },
  {
    "id": 619,
    "title": "The Space Economy",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-space-economy.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-space-economy.txt",
    "genre": "Business & Finance"
  },
  {
    "id": 620,
    "title": "The Space Race",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-space-race.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-space-race.txt",
    "genre": "History"
  },
  {
    "id": 621,
    "title": "The Space Shuttle Program",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-space-shuttle-program.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-space-shuttle-program.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 622,
    "title": "The Theory of Evolution: Understanding Life's Diversity",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-theory-of-evolution-understanding-lifes-diversity.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-theory-of-evolution-understanding-lifes-diversity.txt",
    "genre": "Science & Mathematics"
  },
  {
    "id": 623,
    "title": "The Theory of Evolution",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-theory-of-evolution.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-theory-of-evolution.txt",
    "genre": "Science & Mathematics"
  },
  {
    "id": 624,
    "title": "The Theory of Relativity",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-theory-of-relativity.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-theory-of-relativity.txt",
    "genre": "Science & Mathematics"
  },
  {
    "id": 625,
    "title": "The United Nations",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-united-nations.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/the-united-nations.txt",
    "genre": "Political Science & International Relations"
  },
  {
    "id": 626,
    "title": "Timor-Leste (East Timor)",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/timor-leste-(east-timor).png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/timor-leste-(east-timor).txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 627,
    "title": "Togo",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/togo.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/togo.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 628,
    "title": "Tonga",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/tonga.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/tonga.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 629,
    "title": "Topology: The Mathematics of Shapes and Spaces",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/topology-the-mathematics-of-shapes-and-spaces.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/topology-the-mathematics-of-shapes-and-spaces.txt",
    "genre": "Science & Mathematics"
  },
  {
    "id": 630,
    "title": "Topology: The Study of Spatial Properties",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/topology-the-study-of-spatial-properties.png",
    "txt": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/topology-the-study-of-spatial-properties.txt",
    "genre": "Science & Mathematics"
  },
  {
    "id": 631,
    "title": "Totalitarianism: The Absolute Control of the State",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/totalitarianism-the-absolute-control-of-the-state.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/totalitarianism-the-absolute-control-of-the-state.txt",
    "genre": "Political Science & International Relations"
  },
  {
    "id": 632,
    "title": "Transcendentalism: The Philosophy of the Spirit",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/transcendentalism-the-philosophy-of-the-spirit.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/transcendentalism-the-philosophy-of-the-spirit.txt",
    "genre": "Philosophy"
  },
  {
    "id": 633,
    "title": "Trinidad and Tobago",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/trinidad-and-tobago.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/trinidad-and-tobago.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 634,
    "title": "Tunisia",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/tunisia.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/tunisia.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 635,
    "title": "Turkey",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/turkey.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/turkey.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 636,
    "title": "Tuvalu",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/tuvalu.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/tuvalu.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 637,
    "title": "Ukraine-Russia Conflict: Diplomatic Negotiations and Security Guarantees",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/ukraine-russia-conflict-diplomatic-negotiations-and-security-guarantees.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/ukraine-russia-conflict-diplomatic-negotiations-and-security-guarantees.txt",
    "genre": "Political Science & International Relations"
  },
  {
    "id": 638,
    "title": "Ukraine",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/ukraine.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/ukraine.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 639,
    "title": "Universal Access to Clean Water and Sanitation",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/universal-access-to-clean-water-and-sanitation.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/universal-access-to-clean-water-and-sanitation.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 640,
    "title": "Urban Farming: Rethinking Food Production in Cities",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/urban-farming-rethinking-food-production-in-cities.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/urban-farming-rethinking-food-production-in-cities.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 641,
    "title": "Urban Farming",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/urban-farming.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/urban-farming.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 642,
    "title": "Urban Planning and the Future of Cities",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/urban-planning-and-the-future-of-cities.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/urban-planning-and-the-future-of-cities.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 643,
    "title": "Uruguay",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/uruguay.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/uruguay.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 644,
    "title": "Utilitarianism: The Philosophy of Utility",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/utilitarianism-the-philosophy-of-utility.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/utilitarianism-the-philosophy-of-utility.txt",
    "genre": "Philosophy"
  },
  {
    "id": 645,
    "title": "Uzbekistan",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/uzbekistan.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/uzbekistan.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 646,
    "title": "Vanuatu",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/vanuatu.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/vanuatu.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 647,
    "title": "Vatican City",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/vatican-city.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/vatican-city.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 648,
    "title": "Venezuela",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/venezuela.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/venezuela.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 649,
    "title": "Venture Capital: Fueling Innovation and Startups",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/venture-capital-fueling-innovation-and-startups.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/venture-capital-fueling-innovation-and-startups.txt",
    "genre": "Business & Finance"
  },
  {
    "id": 650,
    "title": "Vertical Farming for Sustainable Food Production",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/vertical-farming-for-sustainable-food-production.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/vertical-farming-for-sustainable-food-production.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 651,
    "title": "Vietnam",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/vietnam.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/vietnam.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 652,
    "title": "Viktor Frankl: The Founder of Logotherapy",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/viktor-frankl-the-founder-of-logotherapy.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/viktor-frankl-the-founder-of-logotherapy.txt",
    "genre": "Psychology"
  },
  {
    "id": 653,
    "title": "Virtual Reality and Human Experience",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/virtual-reality-and-human-experience.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/virtual-reality-and-human-experience.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 654,
    "title": "Virtual Reality",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/virtual-reality.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/virtual-reality.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 655,
    "title": "Volunteer and Give Back",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/volunteer-and-give-back.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/volunteer-and-give-back.txt",
    "genre": "Self-Help & Personal Development"
  },
  {
    "id": 656,
    "title": "Wangari Maathai: Environmentalist and Nobel Laureate",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/wangari-maathai-environmentalist-and-nobel-laureate.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/wangari-maathai-environmentalist-and-nobel-laureate.txt",
    "genre": "Environmental Science"
  },
  {
    "id": 657,
    "title": "Warren Buffett: The Oracle of Omaha",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/warren-buffett-the-oracle-of-omaha.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/warren-buffett-the-oracle-of-omaha.txt",
    "genre": "Business & Finance"
  },
  {
    "id": 658,
    "title": "Wearable Technology and Personal Health",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/wearable-technology-and-personal-health.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/wearable-technology-and-personal-health.txt",
    "genre": "Health & Wellness"
  },
  {
    "id": 659,
    "title": "Wearable Technology",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/wearable-technology.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/wearable-technology.txt",
    "genre": "Science & Technology"
  },
  {
    "id": 660,
    "title": "Winston Churchill: The Wartime Leader of Britain",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/winston-churchill-the-wartime-leader-of-britain.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/winston-churchill-the-wartime-leader-of-britain.txt",
    "genre": "History"
  },
  {
    "id": 661,
    "title": "Yemen Civil War: Humanitarian Ceasefire and Peace Talks",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/yemen-civil-war-humanitarian-ceasefire-and-peace-talks.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/yemen-civil-war-humanitarian-ceasefire-and-peace-talks.txt",
    "genre": "Political Science & International Relations"
  },
  {
    "id": 662,
    "title": "Yemen",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/yemen.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/yemen.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 663,
    "title": "Zambia",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/zambia.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/zambia.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 664,
    "title": "Zimbabwe",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/zimbabwe.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/zimbabwe.txt",
    "genre": "Geography & World Nations"
  },
  {
    "id": 665,
    "title": "Zoroaster (Zarathustra): The Prophet of Zoroastrianism",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/zoroaster-zarathustra-the-prophet-of-zoroastrianism.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/zoroaster-zarathustra-the-prophet-of-zoroastrianism.txt",
    "genre": "Religion & Spirituality"
  },
  {
    "id": 666,
    "title": "Zoroastrianism: The Religion of Fire and Light",
    "image": "https://my-lion-assets.s3.eu-north-1.amazonaws.com/zoroastrianism-the-religion-of-fire-and-light.png",
    "txt":   "https://my-lion-assets.s3.eu-north-1.amazonaws.com/zoroastrianism-the-religion-of-fire-and-light.txt",
    "genre": "Religion & Spirituality"
  },
      ],

      searchQuery: '',
      genres: [
        'All',
        'Science & Technology',
        'History',
        'Psychology',
        'Philosophy',
        'Political Science & Economics',
        'Business & Finance',
        'Geography & World Nations',
        'Science & Mathematics',
        'Religion & Spirituality',
        'Ethics & Technology',
        'Environmental Science',
        'Arts & Humanities',
        'Health & Wellness',
        'Education & Learning',
        'Ethics & Philosophy',
        'Self-Help & Personal Development',
        'Political Science & International Relations',
      ],
      selectedGenre: 'All',
    };
  },
  computed: {
    filteredArticles() {
      const query = this.searchQuery.toLowerCase();
      const uniqueTitles = new Set();

      return this.articles.filter((article) => {
        const genreString = article.genre || '';
        const articleGenres = genreString.split(', ');
        const matchesQuery = article.title.toLowerCase().includes(query);
        const matchesGenre =
          this.selectedGenre === 'All' || articleGenres.includes(this.selectedGenre);
        const isUnique = !uniqueTitles.has(article.title);

        if (matchesQuery && matchesGenre && isUnique) {
          uniqueTitles.add(article.title);
          return true;
        }
        return false;
      });
    },
  },
  methods: {
    goToResources() {
      this.$router.push({ name: 'resources' });
    },
    saveSearchQuery() {
      localStorage.setItem('articleSearchQuery', this.searchQuery);
    },
    getSearchQuery() {
      return localStorage.getItem('articleSearchQuery') || '';
    },
    saveScrollPosition() {
      if (this.$refs.container) {
        localStorage.setItem(
          'scrollPosition_ArticlesPage',
          this.$refs.container.scrollTop
        );
      }
    },
    restoreScrollPosition() {
      const position = localStorage.getItem('scrollPosition_ArticlesPage');
      this.$nextTick(() => {
        if (this.$refs.container && position) {
          this.$refs.container.scrollTop = parseInt(position, 10);
        }
      });
    },
    filterByGenre(genre) {
      this.selectedGenre = genre;
      localStorage.setItem('selectedGenre_ArticlesPage', genre);
    },
  },
  mounted() {
    // Restore previous search query + genre
    this.searchQuery = this.getSearchQuery();
    const savedGenre = localStorage.getItem('selectedGenre_ArticlesPage');
    if (savedGenre) {
      this.selectedGenre = savedGenre;
    }

    // Restore scroll
    this.restoreScrollPosition();

    // Listen for scroll + pagehide
    if (this.$refs.container) {
      this.$refs.container.addEventListener('scroll', this.saveScrollPosition);
    }
    window.addEventListener('pagehide', this.saveScrollPosition);
  },
  beforeRouteLeave(to, from, next) {
    this.saveScrollPosition();
    this.saveSearchQuery();
    localStorage.setItem('selectedGenre_ArticlesPage', this.selectedGenre);
    next();
  },
  beforeDestroy() {
    if (this.$refs.container) {
      this.$refs.container.removeEventListener('scroll', this.saveScrollPosition);
    }
    window.removeEventListener('pagehide', this.saveScrollPosition);
  },
};
</script>

<style>
/* ========== GLOBAL SCROLLBAR STYLES ========== */
::-webkit-scrollbar {
  width: 10px;
  background-color: #f0f0f0;
}
::-webkit-scrollbar-track {
  background-color: #e6e6e6;
}
::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
  border: 2px solid #e6e6e6;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
html {
  scrollbar-width: thin;
  scrollbar-color: #888 #e6e6e6;
}
/* ============================================ */
</style>

<style scoped>
.container {
  position: absolute;
  top: 80px; /* top offset matching 80px black header */
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  background-color: #fafafa;
  font-family: "Helvetica Neue", Arial, sans-serif;
}

.content h1 {
  font-size: 1.8rem;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
  font-weight: 600;
}

input[type='text'] {
  width: 100%;
  max-width: 600px;
  padding: 10px;
  margin-bottom: 20px;
  box-sizing: border-box;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s ease;
}
input[type='text']:focus {
  outline: none;
  border-color: #333;
}

.genres-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
}

.genre-button {
  margin: 5px;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  background: linear-gradient(135deg, #444444, #2c2c2c);
  color: #ffffff;
  cursor: pointer;
  font-size: 0.95rem;
  transition: transform 0.3s, box-shadow 0.3s;
}
.genre-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.active-genre {
  background: linear-gradient(135deg, #f39c12, #e74c3c) !important;
  transform: scale(1.1) !important;
  box-shadow: 0 8px 20px rgba(255, 130, 0, 0.4) !important;
  color: #fff !important;
  font-weight: 600;
}

.articles-list {
  list-style: none;
  padding: 0;
  width: 100%;
  text-align: center;
}

.article-item {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  transition: background-color 0.3s;
}
.article-item:hover {
  background-color: #f0f0f0;
}

.article-item a {
  text-decoration: none;
  color: #333;
  font-weight: 500;
  transition: color 0.3s;
}
.article-item a:hover {
  color: #555;
}
</style>
