<template>
  <div>
    <!-- 1) Use your Header component -->
    <!--    We show a back button labeled "Get More Resources" -->
    <Header
      :showBackButton="true"
      backText="Get More Resources"
      @back="goToResources"
    />

    <!-- 2) Same .container code as in ResourcesPage.vue (or your standard container) -->
    <div class="container">
      <h1>Games</h1>
      <!-- 
        3) The list of games in a simple vertical layout. 
           If you prefer a grid, replicate the approach from .resources-grid or .videos-grid.
      -->
      <div
        v-for="(game) in games"
        :key="game.id"
        class="game-box"
        @click="goToGameDetails(game.id)"
      >
        {{ game.title }}
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";

export default {
  name: "GamesPage",
  components: {
    Header,
  },
  data() {
    return {
      games: [
        { id: 1, title: "Quiz" },
        { id: 3, title: "Hangman" },
        { id: 11, title: "Trivia Timer" },
        { id: 12, title: "Math Game" },
      ],
    };
  },
  methods: {
    // Called when user clicks the "Get More Resources" back button in the header
    goToResources() {
      this.$router.push({ name: "resources" });
    },
    // Opens the details page for a given game
    goToGameDetails(gameId) {
      this.$router.push({ name: "game-details", params: { gameId } });
    },
  },
};
</script>

<style scoped>
/* --------------------------------------------------
   1) EXACT SAME .container code from ResourcesPage.vue
   -------------------------------------------------- */
.container {
  margin-top: 60px;           /* The pinned header is 60px tall */
  height: calc(100vh - 60px); /* Fill the remaining viewport height */
  overflow-y: auto;           /* Enable vertical scrolling */

  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 20px;
}

/* Title for the page */
h1 {
  font-size: 1.8rem;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

/* 
  A simple vertical list of games 
  (similar to .video-item or .resource-box) 
*/
.game-box {
  background: linear-gradient(135deg, #444444, #2c2c2c);
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 20px;
  cursor: pointer;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s, background-color 0.3s;

  width: 100%;
  max-width: 200px;
  margin: 20px 0;
}

.game-box:hover {
  background-color: #555;
  transform: translateY(-3px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}
</style>
