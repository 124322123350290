import { createRouter, createWebHistory } from 'vue-router';

/* Existing imports */
import ResourcesPage from '@/components/ResourcesPage.vue';
import BooksPage from '@/components/BooksPage.vue';
import BookDetails from '@/components/BookDetails.vue';
import SimplerBookDetails from '@/components/SimplerBookDetails.vue';
import ArticlesPage from '@/components/ArticlesPage.vue';
import ArticleDetails from '@/components/ArticleDetails.vue';
import DonatePage from '@/components/DonatePage.vue';
import NewsPage from '@/components/NewsPage.vue';
import ResearchPage from '@/components/ResearchPage.vue';
import ResearchDetails from '@/components/ResearchDetails.vue';
import ChooseActionPage from '@/components/ChooseActionPage.vue';
import TestPage from '@/components/TestPage.vue';
import AboutResuvoniaPage from '@/components/AboutResuvoniaPage.vue';
import YourText from '@/components/YourText.vue';

/* Games */
import GamesPage from '@/components/GamesPage.vue';
import GameDetails from '@/components/GameDetails.vue';

/* NEW: VideosPage */
import VideosPage from '@/components/VideosPage.vue';

const routes = [
  // Home => Resources
  { path: '/', name: 'resources', component: ResourcesPage },

  // Books
  { path: '/books', name: 'books', component: BooksPage },
  {
    path: '/books/:bookId',
    name: 'book-details',
    component: BookDetails,
    props: true,
  },
  {
    path: '/simpler-book-details/:bookId',
    name: 'simpler-book-details',
    component: SimplerBookDetails,
    props: true,
  },

  // Articles
  { path: '/articles', name: 'articles', component: ArticlesPage },
  {
    path: '/articles/:articleId',
    name: 'article-details',
    component: ArticleDetails,
    props: true,
  },

  // Donate
  { path: '/donate', name: 'donate', component: DonatePage },

  // News
  // OPTIONAL: If you want /news => default to e.g. /news/1, do:
  // { path: '/news', redirect: '/news/1' },
  // Or remove this if you don't need a redirect.
  {
    path: '/news/:newsId',
    name: 'news-page',
    component: NewsPage,
    props: true,
  },

  // Research
  { path: '/research', name: 'research', component: ResearchPage },
  {
    path: '/research/:studyId',
    name: 'research-details',
    component: ResearchDetails,
    props: true,
  },

  // Choose Action => universal
  {
    path: '/choose-action/:topicType/:topicId/:topicName',
    name: 'choose-action',
    component: ChooseActionPage,
    props: true,
  },
  {
    path: '/choose-action',
    name: 'choose-action-base',
    component: ChooseActionPage,
  },

  // Test => used for news, articles, research, etc.
  {
    path: '/test/:topicType/:topicId/:topicName',
    name: 'test-page',
    component: TestPage,
    props: true,
  },

  // Games => main listing
  { path: '/games', name: 'games', component: GamesPage },
  {
    path: '/games/:gameId',
    name: 'game-details',
    component: GameDetails,
    props: true,
  },

  // About Resuvonia
  {
    path: '/about-resuvonia',
    name: 'about-resuvonia',
    component: AboutResuvoniaPage
  },

  // "Get Help With Everything"
  { path: '/your-text', name: 'your-text', component: YourText },

  // NEW: Videos route
  {
    path: '/videos',
    name: 'videos',
    component: VideosPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
