<template>
  <div>
    <!-- There's no pinned <Header> here in your snippet, 
         but let's keep the same approach with container below, 
         in case you do have the header somewhere. 
    -->
    <div class="trivia-container">
      <h1 class="title">Trivia Timer</h1>

      <!-- If gameOver => show final score & button -->
      <div v-if="gameOver" class="game-over">
        <p>Game Over! Your Score: {{ score }}</p>
        <button class="action-button" @click="startQuiz">Play Again</button>
      </div>

      <!-- Otherwise => show question, timer, options, etc. -->
      <div v-else class="trivia-content">
        <p class="question-status">
          Question {{ currentQuestionIndex + 1 }} of {{ questions.length }}
        </p>
        <p class="timer-text">Time Left: {{ timer }}s</p>

        <h2 class="question-text">{{ currentQuestion.question }}</h2>
        <ul class="options">
          <li
            v-for="(opt, i) in currentQuestion.options"
            :key="i"
            class="option"
            @click="selectAnswer(i)"
          >
            {{ opt }}
          </li>
        </ul>

        <p class="score-display">Score: {{ score }}</p>
      </div>

      <!-- Leaderboard => local results stored in localStorage -->
      <div class="leaderboard">
        <h3>Leaderboard</h3>
        <ol>
          <li v-for="(entry, i) in leaderboard" :key="i">
            {{ entry.name }} - {{ entry.points }}
          </li>
        </ol>
      </div>

      <!-- Start/Restart button if not gameOver -->
      <div class="controls" v-if="!gameOver">
        <button class="action-button" @click="startQuiz">Start / Restart</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TriviaTimer",
  data() {
    return {
      questions: [
        {
          question: "Which planet is known as the Red Planet?",
          options: ["Venus", "Mars", "Jupiter", "Saturn"],
          correctIndex: 1,
        },
        {
          question: "Who painted the Mona Lisa?",
          options: ["Van Gogh", "Picasso", "Da Vinci", "Michelangelo"],
          correctIndex: 2,
        },
        {
          question: "What is the largest mammal on Earth?",
          options: ["Elephant", "Blue Whale", "Giraffe", "Hippopotamus"],
          correctIndex: 1,
        },
      ],
      currentQuestionIndex: 0,
      timer: 20, // 20-second countdown
      score: 0,
      gameOver: false,
      timerInterval: null,
      leaderboard: [],
    };
  },
  computed: {
    currentQuestion() {
      return this.questions[this.currentQuestionIndex];
    },
  },
  methods: {
    startQuiz() {
      this.gameOver = false;
      this.score = 0;
      this.currentQuestionIndex = 0;
      this.loadLeaderboard();
      this.startTimer();
    },
    startTimer() {
      clearInterval(this.timerInterval);
      this.timer = 20;
      this.timerInterval = setInterval(() => {
        this.timer--;
        if (this.timer <= 0) {
          this.nextQuestion();
        }
      }, 1000);
    },
    selectAnswer(index) {
      if (this.gameOver) return;
      if (index === this.currentQuestion.correctIndex) {
        this.score++;
      }
      this.nextQuestion();
    },
    nextQuestion() {
      clearInterval(this.timerInterval);
      this.currentQuestionIndex++;
      if (this.currentQuestionIndex >= this.questions.length) {
        this.endQuiz();
      } else {
        this.startTimer();
      }
    },
    endQuiz() {
      this.gameOver = true;
      clearInterval(this.timerInterval);
      this.saveScore();
    },
    saveScore() {
      let name = prompt("Enter your name:") || "Anonymous";
      let newEntry = { name, points: this.score };
      this.leaderboard.push(newEntry);
      // Sort descending
      this.leaderboard.sort((a, b) => b.points - a.points);
      localStorage.setItem("triviaLeaderboard", JSON.stringify(this.leaderboard));
    },
    loadLeaderboard() {
      const stored = localStorage.getItem("triviaLeaderboard");
      this.leaderboard = stored ? JSON.parse(stored) : [];
    },
  },
  mounted() {
    this.loadLeaderboard();
  },
  beforeUnmount() {
    clearInterval(this.timerInterval);
  },
};
</script>

<style>
/* ================= GLOBAL SCROLLBAR STYLES ================= */
::-webkit-scrollbar {
  width: 10px;
  background-color: #f0f0f0;
}
::-webkit-scrollbar-track {
  background-color: #e6e6e6;
}
::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
  border: 2px solid #e6e6e6;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
html {
  scrollbar-width: thin;
  scrollbar-color: #888 #e6e6e6;
}
/* =========================================================== */
</style>

<style scoped>
.trivia-container {
  position: absolute; 
  top: 80px; 
  left: 0; 
  right: 0; 
  bottom: 0; 
  overflow: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  background: #fefefe;
  font-family: Arial, sans-serif;
  text-align: center;
  padding: 20px;
}

.title {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333;
}

.game-over {
  margin: 20px 0;
  font-size: 1.2rem;
  color: #333;
}

.trivia-content {
  margin-bottom: 20px;
}

.question-status {
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}
.timer-text {
  font-size: 1.2rem;
  color: #f33;
  margin-bottom: 10px;
}
.question-text {
  font-size: 1.3rem;
  margin: 20px 0;
}

.options {
  list-style: none;
  padding: 0;
  margin: 10px auto;
  max-width: 250px;
  width: 100%;
  text-align: center;
}

.option {
  background: #eee;
  margin: 5px 0;
  padding: 8px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
  text-align: center;
}
.option:hover {
  background-color: #ccc;
}

.score-display {
  margin-top: 10px;
  font-size: 1.1rem;
  color: #333;
  font-weight: bold;
}

.leaderboard {
  margin-top: 20px;
}
.leaderboard h3 {
  margin-bottom: 10px;
  font-size: 1.2rem;
  color: #444;
}
.leaderboard ol {
  margin: 0 auto;
  padding: 0;
  list-style-position: inside;
  display: inline-block;
  text-align: left;
  max-width: 250px;
}
.leaderboard li {
  margin: 0.2rem 0;
}

.controls {
  margin-top: 20px;
}

.action-button {
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 1rem;
  margin: 5px;
  transition: background-color 0.3s, transform 0.3s;
}
.action-button:hover {
  background-color: #555;
  transform: translateY(-2px);
}

@media (max-width: 600px) {
  .title {
    font-size: 1.6rem;
  }
  .timer-text {
    font-size: 1.1rem;
  }
  .option {
    font-size: 1rem;
    padding: 8px;
  }
}
</style>
