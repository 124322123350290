// src/axiosConfig.js
import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'https://shielded-plains-95107-e0431980d96a.herokuapp.com',
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 60000,
});

axiosInstance.interceptors.request.use(
  (config) => {
    // We'll only add 'Authorization' if request is for our Heroku domain
    // i.e. if config.url does NOT contain 'https://my-lion-assets.s3'
    // or any S3 domain.
    
    // Option 1: Check if config.url is relative or includes your Heroku domain
    // If the request is a full URL to S3, we'll skip the token.
    
    // If config.url is absolute, e.g. 'https://my-lion-assets.s3...'
    //   => skip
    // If config.url is relative or includes 'herokuapp' => add token
    
    // A simple check might be:
    const isHerokuRequest = !config.url.startsWith('https://my-lion-assets.s3.');

    if (isHerokuRequest) {
      const token = localStorage.getItem('jwtToken');
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default axiosInstance;
