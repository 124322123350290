<template>
  <div class="container">
    <!-- Header with back button -->
    <Header :showBackButton="true" backText="Get More Resources" @back="goBackToResources" />

    <!-- Main content area -->
    <div class="content">
      <h1 class="centered-title">Get Help With Everything</h1>
      <div class="centered-content">
        <p>
          Send your text to <strong>resuvonia@gmail.com</strong> to get an evaluation,
          simplification, or feedback on your text, and you will receive a free response
          as quickly as possible!
        </p>
        <p>
          If you have any other questions or need any services about anything, feel free
          to send a message, and you will also receive a free response as quickly as possible.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Header from './Header.vue';

export default {
  name: 'YourText',
  components: {
    Header,
  },
  methods: {
    goBackToResources() {
      // Navigate back to the Resources page
      this.$router.push({ name: 'resources' });
    },
  },
};
</script>

<style scoped>
/* 
  Flex container for full screen height, 
  ensuring the Header is at the top and .content fills the rest.
*/
.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

/* 
  .content remains scrollable. We center its contents 
  (both horizontally and vertically) using flex.
*/
.content {
  flex: 1;                  /* So .content fills all remaining space below Header */
  margin-top: 60px;         /* Space for the fixed header height */
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;  /* Center vertically */
  align-items: center;      /* Center horizontally */
  box-sizing: border-box;
  overflow-y: auto;

  /* Subtle gradient background for a refined look */
  background: linear-gradient(135deg, #f9f9f9, #f1f1f1);
  border-radius: 8px;

  /* Slightly stronger shadow for depth */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

/* Title styling, centered horizontally */
.centered-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.5rem;
  line-height: 1.2;
  font-family: 'Georgia', serif;
}

/* 
  .centered-content is displayed as normal text,
  remains centered with the above flex layout.
*/
.centered-content {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 1.2rem;
  text-align: center; /* text is centered */
  width: 100%;
  max-width: 800px;
  line-height: 1.5;
  font-family: 'Georgia', serif;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 600px) {
  .content {
    padding: 10px;
  }

  .centered-title {
    font-size: 1.25rem;
  }

  .centered-content {
    font-size: 1rem;
  }
}
</style>
