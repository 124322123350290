<template>
  <div class="donate-page">
    <Header
      :showBackButton="true"
      backText="Previous Page"
      @back="goBackToPreviousPage"
    />
    <div class="donate-content">
      <h1>Donate If You Want</h1>
      <div class="payment-options">
        <button @click="donateWithStripe" class="stripe-button">
          Donate with Stripe
        </button>
      </div>
      <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
    </div>
  </div>
</template>

<script>
import Header from './Header.vue';

export default {
  components: { Header },
  data() {
    return {
      errorMessage: '',
    };
  },
  methods: {
    donateWithStripe() {
      try {
        window.location.href = 'https://donate.stripe.com/14k5mt0zk7DXf1m9AA';
      } catch (error) {
        this.errorMessage =
          'There was an error with the donation process. Please try again.';
      }
    },
    goBackToPreviousPage() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.donate-page {
  /* Let #app do the scrolling. No forced overflow here. */
}

.donate-content {
  /* Start 70px below pinned header, to match the style of ResourcesPage. */
  margin-top: 70px;

  /* Enough height to center vertically if content is short */
  min-height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* vertically center */

  padding: 20px;
  box-sizing: border-box;
}

h1 {
  margin-bottom: 20px;
  font-size: 1.8rem;
  color: #333;
  text-align: center;
}

.payment-options {
  margin-top: 20px;
}

.stripe-button {
  background-color: #2c2c2c;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 14px;
  cursor: pointer;
  width: 100%;
  max-width: 400px;
  font-size: 16px;
  transition: background-color 0.3s ease, transform 0.3s ease,
    box-shadow 0.3s ease;
}
.stripe-button:hover {
  background-color: #444444;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.error-message {
  color: red;
  margin-top: 10px;
  font-weight: 500;
  text-align: center;
}
</style>
