<template>
  <div>
    <!-- Header with back button -->
    <Header
      :showBackButton="true"
      backText="Back to Choose Action"
      @back="goBackToChooseAction"
    />
    <!-- Container with overflow-y -->
    <div class="container" ref="container">
      <div class="content">
        <h1 class="centered-title">{{ newsItem.title }}</h1>
        <!-- Show .txt content in a <pre> -->
        <pre class="centered-content">{{ newsContent }}</pre>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "./Header.vue";

export default {
  name: "NewsPage",
  components: { Header },
  data() {
    return {
      // Fallback if no item is found
      newsItem: { id: 0, title: "No such news", txt: "" },
      newsContent: "",
    };
  },
  mounted() {
    // 1) Get the ID from route params => /news/:newsId
    const newsId = parseInt(this.$route.params.newsId, 10);
    console.log("[NewsPage] mounted. newsId =", newsId);

    // 2) Hardcoded array referencing your S3 bucket
    const allNews = [
      {
        id: 1,
        title: "January 5 2025",
        // This is the EXACT S3 link for january 5
        txt: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/5jan2025.txt",
      },
      {
        id: 2,
        title: "January 6 2025",
        // If you have a second file => e.g. "6jan2025.txt"
        txt: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/6jan2025.txt",
      },
      // Add more news items if you have them...
      // {
      //   id: 3,
      //   title: "January 7 2025",
      //   txt: "https://my-lion-assets.s3.eu-north-1.amazonaws.com/7jan2025.txt",
      // },
    ];

    // 3) Find the matching item
    const found = allNews.find(item => item.id === newsId);
    if (found) {
      this.newsItem = found; 
    } else {
      // fallback => "No such news"
      this.newsItem = { id: 0, title: "No such news", txt: "" };
    }

    // 4) If we do have a .txt link, fetch it
    if (this.newsItem.txt) {
      this.fetchNewsContent(this.newsItem.txt).then(() => {
        this.restoreScrollPosition();
      });
    }

    // Listen for scroll
    if (this.$refs.container) {
      this.$refs.container.addEventListener("scroll", this.saveScrollPosition);
    }
    window.addEventListener("pagehide", this.saveScrollPosition);
  },
  beforeDestroy() {
    if (this.$refs.container) {
      this.$refs.container.removeEventListener("scroll", this.saveScrollPosition);
    }
    window.removeEventListener("pagehide", this.saveScrollPosition);
  },
  methods: {
    async fetchNewsContent(url) {
      try {
        // Plain fetch => no extra interceptors
        const response = await fetch(encodeURI(url));
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        this.newsContent = await response.text();
      } catch (err) {
        console.error("Fetch error:", err);
        this.newsContent = "(Failed to load news text. Check your S3 link, policy, and CORS.)";
      }
    },
    goBackToChooseAction() {
      // Return to choose-action page
      this.$router.push({
        name: "choose-action",
        params: {
          topicType: "news",
          topicId: this.newsItem.id,
          topicName: this.newsItem.title,
        },
      });
    },
    saveScrollPosition() {
      if (this.$refs.container) {
        const scrollPosition = this.$refs.container.scrollTop;
        localStorage.setItem("scrollPosition_NewsPage", scrollPosition);
      }
    },
    restoreScrollPosition() {
      const pos = localStorage.getItem("scrollPosition_NewsPage");
      this.$nextTick(() => {
        if (this.$refs.container && pos) {
          this.$refs.container.scrollTop = parseInt(pos, 10);
        }
      });
    },
  },
};
</script>

<style>
/* ================= GLOBAL SCROLLBAR STYLES ================= */
::-webkit-scrollbar {
  width: 10px;
  background-color: #f0f0f0;
}
::-webkit-scrollbar-track {
  background-color: #e6e6e6;
}
::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
  border: 2px solid #e6e6e6;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
html {
  scrollbar-width: thin;
  scrollbar-color: #888 #e6e6e6;
}
/* =========================================================== */
</style>

<style scoped>
.container {
  position: absolute;
  top: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  font-family: "Helvetica Neue", Arial, sans-serif;
  background: linear-gradient(135deg, #ffffff, #f4f4f4);
}

.content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  background-color: #f7f7f7;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  min-height: 100%;
}

.centered-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.6rem;
  font-family: "Georgia", serif;
  color: #333;
  font-weight: 600;
}

.centered-content {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 1.2rem;
  text-align: justify;
  width: 100%;
  max-width: 800px;
  line-height: 1.6;
  font-family: "Georgia", serif;
  color: #333;
}

@media (max-width: 600px) {
  .content {
    padding: 10px;
  }
  .centered-title {
    font-size: 1.25rem;
  }
  .centered-content {
    font-size: 1rem;
    text-align: left;
  }
}
</style>
